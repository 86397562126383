import React from 'react';
import {
  Edit,
  SimpleForm,
  required,
  NumberInput,
  TextInput,
  regex,
  number,
  CardActions,
  Toolbar,
  SaveButton,
} from 'react-admin';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const ServiceEditActions = () => (
  <CardActions style={cardActionStyle}>
    {/* <EditButton basePath={basePath} record={data} /> */}
  </CardActions>
);

const ServiceEditTitle = ({ record }) => (
  <span>
    {record ? `Editar Empleado: ${record.id}` : ''}
  </span>
);

const EmpleadoEditToolBar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const VnoEmpleado = [required(), number('Debe contener solo numeros.')];
const Vnombres = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vpaterno = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vmaterno = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vpuesto = [required(), regex(/^[ñA-Za-z0-9 _]*[ñA-Za-z0-9][ñA-Za-z0-9 _]*$/i, 'Debe contener solo letras o numeros.')];
const Varea = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];

export const EmpleadoCorregidoEdit = props => (
  <Edit title={<ServiceEditTitle />} {...props} actions={<ServiceEditActions />}>
    <SimpleForm toolbar={<EmpleadoEditToolBar />}>
      <NumberInput
        label="No de Empleado"
        source="noEmpleado"
        validate={VnoEmpleado}
      />
      <TextInput
        label="Nombres"
        source="nombres"
        validate={Vnombres}
      />
      <TextInput
        label="Apellido Paterno"
        source="paterno"
        validate={Vpaterno}
      />
      <TextInput
        label="Apellido Materno"
        source="materno"
        validate={Vmaterno}
      />
      <TextInput
        label="Puesto"
        source="denominacionPosiciones"
        validate={Vpuesto}
      />
      <TextInput
        label="Area"
        source="denominacionUnidadOrganizacional"
        validate={Varea}
      />
    </SimpleForm>
  </Edit>
);

export { EmpleadoCorregidoEdit as default };
