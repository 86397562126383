import React from 'react';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  CardActions,
  CreateButton,
  RefreshButton,
  EditButton,
  FunctionField,
  downloadCSV,
  ExportButton,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporters = (record) => {
  const data = record;
  const csv = convertToCSV({
    data,
    // select and order fields in the export
    fields: ['telefono', 'area', 'empleadoId'],
  });
  downloadCSV(csv, 'celulares'); // download as 'empleados.csv` file
};

const ListActions = ({
  basePath, exporter, resource, total, currentSort, filterValues,
}) => (
  <CardActions>
    <CreateButton basePath={basePath} label="Capturar Celular" />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton />
  </CardActions>
);

const empleadoListRowStyle = record => ({
  backgroundColor: record.fuente === 2 ? 'white' : 'white',
});

export const CelularList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    title="Celulares de la sucursal"
    actions={<ListActions />}
    perPage={20}
    sort={{ field: 'telefono', order: 'DESC' }}
    exporter={exporters}
  >
    <Responsive
      medium={(
        <Datagrid rowStyle={empleadoListRowStyle}>
          <TextField source="telefono" label="Telefono" />
          <TextField source="area" label="area" />
          <FunctionField label="Empleado" render={record => ((record.Empleado) ? `${record.Empleado.paterno} ${record.Empleado.materno} ${record.Empleado.nombres} ` : null)} />
          <EditButton />
        </Datagrid>
)}
    />
  </List>
);

export default CelularList;
