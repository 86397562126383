import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import ValidacionDatos from './Dashboard/ValidacionDatos';
import CapturaDeDatos from './Dashboard/CapturaDeDatos';
import EstablecerMetas from './Dashboard/EstablecerMetas';
import MovimientosRecientes from './Dashboard/MovimientosRecientes';
import Reportes from './Dashboard/Reportes';
import URL from './url';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  titulos: { height: '60px', fontSize: '25px' },
  overlay: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 10,
    backgroundColor: 'grey',
    opacity: '0.5',
  },
  spinner: {
    position: 'fixed',
    left: '50%',
    right: '50%',
    top: '50%',
  },
};

const Loading = () => (
  <div style={styles.overlay}>
    <CircularProgress style={styles.spinner} color="secondary" />
  </div>
);

class OtroMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEmpresaValue: 'TODAS',
      selectSucursalValue: 'TODAS',
      dataPersonal: {},
      dataVehiculos: {},
      dataTelefonia: {},
      dataTotales: {},
      empresas: [],
      sucursales: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getPersonal();
    this.getVehiculos();
    this.getCelulares();
    this.getTotal();
    this.getEmpresas();
  }

  getPersonal = (empresa, sucursal) => {
    this.setState({ loading: true });
    if (!empresa && !sucursal) {
      fetch(`${URL()}/dashboard/personal/`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    } else {
      fetch(`${URL()}/dashboard/personal/${empresa}/${sucursal}`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    }
    this.setState({ loading: false });
  }

  getVehiculos = (empresa, sucursal) => {
    this.setState({ loading: true });
    if (!empresa && !sucursal) {
      fetch(`${URL()}/dashboard/vehiculos/`)
        .then(response => response.json())
        .then(dataVehiculos => this.setState({ dataVehiculos }));
    } else {
      fetch(`${URL()}/dashboard/vehiculos/${empresa}/${sucursal}`)
        .then(response => response.json())
        .then(dataVehiculos => this.setState({ dataVehiculos }));
    }
    this.setState({ loading: false });
  }

  getCelulares = (empresa, sucursal) => {
    this.setState({ loading: true });
    if (!empresa && !sucursal) {
      fetch(`${URL()}/dashboard/celulares/`)
        .then(response => response.json())
        .then(dataTelefonia => this.setState({ dataTelefonia }));
    } else {
      fetch(`${URL()}/dashboard/celulares/${empresa}/${sucursal}`)
        .then(response => response.json())
        .then(dataTelefonia => this.setState({ dataTelefonia }));
    }
    this.setState({ loading: false });
  }

  getTotal = (empresa, sucursal) => {
    this.setState({ loading: true });
    if (!empresa && !sucursal) {
      fetch(`${URL()}/dashboard/total/`)
        .then(response => response.json())
        .then(dataTotales => this.setState({ dataTotales }));
    } else {
      fetch(`${URL()}/dashboard/total/${empresa}/${sucursal}`)
        .then(response => response.json())
        .then(dataTotales => this.setState({ dataTotales }));
    }
    this.setState({ loading: false });
  }

  getEmpresas = () => {
    this.setState({ loading: true });
    fetch(`${URL()}/auth/empresas/`)
      .then(response => response.json())
      .then(empresas => this.setState({ empresas }));
    this.setState({ loading: false });
  }

  getSucursales = (empresa) => {
    this.setState({ loading: true });
    fetch(`${URL()}/auth/sucursales/${empresa}`)
      .then(response => response.json())
      .then(sucursales => this.setState({ sucursales }));
    this.setState({ loading: false });
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    const { selectEmpresaValue, selectSucursalValue } = this.state;
    this.getPersonal(selectEmpresaValue, selectSucursalValue);
    this.getVehiculos(selectEmpresaValue, selectSucursalValue);
    this.getCelulares(selectEmpresaValue, selectSucursalValue);
    this.getTotal(selectEmpresaValue, selectSucursalValue);
    this.setState({ loading: false });
  };

  handleChangeE = (event) => {
    this.setState({ selectEmpresaValue: event.target.value });
    this.getSucursales(event.target.value);
  };

  handleChangeS = (event) => {
    this.setState({ selectSucursalValue: event.target.value });
  };

  render() {
    const {
      dataPersonal,
      dataTelefonia,
      dataVehiculos,
      dataTotales,
      loading,
    } = this.state;
    const {
      totalEnSap,
      totalPersonalNoReconocido,
      totalPersonalEnSucursal,
      totalPersonalCapturado,
    } = dataPersonal;
    const {
      totalCelularesSucursal,
      totalCelularesPersonas,
      totalCelularesAreas,
    } = dataTelefonia;
    const {
      totalVehiculosActivos,
      totalActivosUtilitarios,
      totalActivosBlindados,
    } = dataVehiculos;
    const {
      totalTarjetasGasolina,
      totalVehiculosGasolina,
      totalVehiculosGas,
    } = dataTotales;
    return (
      <Fragment>
        { loading ? <Loading /> : null }
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol}>
              <ValidacionDatos
                totalEnSap={totalEnSap}
                totalNoReconocido={totalPersonalNoReconocido}
                totalPersonalCapturado={totalPersonalCapturado}
                totalPersonalEnSucursal={totalPersonalEnSucursal}
              />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.singleCol}>
              <CapturaDeDatos
                parqueVehicularActivos={totalVehiculosActivos}
                activosUtilitarios={totalActivosUtilitarios}
                activosBlindados={totalActivosBlindados}
              />
            </div>
          </div>
        </div>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol}>
              <EstablecerMetas
                totalCelulares={totalCelularesSucursal}
                asignadosPersonas={totalCelularesPersonas}
                asignadosDepartamentos={totalCelularesAreas}
              />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.singleCol}>
              <MovimientosRecientes
                totalTarjetasGasolina={totalTarjetasGasolina}
                totalVehiculosGasolina={totalVehiculosGasolina}
                totalVehiculosGas={totalVehiculosGas}
              />
            </div>
          </div>
        </div>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol}>
              <Reportes
                totalCelulares={totalCelularesSucursal}
                asignadosPersonas={totalCelularesPersonas}
                asignadosDepartamentos={totalCelularesAreas}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(connect(null, {
  showNotification,
  push,
})(OtroMenu));
