import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import IdeasIcon from '@material-ui/icons/QuestionAnswer';
import ValidacionIcon from '@material-ui/icons/Done';
import ReportesIcon from '@material-ui/icons/Assessment';
import MetasIcon from '@material-ui/icons/Assessment';
import ReportesFH from '@material-ui/icons/Streetview';
import { withRouter } from 'react-router-dom';
import {
  DashboardMenuItem,
  MenuItemLink,
  Responsive,
  WithPermissions,
} from 'react-admin';

import SubMenu from './SubMenu';

class Menu extends Component {
    state = {
      menuCargaInicial: false,
      menuValidacion: false,
      menuCaptura: false,
      menuMetas: false,
      menuReportes: false,
    };

    static propTypes = {
      onMenuClick: PropTypes.func,
      logout: PropTypes.object,
    };

    handleToggle = (menu) => {
      this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
      const TituloEmpresa = () => (
        <div>
          <div>
            {localStorage.getItem('empresa')}
          </div>
          <div>
            {(localStorage.getItem('sucursal') === 'ALL' ? 'TODAS' : localStorage.getItem('sucursal'))}
          </div>
        </div>
      );
      const {
        onMenuClick, open, logout,
      } = this.props;
      const {
        menuCargaInicial,
        menuValidacion,
        menuMetas,
        menuReportes, menuIdeas, menuFH, menuTesoreria, menuMovimientos, menuAltas, menuSueldos,
        menuBajas,
      } = this.state;
      return (
        <div>
          <TituloEmpresa />
          <DashboardMenuItem onClick={onMenuClick} />

          <WithPermissions
            render={({ permissions }) => (
              permissions >= '3'
                ? (
                  <MenuItemLink to="/dashmina" primaryText="Dashboard Por Sucursal" onClick={onMenuClick} />
                )
                : null
            )}
          />
          {/* <SubMenu
            handleToggle={() => this.handleToggle('menuIdeas')}
            isOpen={menuIdeas}
            sidebarIsOpen={open}
            name="Ideas"
            icon={<IdeasIcon />}
          >
            <MenuItemLink to="/ipersonal" primaryText="Personal" onClick={onMenuClick} />
            <MenuItemLink to="/iinversion" primaryText="Inversion" onClick={onMenuClick} />
            <MenuItemLink to="/igenerales" primaryText="Generales" onClick={onMenuClick} />
            <MenuItemLink to="/iviaje" primaryText="Viajes" onClick={onMenuClick} />
            <MenuItemLink to="/ioperativos" primaryText="Operativos" onClick={onMenuClick} />
            <MenuItemLink to="/icahorro" primaryText="Cultura de Ahorro" onClick={onMenuClick} />
            <MenuItemLink to="/inuevosp" primaryText="Nuevos Proyectos" onClick={onMenuClick} />
            <MenuItemLink to="/iportal" primaryText="Portal de Gastos" onClick={onMenuClick} />
          </SubMenu> */}

          <WithPermissions
            render={({ permissions }) => (
              permissions !== '1'
                ? (
                  <div>
                    <SubMenu
                      handleToggle={() => this.handleToggle('menuCargaInicial')}
                      isOpen={menuCargaInicial}
                      sidebarIsOpen={open}
                      name="Carga Inicial"
                      icon={<ValidacionIcon />}
                    >
                      <SubMenu
                        handleToggle={() => this.handleToggle('menuValidacion')}
                        isOpen={menuValidacion}
                        sidebarIsOpen={open}
                        name="1 Validacion de Datos"
                        icon={<ValidacionIcon />}
                      >
                        <MenuItemLink to="/epe" primaryText="Personal en SAP" onClick={onMenuClick} />
                        <MenuItemLink to="/ames" primaryText="Altas por Mes" onClick={onMenuClick} />
                        <MenuItemLink to="/bmes" primaryText="Bajas del Mes" onClick={onMenuClick} />
                        <MenuItemLink to="/sbajas" primaryText="Suspensiones" onClick={onMenuClick} />
                        {/* <MenuItemLink to="/epece" primaryText="Rechazados GSI" onClick={onMenuClick} /> */}
                      </SubMenu>
                      {/* <MenuItemLink to="/epe/create" primaryText="2 Empleados Faltantes" onClick={onMenuClick} /> */}
                      <MenuItemLink to="/tarjetasg" primaryText="3 Tarjetas Gasolina" onClick={onMenuClick} />
                      <MenuItemLink to="/vehiculos" primaryText="4 Parque Vehicular" onClick={onMenuClick} />
                      <MenuItemLink to="/celulares" primaryText="5 Telefonia Celular" onClick={onMenuClick} />
                      <MenuItemLink to="/gasolinasc/create" primaryText="6 Solicitud de Dictamen para Baja de Vehiculos" onClick={onMenuClick} />
                      <MenuItemLink to="/celularesc/create" primaryText="7 Baja de Celular" onClick={onMenuClick} />
                      {/* <MenuItemLink to="/cepe" primaryText="8 Empleados Disputados" onClick={onMenuClick} /> */}
                    </SubMenu>
                    <SubMenu
                      handleToggle={() => this.handleToggle('menuMetas')}
                      isOpen={menuMetas}
                      sidebarIsOpen={open}
                      name="Segunda Etapa"
                      icon={<MetasIcon />}
                    >
                      <SubMenu
                        handleToggle={() => this.handleToggle('menuMetas')}
                        isOpen={menuMetas}
                        sidebarIsOpen={open}
                        name="Establecimiento de Metas"
                        icon={<MetasIcon />}
                      >
                        <MenuItemLink to="/metac" primaryText="Metas Celulares" onClick={onMenuClick} />
                        <MenuItemLink to="/metag" primaryText="Metas Gasolinas" onClick={onMenuClick} />
                        <MenuItemLink to="/metae" primaryText="Metas Empleados" onClick={onMenuClick} />
                      </SubMenu>
                    </SubMenu>
                  </div>
                )
                : null
            )}
          />

          <WithPermissions
            render={({ permissions }) => (
              permissions !== '1'
                ? (
                  <div>
                    <SubMenu
                      handleToggle={() => this.handleToggle('menuMovimientos')}
                      isOpen={menuMovimientos}
                      sidebarIsOpen={open}
                      name="Movimientos"
                      icon={<ValidacionIcon />}
                    >
                      <SubMenu
                        handleToggle={() => this.handleToggle('menuAltas')}
                        isOpen={menuAltas}
                        sidebarIsOpen={open}
                        name="Altas"
                        icon={<ValidacionIcon />}
                      >
                        <MenuItemLink to="/ames" primaryText="Validacion de Altas" onClick={onMenuClick} />
                        <MenuItemLink to="/altasmesa" primaryText="Altas Aceptadas" onClick={onMenuClick} />
                        <MenuItemLink to="/altasmesr" primaryText="Altas Rechazadas" onClick={onMenuClick} />
                        <MenuItemLink to="/altasscb" primaryText="Altas Sin Cuenta" onClick={onMenuClick} />
                        <MenuItemLink to="/altasmesmov" primaryText="Altas por Movimiento" onClick={onMenuClick} />
                      </SubMenu>
                      <SubMenu
                        handleToggle={() => this.handleToggle('menuBajas')}
                        isOpen={menuBajas}
                        sidebarIsOpen={open}
                        name="Bajas"
                        icon={<ValidacionIcon />}
                      >
                        <MenuItemLink to="/bmes" primaryText="Bajas del Mes" onClick={onMenuClick} />
                        <MenuItemLink to="/bajasmesa" primaryText="Bajas Aceptadas" onClick={onMenuClick} />
                        <MenuItemLink to="/bajasmesr" primaryText="Bajas Rechazadas" onClick={onMenuClick} />
                      </SubMenu>
                      <SubMenu
                        handleToggle={() => this.handleToggle('menuSueldos')}
                        isOpen={menuSueldos}
                        sidebarIsOpen={open}
                        name="Sueldos"
                        icon={<ValidacionIcon />}
                      >
                        <MenuItemLink to="/altasmess" primaryText="Cambios de Sueldo" onClick={onMenuClick} />
                        <MenuItemLink to="/altasmessa" primaryText="Cambios de Sueldo Aceptados" onClick={onMenuClick} />
                        <MenuItemLink to="/altasmessr" primaryText="Cambios de Sueldo Rechazados" onClick={onMenuClick} />
                      </SubMenu>
                    </SubMenu>
                  </div>
                )
                : null
            )}
          />

          <WithPermissions
            render={({ permissions }) => (
              permissions !== '1'
                ? (
                  <div>
                    <SubMenu
                      handleToggle={() => this.handleToggle('menuFH')}
                      isOpen={menuFH}
                      sidebarIsOpen={open}
                      name="Factor Humano"
                      icon={<ReportesFH />}
                    >
                      <MenuItemLink to="/epecu/create" primaryText="Captura de Cuentas" onClick={onMenuClick} />
                      <MenuItemLink to="/epecugsi/create" primaryText="Captura de Cuentas GSI" onClick={onMenuClick} />
                      <MenuItemLink to="/epecu/list" primaryText="Listado de Cuentas" onClick={onMenuClick} />
                      <MenuItemLink to="/validafh/create" primaryText="Validacion de Cuentas" onClick={onMenuClick} />
                      <MenuItemLink to="/fhbaja" primaryText="Baja a Tesoreria" onClick={onMenuClick} />
                    </SubMenu>
                  </div>
                )
                : null
            )}
          />

          <WithPermissions
            render={({ permissions }) => (
              permissions !== '1'
                ? (
                  <div>
                    <SubMenu
                      handleToggle={() => this.handleToggle('menuTesoreria')}
                      isOpen={menuTesoreria}
                      sidebarIsOpen={open}
                      name="Tesoreria"
                      icon={<ReportesFH />}
                    >
                      {/* <MenuItemLink to="/epecu/create" primaryText="Captura de Cuentas" onClick={onMenuClick} /> */}
                      <MenuItemLink to="/validat/create" primaryText="Validacion de Cuentas" onClick={onMenuClick} />
                      <MenuItemLink to="/validatbanorte/create" primaryText="Validacion de Banorte" onClick={onMenuClick} />
                      <MenuItemLink to="/validabt/create" primaryText="Validacion de Bajas" onClick={onMenuClick} />
                      {/* <MenuItemLink to="/fhbaja" primaryText="Baja a Tesoreria" onClick={onMenuClick} /> */}
                    </SubMenu>
                  </div>
                )
                : null
            )}
          />

          <SubMenu
            handleToggle={() => this.handleToggle('menuReportes')}
            isOpen={menuReportes}
            sidebarIsOpen={open}
            name="Reportes"
            icon={<ReportesIcon />}
          >
            <MenuItemLink to="/rpa" primaryText="Personal Activo" onClick={onMenuClick} />
            <MenuItemLink to="/ram" primaryText="Altas" onClick={onMenuClick} />
            <MenuItemLink to="/rbm" primaryText="Bajas" onClick={onMenuClick} />
            <MenuItemLink to="/ras" primaryText="Cambios de Sueldo" onClick={onMenuClick} />
            <MenuItemLink to="/enr" primaryText="Rechazados Sucursal" onClick={onMenuClick} />
            <MenuItemLink to="/ecns" primaryText="Personal Capturado" onClick={onMenuClick} />
            <MenuItemLink to="/rgc" primaryText="Gasto Celular por Mes" onClick={onMenuClick} />
            <MenuItemLink to="/rcelmes" primaryText="Gasto por Celular" onClick={onMenuClick} />
            <MenuItemLink to="/rgg" primaryText="Gasto Gasolina por Mes" onClick={onMenuClick} />
            <MenuItemLink to="/gpg" primaryText="Gasto por Tarjeta" onClick={onMenuClick} />
            <MenuItemLink to="/cno" primaryText="Costo de Nomina" onClick={onMenuClick} />
            <MenuItemLink to="/repnomina" primaryText="Nomina" onClick={onMenuClick} />
            <MenuItemLink to="/repnominate" primaryText="Nomina Tiempo Extra" onClick={onMenuClick} />
            <MenuItemLink to="/repnominap" primaryText="Nomina Productividad" onClick={onMenuClick} />
            <MenuItemLink to="/anr" primaryText="Altas No Reconocidas" onClick={onMenuClick} />
            <MenuItemLink to="/bnr" primaryText="Bajas No Reconocidas" onClick={onMenuClick} />
            <MenuItemLink to="/celularesc" primaryText="Celulares para baja" onClick={onMenuClick} />
            <MenuItemLink to="/gasolinasc" primaryText="Vehiculos para baja" onClick={onMenuClick} />
          </SubMenu>
          <MenuItemLink to="/videos" primaryText="Videos de Referencia" onClick={onMenuClick} />

          <Responsive
            xsmall={(
              <MenuItemLink
                to="/configuration"
                primaryText="pos.configuration"
                leftIcon={<SettingsIcon />}
                onClick={onMenuClick}
              />
)}
            medium={null}
          />
          <Responsive
            small={logout}
            medium={null}
          />
        </div>
      );
    }
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  locale: state.i18n.locale,
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    {},
  ),
);

export default enhance(Menu);
