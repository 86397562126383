import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
      Seleccionar el no de empleado a dar de baja y el mes en que esta fue o sera efectiva.
      </Typography>
    </Paper>
  </div>
);

export const EmpleadoCreate = props => (
  <div>
    <Aside />
    <Create {...props} title="Captura de empleados que se quieren dar de baja de SAP">
      <SimpleForm redirect="list">
        <ReferenceInput
          label="Empleado:"
          source="empleadoId"
          reference="epe"
          filter={{ agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
        >
          <AutocompleteInput
            optionText={choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`}
            optionValue="id"
          />
          {/* <SelectInput optionText={fullNameRenderer} /> */}
        </ReferenceInput>
        <SelectInput
          source="mesBaja"
          choices={[
            { id: '1', name: 'Enero' },
            { id: '2', name: 'Febrero' },
            { id: '3', name: 'Marzo' },
            { id: '4', name: 'Abril' },
            { id: '5', name: 'Mayo' },
            { id: '6', name: 'Junio' },
            { id: '7', name: 'Julio' },
            { id: '8', name: 'Agosto' },
            { id: '9', name: 'Septiembre' },
            { id: '10', name: 'Octubre' },
            { id: '11', name: 'Noviembre' },
            { id: '12', name: 'Diciembre' },
          ]}
        />
        <SelectInput
          source="motivo"
          choices={[
            { id: 'baja', name: 'Baja' },
            { id: 'demanda', name: 'Demanda' },
            { id: 'abandono', name: 'Abandono' },
          ]}
        />
      </SimpleForm>
    </Create>
  </div>
);

export { EmpleadoCreate as default };
