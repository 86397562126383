// in src/Dashboard.js
import React, { Component, Fragment } from 'react';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import PersonalSucursal from './Dashboard/PersonalSucursal';
import ParqueVehicular from './Dashboard/ParqueVehicular';
import TelefoniaSucursal from './Dashboard/TelefoniaSucursal';
import TotalesSucursal from './Dashboard/TotalesSucursal';
import URL from './url';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  titulos: { height: '60px', fontSize: '25px' },
  overlay: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 10,
    backgroundColor: 'grey',
    opacity: '0.5',
  },
  spinner: {
    position: 'fixed',
    left: '50%',
    right: '50%',
    top: '50%',
  },
};

const Loading = () => (
  <div style={styles.overlay}>
    <CircularProgress style={styles.spinner} color="secondary" />
  </div>
);

class Dashmina extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEmpresaValue: localStorage.getItem('empresa'),
      selectSucursalValue: 'TODAS',
      dataPersonal: {},
      dataVehiculos: {},
      dataTelefonia: {},
      dataTotales: {},
      sucursales: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getPersonal(localStorage.getItem('empresa'));
    this.getVehiculos(localStorage.getItem('empresa'));
    this.getCelulares(localStorage.getItem('empresa'));
    this.getTotal(localStorage.getItem('empresa'));
    // this.getEmpresas();
    this.getSucursales(localStorage.getItem('empresa'));
  }

  getPersonal = (empresa, sucursal) => {
    this.setState({ loading: true });
    if (!empresa && !sucursal) {
      fetch(`${URL()}/dashboard/personal/`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    } else if ((!sucursal) || sucursal === 'TODAS') {
      fetch(`${URL()}/dashboard/personal/${empresa}/`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    } else {
      fetch(`${URL()}/dashboard/personal/${empresa}/${sucursal}`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    }
    this.setState({ loading: false });
  }

  getVehiculos = (empresa, sucursal) => {
    this.setState({ loading: true });
    if (!empresa && !sucursal) {
      fetch(`${URL()}/dashboard/vehiculos/`)
        .then(response => response.json())
        .then(dataVehiculos => this.setState({ dataVehiculos }));
    } else if ((!sucursal) || sucursal === 'TODAS') {
      fetch(`${URL()}/dashboard/vehiculos/${empresa}/`)
        .then(response => response.json())
        .then(dataVehiculos => this.setState({ dataVehiculos }));
    } else {
      fetch(`${URL()}/dashboard/vehiculos/${empresa}/${sucursal}`)
        .then(response => response.json())
        .then(dataVehiculos => this.setState({ dataVehiculos }));
    }
    this.setState({ loading: false });
  }

  getCelulares = (empresa, sucursal) => {
    this.setState({ loading: true });
    if (!empresa && !sucursal) {
      fetch(`${URL()}/dashboard/celulares/`)
        .then(response => response.json())
        .then(dataTelefonia => this.setState({ dataTelefonia }));
    } else if ((!sucursal) || sucursal === 'TODAS') {
      fetch(`${URL()}/dashboard/celulares/${empresa}`)
        .then(response => response.json())
        .then(dataTelefonia => this.setState({ dataTelefonia }));
    } else {
      fetch(`${URL()}/dashboard/celulares/${empresa}/${sucursal}`)
        .then(response => response.json())
        .then(dataTelefonia => this.setState({ dataTelefonia }));
    }
    this.setState({ loading: false });
  }

  getTotal = (empresa, sucursal) => {
    this.setState({ loading: true });
    if (!empresa && !sucursal) {
      fetch(`${URL()}/dashboard/total/`)
        .then(response => response.json())
        .then(dataTotales => this.setState({ dataTotales }));
    } else if ((!sucursal) || sucursal === 'TODAS') {
      fetch(`${URL()}/dashboard/total/${empresa}`)
        .then(response => response.json())
        .then(dataTotales => this.setState({ dataTotales }));
    } else {
      fetch(`${URL()}/dashboard/total/${empresa}/${sucursal}`)
        .then(response => response.json())
        .then(dataTotales => this.setState({ dataTotales }));
    }
    this.setState({ loading: false });
  }

  // getEmpresas = () => {
  //   this.setState({ loading: true });
  //   fetch(`${URL()}/auth/empresas/`)
  //     .then(response => response.json())
  //     .then(empresas => this.setState({ empresas }));
  //   this.setState({ loading: false });
  // }

  getSucursales = (empresa) => {
    this.setState({ loading: true });
    fetch(`${URL()}/auth/sucursales/${empresa}`)
      .then(response => response.json())
      .then(sucursales => this.setState({ sucursales }));
    this.setState({ loading: false });
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    const { selectEmpresaValue, selectSucursalValue } = this.state;
    this.getPersonal(selectEmpresaValue, selectSucursalValue);
    this.getVehiculos(selectEmpresaValue, selectSucursalValue);
    this.getCelulares(selectEmpresaValue, selectSucursalValue);
    this.getTotal(selectEmpresaValue, selectSucursalValue);
    localStorage.setItem('sucursal', selectSucursalValue);
    this.setState({ loading: false });
  };

  handleChangeS = (event) => {
    this.setState({ selectSucursalValue: event.target.value });
  };

  render() {
    const {
      selectEmpresaValue,
      selectSucursalValue,
      dataPersonal,
      dataTelefonia,
      dataVehiculos,
      dataTotales,
      sucursales,
      loading,
    } = this.state;
    const {
      totalEnSap,
      totalPersonalNoReconocido,
      totalPersonalEnSucursal,
      totalValorPersonalEnSucursal,
      totalPersonalCapturado,
      totalAltas,
      totalAltasValor,
      totalBajas,
      totalBajasValor,
    } = dataPersonal;
    const {
      totalCelularesSucursal,
      totalCelularesPersonas,
      totalCelularesAreas,
    } = dataTelefonia;
    const {
      totalVehiculosActivos,
      totalActivosUtilitarios,
      totalActivosBlindados,
    } = dataVehiculos;
    const {
      totalTarjetasGasolina,
      totalVehiculosGasolina,
      totalVehiculosGas,
    } = dataTotales;
    const q = { minWidth: 170 };
    return (
      <Fragment>
        { loading ? <Loading /> : null }
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol}>
              <div style={styles.titulos}>
                <FormControl style={q}>
                  <InputLabel htmlFor="empresaSelect">Sucursal</InputLabel>
                  <Select
                    value={selectSucursalValue}
                    onChange={this.handleChangeS}
                    inputProps={{
                      name: 'selectSucursal',
                      id: 'sucursalSelect',
                    }}
                  >
                    <MenuItem value="">
                      <em>TODAS</em>
                    </MenuItem>
                    {sucursales.map(
                      item => <MenuItem value={item.sucursal}>{item.sucursal}</MenuItem>,
                    )}
                  </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={this.handleSubmit}>
                  MOSTRAR
                </Button>

              </div>
              <PersonalSucursal
                totalEnSap={totalEnSap}
                totalNoReconocido={totalPersonalNoReconocido}
                totalPersonalCapturado={totalPersonalCapturado}
                totalPersonalEnSucursal={totalPersonalEnSucursal}
                totalValorPersonalEnSucursal={totalValorPersonalEnSucursal}
                totalAltasEnSucursal={totalAltas}
                totalAltasValorEnSucursal={totalAltasValor}
                totalBajasEnSucursal={totalBajas}
                totalBajasValorEnSucursal={totalBajasValor}
              />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.singleCol}>
              <div style={styles.titulos}>
                {selectEmpresaValue}
                {' '}
-
                {' '}
                {selectSucursalValue}
              </div>
              <ParqueVehicular
                parqueVehicularActivos={totalVehiculosActivos}
                activosUtilitarios={totalActivosUtilitarios}
                activosBlindados={totalActivosBlindados}
              />
            </div>
          </div>
        </div>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol}>
              <TelefoniaSucursal
                totalCelulares={totalCelularesSucursal}
                asignadosPersonas={totalCelularesPersonas}
                asignadosDepartamentos={totalCelularesAreas}
              />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.singleCol}>
              <TotalesSucursal
                totalTarjetasGasolina={totalTarjetasGasolina}
                totalVehiculosGasolina={totalVehiculosGasolina}
                totalVehiculosGas={totalVehiculosGas}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(connect(null, {
  showNotification,
  push,
})(Dashmina));
