import React from 'react';
import Card from '@material-ui/core/Card';
import PeopleIcon from '@material-ui/icons/People';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardIcon from './CardIcon';

const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
    'font-size': '12px',
    'font-weight': 'normal',
  },
  titulo: {
    'font-weight': 'bold',
    'font-size': '20px',
    color: '#31708f',
    'text-align': 'left',
  },
  t: {
    'text-align': 'right',
    'font-weight': 'bold',
    'font-size': '16px',
  },
  t2: {
    'text-align': 'right',
    'font-size': '16px',
  },
};

const PersonalSucursal = ({
  totalEnSap, classes, totalValorPersonalEnSucursal, totalAltasEnSucursal,
  totalAltasValorEnSucursal, totalBajasEnSucursal, totalBajasValorEnSucursal,
}) => (
  <div className={classes.main}>
    <CardIcon Icon={PeopleIcon} bgColor="#31708f" />
    <Card className={classes.card}>
      <Typography className={classes.titulo} color="textSecondary" component="h2" variant="h2" gutterBottom>
        PERSONAL
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Personal SAP:
        {' '}
        {totalEnSap}
      </Typography>
      <Typography gutterBottom className={classes.t2}>
        Valor del Personal Que Pertenece A La Sucursal:
        {' '}
        {totalValorPersonalEnSucursal}
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Altas del Mes:
        {' '}
        {totalAltasEnSucursal}
      </Typography>
      <Typography gutterBottom className={classes.t2}>
        Valor del Personal de Alta:
        {' '}
        {totalAltasValorEnSucursal}
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Bajas del Mes:
        {' '}
        {totalBajasEnSucursal}
      </Typography>
      <Typography gutterBottom className={classes.t2}>
        Valor del Personal de Baja:
        {' '}
        {totalBajasValorEnSucursal}
      </Typography>
    </Card>
  </div>
);

export default withStyles(styles)(PersonalSucursal);
