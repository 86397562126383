import React from 'react';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  CardActions,
  CreateButton,
  RefreshButton,
} from 'react-admin';

const ListActions = ({ basePath, data }) => (
  <CardActions>
    <CreateButton basePath={basePath} label="Capturar Vehiculo" />
    <RefreshButton />
  </CardActions>
);

const gasolinaListRowStyle = (record, index) => ({
  backgroundColor: record.fuente === 2 ? '#efe' : 'white',
});

export const GasolinaCorregidaList = props => (
  <List {...props} bulkActions={false} title="Parque vehicular y relacion de tarjetas de gasolina asignadas" actions={<ListActions />}>
    <Responsive
      medium={(
        <Datagrid rowstyle={gasolinaListRowStyle}>
          <TextField source="noEconomico" label="Numero Economico" />
          <TextField source="placa" label="Placa" />
        </Datagrid>
)}
    />
  </List>
);

export default GasolinaCorregidaList;
// export { ServiceList as default };
