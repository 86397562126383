import React from 'react';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  regex,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
        CAPTURAR LOS VEHICULOS
      </Typography>
    </Paper>
  </div>
);

const Vplaca = [required(), regex(/^[a-zA-Z0-9]+$/, 'Debe contener solo letras y numeros.')];
const VEconomico = [regex(/^[a-zA-Z0-9]+$/, 'Debe contener solo letras y numeros.')];
const VRequired = [required()];

export const GasolinaCorregidaCreate = props => (
  <div>
    <Aside />
    <Create {...props} title="Solicitud de dictamen de baja de vehiculo">
      <SimpleForm redirect="list">
        <TextInput source="placa" label="Placa" validate={Vplaca} />
        <TextInput source="noSerie" label="Numero de Serie" validate={VEconomico} />
        <SelectInput
          source="motivo"
          choices={[
            { id: 'Baja para destruccion', name: 'Baja para destruccion' },
            { id: 'Venta a terceros', name: 'Venta a terceros' },
            { id: 'Cambio de Empresa', name: 'Cambio de Empresa' },
            { id: 'Cambio de Sucursal', name: 'Cambio de Sucursal' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData }) => formData.motivo === 'Cambio de Empresa'
                      && (
                        <TextInput source="destino" label="Empresa" validate={VRequired} />
                      )
                 }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => formData.motivo === 'Cambio de Sucursal'
                      && (
                        <TextInput source="destino" label="Sucursal" validate={VRequired} />
                      )
                 }
        </FormDataConsumer>
      </SimpleForm>
    </Create>

  </div>
);

export { GasolinaCorregidaCreate as default };
