import SolicitudDeBajasList from './solicituddebajaslist';
import SolicitudDeBajasCreate from './solicituddebajascreate';

export default {
  list: SolicitudDeBajasList,
  create: SolicitudDeBajasCreate,
};
// export * from './altasmesshow';
// export * from './altasmescreate';
// export * from './altasmesedit';
