// in src/Dashboard.js
import React, { Component, Fragment } from 'react';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import PersonalSucursal from './Dashboard/PersonalSucursal';
import ParqueVehicular from './Dashboard/ParqueVehicular';
import TelefoniaSucursal from './Dashboard/TelefoniaSucursal';
import TotalesSucursal from './Dashboard/TotalesSucursal';
import URL from './url';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appV: localStorage.getItem('appV'),
      dataPersonal: {},
      dataVehiculos: {},
      dataTelefonia: {},
      dataTotales: {},
      metas: {},
    };
  }

  componentDidMount() {
    // const { push, showNotification } = this.props;
    const { appV } = this.state;
    if (appV === '1') {
      showNotification('Favor de cambiar su password.');
      push('/passwordchange');
    }

    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('empresa');
    fetch(`${URL()}/dashboard/personal/${agrupamiento}/${sucursal}`)
      .then(response => response.json())
      .then(dataPersonal => this.setState({ dataPersonal }));

    fetch(`${URL()}/dashboard/vehiculos/${agrupamiento}/${sucursal}`)
      .then(response => response.json())
      .then(dataVehiculos => this.setState({ dataVehiculos }));

    fetch(`${URL()}/dashboard/celulares/${agrupamiento}/${sucursal}`)
      .then(response => response.json())
      .then(dataTelefonia => this.setState({ dataTelefonia }));

    fetch(`${URL()}/dashboard/total/${agrupamiento}/${sucursal}`)
      .then(response => response.json())
      .then(dataTotales => this.setState({ dataTotales }));

    fetch(`${URL()}/dashboard/metas/${agrupamiento}/${sucursal}`)
      .then(response => response.json())
      .then(metas => this.setState({ metas }));
  }

  render() {
    const {
      dataPersonal,
      dataTelefonia,
      dataVehiculos,
      dataTotales,
    } = this.state;
    const {
      totalEnSap,
      totalPersonalNoReconocido,
      totalValorNoReconocidos,
      totalPersonalEnSucursal,
      totalValorPersonalEnSucursal,
      totalPersonalCapturado,
      totalAltas,
      totalAltasValor,
      totalBajas,
      totalBajasValor,
    } = dataPersonal;
    const {
      totalCelularesSucursal,
      totalCelularesPersonas,
      totalCelularesAreas,
    } = dataTelefonia;
    const {
      totalVehiculosActivos,
      totalActivosUtilitarios,
      totalActivosBlindados,
      totalActivosParticulares,
      totalActivosDiesel,
    } = dataVehiculos;
    const {
      totalTarjetasGasolina,
      totalVehiculosGasolina,
      totalVehiculosGas,
    } = dataTotales;
    return (
      <Fragment>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol}>
              <PersonalSucursal
                totalEnSap={totalEnSap}
                totalNoReconocido={totalPersonalNoReconocido}
                totalValorNoReconocido={totalValorNoReconocidos}
                totalPersonalCapturado={totalPersonalCapturado}
                totalPersonalEnSucursal={totalPersonalEnSucursal}
                totalValorPersonalEnSucursal={totalValorPersonalEnSucursal}
                totalAltasEnSucursal={totalAltas}
                totalAltasValorEnSucursal={totalAltasValor}
                totalBajasEnSucursal={totalBajas}
                totalBajasValorEnSucursal={totalBajasValor}
              />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.singleCol}>
              <ParqueVehicular
                parqueVehicularActivos={totalVehiculosActivos}
                activosUtilitarios={totalActivosUtilitarios}
                activosBlindados={totalActivosBlindados}
                activosParticulares={totalActivosParticulares}
                activosDiesel={totalActivosDiesel}
              />
            </div>
          </div>
        </div>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol}>
              <TelefoniaSucursal
                totalCelulares={totalCelularesSucursal}
                asignadosPersonas={totalCelularesPersonas}
                asignadosDepartamentos={totalCelularesAreas}
              />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.singleCol}>
              <TotalesSucursal
                totalTarjetasGasolina={totalTarjetasGasolina}
                totalVehiculosGasolina={totalVehiculosGasolina}
                totalVehiculosGas={totalVehiculosGas}
              />
            </div>
          </div>
        </div>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol}>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  showNotification,
  push,
})(Dashboard);
