// in ./updateConfirmado.js
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, crudUpdateMany } from 'react-admin';

class DesconocerBajaButton extends Component {
    state = {
      isOpen: false,
      titulo: '',
    }

    titleString = '';

    handleClick = () => {
      this.setState({
        isOpen: true,
        titulo: `${this.props.selectedIds.length} empleados fueron seleccionados`,
      });
    };

    handleDialogClose = () => {
      this.setState({ isOpen: false });
      // console.log(this.state.seleccionados);
    };

    handleConfirm = () => {
      const {
        basePath, crudUpdateMany, resource, selectedIds,
      } = this.props;
      crudUpdateMany(resource, selectedIds, { corregido: 1 }, basePath);
      this.setState({ isOpen: true });
    };

    render() {
      return (
        <Fragment>
          <Button label="DESCONOCER BAJA" onClick={this.handleClick} />
          <Confirm
            isOpen={this.state.isOpen}
            title={this.state.titulo}
            content="Estos empleados seran marcados como bajas desconocidas, desea continuar?"
            onConfirm={this.handleConfirm}
            onClose={this.handleDialogClose}
          />
        </Fragment>
      );
    }
}

export default connect(undefined, { crudUpdateMany })(DesconocerBajaButton);
