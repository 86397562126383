import React from 'react';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import { Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  flex: {
    display: 'flex',
  },
});

const PrefijoTarjeta = () => (
  <span>
    <Field name="lat" component="input" type="number" placeholder="latitude" />
      &nbsp;
    <Field name="lng" component="input" type="number" placeholder="longitude" />
  </span>
);

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3" />
      <Typography component="p" />
    </Paper>
  </div>
);
const Vrequerido = [required()];

const VRequired = [required()];

export const TarjetasGasolinaCreate = props => (
  <div>
    <Aside />
    <Create {...props} title="Captura de Tarjetas de Gasolina">
      <SimpleForm redirect="list">
        <SelectInput
          source="tipoProveedor"
          choices={[
            { id: 'Si Vale', name: 'Si Vale' },
            { id: 'Edenred', name: 'Edenred' },
            { id: 'Otro', name: 'Otro' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tipoProveedor === 'Si Vale'
                      && (
                      <div>
                        49858901
                        <TextInput
                          label="No de Tarjeta"
                          source="noTarjeta"
                          validate={Vrequerido}
                        />
                      </div>
                      )
                 }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tipoProveedor === 'Edenred'
                      && (
                      <div>
                        63631800
                        <TextInput
                          label="No de Tarjeta"
                          source="noTarjeta"
                          validate={Vrequerido}
                        />
                      </div>
                      )
                 }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tipoProveedor === 'Otro'
                      && (
                      <div>
                        <div>
                          <TextInput source="proveedor" label="Proveedor" validate={Vrequerido} {...rest} />
                        </div>
                        <TextInput
                          label="No de Tarjeta"
                          source="noTarjeta"
                          validate={Vrequerido}
                        />
                      </div>
                      )
                 }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  </div>
);

export { TarjetasGasolinaCreate as default };
