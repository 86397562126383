import React from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardIcon from './CardIcon';

const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
    'font-size': '12px',
    'font-weight': 'normal',
  },
  titulo: {
    'font-weight': 'bold',
    'font-size': '20px',
    color: '#4caf50',
    'text-align': 'left',
  },
  t: {
    'text-align': 'right',
    'font-weight': 'bold',
    'font-size': '16px',
  },
};

const TotalesSucursal = ({
  totalTarjetasGasolina, totalVehiculosGasolina, totalVehiculosGas, classes,
}) => (
  <div className={classes.main}>
    <CardIcon Icon={DollarIcon} bgColor="#4caf50" />
    <Card className={classes.card}>
      <Typography className={classes.titulo} color="textSecondary" component="h2" variant="h2" gutterBottom>
        TOTALES
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Total De Tarjetas De Gasolina:
        {' '}
        {totalTarjetasGasolina}
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Total De Unidades Activas - Gasolina:
        {' '}
        {totalVehiculosGasolina}
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Total De Unidades Activas - Gas:
        {' '}
        {totalVehiculosGas}
      </Typography>
    </Card>
  </div>
);

export default withStyles(styles)(TotalesSucursal);
