import React from 'react';
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  FormDataConsumer,
  TextInput,
  regex,
  RadioButtonGroupInput,
  AutocompleteInput,
  FileInput,
  FileField,
  NumberInput,
  DateInput,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const divContainer = {
  display: 'flex',
  width: '100%',
};

const divLeft = {
  display: 'flex',
  alignItems: 'right',
  width: '15%',
};

const divRight = {
  display: 'flex',
  alignItems: 'right',
  width: '85%',
};

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
        CAPTURAR LOS VEHICULOS QUE SE ENCUENTRAN EN LA SUCURSAL
        INDICANDO SI ES BLINDADO O UTILITARIO Y SI TIENE UNA
        TARJETA DE GASOLINA ASIGNADA, ASI COMO SI ESTA
        ASIGNADA A UNA PERSONA O DEPARTAMENTO.
      </Typography>
    </Paper>
  </div>
);

const Vplaca = [required(), regex(/^[a-zA-Z0-9]+$/, 'Debe contener solo letras y numeros.')];
const VNoSerie = [required(), regex(/^[a-zA-Z0-9]+$/, 'Debe contener solo letras y numeros.')];
const VRequired = [required()];
const VEconomico = [regex(/^[a-zA-Z0-9]+$/, 'Debe contener solo letras y numeros.')];

const validateGasolinaCreate = (values) => {
  const errors = {};
  if (values.tieneNoEconomico === '2') {
    if (!values.noEconomico) { errors.noEconomico = ['El no. economico es requerido']; }
  }
  if (values.tieneTarjetaGasolina === '1') {
    if (!values.tarjetaGasolina) { errors.tarjetaGasolina = ['La tarjeta de gasolina es requerida']; }
  }
  if (values.asignado === '1') {
    if (!values.empleadoId) { errors.empleadoId = ['El empleado es requerido']; }
  }
  if (values.asignado === '2') {
    if (!values.area) { errors.area = ['El area es requerida']; }
  }
  return errors;
};

export const VehiculosCreate = props => (
  <div>
    <Aside />
    <Create {...props} title="Captura de parque vehicular y tarjetas de gasolina">
      <SimpleForm redirect="list" validate={validateGasolinaCreate}>
        <TextInput source="placas" label="Placa" validate={Vplaca} />
        <RadioButtonGroupInput
          source="tipoPlaca"
          choices={[
            { id: '1', name: 'Estatal (Interior de la Republica)' },
            { id: '2', name: 'Edo de Mex' },
            { id: '3', name: 'CDMX' },
            { id: '4', name: 'Federal' },
          ]}
          validate={VRequired}
        />
        <TextInput source="denominacionActivoFijo" label="Denominacion Activo Fijo" validate={VRequired} />
        <SelectInput
          source="modelo"
          choices={[
            { id: '2007', name: '2007' },
            { id: '2008', name: '2008' },
            { id: '2009', name: '2009' },
            { id: '2010', name: '2010' },
            { id: '2011', name: '2011' },
            { id: '2012', name: '2012' },
            { id: '2013', name: '2013' },
            { id: '2014', name: '2014' },
            { id: '2015', name: '2015' },
            { id: '2016', name: '2016' },
            { id: '2017', name: '2017' },
            { id: '2018', name: '2018' },
            { id: '2019', name: '2019' },
            { id: '2020', name: '2020' },
            { id: '2021', name: '2021' },
            { id: '2022', name: '2022' },
          ]}
          validate={VRequired}
        />
        <TextInput source="noSerie" label="No de Serie / VIN (Numero de Identificacion Vehicular)" validate={VNoSerie} />
        <RadioButtonGroupInput
          source="tieneNoEconomico"
          choices={[
            { id: '1', name: 'No' },
            { id: '2', name: 'Si' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tieneNoEconomico === '2'
                      && <TextInput source="noEco" label="No. Economico" validate={VEconomico} {...rest} />
                 }
        </FormDataConsumer>
        <RadioButtonGroupInput
          source="tipoCombustibleId"
          choices={[
            { id: '1', name: 'Gasolina' },
            { id: '2', name: 'Gas' },
            { id: '3', name: 'Diesel' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tipoCombustibleId === '2'
                      && (
                        <div>
                          <div>
                            <div>Tanque Izquierdo</div>
                            <div>
                              <TextInput source="noSerieI" label="No Serie" validate={VRequired} />
                              <TextInput source="marcaI" label="Marca" validate={VRequired} />
                              <NumberInput source="capacidadI" label="Capacidad" validate={VRequired} />
                              <DateInput source="fabricacionI" label="Fecha Fabricacion" validate={VRequired} />
                            </div>
                            <div style={divContainer}>
                              <div style={divLeft}>
                                <a href="https://gsyastorage.blob.core.windows.net/contratos/tanqueizquierdo.jpeg" target="_blank" rel="noopener noreferrer">
                                  <img src="https://gsyastorage.blob.core.windows.net/contratos/tanqueizquierdo.jpeg" alt="Unidad" width="200px" height="200px" />
                                </a>
                              </div>
                              <div style={divRight}>
                                <FileInput source="placaI" label="Placa Identificacion Tanque Izquierdo" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false} validate={VRequired}>
                                  <FileField source="placaI" title="title" />
                                </FileInput>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>Tanque Derecho</div>
                            <div>
                              <TextInput source="noSerieD" label="No Serie" validate={VRequired} />
                              <TextInput source="marcaD" label="Marca" validate={VRequired} />
                              <NumberInput source="capacidadD" label="Capacidad" validate={VRequired} />
                              <DateInput source="fabricacionD" label="Fecha Fabricacion" validate={VRequired} />
                            </div>
                            <div style={divContainer}>
                              <div style={divLeft}>
                                <a href="https://gsyastorage.blob.core.windows.net/contratos/tanquederecho.jpeg" target="_blank" rel="noopener noreferrer">
                                  <img src="https://gsyastorage.blob.core.windows.net/contratos/tanquederecho.jpeg" alt="Unidad" width="200px" height="200px" />
                                </a>
                              </div>
                              <div style={divRight}>
                                <FileInput source="placaD" label="Placa Identificacion Tanque Derecho" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false} validate={VRequired}>
                                  <FileField source="placaD" title="title" />
                                </FileInput>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                 }
        </FormDataConsumer>
        <RadioButtonGroupInput
          source="tieneTarjetaGasolina"
          choices={[
            { id: '1', name: 'Si' },
            { id: '2', name: 'No' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tieneTarjetaGasolina === '1'
                      && (
                        <ReferenceInput
                          label="Tarjeta:"
                          source="noTarjetaId"
                          reference="tarjetasg"
                          filter={{ agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
                          {...rest}
                        >
                          <AutocompleteInput
                            optionText={(choice) => {
                              let pref;
                              if (choice.proveedor === 'Edenred') {
                                pref = '63631800';
                              } else if (choice.proveedor === 'Si Vale') {
                                pref = '49858901';
                              } else {
                                pref = '';
                              }
                              return `${choice.proveedor} ${pref}${choice.noTarjeta}`;
                            }}
                            optionValue="id"
                          />
                          {/* <SelectInput optionText={fullNameRenderer} /> */}
                        </ReferenceInput>
                      )
                 }
        </FormDataConsumer>
        <RadioButtonGroupInput
          source="tipoVehiculoId"
          choices={[
            { id: '1', name: 'Blindado' },
            { id: '2', name: 'Utilitario' },
            { id: '5', name: 'Arrendado' },
          ]}
          validate={VRequired}
        />
        <SelectInput
          source="asignado"
          choices={[
            { id: '1', name: 'Persona' },
            { id: '2', name: 'Area' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.asignado === '1'
                      && (
                      <ReferenceInput
                        label="Asignado a: (especifico: Gerente, Ejecutivo, Funcionario)"
                        source="empleadoId"
                        reference="epe"
                        filter={{ agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
                        {...rest}
                      >
                        <AutocompleteInput
                          optionText={choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`}
                          optionValue="id"
                        />
                        {/* <SelectInput optionText={fullNameRenderer} /> */}
                      </ReferenceInput>
                      )
                 }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.asignado === '2'
                      && <TextInput source="area" label="Area asignada" {...rest} />
                 }
        </FormDataConsumer>
        {/* <TextInput source="departamento" label="Departamento"/> */}

        <div style={divContainer}>
          <div style={divLeft}>
            <a href="https://gsyastorage.blob.core.windows.net/contratos/unidad.jpeg" target="_blank" rel="noopener noreferrer">
              <img src="https://gsyastorage.blob.core.windows.net/contratos/unidad.jpeg" alt="Unidad" width="200px" height="200px" />
            </a>
          </div>
          <div style={divRight}>
            <FileInput source="unidad" label="Foto de la Unidad" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false} validate={VRequired}>
              <FileField source="src" title="title" />
            </FileInput>
          </div>
        </div>
        <div style={divContainer}>
          <div style={divLeft}>
            <a href="https://gsyastorage.blob.core.windows.net/contratos/tarjetacirculacion.jpeg" target="_blank" rel="noopener noreferrer">
              <img src="https://gsyastorage.blob.core.windows.net/contratos/tarjetacirculacion.jpeg" alt="Tarjeta de Circulacion" width="200px" height="200px" />
            </a>
          </div>
          <div style={divRight}>
            <FileInput source="tarjetaCirculacion" label="Tarjeta de circulacion" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false} validate={VRequired}>
              <FileField source="src" title="title" />
            </FileInput>
          </div>
        </div>
        <div style={divContainer}>
          <div style={divLeft}>
            <a href="https://gsyastorage.blob.core.windows.net/contratos/noserie.jpeg" target="_blank" rel="noopener noreferrer">
              <img src="https://gsyastorage.blob.core.windows.net/contratos/noserie.jpeg" alt="No Serie" width="200px" height="200px" />
            </a>
          </div>
          <div style={divRight}>
            <FileInput source="motor" label="No de Serie / VIN (Numero de Identificacion Vehicular)" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false} validate={VRequired}>
              <FileField source="src" title="title" />
            </FileInput>
          </div>
        </div>
      </SimpleForm>
    </Create>

  </div>
);

export { VehiculosCreate as default };
