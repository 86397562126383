import React from 'react';
import {
  EditButton,
  List,
  Responsive,
  Datagrid,
  TextField,
  RefreshButton,
  CardActions,
  Filter,
  TextInput,
  Pagination,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import { BulkDeleteButton } from 'react-admin';
// import ResetViewsButton from './components/updateConfirmado';
import Totales from './components/totales';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
        SELECCIONAR LA CASILLA EN CASO DE QUE EL EMPLEADO SI PERTENEZCA A LA SUCURSAL
      </Typography>
    </Paper>
    <Totales />
  </div>
);

const ListActions = () => (
  <CardActions>
    {/* <CreateButton basePath={basePath} /> */}
    <RefreshButton />
  </CardActions>
);

// const PostBulkActionButtons = props => (
//   <Fragment>
//     <ResetViewsButton label="Confirmar" {...props} />
//     {/* Add the default bulk delete action */}
//     {/* <BulkDeleteButton {...props} /> */}
//   </Fragment>
// );

const PostRowClick = (props) => {
  const { basePath, record } = props;
  const { fuente } = record;
  if (fuente === 2) {
    return <EditButton basePath={basePath} record={record} />;
    // return <EditButton />;
  }
  return '';
};

const empleadoCorregidoListRowStyle = record => ({
  backgroundColor: record.fuente === 2 ? '#efe' : 'white',
});

const EmpleadoFilter = props => (
  <Filter {...props}>
    <TextInput label="No Empleado" source="noEmpleado" alwaysOn />
  </Filter>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const EmpleadoCorregidoList = props => (
  <div>
    <Aside />
    <List
      {...props}
      // bulkActionButtons={<PostBulkActionButtons />}
      title="Personal NO reconocido por la sucursal"
      actions={<ListActions />}
      filters={<EmpleadoFilter />}
      pagination={<PostPagination />}
      perPage={20}
    >
      <Responsive
        medium={(
          <Datagrid rowStyle={empleadoCorregidoListRowStyle}>
            <TextField source="noEmpleado" label="Empleado" />
            <TextField source="nombres" label="Nombres" />
            <TextField source="paterno" label="Paterno" />
            <TextField source="materno" label="Materno" />
            <TextField source="denominacionPosiciones" label="Posicion" />
            <TextField source="denominacionUnidadOrganizacional" label="Area" />
            <PostRowClick />
          </Datagrid>
)}
      />
    </List>
  </div>
);

export default EmpleadoCorregidoList;
// export { ServiceList as default };
