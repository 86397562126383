import React from 'react';
import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  translate,
  BooleanInput,
  SelectInput,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import Button from '@material-ui/core/Button';

const MetasEmpleadosEditToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const choices = [
  { id: 1, mes: 'Enero' },
  { id: 2, mes: 'Febrero' },
  { id: 3, mes: 'Marzo' },
  { id: 4, mes: 'Abril' },
  { id: 5, mes: 'Mayo' },
  { id: 6, mes: 'Junio' },
  { id: 7, mes: 'Julio' },
  { id: 8, mes: 'Agosto' },
  { id: 9, mes: 'Septiembre' },
  { id: 10, mes: 'Octubre' },
  { id: 11, mes: 'Noviembre' },
  { id: 12, mes: 'Diciembre' },
];

const optionRenderer = choice => `${choice.mes}`;

const MetasEmpleadosEdit = ({ onCancel, ...props }) => (
  <Edit {...props}>
    <SimpleForm toolbar={<MetasEmpleadosEditToolbar onCancel={onCancel} />}>
      <BooleanInput label="Dar de baja" source="darDeBaja" />
      <FormDataConsumer>
        {({ formData, ...rest }) => !formData.darDeBaja
                 && (
                 <div>
                   <NumberInput source="montoBaja" label="Reduccion tiempo extra (Horas)" {...rest} />
                 </div>
                 )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.darDeBaja
                 && (
                 <div>
                   <SelectInput source="mesBaja" choices={choices} optionValue="mes" optionText={optionRenderer} {...rest} />
                 </div>
                 )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export default MetasEmpleadosEdit;
