import React from 'react';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  CreateButton,
  RefreshButton,
  CardActions,
  RichTextField,
} from 'react-admin';
// import { BulkDeleteButton } from 'react-admin';

const ListActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} label="Crear Idea" />
    <RefreshButton />
  </CardActions>
);

const empleadoListRowStyle = record => ({
  backgroundColor: record.usuario !== 'garmexic' ? '#efe' : 'white',
});

const postRowClick = (id, basePath, record) => (record.editable ? 'edit' : 'show');

export const IdeasList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    title="Tablero de Ideas del Portal"
    actions={<ListActions />}
    filter={{ categoria: 'PORTAL PARA EL CONTROL DE PERSONAL Y GASTOS INDIVIDUALIZADOS' }}
  >
    <Responsive
      medium={(
        <Datagrid rowClick={postRowClick} rowStyle={empleadoListRowStyle}>
          <TextField source="categoria" label="Categoria" />
          <TextField source="titulo" label="Titulo" />
          <RichTextField source="idea" label="Idea" />
        </Datagrid>
)}
    />
  </List>
);

export default IdeasList;
