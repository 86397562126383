import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import MetasEmpleadosEdit from './metasempleadosedit';

// import { EditButton } from 'ra-ui-materialui/lib/button';

// const CustomTableCell = withStyles(theme => ({
//   head: {
//     backgroundColor: 'red',
//     color: theme.palette.common.white,
//   },
//   body: {
//     backgroundColor: 'red',
//     color: theme.palette.common.white,
//     fontSize: 14,
//   },
// }))(TableCell);

// const empleadoListRowStyle = record => ({
//   backgroundColor: record.montoBaja === 2 ? 'blue' : 'red',
// });

// const MyDatagridRow = ({
//   record, resource, id, onToggleItem, children, selected, basePath,
// }) => (
//   <TableRow key={id}>
//     {/* first column: selection checkbox */}
//     {/* <TableCell padding="none">
//       {record.status !== 'pending' && (
//         <Checkbox
//           checked={selected}
//           onClick={() => onToggleItem(id)}
//         />
//       )}
//     </TableCell> */}
//     {/* data columns based on children */}
//     {React.Children.map(children, field => (
//       <TableCell key={`${id}-${field.props.source}`} classes={{ backgroundColor: 'pink' }}>
//         {React.cloneElement(field, {
//           record,
//           basePath,
//           resource,
//         })}
//       </TableCell>
//     ))}
//   </TableRow>
// );

// const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
// const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  drawerContent: {
    width: 300,
  },
});

// const PostBulkActionButtons = () => (
//   <Fragment>
//     {/* <ResetViewsButton label="Confirmar" {...props} /> */}
//   </Fragment>
// );

// const PostFilter = props => (
//   <Filter {...props}>
//     {/* <TextInput label="Search" source="q" alwaysOn /> */}
//     <TextInput label="Paterno" source="paterno" defaultValue="" />
//   </Filter>
// );

// const MetasEmpleadosListActions = ({ basePath }) => (
//   <CardActions>
//     <CreateButton basePath={basePath} />
//   </CardActions>
// );

// const NameRender = record => `${record.nombres} ${record.paterno}`;

class MetasEmpleadosList extends React.Component {
  handleClose = () => {
    this.props.push('/metae');
  };

  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          bulkActionButtons={false}
          title="Metas para Empleados en sucursal"
          filter={{
            agrupamiento: localStorage.getItem('empresa'),
            sucursal: localStorage.getItem('sucursal'),
          }}
        >
          <Responsive
            medium={(
              <Datagrid>
                <TextField source="noEmpleado" label="No de Empleado" />
                <FunctionField label="Empleado" render={record => ((record) ? `${record.paterno} ${record.materno} ${record.nombres} ` : null)} />

                {/* <TextField source="paterno" label="Paterno" />
                <TextField source="materno" label="Materno" />
                <TextField source="nombres" label="Nombres" /> */}
                <TextField source="ordinario" label="Ordinario" />
                <TextField source="cargasocial" label="Carga Social" />
                <TextField source="otras" label="Otros" />
                <TextField source="productividad" label="Productividad" />
                <TextField source="previsionsocial" label="Prevision Social" />
                <TextField source="cantidadtiempoextradoble" label="Tiempo extra doble" />
                <TextField source="cantidadtiempoextratriple" label="Tiempo extra triple" />
                {/* <TextField source="total" label="Total" /> */}
                {/* <TextField source="montoBaja" label="Reduccion de Horas extra" /> */}
                {/* <TextField source="MetasEmpleados[0].enero" label="Enero" />
                <TextField source="MetasEmpleados[0].febrero" label="Febrero" />
                <TextField source="MetasEmpleados[0].marzo" label="Marzo" />
                <TextField source="MetasEmpleados[0].abril" label="Abril" />
                <TextField source="MetasEmpleados[0].mayo" label="Mayo" />
                <TextField source="MetasEmpleados[0].junio" label="Junio" />
                <TextField source="MetasEmpleados[0].julio" label="Julio" /> */}
                <EditButton label="Meta" />
              </Datagrid>
            )}
          />
        </List>

        <Route path="/metae/:id">
          {({ match }) => {
            const isMatch = match
                                && match.params
                                && match.params.id !== 'create';

            return (
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={this.handleClose}
              >
                {isMatch ? (
                  <MetasEmpleadosEdit
                    className={styles.drawerContent}
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={styles.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>

    );
  }
}


export default compose(
  connect(
    undefined,
    { push },
  ),
  withStyles(styles),
)(MetasEmpleadosList);


// export default withStyles(styles)(MetasEmpleadosList);
// export default EmpleadoList;
