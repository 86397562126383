import React from 'react';
import {
  CardActions,
  ListButton,
  Show,
  TextField,
  SimpleShowLayout,
  RichTextField,
} from 'react-admin';

const ServiceShowTitle = ({ record }) => (
  <span>
    {record ? `Ingreso No. ${record.prefijoFolio} ${record.folio}` : ''}
  </span>
);
const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const ServiceShowActions = ({ basePath }) => (
  <CardActions style={cardActionStyle}>
    {/* <EditButton basePath={basePath} record={data} /> */}
    <ListButton basePath={basePath} />
    {/* <PrintButton /> */}
    {/* <CloseServiceButton record={data} /> */}
  </CardActions>
);

export const IdeasShow = props => (
  <Show
    title={<ServiceShowTitle />}
    {...props}
    actions={<ServiceShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="categoria" />
      <TextField source="titulo" />
      <RichTextField source="idea" />
    </SimpleShowLayout>
  </Show>
);

export default IdeasShow;
