// in ./updateConfirmado.js
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, crudUpdateMany } from 'react-admin';

class ResetViewsButton extends Component {
    state = {
      isOpen: false,
    }

    handleClick = () => {
      this.setState({ isOpen: true });
    }

    handleDialogClose = () => {
      this.setState({ isOpen: false });
    };

    handleConfirm = () => {
      const {
        basePath, crudUpdateMany, resource, selectedIds,
      } = this.props;
      const agrupamiento = localStorage.getItem('empresa');
      const sucursal = localStorage.getItem('sucursal');
      crudUpdateMany(resource, selectedIds, { corregido: 0, agrupamiento, sucursal }, basePath);
      this.setState({ isOpen: true });
    };

    render() {
      return (
        <Fragment>
          <Button label="Reconocer Empleado" onClick={this.handleClick} />
          <Confirm
            isOpen={this.state.isOpen}
            title="Marcar Empleado como reconocidos"
            content="Estos empleados habían sido marcados como no identificados. ¿Esta seguro de que los quiere marcar como que pertenecen a la sucursal?"
            onConfirm={this.handleConfirm}
            onClose={this.handleDialogClose}
            confirm="Confirmar"
            cancel="Cancelar"
          />
        </Fragment>
      );
    }
}

export default connect(undefined, { crudUpdateMany })(ResetViewsButton);
