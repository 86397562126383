import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  drawerContent: {
    width: 300,
  },
});

class GastoCelularMesList extends React.Component {
  handleClose = () => {
    this.props.push('/metac');
  };

  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          bulkActionButtons={false}
          title="Gastos total de celular de la Sucursal"
          filter={{
            agrupamiento: localStorage.getItem('empresa'),
            sucursal: localStorage.getItem('sucursal'),
            denominacion: 'activo',
          }}
        >
          <Responsive
            medium={(
              <Datagrid>
                <TextField source="concepto" label="Concepto" />
                <TextField source="enero" label="Enero" />
                <TextField source="febrero" label="Febrero" />
                <TextField source="marzo" label="Marzo" />
                <TextField source="abril" label="Abril" />
                <TextField source="mayo" label="Mayo" />
                <TextField source="junio" label="Junio" />
                <TextField source="julio" label="Julio" />
                <TextField source="agosto" label="Agosto" />
              </Datagrid>
            )}
          />
        </List>
        {/*
        <Route path="/metac/:id">
          {({ match }) => {
            const isMatch = match
                                && match.params
                                && match.params.id !== 'create';

            return (
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={this.handleClose}
              >
                {isMatch ? (
                  <MetasCelularesEdit
                    className={styles.drawerContent}
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={styles.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route> */}
      </Fragment>

    );
  }
}


export default compose(
  connect(
    undefined,
    { push },
  ),
  withStyles(styles),
)(GastoCelularMesList);


// export default withStyles(styles)(MetasCelularesList);
// export default EmpleadoList;
