// in src/Dashboard.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import MUIDataTable from 'mui-datatables';

import FusionCharts from 'fusioncharts/core';
import MSLine from 'fusioncharts/viz/msline';
import FusionTheme from 'fusioncharts/themes/es/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';

ReactFC.fcRoot(FusionCharts, MSLine, FusionTheme);

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const columns3 = ['Concepto', 'Enero', 'Febrero'];

const data3 = [
  { Concepto: 'Personal Activo', Enero: '1000', Febrero: '2000' },
  { Concepto: 'Altas', Enero: '10', Febrero: '15' },
  { Concepto: 'Bajas', Enero: '11', Febrero: '10' },
  { Concepto: 'Diferencia', Enero: '-1', Febrero: '5' },
  { Concepto: 'Meta Bajas', Enero: '3', Febrero: '3' },
  { Concepto: 'Incumplimiento', Enero: '2', Febrero: '8' },
];

const data4 = [
  { Concepto: 'Personal Activo', Enero: '$4,646,444.00', Febrero: '$4,696,444.00' },
  { Concepto: 'Altas', Enero: '$52,222.00', Febrero: '$150,000.00' },
  { Concepto: 'Bajas', Enero: '$58,774.00', Febrero: '$100,000.00' },
  { Concepto: 'Diferencia', Enero: '$-3,552.00', Febrero: '$50,000.00' },
  { Concepto: 'Meta Bajas', Enero: '$3,000.00', Febrero: '$3,000.00' },
  { Concepto: 'Incumplimiento', Enero: '$-552.00', Febrero: '$53,000.00' },
];

const options2 = {
  selectableRows: false, // <===== will turn off checkboxes in rows,
  pagination: false,
  sort: false,
  filter: false,
  search: false,
  print: false,
  download: false,
  sortFilterList: false,
  viewColumns: false,
};

const chartConfigs = {
  type: 'msline', // The chart type
  width: '700', // Width of the chart
  height: '400', // Height of the chart
  dataFormat: 'json', // Data type
  dataSource: {
    // Chart Configuration
    chart: {
      caption: '',
      subCaption: '',
      xAxisName: 'Mes',
      yAxisName: 'Movimientos $',
      theme: 'fusion',
    },
    categories: [
      {
        category: [
          {
            label: 'Enero',
          },
          {
            label: 'Febrero',
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: 'Altas',
        data: [
          {
            value: 52222.00,
          },
          {
            value: 150000.00,
          },
        ],
      },
      {
        seriesname: 'Bajas',
        data: [
          {
            value: 58774.00,
          },
          {
            value: 100000.00,
          },
        ],
      },
    ],
    trendlines: [
      {
        line: [
          {
            startvalue: '3000',
            color: 'red',
            valueOnRight: '1',
            displayvalue: 'Meta',
          },
        ],
      },
    ],
  },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appV: localStorage.getItem('appV'),
    };
  }

  componentDidMount() {
    const { appV } = this.state;
    if (appV === '1') {
      showNotification('Favor de cambiar su password.');
      push('/passwordchange');
    }
  }


  render() {
    return (
      <Fragment>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol} />

            <MUIDataTable
              title="Comparativo de Movimiento de Personal (Cantidad)"
              data={data3}
              columns={columns3}
              options={options2}
            />

            <MUIDataTable
              title="Comparativo de Movimiento de Personal (Valor)"
              data={data4}
              columns={columns3}
              options={options2}
            />

          </div>

          <ReactFC {...chartConfigs} />
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  showNotification,
  push,
})(Dashboard);
