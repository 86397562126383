import React from 'react';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
// import ResetViewsButton from './components/updateConfirmado';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="No Empleado" source="noEmpleado" alwaysOn />
    <TextInput label="Paterno" source="paterno" defaultValue="" />
  </Filter>
);

export const SolicitudDeBajasList = props => (
  <div>
    <List
      {...props}
      title="Solicitud de Baja de Personal"
      filter={{
        agrupamiento: localStorage.getItem('empresa'),
        sucursal: localStorage.getItem('sucursal'),
        corregido: 3,
      }}
      perPage={20}
      filters={<PostFilter />}
    >
      <Responsive
        medium={(
          <Datagrid>
            <TextField source="noEmpleado" label="Empleado" />
            <TextField source="nombres" label="Nombres" />
            <TextField source="paterno" label="Paterno" />
            <TextField source="materno" label="Materno" />
            <TextField source="denominacionPosiciones" label="Posicion" />
            <TextField source="denominacionUnidadOrganizacional" label="Area" />
          </Datagrid>
        )}
      />
    </List>
  </div>
);


export default withStyles(styles)(SolicitudDeBajasList);
// export default EmpleadoList;
