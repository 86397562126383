import React from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/People';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MenuItemLink } from 'react-admin';
import CardIcon from './CardIcon';

const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
  titulo: {
    'font-weight': 'bold',
    'font-size': '20px',
    color: '#31708f',
    'text-align': 'left',
  },
  t: {
    'text-align': 'right',
  },
};

const CapturaDatos = ({
  totalEnSap, totalPersonalCapturado, totalNoReconocido, totalPersonalEnSucursal, classes, onMenuClick,
}) => (
  <div className={classes.main}>
    <CardIcon Icon={DollarIcon} bgColor="#31708f" />
    <Card className={classes.card}>
      <Typography className={classes.titulo} color="textSecondary" component="h2" variant="h2" gutterBottom>
        CAPTURA DE DATOS
      </Typography>
      <Typography component="h3" variant="h3" gutterBottom className={classes.t}>
        <MenuItemLink to="/gasolinas" primaryText="Parque Vehicular" onClick={onMenuClick} />
      </Typography>
      <Typography component="h3" variant="h3" gutterBottom className={classes.t}>
        <MenuItemLink to="/celulares" primaryText="Telefonia Celular" onClick={onMenuClick} />
      </Typography>
      <Typography component="h3" variant="h3" gutterBottom className={classes.t}>
        <MenuItemLink to="/epe/create" primaryText="Empleados que NO en SAP" onClick={onMenuClick} />
      </Typography>
    </Card>
  </div>
);

export default withStyles(styles)(CapturaDatos);
