import React from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/DirectionsCar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardIcon from './CardIcon';

const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
    'font-size': '12px',
    'font-weight': 'normal',
  },
  titulo: {
    'font-weight': 'bold',
    'font-size': '20px',
    color: '#ff9800',
    'text-align': 'left',
  },
  t: {
    'text-align': 'right',
    'font-weight': 'bold',
    'font-size': '16px',
  },
};

const ParqueVehicular = ({
  parqueVehicularActivos, activosUtilitarios, activosBlindados, activosParticulares, activosDiesel, classes,
}) => (
  <div className={classes.main}>
    <CardIcon Icon={DollarIcon} bgColor="#ff9800" />
    <Card className={classes.card}>
      <Typography className={classes.titulo} color="textSecondary" component="h2" variant="h2" gutterBottom>
        PARQUE VEHICULAR
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Total Parque Vehicular Activos:
        {' '}
        {parqueVehicularActivos}
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Utilitarios:
        {' '}
        {activosUtilitarios}
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Blindados:
        {' '}
        {activosBlindados}
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Particulares con tarjeta asignada:
        {' '}
        {activosParticulares}
      </Typography>
      <Typography gutterBottom className={classes.t}>
        Total de Vehiculos que utilizan Diesel:
        {' '}
        {activosDiesel}
      </Typography>
    </Card>
  </div>
);

export default withStyles(styles)(ParqueVehicular);
