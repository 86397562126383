import React, { Fragment, cloneElement } from 'react';
import {
  EditButton,
  List,
  Datagrid,
  TextField,
  downloadCSV,
  TextInput,
  Filter,
  ExportButton,
  // TopToolbar,
  CardActions,
  RefreshButton,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import { BulkDeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import ResetViewsButton from './components/updateConfirmado';
import Totales from './components/totales';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions && React.cloneElement(bulkActions, {
      basePath,
      filterValues,
      resource,
      selectedIds,
      onUnselectItems,
    })}
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    }) }
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton />
  </CardActions>
);

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
        PANTALLA DE VALIDACION PARA QUITAR DEL LISTADO AL PERSONAL QUE NO PERTENECE A LA SUCURSAL.
      </Typography>
      <Typography component="p">
        SELECCIONA EL PERSONAL QUE NO LABORA EN TU SUCURSAL
        Y PROCEDE A PRESIONAR EL BOTON DESACTIVAR EMPLEADO.
      </Typography>
    </Paper>
    <Totales />
  </div>
);

const exporters = (empleados) => {
  console.log(empleados);
  const data = empleados;
  const csv = convertToCSV({
    data,
    // select and order fields in the export
    fields: ['noEmpleado', 'nombres', 'paterno', 'materno', 'denominacionPosiciones', 'denominacionUnidadOrganizacional'],
  });
  downloadCSV(csv, 'empleados'); // download as 'empleados.csv` file
};

const PostBulkActionButtons = props => (
  <Fragment>
    <ExportButton />
    {/* <ResetViewsButton label="Confirmar" {...props} /> */}
  </Fragment>
);

// const PostRowClick = (props) => {
//   const { basePath, record } = props;
//   const { fuente } = record;
//   if (fuente === 2) {
//     return <EditButton basePath={basePath} record={record} />;
//   }
//   return '';
// };

const empleadoListRowStyle = record => ({
  backgroundColor: record.fuente === 2 ? '#efe' : 'white',
});

const EmpleadoFilter = props => (
  <Filter {...props}>
    <TextInput label="No Empleado" source="noEmpleado" alwaysOn />
    <TextInput label="Nombre" source="nombreCompleto" alwaysOn />
  </Filter>
);

// const agrupamiento = localStorage.getItem('empresa');
// const sucursal = localStorage.getItem('sucursal');
// const corregido = 0;

// const exporter = posts => {
//   const postsForExport = posts.map(post => {
//       const { backlinks, author, ...postForExport } = post; // omit backlinks and author
//       postForExport.author_name = post.author.name; // add a field
//       return postForExport;
//   });
//   const csv = convertToCSV({
//       data: postsForExport,
//       fields: ['id', 'title', 'author_name', 'body'] // order fields in the export
//   });
//   downloadCSV(csv, 'posts'); // download as 'posts.csv` file
// })

export const EmpleadoList = props => (
  <div>
    <Aside />
    <List
      {...props}
      bulkActionButtons={false}
      actions={<PostActions />}
      title="Empleados Registrados en Sucursal"
      exporter={exporters}
      filters={<EmpleadoFilter />}
      // filter={{ agrupamiento, sucursal, corregido }}
      perPage={20}
      // actions={<PostBulkActionButtons />}
    >
      <Datagrid rowStyle={empleadoListRowStyle}>
        <TextField source="noEmpleado" label="Empleado" />
        <TextField source="nombres" label="Nombres" />
        <TextField source="paterno" label="Paterno" />
        <TextField source="materno" label="Materno" />
        <TextField source="denominacionPosiciones" label="Posicion" />
        <TextField source="denominacionUnidadOrganizacional" label="Area" />
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  </div>
);


export default withStyles(styles)(EmpleadoList);
// export default EmpleadoList;
