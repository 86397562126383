import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './Configuration/configuration';
import Dashmin from './dashminA';
import OtroMenu from './otromenu';
import rcostonominaempleado from './Reportes/costonominaempleado';
import rcostonomina from './Reportes/costonomina';
import rcostoporcelular from './Reportes/costoporcelular';
import rcostoportarjeta from './Reportes/costoportarjeta';
import rcostocelularmes from './Reportes/costocelularmes';
import movimientospersonal from './Reportes/movimientospersonal';
import personalActivo from './Reportes/ARpersonalActivo';
import altasMes from './Reportes/ARaltasMes';
import bajasMes from './Reportes/ARbajasMes';
import cambioSueldoMes from './Reportes/ARcambioSueldos';
import videos from './videos';

export default [
  <Route exact path="/passwordchange" component={Configuration} />,
  <Route exact path="/dashmina" component={Dashmin} />,
  <Route exact path="/nav" component={OtroMenu} />,
  <Route exact path="/cnoe" component={rcostonominaempleado} />,
  <Route exact path="/cno" component={rcostonomina} />,
  <Route exact path="/mov" component={movimientospersonal} />,
  <Route exact path="/gpc" component={rcostoporcelular} />,
  <Route exact path="/gpg" component={rcostoportarjeta} />,
  <Route exact path="/rccm" component={rcostocelularmes} />,
  <Route exact path="/videos" component={videos} />,
  <Route exact path="/rpa" component={personalActivo} />,
  <Route exact path="/ram" component={altasMes} />,
  <Route exact path="/rbm" component={bajasMes} />,
  <Route exact path="/ras" component={cambioSueldoMes} />,
];
