import React from 'react';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  maxLength,
  minLength,
  regex,
} from 'react-admin';

const VTelefono = [required(), minLength(10), maxLength(11), regex(/^[0-9]+$/, 'Debe contener solo numeros.')];

const validateCelularCreate = (values) => {
  const errors = {};
  if (values.asignado === '1') {
    if (!values.empleadoId) { errors.empleadoId = ['El empleado es requerido']; }
  }
  if (values.asignado === '2') {
    if (!values.area) { errors.area = ['El area es requerida']; }
  }
  return errors;
};

export const CelularCorregidoCreate = props => (
  <Create {...props} title="Captura de Celulares">
    <SimpleForm redirect="list" validate={validateCelularCreate}>
      <TextInput source="telefono" label="No de Telefono" validate={VTelefono} />
    </SimpleForm>
  </Create>
);

export { CelularCorregidoCreate as default };
