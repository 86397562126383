import EmpleadosDisputadosList from './empleadosdisputadoslist';
// import IdeasShow from './ideasshow';
// import IdeasEdit from './ideas';
// import IdeasCreate from './ideascreate';

export default {
  list: EmpleadosDisputadosList,
//   show: IdeasShow,
//   create: IdeasCreate,
  // edit: IdeasEdit,
//   create: IdeasCreate,
//   edit: IdeasEdit
};
// export * from './altasmesshow';
// export * from './altasmescreate';
// export * from './altasmesedit';
