import React, { Fragment } from 'react';
import {
  List,
  Responsive,
  Datagrid,
  ReferenceField,
  TextField,
  CardActions,
  CreateButton,
  DeleteButton,
  RefreshButton,
  EditButton,
  Pagination,
  FunctionField,
  downloadCSV,
  ExportButton,
} from 'react-admin';
// import DesconocerVehiculoButton from './components/botonDesconocer';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import CierreCapturaButton from './components/cierreCaptura';

const ListActions = ({
  basePath, total, resource, currentSort, filterValues, exporter,
}) => (
  <CardActions>
    <CreateButton basePath={basePath}/>
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <CierreCapturaButton label="Cerrar Captura" />
    <RefreshButton />
  </CardActions>
);

const exporters = (vehiculos) => {
  const data = vehiculos;
  const csv = convertToCSV({
    data,
    // select and order fields in the export
    fields: [
      'noEco',
      'denominacionActivoFijo',
      'placas',
      'tipoVehiculoId',
      'tipoCombustibleId',
      'noSerie',
      'noTarjeta',
      'area',
      'empleadoId',
      'reconocido',
    ],
  });
  downloadCSV(csv, 'vehiculos'); // download as 'vehiculos.csv` file
};

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

// const PostRowClick = (props) => {
//   const { basePath, record } = props;
//   return <EditButton basePath={basePath} record={record} label="Editar" />;
// };

const PostBulkActionButtons = props => (
  <Fragment>
    {/* <DesconocerVehiculoButton label="Desconocer" {...props} /> */}
  </Fragment>
);

export const TarjetasGasolinaList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    title="Tarjetas de Gasolina"
    actions={<ListActions />}
    pagination={<PostPagination />}
    perPage={20}
    // filter={{ fuente: 1 }}
    exporter={exporters}
  >
    <Responsive
      medium={(
        <Datagrid>
          <TextField source="noTarjeta" label="No Tarjeta" />
          <TextField source="proveedor" label="Proveedor" />
          <EditButton />
        </Datagrid>
)}
    />
  </List>
);

export default TarjetasGasolinaList;
// export { ServiceList as default };
