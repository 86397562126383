import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import '../node_modules/video-react/dist/video-react.css';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import URL from './url';

// see documentation for supported input formats
// const data = [['attribute', 'attribute2'], ['value1', 'value2']];

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  titulos: { height: '60px', fontSize: '25px' },
  overlay: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 10,
    backgroundColor: 'grey',
    opacity: '0.5',
  },
  spinner: {
    position: 'fixed',
    left: '50%',
    right: '50%',
    top: '50%',
  },
};

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datax: [],
    };
  }

  componentDidMount() {
    fetch(`${URL()}/epe/e/a`)
      .then(response => response.json())
      .then(datax => this.setState({ datax }));
  }

  render() {
    return (
      <PivotTableUI
        data={this.state.datax}
        onChange={s => this.setState(s)}
        {...this.state}
      />
    );
  }
}

export default withStyles(styles)(connect(null, {
  showNotification,
  push,
})(Videos));
