import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';

const vrequerido = [required()];

const choices = [
  { id: 'RELATIVAS A PERSONAL', categoria: 'Relativas a Personal' },
  { id: 'RELATIVAS A INVERSION', categoria: 'Relativas a Inversion' },
  { id: 'RELATIVAS A GASTOS GENERALES', categoria: 'Relativas a Gastos Generales' },
  { id: 'RELATIVAS A GASTOS DE VIAJE', categoria: 'Relativas a Gastos de Viaje' },
  { id: 'RELATIVAS A GASTOS OPERATIVOS', categoria: 'Relativas a Gastos Operativos' },
  { id: 'RELATIVAS A CREACIÓN DE NUEVOS PROYECTOS Y DEPARTAMENTOS', categoria: 'Relativas a Creacion de Nuevos Proyectos y Departamentos' },
  { id: 'PORTAL PARA EL CONTROL DE PERSONAL Y GASTOS INDIVIDUALIZADOS', categoria: 'Relativas al Portal de Control de Personal y Gastos' },
  { id: 'CULTURA DE AHORRO EN EL USO DE LOS SERVICIOS', categoria: 'Relativas a la Cultura de Ahorro en el uso de los Servicios' },
];
export const IdeasCreate = props => (
  <Create {...props} title="Captura de Ideas">
    <SimpleForm redirect="list">
      <TextInput source="titulo" validate={vrequerido} />
      <SelectInput source="categoria" choices={choices} optionText="categoria" optionValue="id" validate={vrequerido} />
      <RichTextInput source="ideaField" label="Describe tu idea con detalle" validate={vrequerido} />
    </SimpleForm>
  </Create>
);

export { IdeasCreate as default };
