import IdeasList from './ideaslist';
import IdeasShow from './ideasshow';
// import IdeasEdit from './ideas';
import IdeasCreate from './ideascreate';

export default {
  list: IdeasList,
  show: IdeasShow,
  create: IdeasCreate,
  // edit: IdeasEdit,
//   create: IdeasCreate,
//   edit: IdeasEdit
};
// export * from './altasmesshow';
// export * from './altasmescreate';
// export * from './altasmesedit';
