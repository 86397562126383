import FormData from 'form-data';
import URL from './url';

const addUploadCapabilities = requestHandler => async (type, resource, params) => {
  if (type === 'CREATE' && resource === 'sbajas') {
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          corregido: 3,
          fuente: 2,
          ...params.data,
        },
      },
    );
  }

  if (type === 'CREATE' && resource === 'tarjetasg') {
    // const permiso = localStorage.getItem('permiso');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('empresa');
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          agrupamiento,
          sucursal,
          ...params.data,
        },
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'tarjetasg') {
    console.log('GET_LIST Tarjetas');
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal,
      };
    } else {
      p.filter = { };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'altasscb') {
    const filterType = params.filter.type ? params.filter.type : 'none';
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal,
      };
      if (filterType === 'dropdown') {
        p.pagination = { page: 1, perPage: 5000 };
        p.sort = { field: 'noEmpleado', order: 'DESC' };
      }
    } else {
      p.filter = { };
    }
    console.log(p);
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'movco') {
    const filterType = params.filter.type ? params.filter.type : 'none';
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal,
      };
      if (filterType === 'dropdown') {
        p.pagination = { page: 1, perPage: 5000 };
        p.sort = { field: 'noEmpleado', order: 'DESC' };
      }
    } else {
      p.filter = { };
    }
    console.log(p);
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'vehiculos') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal,
      };
    } else {
      p.filter = { };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'CREATE' && resource === 'vehiculos') {
    const permiso = localStorage.getItem('permiso');
    const empresa = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('agrupamiento');
    const p = params;
    const form = new FormData();
    form.append('noSerie', params.data.noSerie);
    form.append('tarjetaCirculacion', params.data.tarjetaCirculacion.rawFile);
    form.append('motor', params.data.motor.rawFile);
    form.append('unidad', params.data.unidad.rawFile);
    if (typeof params.data.placaI !== 'undefined') {
      form.append('placaI', params.data.placaI.rawFile);
    }
    if (typeof params.data.placaD !== 'undefined') {
      form.append('placaD', params.data.placaD.rawFile);
    }
    return fetch(`${URL()}/vehiculos/upload/`, {
      method: 'POST',
      body: form,
    })
      .then(x => x.json())
      .then(json => json)
      .then(response => requestHandler(
        type,
        resource,
        {
          ...params,
          data: {
            empresa,
            agrupamiento,
            sucursal,
            uploadedFileurl: response,
            ...params.data,
          },
        },
      ));
  }

  if (type === 'UPDATE' && resource === 'vehiculos') {
    const permiso = localStorage.getItem('permiso');
    const empresa = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('agrupamiento');
    const p = params;
    const form = new FormData();
    form.append('noSerie', params.data.noSerie);
    form.append('tarjetaCirculacion', params.data.tarjetaCirculacion.rawFile);
    form.append('motor', params.data.motor.rawFile);
    form.append('unidad', params.data.unidad.rawFile);
    if (typeof params.data.placaI !== 'undefined') {
      form.append('placaI', params.data.placaI.rawFile);
    }
    if (typeof params.data.placaD !== 'undefined') {
      form.append('placaD', params.data.placaD.rawFile);
    }
    return fetch(`${URL()}/vehiculos/upload/`, {
      method: 'POST',
      body: form,
    })
      .then(x => x.json())
      .then(json => json)
      .then(response => requestHandler(
        type,
        resource,
        {
          ...params,
          data: {
            empresa,
            agrupamiento,
            sucursal,
            uploadedFileurl: response,
            ...params.data,
          },
        },
      ));
  }

  if (type === 'CREATE' && resource === 'fhbaja') {
    const permiso = localStorage.getItem('permiso');
    const empresa = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('agrupamiento');
    const p = params;
    const form = new FormData();
    if (typeof params.data.moper !== 'undefined') {
      form.append('noempleado', params.data.empleadoId);
      form.append('moper', params.data.moper.rawFile);
      form.append('moper', params.data.moper.rawFile);
    }
    return fetch(`${URL()}/fhbaja/upload/`, {
      method: 'POST',
      body: form,
    })
      .then(x => x.json())
      .then(json => json)
      .then(response => requestHandler(
        type,
        resource,
        {
          ...params,
          data: {
            empresa,
            agrupamiento,
            sucursal,
            uploadedFileurl: response,
            ...params.data,
          },
        },
      ));
  }

  if (type === 'CREATE' && resource === 'validafh') {
    const p = params;
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/validafh/uploadfh/`, {
      method: 'POST',
      body: form,
    });
    return requestHandler(
      type,
      resource,
      {
        ...p,
        extra: response.status,
      },
    );
  }

  if (type === 'CREATE' && resource === 'validatbanorte') {
    const p = params;
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/validat/uploadtban/`, {
      method: 'POST',
      body: form,
    });
    return requestHandler(
      type,
      resource,
      {
        ...p,
        extra: response.status,
      },
    );
  }

  if (type === 'CREATE' && resource === 'validat') {
    const p = params;
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/validat/uploadt/`, {
      method: 'POST',
      body: form,
    });
    return requestHandler(
      type,
      resource,
      {
        ...p,
        extra: response.status,
      },
    );
  }

  if (type === 'CREATE' && resource === 'validatb') {
    const p = params;
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/validatb/uploadtb/`, {
      method: 'POST',
      body: form,
    });
    return requestHandler(
      type,
      resource,
      {
        ...p,
        extra: response.status,
      },
    );
  }

  if (type === 'CREATE' && resource === 'epecugsi') {
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const form = new FormData();
    console.log(params.data)
    if (typeof params.data.contratoBanco !== 'undefined') {
      form.append('noempleado', params.data.noempleado);
      form.append('contratoBanco', params.data.contratoBanco.rawFile);
      form.append('credencial', params.data.credencial.rawFile);
      if (typeof params.data.moper !== 'undefined') {
        form.append('moper', params.data.moper.rawFile);
      }
      if (typeof params.data.contratoServicio !== 'undefined') {
        form.append('contratoServicio', params.data.contratoServicio.rawFile);
      }
    }
    return fetch(`${URL()}/epecu/upload/`, {
      method: 'POST',
      body: form,
    })
      .then(x => x.json())
      .then(json => json)
      .then(response => requestHandler(
        type,
        resource,
        {
          ...params,
          data: {
            agrupamiento,
            sucursal,
            response,
            ...params.data,
          },
        },
      ));
  }

  if (type === 'EDIT' && resource === 'epecugsi') {
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('noempleado', params.data.noempleado);
      form.append('file', params.data.files.rawFile);
      form.append('image', params.data.img.rawFile);
      if (typeof params.data.moper !== 'undefined') {
        form.append('moper', params.data.moper.rawFile);
      }
    }
    return fetch(`${URL()}/epecu/upload/`, {
      method: 'POST',
      body: form,
    })
      .then(x => x.json())
      .then(json => json)
      .then(response => requestHandler(
        type,
        resource,
        {
          ...params,
          data: {
            agrupamiento,
            sucursal,
            uploadedFileurl: response,
            ...params.data,
          },
        },
      ));
  }

  if (type === 'CREATE' && resource === 'epecu') {
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const form = new FormData();
    if (typeof params.data.contratoBanco !== 'undefined') {
      form.append('noempleado', params.data.noempleado);
      form.append('contratoBanco', params.data.contratoBanco.rawFile);
      form.append('credencial', params.data.credencial.rawFile);
      if (typeof params.data.moper !== 'undefined') {
        form.append('moper', params.data.moper.rawFile);
      }
    }
    return fetch(`${URL()}/epecu/upload/`, {
      method: 'POST',
      body: form,
    })
      .then(x => x.json())
      .then(json => json)
      .then(response => requestHandler(
        type,
        resource,
        {
          ...params,
          data: {
            agrupamiento,
            sucursal,
            response,
            ...params.data,
          },
        },
      ));
  }

  if (type === 'GET_LIST' && resource === 'epecu') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento, corregido: 1, denominacion: 'Activo' };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal, corregido: 1, denominacion: 'Activo',
      };
    } else {
      p.filter = { corregido: 0 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'EDIT' && resource === 'epecu') {
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('noempleado', params.data.noempleado);
      form.append('file', params.data.files.rawFile);
      form.append('image', params.data.img.rawFile);
      if (typeof params.data.moper !== 'undefined') {
        form.append('moper', params.data.moper.rawFile);
      }
    }
    return fetch(`${URL()}/epecu/upload/`, {
      method: 'POST',
      body: form,
    })
      .then(x => x.json())
      .then(json => json)
      .then(response => requestHandler(
        type,
        resource,
        {
          ...params,
          data: {
            agrupamiento,
            sucursal,
            uploadedFileurl: response,
            ...params.data,
          },
        },
      ));
  }

  if (type === 'CREATE' && resource === 'sbancos') {
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('empresa', params.data.empresa);
      form.append('mes', params.data.mes);
      form.append('ano', params.data.ano);
      form.append('banco', params.data.banco);
      form.append('tipoMovimiento', params.data.tipoMovimiento);
      form.append('tipoNomina', params.data.tipoNomina);
      form.append('noNomina', params.data.noNomina);
      form.append('tipoArchivo', params.data.tipoArchivo);
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/sbancos/upload/`, {
      method: 'POST',
      body: form,
    });
    if (response.status === 200) {
      return { message: 'Terminado' };
    }
    return response;
  }

  if (type === 'CREATE' && resource === 'sb') {
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/sb/upload/`, {
      method: 'POST',
      body: form,
    });
    return response;
  }

  if (type === 'CREATE' && resource === 'sa') {
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/sa/upload/`, {
      method: 'POST',
      body: form,
    });
    return response;
  }

  if (type === 'CREATE' && resource === 'sn') {
    console.log(params.data)
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
      form.append('file', 'referencia');
    }
    const response = await fetch(`${URL()}/sn/upload/`, {
      method: 'POST',
      body: form,
    });
    return response;
  }

  if (type === 'CREATE' && resource === 'st') {
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/st/upload/`, {
      method: 'POST',
      body: form,
    });
    return response;
  }

  if (type === 'CREATE' && resource === 'comparatesoreria') {
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file1', params.data.files.rawFile);
      form.append('file2', params.data.files2.rawFile);
    }
    const response = await fetch(`${URL()}/compara/compare-files/`, {
      method: 'POST',
      body: form,
    });
    return response;
  }

  if (type === 'CREATE' && resource === 'se') {
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
    }
    const response = await fetch(`${URL()}/se/upload/`, {
      method: 'POST',
      body: form,
    });
    return response;
  }

  if (type === 'CREATE' && resource === 'ssv') {
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
      form.append('mes', params.data.mes);
    }
    const response = await fetch(`${URL()}/ssv/upload/`, {
      method: 'POST',
      body: form,
    });
    return response;
  }

  if (type === 'CREATE' && resource === 'sac') {
    const form = new FormData();
    if (typeof params.data.files !== 'undefined') {
      form.append('file', params.data.files.rawFile);
      // form.append('mes', params.data.mes);
    }
    const response = await fetch(`${URL()}/sac/upload/`, {
      method: 'POST',
      body: form,
    });
    return response;
  }

  let filterCheck;
  if (params.filter) {
    filterCheck = Object.entries(params.filter).length === 0
      && params.filter.constructor === Object;
  }

  if (type === 'GET_LIST' && resource === 'epe' && filterCheck) {
    const filterType = params.filter.type ? params.filter.type : 'none';
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento, corregido: 0, denominacion: 'Activo' };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal, corregido: 0, denominacion: 'Activo',
      };
      if (filterType === 'dropdown') {
        p.pagination = { page: 1, perPage: 5000 };
        p.sort = { field: 'noEmpleado', order: 'DESC' };
      }
    } else {
      p.filter = { corregido: 0 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'epe' && filterCheck === false) {
    const filterType = params.filter.type ? params.filter.type : 'none';
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = {
        ...p.filter, agrupamiento, corregido: 0, denominacion: 'Activo',
      };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        ...p.filter, agrupamiento, sucursal, corregido: 0, denominacion: 'Activo',
      };
      if (filterType === 'dropdown') {
        p.pagination = { page: 1, perPage: 5000 };
        p.sort = { field: 'noEmpleado', order: 'DESC' };
      }
    } else {
      p.filter = { ...p.filter, corregido: 0, denominacion: 'Activo' };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'epec') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento, corregido: 1, denominacion: 'Activo' };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal, corregido: 1, denominacion: 'Activo',
      };
    } else {
      p.filter = { corregido: 0 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  // if (type === 'GET_LIST' && resource === 'epece') {
  //   const permiso = localStorage.getItem('permiso');
  //   const p = params;
  //   console.log(p);
  //   if (permiso === '1') {
  //     p.filter = { corregido: 1 };
  //   } else {
  //     p.filter = { corregido: 1 };
  //   }
  //   return requestHandler(
  //     type,
  //     resource,
  //     {
  //       ...p,
  //     },
  //   );
  // }

  let filterCheck2;
  if (params.filter) {
    filterCheck2 = Object.entries(params.filter).length === 0
      && params.filter.constructor === Object;
  }
  if (type === 'GET_LIST' && resource === 'epece' && filterCheck2) {
    const p = params;
    p.filter = { corregido: 1 };
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'enr') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento, corregido: 1 };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = { agrupamiento, sucursal, corregido: 1 };
    } else {
      p.filter = { corregido: 1 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'ecns') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { fuente: 2, agrupamiento };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = { fuente: 2, agrupamiento, sucursal };
    } else {
      p.filter = { fuente: 2 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'gasolinas') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = { agrupamiento, sucursal };
    } else {
      p.filter = {};
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'gasolinasc') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento, fuente: 2, corregido: 2 };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal, fuente: 2, corregido: 2,
      };
    } else {
      p.filter = { fuente: 2, corregido: 2 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'celulares') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento, activo: 1 };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = { agrupamiento, sucursal, activo: 1 };
    } else {
      p.filter = { activo: 1 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'GET_LIST' && resource === 'celularesc') {
    const permiso = localStorage.getItem('permiso');
    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const p = params;
    if (permiso === '1') {
      p.filter = { agrupamiento, fuente: 2, corregido: 2 };
    } else if (permiso === '2' || permiso === '3') {
      p.filter = {
        agrupamiento, sucursal, fuente: 2, corregido: 2,
      };
    } else {
      p.filter = { fuente: 2, corregido: 2 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...p,
      },
    );
  }

  if (type === 'CREATE' && resource === 'epe') {
    const permiso = localStorage.getItem('permiso');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('empresa');
    const p = params;
    if (permiso === 1) {
      p.filter = { agrupamiento };
    } else {
      p.filter = { agrupamiento, sucursal, corregido: 1 };
    }
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          agrupamiento,
          sucursal,
          corregido: 0,
          fuente: 2,
          ...params.data,
        },
      },
    );
  }

  if (type === 'CREATE' && resource === 'gasolinas') {
    const permiso = localStorage.getItem('permiso');
    const empresa = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('agrupamiento');
    const p = params;
    if (permiso === 1) {
      p.filter = { agrupamiento };
    } else {
      p.filter = { agrupamiento, sucursal };
    }
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          agrupamiento,
          sucursal,
          empresa,
          corregido: 0,
          fuente: 2,
          ...params.data,
        },
      },
    );
  }

  if (type === 'CREATE' && resource === 'gasolinasc') {
    const permiso = localStorage.getItem('permiso');
    const empresa = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('agrupamiento');
    const p = params;
    if (permiso === 1) {
      p.filter = { agrupamiento };
    } else {
      p.filter = { agrupamiento, sucursal };
    }
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          agrupamiento,
          sucursal,
          empresa,
          corregido: 2,
          fuente: 2,
          ...params.data,
        },
      },
    );
  }

  if (type === 'CREATE' && resource === 'celulares') {
    const permiso = localStorage.getItem('permiso');
    const empresa = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('agrupamiento');
    const p = params;
    if (permiso === 1) {
      p.filter = { agrupamiento };
    } else {
      p.filter = { agrupamiento, sucursal };
    }
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          agrupamiento,
          sucursal,
          empresa,
          corregido: 0,
          fuente: 2,
          ...params.data,
        },
      },
    );
  }

  if (type === 'CREATE' && resource === 'celularesc') {
    const permiso = localStorage.getItem('permiso');
    const empresa = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('agrupamiento');
    const p = params;
    if (permiso === 1) {
      p.filter = { agrupamiento };
    } else {
      p.filter = { agrupamiento, sucursal };
    }
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          agrupamiento,
          sucursal,
          empresa,
          corregido: 2,
          fuente: 2,
          ...params.data,
        },
      },
    );
  }

  if (type === 'CREATE' && resource === 'ideas') {
    const usuario = localStorage.getItem('username');
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          usuario,
          ...params.data,
        },
      },
    );
  }

  if (type === 'CREATE' && resource === 'celularesc') {
    const permiso = localStorage.getItem('permiso');
    const empresa = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    const agrupamiento = localStorage.getItem('agrupamiento');
    const p = params;
    if (permiso === 1) {
      p.filter = { agrupamiento };
    } else {
      p.filter = { agrupamiento, sucursal };
    }
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          agrupamiento,
          sucursal,
          empresa,
          corregido: 2,
          fuente: 2,
          ...params.data,
        },
      },
    );
  }

  if (type === 'UPDATE_MANY' && resource === 'ames') {
    return requestHandler(
      type,
      resource,
      {
        ...params,
        data: {
          ...params.data,
        },
      },
    );
  }


  // if (type === 'UPDATE' && resource === 'gasolinas') {
  //   console.log('update');
  // }

  return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
