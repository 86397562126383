import React from 'react';
import {
  Edit,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  FormDataConsumer,
  SaveButton,
  Toolbar,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  flex: {
    display: 'flex',
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3" />
      <Typography component="p" />
    </Paper>
  </div>
);

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Vrequerido = [required()];

const VRequired = [required()];

export const TarjetasGasolinaEdit = props => (
  <div>
    <Aside />
    <Edit {...props} title="Captura de Tarjetas de Gasolina">
      <SimpleForm redirect="list">

        <SelectInput
          source="tipoProveedor"
          choices={[
            { id: 'Si Vale', name: 'Si Vale' },
            { id: 'Edenred', name: 'Edenred' },
            { id: 'Otro', name: 'Otro' },
          ]}
          validate={VRequired}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tipoProveedor === 'Otro'
                      && <TextInput source="proveedor" label="Proveedor" validate={Vrequerido} {...rest} />
                 }
        </FormDataConsumer>
        <div>
          <TextInput
            label="No de Tarjeta"
            source="noTarjeta"
            validate={Vrequerido}
          />
        </div>
      </SimpleForm>
    </Edit>
  </div>
);

export { TarjetasGasolinaEdit as default };
