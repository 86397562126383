import React from 'react';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  regex,
  number,
  maxLength,
  minLength,
  SelectInput,
  FileInput,
  FileField,
  FormDataConsumer,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BanorteExtra from './components/banorteextra';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  flex: {
    display: 'flex',
  },
});

const divContainer = {
  display: 'flex',
  width: '100%',
};

const divLeft = {
  display: 'flex',
  alignItems: 'right',
  width: '15%',
};

const divRight = {
  display: 'flex',
  alignItems: 'right',
  width: '85%',
};

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3" />
      <Typography component="p" />
    </Paper>
  </div>
);

const empresas = [
  { id: 'SEPSA', name: 'SEPSA' },
  { id: 'COMETRA', name: 'COMETRA' },
  { id: 'COSEIN', name: 'COSEIN' },
  { id: 'GALAHUI', name: 'GALAHUI' },
  { id: 'SESEIN', name: 'SESEIN' },
  { id: 'SESEPRO', name: 'SESEPRO' },
  { id: 'GSI GESTIÓN DE EFECTIVO', name: 'GSI GESTIÓN DE EFECTIVO' },
  { id: 'TECNOPRO', name: 'TECNOPRO' },
  { id: 'CONSILIUM EN ADMÓN. Y FIN', name: 'CONSILIUM EN ADMÓN. Y FIN' },
  { id: 'TECNOVAL', name: 'TECNOVAL' },
  { id: 'SEGURITEC', name: 'SEGURITEC' },
  { id: 'SEPSA CUSTODIA', name: 'SEPSA CUSTODIA' },
  { id: 'TAMEME', name: 'TAMEME' },
  { id: 'GRUMER ELECTRONICA', name: 'GRUMER ELECTRONICA' },
  { id: 'GRUMER GESTION', name: 'GRUMER GESTION' },
  { id: 'GSI P.P.P', name: 'GSI P.P.P' },
  { id: 'COGAR TRADE', name: 'COGAR TRADE' },
  { id: 'TOHUANTI CONSULTORES, S.C', name: 'TOHUANTI CONSULTORES, S.C' },
  { id: 'SEPSA TRANSPORTES', name: 'SEPSA TRANSPORTES' },
  { id: 'REGIO TRANSLADO', name: 'REGIO TRANSLADO' },
  { id: 'CAMSA', name: 'CAMSA' },
  { id: 'AVT CONSULTORES S.A. DE C', name: 'AVT CONSULTORES S.A. DE C' },
  { id: 'SEGUSEPRI', name: 'SEGUSEPRI' },
  { id: 'BUFETE DE ADMON Y CONTROL', name: 'BUFETE DE ADMON Y CONTROL' },
  { id: 'REGIO PROCESO', name: 'REGIO PROCESO' },
  { id: 'GSI SEGURIDAD PRIVADA', name: 'GSI SEGURIDAD PRIVADA' },
  { id: 'COSEPRI', name: 'COSEPRI' },
  { id: 'AX TRANSPORTER', name: 'AX TRANSPORTER' },
  { id: 'SESEPRI', name: 'SESEPRI' },
  { id: 'CHIMALLI', name: 'CHIMALLI' },
  { id: 'GARCIA SANCHEZ Y ASOCIADO', name: 'GARCIA SANCHEZ Y ASOCIADO' },
  { id: 'YACUNDA', name: 'YACUNDA' },
  { id: 'SEPROBAJA TRASLADO', name: 'SEPROBAJA TRASLADO' },
  { id: 'CINCO ELEMENTOS', name: 'CINCO ELEMENTOS' },
  { id: 'MERCURIO', name: 'MERCURIO' },
  { id: 'MONLEMA SERVICIOS S.A. DE', name: 'MONLEMA SERVICIOS S.A. DE' },
  { id: 'RT PRIVADA', name: 'RT PRIVADA' },
  { id: 'TACTICA Y REACTIVA', name: 'TACTICA Y REACTIVA' },
  { id: 'AX LOGISTICA', name: 'AX LOGISTICA' },
  { id: 'GSI FABRIL', name: 'GSI FABRIL' },
  { id: 'TECNOGUARD', name: 'TECNOGUARD' },
  { id: 'TYR SEGURIDAD', name: 'TYR SEGURIDAD' },
  { id: 'GO MET OPERADORA, S. A. D', name: 'GO MET OPERADORA, S. A. D' },
];

const Vrequerido = [required()];
const VnoEmpleado = [required(), regex(/^[0-9]*$/i, 'Debe contener solo numeros.'), number('Debe contener solo numeros.'), maxLength(10, 'El no de empleado no puede tener mas de 10 digitos.'), minLength(2)];
const Vnombres = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vpaterno = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vmaterno = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vcuentabancaria11 = [required(), regex(/^([0-9]{11})$/i, 'Debe ser una cuenta con 11 digitos.')];
const Vcuentabancaria10 = [required(), regex(/^([0-9]{10})$/i, 'Debe ser una cuenta con 10 digitos.')];
const Vclabe = [required(), regex(/^([0-9]{18})$/i, 'Debe ser una CLABE valida.')];
const Vcurp = [required(), regex(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/, 'El CURP debe ser valido.')];

export const GuardiasCuentasBancosCreate = props => (
  <div>
    <Aside />
    <Create {...props} title="Captura de cuentas de banco de empleados">
      <SimpleForm redirect="list">
        <SelectInput
          source="empresa"
          choices={empresas}
          validate={Vrequerido}
        />
        <SelectInput
          source="tipoMovimiento"
          choices={[
            { id: '1', name: 'Alta' },
            { id: '2', name: 'Reposicion o Modificacion' },
            { id: '3', name: 'Cambio organizativo' },
          ]}
          validate={Vrequerido}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.empresa
          && (
            <div>
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.tipoMovimiento === '1'
                 && (
                 <ReferenceInput
                   label="Empleado:"
                   source="noEmpleado"
                   reference="altasscb"
                   filterToQuery={searchText => ({ noEmpleado: searchText })}
                   filter={{ type: 'dropdown', agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
                 >
                   <AutocompleteInput
                     optionText={choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`}
                     optionValue="noEmpleado"
                   />
                 </ReferenceInput>
                 )
            }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.tipoMovimiento === '2'
                 && (
                 <ReferenceInput
                   label="Empleado:"
                   source="noEmpleado"
                   reference="epe"
                   filter={{ type: 'dropdown', agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
                 >
                   <AutocompleteInput
                     optionText={choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`}
                     optionValue="noEmpleado"
                   />
                 </ReferenceInput>
                 )
            }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.tipoMovimiento === '3'
                 && (
                   <div>
                     <ReferenceInput
                       label="Empleado:"
                       source="noEmpleado"
                       reference="movco"
                       filter={{ type: 'dropdown', agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
                     >
                       <AutocompleteInput
                         optionText={choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`}
                         optionValue="noEmpleado"
                       />
                     </ReferenceInput>
                     <BooleanInput label="Nueva Cuenta" source="complemento" />
                   </div>
                 )
            }
              </FormDataConsumer>
            </div>

          )}
        </FormDataConsumer>


        <FormDataConsumer>
          {({ formData, ...rest }) => (formData.tipoMovimiento < 3 || formData.complemento)
          && (
            <div>
              <div>
                <DateInput
                  source="fechaIngreso"
                  label="Fecha Ingreso"
                  validate={Vrequerido}
                />
              </div>
              <div>
                <SelectInput
                  source="codigoBanco"
                  choices={[
                    { id: '002', name: 'Banamex' },
                    { id: '072', name: 'Banorte' },
                    { id: '012', name: 'BBVA' },
                    { id: '021', name: 'HSBC' },
                    { id: '014', name: 'Santander' },
                  ]}
                  validate={Vrequerido}
                />
              </div>
              <div>
                <FormDataConsumer>
                  {({ formData, ...rest }) => formData.codigoBanco === '072'
                      && <BanorteExtra {...rest} />
                 }
                </FormDataConsumer>
              </div>
              <div>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (formData.codigoBanco === '002' || formData.codigoBanco === '014')
                      && (
                      <TextInput
                        label="Cuenta Bancaria"
                        source="cuenta"
                        validate={Vcuentabancaria11}
                        {...rest}
                      />
                      )
                 }
                </FormDataConsumer>
              </div>
              <div>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (formData.codigoBanco === '072' || formData.codigoBanco === '012' || formData.codigoBanco === '021')
                      && (
                      <TextInput
                        label="Cuenta Bancaria"
                        source="cuenta"
                        validate={Vcuentabancaria10}
                        {...rest}
                      />
                      )
                 }
                </FormDataConsumer>
              </div>
              <div>
                <TextInput
                  label="CLABE Interbancaria"
                  source="clabe"
                  validate={Vclabe}
                />
              </div>
              <div>
                <TextInput
                  label="Curp"
                  source="curp"
                  validate={Vcurp}
                />
              </div>
              <div style={divContainer}>
                <div style={divLeft}>
                  <a href="https://gsyastorage.blob.core.windows.net/contratos/contrato.png" target="_blank" rel="noopener noreferrer">
                    <img src="https://gsyastorage.blob.core.windows.net/contratos/contrato.png" alt="Contrato" width="200px" height="200px" />
                  </a>
                </div>
                <div style={divRight}>
                  <FileInput source="files" label="Archivo PDF de contrato bancario" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false} validate={Vrequerido}>
                    <FileField source="src" title="title" />
                  </FileInput>
                </div>
              </div>
              <div style={divContainer}>
                <div style={divLeft}>
                  <a href="https://gsyastorage.blob.core.windows.net/contratos/credencial.png" target="_blank" rel="noopener noreferrer">
                    <img src="https://gsyastorage.blob.core.windows.net/contratos/credencial.png" alt="Credencial" width="200px" height="200px" />
                  </a>
                </div>
                <div style={divRight}>
                  <FileInput source="img" label="Foto de la credencial del empleado por ambos lados" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false} validate={Vrequerido}>
                    <FileField source="src" title="title" />
                  </FileInput>
                </div>
              </div>

              <FormDataConsumer>
                {({ formData, ...rest }) => (formData.tipoMovimiento === '1')
                      && (
                        <div style={divContainer}>
                          <div style={divLeft}>
                            <a href="https://gsyastorage.blob.core.windows.net/contratos/moper.png" target="_blank" rel="noopener noreferrer">
                              <img src="https://gsyastorage.blob.core.windows.net/contratos/moper.png" alt="Credencial" width="200px" height="200px" />
                            </a>
                          </div>
                          <div style={divRight}>
                            <FileInput source="moper" label="Scan del moper firmado" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false} validate={Vrequerido}>
                              <FileField source="src" title="title" />
                            </FileInput>
                          </div>
                        </div>
                      )
                      }
              </FormDataConsumer>

            </div>

          )
   }
        </FormDataConsumer>

      </SimpleForm>
    </Create>
  </div>
);

export { GuardiasCuentasBancosCreate as default };
