import React from 'react';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  FormDataConsumer,
  TextInput,
  maxLength,
  minLength,
  regex,
} from 'react-admin';
// import { Field } from 'redux-form';
// import BotonAuto from './components/botonAuto';
// import { Field } from 'redux-form';
// import BotonAuto from './components/imagesUpload';


const VTelefono = [required(), minLength(10), maxLength(11), regex(/^[0-9]+$/, 'Debe contener solo numeros.')];

const optionRenderer = choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`;

const validateCelularCreate = (values) => {
  const errors = {};
  if (values.asignado === '1') {
    if (!values.empleadoId) { errors.empleadoId = ['El empleado es requerido']; }
  }
  if (values.asignado === '2') {
    if (!values.area) { errors.area = ['El area es requerida']; }
  }
  return errors;
};

export const CelularCreate = props => (
  <Create {...props} title="Captura de Celulares">
    <SimpleForm redirect="list" validate={validateCelularCreate}>
      <TextInput source="telefono" label="No de Telefono" validate={VTelefono} />
      <SelectInput
        source="asignado"
        choices={[
          { id: '1', name: 'Persona' },
          { id: '2', name: 'Area' },
        ]}
      />
      <FormDataConsumer>
        {({ formData }) => formData.asignado === '1'
                      && (
                      <ReferenceInput
                        label="Asignado a:"
                        source="empleadoId"
                        reference="epe"
                        resource="epe"
                        filter={{ agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
                      >
                        <AutocompleteInput
                          optionText={optionRenderer}
                          optionValue="id"
                        />
                      </ReferenceInput>
                      )
                 }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.asignado === '2'
                      && <TextInput source="area" label="Area asignada" {...rest} />
                 }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export { CelularCreate as default };
