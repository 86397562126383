import React from 'react';
import { Field, reduxForm } from 'redux-form';

const SimpleForm = (props) => {
  const {
    handleSubmit, pristine, reset, submitting,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Password Anterior</label>
        <div>
          <Field
            name="currentPassword"
            component="input"
            type="password"
            placeholder="Password Anterior"
          />
        </div>
      </div>
      <div>
        <label>Password Nuevo</label> 
        <div>
          <Field
            name="newPassword"
            component="input"
            type="password"
            placeholder="Nuevo Password"
          />
        </div>
      </div>
      <div>
        <button type="submit" disabled={pristine || submitting}>
          Guardar
        </button>
        <button type="button" disabled={pristine || submitting} onClick={reset}>
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'simple', // a unique identifier for this form
})(SimpleForm);
