import React from 'react';
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  CardActions,
  ListButton,
  TextInput,
  FormDataConsumer,
  required,
  minLength,
  maxLength,
  regex,
} from 'react-admin';

const VTelefono = [required(), minLength(10), maxLength(11), regex(/^[0-9]+$/, 'Debe contener solo numeros.')];

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const ServiceEditActions = ({ basePath, data }) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath} />
  </CardActions>
);

const optionRenderer = choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`;

const ServiceEditTitle = ({ record }) => (
  <span>
    {record ? `Editar Celular: ${record.telefono}` : ''}
  </span>
);

const validateCelularEdit = (values) => {
  const errors = {};
  if (values.asignado === '1') {
    if (!values.empleadoId) { errors.empleadoId = ['El empleado es requerido']; }
  }
  if (values.asignado === '2') {
    if (!values.area) { errors.area = ['El area es requerida']; }
  }
  return errors;
};

export const CelularEdit = props => (
  <Edit title={<ServiceEditTitle />} {...props} actions={<ServiceEditActions />}>
    <SimpleForm validate={validateCelularEdit}>
      <TextInput source="telefono" label="No de Telefono" validate={VTelefono} />
      <SelectInput
        source="asignado"
        choices={[
          { id: '1', name: 'Persona' },
          { id: '2', name: 'Area' },
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.asignado === '1'
                      && (
                      <ReferenceInput
                        label="Asignado a:"
                        source="empleadoId"
                        reference="epe"
                        filter={{ agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
                        {...rest}
                      >
                        <AutocompleteInput
                          optionText={optionRenderer}
                          allowEmpty
                        />
                      </ReferenceInput>
                      )
                 }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.asignado === '2'
                      && <TextInput source="area" label="Area asignada" {...rest} />
                 }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export { CelularEdit as default };
