import React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  SelectInput,
  required,
  NumberInput,
} from 'react-admin';

const validation = [required('Este campo es requerido.')];

export const SubeBanorteCreate = props => (
  <Create {...props} title="Subir Archivo de Bancos">
    <SimpleForm redirect="list">
      {/* <TextInput
        source="empresa"
        label="Empresa"
        validate={validation}
      /> */}
      <SelectInput
        source="empresa"
        choices={[
          { id: 'AVT', name: 'AVT' },
          { id: 'CHIMALLI', name: 'CHIMALLI' },
          { id: 'COGAR TRADE', name: 'COGAR TRADE' },
          { id: 'COMETRA', name: 'COMETRA' },
          { id: 'CONSILIUM', name: 'CONSILIUM' },
          { id: 'COSEIN', name: 'COSEIN' },
          { id: 'COSEPRI', name: 'COSEPRI' },
          { id: 'GALAHUI', name: 'GALAHUI' },
          { id: 'GOMET', name: 'GOMET' },
          { id: 'GRUMER ELECTRONICA', name: 'GRUMER ELECTRONICA' },
          { id: 'GRUMER GESTION', name: 'GRUMER GESTION' },
          { id: 'GSI GESTION DE EFECTIVO', name: 'GSI GESTION DE EFECTIVO' },
          { id: 'GSI PPP', name: 'GSI PPP' },
          { id: 'GSI SEGURIDAD PRIVADA', name: 'GSI SEGURIDAD PRIVADA' },
          { id: 'GSI FABRIL', name: 'GSI FABRIL' },
          { id: 'MERCURIO', name: 'MERCURIO' },
          { id: 'MONLEMA', name: 'MONLEMA' },
          { id: 'REGIO TRANSLADO', name: 'REGIO TRANSLADO' },
          { id: 'REGIO GUARDIAS', name: 'REGIO GUARDIAS' },
          { id: 'RT PRIVADA', name: 'RT PRIVADA' },
          { id: 'SECA', name: 'SECA' },
          { id: 'SEGURITEC', name: 'SEGURITEC' },
          { id: 'SEGUSEPRI', name: 'SEGUSEPRI' },
          { id: 'SEPSA', name: 'SEPSA' },
          { id: 'SEPSA CUSTODIAS', name: 'SEPSA CUSTODIAS' },
          { id: 'SEPSA TRANSPORTES', name: 'SEPSA TRANSPORTES' },
          { id: 'SESEIN', name: 'SESEIN' },
          { id: 'SEPROBAJA', name: 'SEPROBAJA' },
          { id: 'SESEPRO', name: 'SESEPRO' },
          { id: 'SESEPRI', name: 'SESEPRI' },
          { id: 'TAMEME', name: 'TAMEME' },
          { id: 'TACTICA Y REACTIVA', name: 'TACTICA Y REACTIVA' },
          { id: 'TECNOGUARDIAS', name: 'TECNOGUARDIAS' },
          { id: 'TECNOPRO', name: 'TECNOPRO' },
          { id: 'TECNOVAL', name: 'TECNOVAL' },
          { id: 'TOHUANTI', name: 'TOHUANTI' },
          { id: 'T Y R SEGURIDAD', name: 'T Y R SEGURIDAD' },
          { id: 'YACUNDA', name: 'YACUNDA' },
        ]}
        validate={validation}
      />
      <SelectInput
        source="mes"
        choices={[
          { id: '1', name: 'Enero' },
          { id: '2', name: 'Febrero' },
          { id: '3', name: 'Marzo' },
          { id: '4', name: 'Abril' },
          { id: '5', name: 'Mayo' },
          { id: '6', name: 'Junio' },
          { id: '7', name: 'Julio' },
          { id: '8', name: 'Agosto' },
          { id: '9', name: 'Septiembre' },
          { id: '10', name: 'Octubre' },
          { id: '11', name: 'Noviembre' },
          { id: '12', name: 'Diciembre' },
        ]}
        validate={validation}
      />
      <SelectInput
        source="ano"
        choices={[
          { id: '2019', name: '2019' },
          { id: '2020', name: '2020' },
          { id: '2021', name: '2021' },
        ]}
        defaultValue="2019"
        validate={validation}
      />
      <SelectInput
        source="tipoNomina"
        choices={[
          { id: '1', name: 'Semana Logistica' },
          { id: '2', name: 'Semana Guardias' },
          { id: '3', name: 'Quincena' },
          { id: '4', name: 'Catorcena Operativa' },
          { id: '5', name: 'Catorcena Administrativa' },
        ]}
        validate={validation}
      />
      <NumberInput
        source="noNomina"
        label="Numero de Nomina"
        validate={validation}
      />
      <SelectInput
        source="tipoMovimiento"
        choices={[
          { id: '1', name: 'Ordinario' },
          // { id: '2', name: 'PPS' },
          { id: '3', name: 'Complemento Nomina' },
        ]}
        validate={validation}
      />
      <SelectInput
        source="banco"
        choices={[
          { id: 'hsbc', name: 'HSBC' },
          { id: 'banamex', name: 'Banamex' },
          { id: 'bbva', name: 'BBVA' },
          { id: 'santander', name: 'Santander' },
          { id: 'banorte', name: 'Banorte' },
        ]}
        validate={validation}
      />
      <SelectInput
        source="tipoArchivo"
        choices={[
          { id: '1', name: 'Dispersion' },
          { id: '2', name: 'Comprobante' },
        ]}
        validate={validation}
      />
      <FileInput source="files" label="Archivo TXT" accept="text/plain" multiple={false} validate={validation} >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export { SubeBanorteCreate as default };
