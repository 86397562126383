// in src/Dashboard.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import URL from '../url';

// const json = require('./repcelulares.json');

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const columns = ['telefono', 'Sucursal', 'Nombre', 'Meta', 'MetasCelulars[0].enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'];

const options = {
  selectableRows: false, // <===== will turn off checkboxes in rows,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agrupamiento: localStorage.getItem('empresa'),
      sucursal: localStorage.getItem('sucursal'),
      permiso: localStorage.getItem('permiso'),
      appV: localStorage.getItem('appV'),
      dataPersonal: [],
    };
  }

  componentDidMount() {
    const {
      appV, agrupamiento, sucursal, permiso,
    } = this.state;
    if (appV === '1') {
      showNotification('Favor de cambiar su password.');
      push('/passwordchange');
    }

    if (permiso === '1') {
      fetch(`${URL()}/gcelmes/${agrupamiento}`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    } else {
      fetch(`${URL()}/gcelmes/${agrupamiento}/totalpormes/${sucursal}`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    }
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
  })

  render() {
    const { dataPersonal } = this.state;
    return (
      <Fragment>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol} />
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                title="Reporte por Telefono"
                data={dataPersonal}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  showNotification,
  push,
})(Dashboard);
