// in src/Dashboard.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import MUIDataTable from 'mui-datatables';
import URL from '../url';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const columns = ['noEmpleado', 'Nombre', 'Mes', 'Ordinario', 'Carga Social', 'Prevision Social', 'Tiempo Extra', 'Cantidad HE', 'Total'];

const options = {
  selectableRows: false, // <===== will turn off checkboxes in rows,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appV: localStorage.getItem('appV'),
      totalesCelulares: [],
    };
  }

  componentDidMount() {
    const { appV } = this.state;
    if (appV === '1') {
      showNotification('Favor de cambiar su password.');
      push('/passwordchange');
    }

    const agrupamiento = localStorage.getItem('empresa');
    const sucursal = localStorage.getItem('sucursal');
    fetch(`${URL()}/metae?filter={"agrupamiento":"${agrupamiento}","sucursal":'${sucursal}'}&range=[0,9]&sort=["id","DESC"]`)
      .then(response => response.json())
      .then(totalesEmpleados => this.setState({ totalesEmpleados }));
  }

  render() {
    return (
      <Fragment>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol} />

            <MUIDataTable
              title="Reporte por Empleado"
              data={this.state.totalesEmpleados}
              columns={columns}
              options={options}
            />

          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  showNotification,
  push,
})(Dashboard);
