import React from 'react';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  regex,
  number,
  maxLength,
  minLength,
  RadioButtonGroupInput,
  FormDataConsumer
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
      Capturar a los empleados que SI pertenecen a la sucursal,
      pero que no están en el listado de SAP.
      </Typography>
    </Paper>
  </div>
);

const Vrequerido = [required()];
const VnoEmpleado = [required(), regex(/^[0-9]*$/i, 'Debe contener solo numeros.'), number('Debe contener solo numeros.'), maxLength(10, 'El no de empleado no puede tener mas de 10 digitos'), minLength(2)];
const Vnombres = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vpaterno = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vmaterno = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];
const Vpuesto = [required(), regex(/^[ñA-Za-z0-9 _]*[ñA-Za-z0-9][ñA-Za-z0-9 _]*$/i, 'Debe contener solo letras o numeros.')];
const Varea = [required(), regex(/^[ña-z][ña-z\s]*$/i, 'Debe contener solo letras.')];

export const EmpleadoCreate = props => (
  <div>
    <Aside />
    <Create {...props} title="Captura de empleados que no se encuentran en el listado de SAP">
      <SimpleForm redirect="list">
        <RadioButtonGroupInput
          source="tipo"
          choices={[
            { id: '1', name: 'Nomina' },
            { id: '2', name: 'Honorarios' },
          ]}
          validate={Vrequerido}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tipo === '1'

&& (
<TextInput
  label="No de Empleado"
  source="noEmpleado"
  validate={VnoEmpleado}
  {...rest}
/>
)
            }
        </FormDataConsumer>
        <TextInput
          label="Nombres"
          source="nombres"
          validate={Vnombres}
        />
        <TextInput
          label="Apellido Paterno"
          source="paterno"
          validate={Vpaterno}
        />
        <TextInput
          label="Apellido Materno"
          source="materno"
          validate={Vmaterno}
        />
        <TextInput
          label="Puesto"
          source="denominacionPosiciones"
          validate={Vpuesto}
        />
        <TextInput
          label="Area"
          source="denominacionUnidadOrganizacional"
          validate={Varea}
        />
      </SimpleForm>
    </Create>
  </div>
);

export { EmpleadoCreate as default };
