import React, { Fragment } from 'react';
import {
  EditButton,
  List,
  Responsive,
  Datagrid,
  TextField,
  downloadCSV,
  TextInput,
  Filter,
  Pagination,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import { BulkDeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
// import ResetViewsButton from './components/updateConfirmado';
// import Totales from './components/totales';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
        PANTALLA DE VALIDACION PARA QUITAR DEL LISTADO AL PERSONAL QUE NO PERTENECE A LA SUCURSAL.
      </Typography>
      <Typography component="p">
        SELECCIONA EL PERSONAL QUE NO LABORA EN TU SUCURSAL
        Y PROCEDE A PRESIONAR EL BOTON DESACTIVAR EMPLEADO.
      </Typography>
    </Paper>
    {/* <Totales /> */}
  </div>
);

const exporters = (empleados) => {
  const data = empleados;
  const csv = convertToCSV({
    data,
    // select and order fields in the export
    fields: ['noEmpleado', 'nombres', 'paterno', 'materno', 'denominacionPosiciones', 'denominacionUnidadOrganizacional'],
  });
  downloadCSV(csv, 'empleados'); // download as 'empleados.csv` file
};

const PostBulkActionButtons = props => (
  <Fragment>
    {/* <ResetViewsButton label="Confirmar" {...props} /> */}
  </Fragment>
);

const PostRowClick = (props) => {
  const { basePath, record } = props;
  const { fuente } = record;
  if (fuente === 2) {
    return <EditButton basePath={basePath} record={record} />;
  }
  return '';
};

const empleadoListRowStyle = record => ({
  backgroundColor: record.fuente === 2 ? '#efe' : 'white',
});

const EmpleadoFilter = props => (
  <Filter {...props}>
    <TextInput label="No Empleado" source="noEmpleado" alwaysOn />
    <TextInput label="Empresa" source="empresa" alwaysOn />
    <TextInput label="Sucursal" source="sucursal" alwaysOn />
  </Filter>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const EmpleadosDisputadosList = props => (
  <div>
    <Aside />
    <List
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      title="Empleados Registrados en Sucursal"
      exporter={exporters}
      filters={<EmpleadoFilter />}
      pagination={<PostPagination />}
      perPage={20}
    >
      <Responsive
        medium={(
          <Datagrid rowStyle={empleadoListRowStyle}>
            <TextField source="noEmpleado" label="Empleado" />
            <TextField source="nombres" label="Nombres" />
            <TextField source="paterno" label="Paterno" />
            <TextField source="materno" label="Materno" />
            <TextField source="empresa" label="Empresa" />
            <TextField source="sucursal" label="Sucursal" />
            <PostRowClick />
          </Datagrid>
        )}
      />
    </List>
  </div>
);


export default withStyles(styles)(EmpleadosDisputadosList);
// export default EmpleadoList;
