// in ./updateConfirmado.js
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Confirm, crudUpdateMany,
} from 'react-admin';

class AceptarBajaButton extends Component {
    state = {
      isOpen: false,
      titulo: '',
    }

    titleString = '';

    handleClick = () => {
      this.setState({
        isOpen: true,
        titulo: `${this.props.selectedIds.length} empleados fueron seleccionados`,
      });
    };

    handleDialogClose = () => {
      this.setState({ isOpen: false });
      // console.log(this.state.seleccionados);
    };

    handleConfirm = () => {
      const {
        basePath, crudUpdateMany, resource, selectedIds,
      } = this.props;
      crudUpdateMany(resource, selectedIds, { corregido: 0 }, basePath);
      this.setState({ isOpen: true });
    };

    render() {
      const {
        isOpen,
        titulo,
      } = this.state;
      return (
        <Fragment>
          <Button label="ACEPTAR BAJA" onClick={this.handleClick} />
          <Confirm
            isOpen={isOpen}
            title={titulo}
            content="Estos empleados seran marcados como baja de su sucursal, desea continuar?"
            onConfirm={this.handleConfirm}
            onClose={this.handleDialogClose}
          />
        </Fragment>
      );
    }
}

export default connect(undefined, { crudUpdateMany })(AceptarBajaButton);
