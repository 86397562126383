import React from 'react';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  CreateButton,
  RefreshButton,
  CardActions,
} from 'react-admin';

const ListActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} label="Capturar Celular" />
    <RefreshButton />
  </CardActions>
);

const empleadoListRowStyle = record => ({
  backgroundColor: record.fuente === 2 ? '#efe' : 'white',
});

export const CelularCorregidoList = props => (
  <List {...props} bulkActionButtons={false} title="Empleados Reconocidos" actions={<ListActions />}>
    <Responsive
      medium={(
        <Datagrid rowStyle={empleadoListRowStyle}>
          <TextField source="telefono" label="Telefono" />
        </Datagrid>
)}
    />
  </List>
);

export default CelularCorregidoList;
