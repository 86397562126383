import VehiculosCreate from './vehiculoscreate';
import VehiculosEdit from './vehiculosedit';
import VehiculosShow from './vehiculosshow';
import VehiculosList from './vehiculoslist';

export default {
  create: VehiculosCreate,
  edit: VehiculosEdit,
  show: VehiculosShow,
  list: VehiculosList,
};
