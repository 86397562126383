import React from 'react';
import {
  List,
  Responsive,
  Datagrid,
  ReferenceField,
  TextField,
  CardActions,
  CreateButton,
  RefreshButton,
  EditButton,
  Pagination,
  FunctionField,
  downloadCSV,
  ExportButton,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const ListActions = ({
  basePath, total, resource, currentSort, filterValues, exporter,
}) => (
  <CardActions>
    <CreateButton basePath={basePath} label="Capturar Vehiculo" />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton />
  </CardActions>
);

const exporters = (vehiculos) => {
  const data = vehiculos;
  const csv = convertToCSV({
    data,
    // select and order fields in the export
    fields: ['placa', 'noSerie', 'noEconomico', 'noTarjeta', 'area', 'empleadoId'],
  });
  downloadCSV(csv, 'vehiculos'); // download as 'vehiculos.csv` file
};

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const GasolinaList = props => (
  <List
    {...props}
    bulkActions={false}
    title="Parque vehicular y relacion de tarjetas de gasolina asignadas"
    actions={<ListActions />}
    pagination={<PostPagination />}
    perPage={20}
    filter={{ fuente: 2 }}
    exporter={exporters}
  >
    <Responsive
      medium={(
        <Datagrid>
          <TextField source="noEconomico" label="Numero Economico" />
          <TextField source="placa" label="Placa" />

          <ReferenceField label="Tipo de Vehiculo" source="tipoVehiculoId" reference="tv" linkType={false}>
            <TextField source="tipoDesc" />
          </ReferenceField>

          <ReferenceField label="Tipo Combustible" source="tipoCombustibleId" reference="tc" linkType={false}>
            <TextField source="tipoDesc" />
          </ReferenceField>

          <TextField source="noSerie" label="Numero de Motor" />
          <TextField source="noTarjeta" label="Tarjeta" />
          <FunctionField label="Empleado o Area" render={record => ((record.Empleado) ? `${record.Empleado.paterno} ${record.Empleado.materno} ${record.Empleado.nombres} ` : `${record.area}`)} />
          <EditButton />

        </Datagrid>
)}
    />
  </List>
);

export default GasolinaList;
// export { ServiceList as default };
