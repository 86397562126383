import React from 'react';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  downloadCSV,
  Filter,
  TextInput,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1} />
  </div>
);

const EmpleadoFilter = props => (
  <Filter {...props}>
    <TextInput label="No Empleado" source="noEmpleado" alwaysOn />
  </Filter>
);

const exporters = (empleados) => {
  const data = empleados;
  const csv = convertToCSV({
    data,
    // select and order fields in the export
    fields: ['noEmpleado', 'nombres', 'paterno', 'materno', 'denominacionPosiciones', 'denominacionUnidadOrganizacional'],
  });
  downloadCSV(csv, 'empleados'); // download as 'empleados.csv` file
};

const empleadoListRowStyle = record => ({
  backgroundColor: record.fuente === 2 ? '#efe' : 'white',
});

export const EmpleadosNoReconocidosList = props => (
  <div>
    <Aside />
    <List
      {...props}
      bulkActionButtons={false}
      title="Empleados sin Cuenta Bancaria"
      exporter={exporters}
      filters={<EmpleadoFilter />}
    >
      <Responsive
        medium={(
          <Datagrid rowStyle={empleadoListRowStyle}>
            <TextField source="noEmpleado" label="Empleado" />
            <TextField source="nombreCompleto" label="Nombre" />
            <TextField source="denominacionPosiciones" label="Posicion" />
            <TextField source="denominacionUnidadOrganizacional" label="Area" />
            <TextField source="cuenta" label="cuenta" />
          </Datagrid>
        )}
      />
    </List>
  </div>
);


export default withStyles(styles)(EmpleadosNoReconocidosList);
// export default EmpleadoList;
