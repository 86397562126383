import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  EditButton,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import MetasGasolinasEdit from './metasgasolinasedit';
// import { EditButton } from 'ra-ui-materialui/lib/button';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  drawerContent: {
    width: 300,
  },
});

const PostFilter = props => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <TextInput label="Paterno" source="paterno" defaultValue="" />
  </Filter>
);

class MetasGasolinasList extends React.Component {
  handleClose = () => {
    this.props.push('/metag');
  };

  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          bulkActionButtons={false}
          title="Metas para tarjetas de gasolina en sucursal"
          filter={{
            agrupamiento: localStorage.getItem('empresa'),
            sucursal: localStorage.getItem('sucursal'),
          }}
          filters={<PostFilter />}
        >
          <Responsive
            medium={(
              <Datagrid>
                <TextField source="placa" label="Placa" />
                <TextField source="noTarjeta" label="Tarjeta" />
                <TextField source="Empleado.noEmpleado" label="No de Empleado" />
                <TextField source="Empleado.nombres" label="Nombres" />
                <TextField source="Empleado.paterno" label="Paterno" />
                <TextField source="noEconomico" label="No Economico" />
                <TextField source="montoBaja" label="Meta" />
                <TextField source="MetasGasolina[0].enero" label="Enero" />
                <TextField source="MetasGasolina[0].febrero" label="Febrero" />
                <TextField source="MetasGasolina[0].marzo" label="Marzo" />
                <TextField source="MetasGasolina[0].abril" label="Abril" />
                <TextField source="MetasGasolina[0].mayo" label="Mayo" />
                <TextField source="MetasGasolina[0].junio" label="Junio" />
                {/* <TextField source="MetasCelulars[0].julio" label="Julio" />
                <TextField source="MetasCelulars[0].agosto" label="Agosto" />
                <TextField source="MetasCelulars[0].septiembre" label="Septiembre" />
                <TextField source="MetasCelulars[0].octubre" label="Octubre" />
                <TextField source="MetasCelulars[0].noviembre" label="Noviembre" />
                <TextField source="MetasCelulars[0].diciembre" label="Diciembre" /> */}
                <TextField source="MetasCelulars[0].acumulado" label="Total" />
                <EditButton label="Meta" />
              </Datagrid>
            )}
          />
        </List>

        <Route path="/metag/:id">
          {({ match }) => {
            const isMatch = match
                                && match.params
                                && match.params.id !== 'create';

            return (
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={this.handleClose}
              >
                {isMatch ? (
                  <MetasGasolinasEdit
                    className={styles.drawerContent}
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={styles.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>

    );
  }
}


export default compose(
  connect(
    undefined,
    { push },
  ),
  withStyles(styles),
)(MetasGasolinasList);


// export default withStyles(styles)(MetasGasolinasList);
// export default EmpleadoList;
