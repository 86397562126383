import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import MetasCelularesEdit from './metascelularesedit';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  drawerContent: {
    width: 300,
  },
});

// function mes() {
//   const z = {
//     1: 'enero',
//     2: 'febrero',
//     3: 'marzo',
//     4: 'abril',
//     5: 'mayo',
//     6: 'junio',
//     7: 'julio',
//     8: 'agosto',
//     9: 'septiembre',
//     10: 'octubre',
//     11: 'noviembre',
//     12: 'diciembre',
//   };
//   const d = new Date();
//   const a = d.getMonth();
//   return z[a];
// }

// const celularesListRowStyle = record => (
//   { backgroundColor: record.MetasCelulars[0].junio > record.junio ? '#efe' : 'white' }
// );

class MetasCelularesList extends React.Component {
  handleClose = () => {
    this.props.push('/metac');
  };

  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          bulkActionButtons={false}
          title="Metas para celulares en sucursal"
          filter={{
            agrupamiento: localStorage.getItem('empresa'),
            sucursal: localStorage.getItem('sucursal'),
          }}
          perPage={20}
        >
          <Responsive
            medium={(
              <Datagrid>
                <TextField source="telefono" label="Telefono" />
                <FunctionField label="Empleado o Area" render={record => ((record.Empleado) ? `${record.Empleado.paterno} ${record.Empleado.materno} ${record.Empleado.nombres} ` : `${record.area}`)} />
                <TextField source="montoBaja" label="Meta" />
                <TextField source="adicionalBaja" label="Meta Adicionales" />
                <TextField source="MetasCelulars[0].enero" label="Enero" />
                <TextField source="MetasCelulars[0].eneroA" label="Adicionales" />
                <TextField source="MetasCelulars[0].febrero" label="Febrero" />
                <TextField source="MetasCelulars[0].febreroA" label="Adicionales" />
                <TextField source="MetasCelulars[0].marzo" label="Marzo" />
                <TextField source="MetasCelulars[0].marzoA" label="Adicionales" />
                <TextField source="MetasCelulars[0].abril" label="Abril" />
                <TextField source="MetasCelulars[0].abrilA" label="Adicionales" />
                <TextField source="MetasCelulars[0].mayo" label="Mayo" />
                <TextField source="MetasCelulars[0].mayoA" label="Adicionales" />
                <TextField source="MetasCelulars[0].junio" label="Junio" />
                <TextField source="MetasCelulars[0].junioA" label="Adicionales" />
                {/* <TextField source="MetasCelulars[0].julio" label="Julio" />
                <TextField source="MetasCelulars[0].agosto" label="Agosto" />
                <TextField source="MetasCelulars[0].septiembre" label="Septiembre" />
                <TextField source="MetasCelulars[0].octubre" label="Octubre" />
                <TextField source="MetasCelulars[0].noviembre" label="Noviembre" />
                <TextField source="MetasCelulars[0].diciembre" label="Diciembre" /> */}
                {/* <TextField source="MetasCelulars[0].acumulado" label="Total" /> */}
                {/* <FunctionField label="Promedio" render={record => ((record.MetasCelulars[0].enero + record.MetasCelulars[0].febrero + record.MetasCelulars[0].marzo + record.MetasCelulars[0].abril + record.MetasCelulars[0].mayo + record.MetasCelulars[0].junio + record.MetasCelulars[0].julio)/6)} /> */}
                <EditButton label="Meta" />
              </Datagrid>
            )}
          />
        </List>

        <Route path="/metac/:id">
          {({ match }) => {
            const isMatch = match
                                && match.params
                                && match.params.id !== 'create';

            return (
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={this.handleClose}
              >
                {isMatch ? (
                  <MetasCelularesEdit
                    className={styles.drawerContent}
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={styles.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>

    );
  }
}


export default compose(
  connect(
    undefined,
    { push },
  ),
  withStyles(styles),
)(MetasCelularesList);
