import React, { Fragment } from 'react';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const PostBulkActionButtons = props => (
  <Fragment>
    {/* <ResetViewsButton label="Confirmar" {...props} /> */}
  </Fragment>
);

const PostFilter = props => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <TextInput label="Paterno" source="paterno" defaultValue="" />
  </Filter>
);

export const BajasNoReconocidasList = props => (
  <div>
    <List
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      title="Bajas no reconocidas"
      filter={{
        agrupamiento: localStorage.getItem('empresa'),
        sucursal: localStorage.getItem('sucursal'),
        corregido: 3,
      }}
      filters={<PostFilter />}
    >
      <Responsive
        medium={(
          <Datagrid>
            <TextField source="noEmpleado" label="Empleado" />
            <TextField source="nombres" label="Nombres" />
            <TextField source="paterno" label="Paterno" />
            <TextField source="materno" label="Materno" />
            <TextField source="denominacionPosiciones" label="Posicion" />
            <TextField source="denominacionUnidadOrganizacional" label="Area" />
          </Datagrid>
        )}
      />
    </List>
  </div>
);


export default withStyles(styles)(BajasNoReconocidasList);
// export default EmpleadoList;
