import React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
  SelectInput,
} from 'react-admin';

export const SubeBanamexCreate = props => (
  <Create {...props} title="Subir Archivo de Banamex Ordinario">
    <SimpleForm redirect="list">
      <TextInput source="empresa" label="Empresa" />
      <SelectInput
        source="mes"
        choices={[
          { id: '1', name: 'Enero' },
          { id: '2', name: 'Febrero' },
          { id: '3', name: 'Marzo' },
          { id: '4', name: 'Abril' },
          { id: '5', name: 'Mayo' },
          { id: '6', name: 'Junio' },
          { id: '7', name: 'Julio' },
          { id: '8', name: 'Agosto' },
          { id: '9', name: 'Septiembre' },
          { id: '10', name: 'Octubre' },
          { id: '11', name: 'Noviembre' },
          { id: '12', name: 'Diciembre' },
        ]}
      />
      <SelectInput
        source="ano"
        choices={[
          { id: '2019', name: '2019' },
          { id: '2020', name: '2020' },
        ]}
        defaultValue="2019"
      />
      <FileInput source="files" label="Related files" accept="text/plain" multiple={false}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export { SubeBanamexCreate as default };
