import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import URL from '../url';
import Form from './Components/form';

class Configuration extends Component {
  handleClick = async (values) => {
    const { push, showNotification } = this.props;
    const usuario = localStorage.getItem('username');
    // const response = await fetch(`${URL()}/auth/`, {
    const jsonValues = JSON.stringify(values);
    const response = await fetch(`${URL()}/auth/${usuario}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: jsonValues,
    });
    if (response.ok) {
      showNotification('El Password ha sido cambiado');
      push('/');
    } else {
      showNotification('El Password Anterior no es correcto');
    }
  }

  render() {
    return <Form onSubmit={this.handleClick} />;
  }
}

export default connect(null, {
  showNotification,
  push,
})(Configuration);
