import React from 'react';
import {
  DateField,
  CardActions,
  ListButton,
  Show,
  TabbedShowLayout,
  ReferenceField,
  TextField,
  EditButton,
  Datagrid,
  ReferenceManyField,
  ShowButton,
  Tab,
  ImageField,
} from 'react-admin';
// import PrintButton from './components/printService';
// import CloseServiceButton from './components/closeServiceButton';

const ServiceShowTitle = ({ record }) => (
  <span>
    {record ? `Ingreso No. ${record.prefijoFolio} ${record.folio}` : ''}
  </span>
);
const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const ServiceShowActions = ({ basePath, data }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    {/* <PrintButton /> */}
    {/* <CloseServiceButton record={data} /> */}
  </CardActions>
);

export const VehiculosShow = props => (
  <Show
    title={<ServiceShowTitle />}
    {...props}
    actions={<ServiceShowActions />}
  >
    <TabbedShowLayout>
      <Tab label="Resumen">
        <TextField source="prefijoFolio" label="Prefijo" />
        <TextField source="folio" label="Folio" />
        <DateField
          source="createdAt"
          label="Fecha"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
          locales="es-MX"
        />
        <ReferenceField
          label="Vehiculo"
          source="vehiculeId"
          reference="autos"
          linkType="show"
        >
          <TextField source="plate" />
        </ReferenceField>
        <ReferenceField
          label="Cliente"
          source="clientId"
          reference="clientes"
          linkType="show"
        >
          <TextField source="names" />
        </ReferenceField>
        <TextField source="comments" label="Comentarios" />
        <ImageField source="diagnostic.diagnostic" title="Diagnostico" />
        <ReferenceManyField
          label="Ordenes"
          reference="ordenes"
          target="serviceId"
        >
          <Datagrid>
            <TextField source="id" label="Folio" />
            <TextField source="vendor.name" label="Proveedor" />
            <TextField source="total" label="Total" />
            <TextField source="status" label="Estatus" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Checklist">
        <ReferenceManyField
          label="Checklist"
          reference="checklist"
          target="serviceId"
        >
          <Datagrid>
            <TextField
              source="serviceChecklistConcept.description"
              label="Conceptos"
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Imagenes">
        <ImageField source="servicesImages" src="url" title="name" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default VehiculosShow;
