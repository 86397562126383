import React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
} from 'react-admin';


export const SubeEdenredCreate = props => (
  <Create {...props} title="Subir Archivo Edenred">
    <SimpleForm redirect="list">
      <TextInput source="mes" label="Mes" />
      <FileInput source="files" label="Related files" multiple={false}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export { SubeEdenredCreate as default };
