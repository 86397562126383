import React, { Fragment } from 'react';
import {
  List,
  Responsive,
  Datagrid,
  ReferenceField,
  TextField,
  CardActions,
  CreateButton,
  DeleteButton,
  RefreshButton,
  EditButton,
  Pagination,
  FunctionField,
  downloadCSV,
  ExportButton,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import DesconocerVehiculoButton from './components/botonDesconocer';
import ConfirmDeleteButton from './components/deleteButtonConfirmation';

const ListActions = ({
  basePath, total, resource, currentSort, filterValues, exporter,
}) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton />
  </CardActions>
);

const exporters = (vehiculos) => {
  const data = vehiculos;
  const csv = convertToCSV({
    data,
    // select and order fields in the export
    fields: [
      'noEco',
      'denominacionActivoFijo',
      'placas',
      'tipoVehiculoId',
      'tipoCombustibleId',
      'noSerie',
      'noTarjeta',
      'area',
      'empleadoId',
      'reconocido',
      'unidad',
      'tarjetaCirculacion',
      'noSerieMotor',
      'tanqueDSerie',
      'tanqueDMarca',
      'tanqueDFoto',
      'tanqueISerie',
      'tanqueIMarca',
      'tanqueIFoto',
    ],
  });
  downloadCSV(csv, 'vehiculos'); // download as 'vehiculos.csv` file
};


const reconocidoListRowStyle = record => ({
  backgroundColor: record.reconocido === 1 ? '#efe' : 'white',
});

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const PostRowClick = (props) => {
  const { basePath, record } = props;
  const { reconocido } = record;
  // if (reconocido === 0) {
  return <EditButton basePath={basePath} record={record} label="Editar" />;
  // }
  // return '';
};

const PostBulkActionButtons = props => (
  <Fragment>
    <DesconocerVehiculoButton label="Desconocer" {...props} />
  </Fragment>
);

export const VehiculosList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    title="Parque vehicular y relacion de tarjetas de gasolina asignadas"
    actions={<ListActions />}
    pagination={<PostPagination />}
    perPage={20}
    filter={{ fuente: 1 }}
    exporter={exporters}
    sort={{ field: 'noEco', order: 'DESC' }}    
  >
    <Responsive
      medium={(
        <Datagrid rowStyle={reconocidoListRowStyle}>
          <TextField source="noEco" label="No Economico" />
          <TextField source="denominacionActivoFijo" label="Denominacion" />
          <TextField source="modelo" label="Modelo" />
          <TextField source="placas" label="Placas" />

          <ReferenceField label="Tipo de Vehiculo" source="tipoVehiculoId" reference="tv" linkType={false} allowEmpty>
            <TextField source="tipoDesc" />
          </ReferenceField>

          <ReferenceField label="Tipo Combustible" source="tipoCombustibleId" reference="tc" linkType={false} allowEmpty>
            <TextField source="tipoDesc" />
            {/* <FunctionField render={referenceRecord => ((referenceRecord.tipoDesc) ? `${referenceRecord.tipoDesc}` : '')} /> */}
          </ReferenceField>

          <TextField source="noSerie" label="No de Serie" />
          <TextField source="noTarjeta" label="Tarjeta" />
          <FunctionField label="Empleado o Area" render={record => ((record.Empleado) ? `${record.Empleado.paterno} ${record.Empleado.materno} ${record.Empleado.nombres} ` : (record.area) ? `${record.area}` : '')} />
          <PostRowClick />
          <ConfirmDeleteButton />

        </Datagrid>
)}
    />
  </List>
);

export default VehiculosList;
// export { ServiceList as default };
