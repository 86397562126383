// in ./updateConfirmado.js
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { crudUpdateMany } from 'react-admin';
import URL from '../../../url';

class ResetViewsButton extends Component {
    state = {
      isOpen: false,
      data: {},
    }

    componentDidMount() {
      const agrupamiento = localStorage.getItem('empresa');
      fetch(`${URL()}/epe/total/${agrupamiento}`)
        .then(response => response.json())
        .then(data => this.setState({ data }));
    }

    render() {
      const {
        data,
      } = this.state;
      return (
        <Fragment>
          <div>
            Total de personal marcado como no reconocido por la sucursal:
            {data.enr}
          </div>
        </Fragment>
      );
    }
}

export default connect(undefined, { crudUpdateMany })(ResetViewsButton);
