import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  drawerContent: {
    width: 300,
  },
});

class GastoGasolinaMesList extends React.Component {
  handleClose = () => {
    this.props.push('/metac');
  };

  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          bulkActionButtons={false}
          title="Gastos total de gasolina de la Sucursal"
          filter={{
            agrupamiento: localStorage.getItem('empresa'),
            sucursal: localStorage.getItem('sucursal'),
            denominacion: 'activo',
          }}
        >
          <Responsive
            medium={(
              <Datagrid>
                <TextField source="concepto" label="Concepto" />
                <TextField source="noviembre" label="Noviembre" />
                <TextField source="diciembre" label="Diciembre" />
                <TextField source="enero" label="Enero" />
                <TextField source="febrero" label="Febrero" />
                <TextField source="marzo" label="Marzo" />
                <TextField source="abril" label="Abril" />
                <TextField source="mayo" label="Mayo" />
                <TextField source="junio" label="Junio" />
              </Datagrid>
            )}
          />
        </List>
      </Fragment>

    );
  }
}


export default compose(
  connect(
    undefined,
    { push },
  ),
  withStyles(styles),
)(GastoGasolinaMesList);


// export default withStyles(styles)(MetasCelularesList);
// export default EmpleadoList;
