export const codigoNacionalOcupacion = [
  {
    codigo: '04',
    descripcion: 'Trabajadores De La E',
  },
];

export const tituloProfesional = [
  {
    codigo: 605,
    descripcion: 'ANTROPOLOGO FISICO',
  },
  {
    codigo: 606,
    descripcion: 'ANTROPOLOGO SOCIAL',
  },
  {
    codigo: 701,
    descripcion: 'ARQUITECTO',
  },
  {
    codigo: 604,
    descripcion: 'BIOLOGO',
  },
  {
    codigo: 862,
    descripcion: 'CARDENAL',
  },
  {
    codigo: 406,
    descripcion: 'CIRUJANO DENTISTA',
  },
  {
    codigo: 502,
    descripcion: 'CONTADOR PUBLICO',
  },
  {
    codigo: 501,
    descripcion: 'CONTADOR PUBLICO AUDITOR',
  },
  {
    codigo: 603,
    descripcion: 'ETNOLOGO',
  },
  {
    codigo: 602,
    descripcion: 'FISICO',
  },
  {
    codigo: 601,
    descripcion: 'FISICO MATEMATICO',
  },
  {
    codigo: 870,
    descripcion: 'GENERAL',
  },
  {
    codigo: 101,
    descripcion: 'ING. ADMINISTRADOR DE SISTEMAS',
  },
  {
    codigo: 103,
    descripcion: 'ING.AGRONOMO',
  },
  {
    codigo: 102,
    descripcion: 'ING. AGROINDUSTRIAL',
  },
  {
    codigo: 104,
    descripcion: 'ING.AGRONOMO ADMINISTRADOR',
  },
  {
    codigo: 105,
    descripcion: 'ING. AGRONOMO FITOTECNISTA',
  },
  {
    codigo: 106,
    descripcion: 'ING. AGRONOMO FRUTICULTOR',
  },
  {
    codigo: 107,
    descripcion: 'ING. AGRONOMO HORTICULTOR',
  },
  {
    codigo: 108,
    descripcion: 'ING. AGRONOMO PARASITOLOGO',
  },
  {
    codigo: 109,
    descripcion: 'ING. AGRONOMO ZOOTECNISTA',
  },
  {
    codigo: 110,
    descripcion: 'ING. BIOMEDICA',
  },
  {
    codigo: 111,
    descripcion: 'ING. BIOQUIMICO',
  },
  {
    codigo: 112,
    descripcion: 'ING. BIOQUIMICO EN ALIMENTOS MARINOS',
  },
  {
    codigo: 113,
    descripcion: 'ING. CIVIL',
  },
  {
    codigo: 114,
    descripcion: 'ING. COMPUTACION ADMTVA Y DE PRODUCCION',
  },
  {
    codigo: 115,
    descripcion: 'ING. ELECTRICISTA',
  },
  {
    codigo: 116,
    descripcion: 'ING. ELECTRICO ADMINISTRATIVO',
  },
  {
    codigo: 117,
    descripcion: 'ING. ELECTRONICA Y COMUNICACIONES',
  },
  {
    codigo: 118,
    descripcion: 'ING. EN ARQUITECTURA',
  },
  {
    codigo: 119,
    descripcion: 'ING. EN AUTOMATIZACION Y CONTROL',
  },
  {
    codigo: 120,
    descripcion: 'ING. EN CIBERNETICA Y C DE LA COMUNICACIÓN',
  },
  {
    codigo: 121,
    descripcion: 'ING. EN CIENCIAS COMPUTACIONALES',
  },
  {
    codigo: 122,
    descripcion: 'ING. EN COMPUTACION',
  },
  {
    codigo: 123,
    descripcion: 'ING. EN COMUNICACIONES',
  },
  {
    codigo: 124,
    descripcion: 'ING. EN CONTROL Y COMPUTACION',
  },
  {
    codigo: 125,
    descripcion: 'ING. EN ELECTRONICA',
  },
  {
    codigo: 126,
    descripcion: 'ING. EN INDUSTRIA ALIMENTARIA',
  },
  {
    codigo: 127,
    descripcion: 'ING. EN PLANEACION',
  },
  {
    codigo: 128,
    descripcion: 'ING. EN PLANEACION Y DISEÑO',
  },
  {
    codigo: 129,
    descripcion: 'ING. EN PRODUCCION',
  },
  {
    codigo: 130,
    descripcion: 'ING. EN SISTEMAS',
  },
  {
    codigo: 131,
    descripcion: 'ING. EN SISTEMAS COMPUTACIONALES',
  },
  {
    codigo: 132,
    descripcion: 'ING. EN SISTEMAS ELECTRONICOS',
  },
  {
    codigo: 133,
    descripcion: 'ING. INDUSTRIAL',
  },
  {
    codigo: 134,
    descripcion: 'ING. INDUSTRIAL ADMINISTRADOR',
  },
  {
    codigo: 135,
    descripcion: 'ING. EN PRODUCION',
  },
  {
    codigo: 136,
    descripcion: 'ING. INDUSTRIAL Y DE SISTEMAS',
  },
  {
    codigo: 137,
    descripcion: 'ING. INDUSTRIAL Y ELECTRONICA',
  },
  {
    codigo: 138,
    descripcion: 'ING. MECANICO',
  },
  {
    codigo: 139,
    descripcion: 'ING. MECANICO ADMINISTRADOR',
  },
  {
    codigo: 140,
    descripcion: 'ING. MECANICO ELECTRICISTA',
  },
  {
    codigo: 141,
    descripcion: 'ING. MECANICO ELECTRICO',
  },
  {
    codigo: 142,
    descripcion: 'ING. METALURGICO',
  },
  {
    codigo: 143,
    descripcion: 'ING. QUIMICO',
  },
  {
    codigo: 144,
    descripcion: 'ING. QUIMICO ADMINISTRADOR',
  },
  {
    codigo: 145,
    descripcion: 'ING. QUIMICO BACTERIOLOGO PARASITOLOGO',
  },
  {
    codigo: 146,
    descripcion: 'ING. QUIMICO BIOLOGO',
  },
  {
    codigo: 147,
    descripcion: 'ING. QUIMICO DE PROCESO',
  },
  {
    codigo: 148,
    descripcion: 'ING. QUIMICO FARMACO-BIOLOGO',
  },
  {
    codigo: 149,
    descripcion: 'ING. QUIMICO INDUSTRIAL',
  },
  {
    codigo: 150,
    descripcion: 'ING. QUIMICO Y DE SISTEMAS',
  },
  {
    codigo: 151,
    descripcion: 'ING. ZOOTECNISTA',
  },
  {
    codigo: 152,
    descripcion: 'INGENIERIA EN SISTEMAS D EINFORMACION',
  },
  {
    codigo: 153,
    descripcion: 'INGENIERO ELECTRONICO',
  },
  {
    codigo: 154,
    descripcion: 'INGENIERO EN ACUSTICA',
  },
  {
    codigo: 155,
    descripcion: 'INGENIERO EN INFORMATICA',
  },
  {
    codigo: 606,
    descripcion: 'INGLES TECNICO INDUSTRIAL',
  },
  {
    codigo: 607,
    descripcion: 'INSTRUCTOR DE LENGUA INGLESA',
  },
  {
    codigo: 202,
    descripcion: 'LIC. ADMINISTRACION BANCARIA',
  },
  {
    codigo: 203,
    descripcion: 'LIC. ADMINISTRACION EMPRESAS TURISTICAS',
  },
  {
    codigo: 204,
    descripcion: 'LIC. ADMINISTRACION INDUSTRIAL',
  },
  {
    codigo: 205,
    descripcion: 'LIC. CIENCIAS ECONOMICAS Y EMPRESARIALES',
  },
  {
    codigo: 206,
    descripcion: 'LIC. COMERCIO INTERNACIONAL',
  },
  {
    codigo: 207,
    descripcion: 'LIC. COMUNICACIÓN SOCIAL',
  },
  {
    codigo: 208,
    descripcion: 'LIC. EN ACTUARIA',
  },
  {
    codigo: 209,
    descripcion: 'LIC. EN ADMINISTRACION',
  },
  {
    codigo: 210,
    descripcion: 'LIC. EN ADMINISTRACION AGROPECUARIA',
  },
  {
    codigo: 211,
    descripcion: 'LIC. EN ADMINISTRACION DE EMPRESAS',
  },
  {
    codigo: 212,
    descripcion: 'LIC. EN ADMINISTRACION DE NEGOCIOS',
  },
  {
    codigo: 213,
    descripcion: 'LIC. EN ADMINISTRACION DE PERSONAL',
  },
  {
    codigo: 214,
    descripcion: 'LIC. EN ADMINISTARCION DE RECURSOS HUMANOS',
  },
  {
    codigo: 215,
    descripcion: 'LIC. EN ADMINISTRACION DEL TIE',
  },
  {
    codigo: 216,
    descripcion: 'LIC. EN ADMINISTRACION EDUCATIVA',
  },
  {
    codigo: 217,
    descripcion: 'LIC. EN ADMINISTRACION FINANCIERA',
  },
  {
    codigo: 218,
    descripcion: 'LIC. EN ADMINISTRACION PROFESIONAL',
  },
  {
    codigo: 219,
    descripcion: 'LIC. EN ADMINISTRACION PUBLICA',
  },
  {
    codigo: 220,
    descripcion: 'LIC. EN ADMINISTRACION Y FINANZAS',
  },
  {
    codigo: 221,
    descripcion: 'LIC. EN ADMON PUBLICA Y CIENCIAS POLITICAS',
  },
  {
    codigo: 222,
    descripcion: 'LIC. EN ADMON Y GESTION DE ORGANIZACIONES',
  },
  {
    codigo: 223,
    descripcion: 'LIC. EN AGRONEGOCIOS',
  },
  {
    codigo: 224,
    descripcion: 'LIC. EN ARTES VISUALES',
  },
  {
    codigo: 225,
    descripcion: 'LIC. EN ASUNTOS INTERNACIONALES',
  },
  {
    codigo: 226,
    descripcion: 'LIC. EN BANCA Y FINANZAS',
  },
  {
    codigo: 228,
    descripcion: 'LIC. EN CIENCIAS ADMINISTRATIVAS',
  },
  {
    codigo: 229,
    descripcion: 'LIC. EN CIENCIAS BIOLOGICAS',
  },
  {
    codigo: 230,
    descripcion: 'LIC. EN CIENCIAS COMPUTACIONALES',
  },
  {
    codigo: 231,
    descripcion: 'LIC. EN CIENCIAS DE EDUCACION',
  },
  {
    codigo: 232,
    descripcion: 'LIC. EN CIENCIAS DE LA COMUNICACIÓN',
  },
  {
    codigo: 227,
    descripcion: 'LIC. EN CIENCIAS DE LA COMUNICACIÓN Y PERIODISMO',
  },
  {
    codigo: 233,
    descripcion: 'LIC. EN CIENCIAS DE LA COMUNIDAD',
  },
  {
    codigo: 234,
    descripcion: 'LIC. EN CIENCIAS DE LA INFORMATICA',
  },
  {
    codigo: 235,
    descripcion: 'LIC. EN CIENCIAS JURIDICAS',
  },
  {
    codigo: 236,
    descripcion: 'LIC. EN CIENCIAS MARITIMAS',
  },
  {
    codigo: 237,
    descripcion: 'LIC. EN CIENCIAS NATURALES',
  },
  {
    codigo: 238,
    descripcion: 'LIC. EN CIENCIAS POLITICAS',
  },
  {
    codigo: 239,
    descripcion: 'LIC. EN CIENCIAS QUIMICAS',
  },
  {
    codigo: 240,
    descripcion: 'LIC. EN CIENCIAS SOCIALES',
  },
  {
    codigo: 241,
    descripcion: 'LIC. EN CIENCIAS Y HUMANIDADES',
  },
  {
    codigo: 242,
    descripcion: 'LIC. EN COMERCIO EXTERIOR',
  },
  {
    codigo: 243,
    descripcion: 'LIC. EN COMPUTACION ADMINISTRATIVA',
  },
  {
    codigo: 244,
    descripcion: 'LIC. EN COMUNICACIÓN GRAFICA',
  },
  {
    codigo: 245,
    descripcion: 'LIC. EN COMUNICACIÓN Y DESARROLLO ORG.',
  },
  {
    codigo: 201,
    descripcion: 'LIC. EN COMUNICACIÓN Y RELACIONES PUBLICAS',
  },
  {
    codigo: 246,
    descripcion: 'LIC. EN CONTABILIDAD',
  },
  {
    codigo: 247,
    descripcion: 'LIC. EN CONTADURIA PUBLICA',
  },
  {
    codigo: 311,
    descripcion: 'LIC. EN CONTADURIA PUBLICA',
  },
  {
    codigo: 248,
    descripcion: 'LIC. EN CONTADURIA PUBLICA Y AUDITORIA',
  },
  {
    codigo: 249,
    descripcion: 'LIC. EN CONTADURIA PUBLICA Y FINANZAS',
  },
  {
    codigo: 250,
    descripcion: 'LIC. EN CRIMINOLOGIA',
  },
  {
    codigo: 251,
    descripcion: 'LIC. EN DEPORTES',
  },
  {
    codigo: 252,
    descripcion: 'LIC. EN DERECHO',
  },
  {
    codigo: 253,
    descripcion: 'LIC. EN DERECHO PENAL',
  },
  {
    codigo: 254,
    descripcion: 'LIC. EN DERECHO Y CIENCIAS JURIDICAS',
  },
  {
    codigo: 255,
    descripcion: 'LIC. EN DERECHO Y CIENCIAS SOCIALES',
  },
  {
    codigo: 256,
    descripcion: 'LIC. EN DIBUJO GRAFICO',
  },
  {
    codigo: 257,
    descripcion: 'LIC. EN DISEÑO DE INTERIORES',
  },
  {
    codigo: 258,
    descripcion: 'LIC. EN DISEÑO DE MODAS',
  },
  {
    codigo: 259,
    descripcion: 'LIC. EN DISEÑO GRAFICO',
  },
  {
    codigo: 260,
    descripcion: 'LIC. EN DISEÑO INDUSTRIAL',
  },
  {
    codigo: 261,
    descripcion: 'LIC. EN ECONOMIA',
  },
  {
    codigo: 262,
    descripcion: 'LIC. EN ECONOMIA INTERNACIONAL',
  },
  {
    codigo: 263,
    descripcion: 'LIC. EN EDUCACION',
  },
  {
    codigo: 264,
    descripcion: 'LIC. EN EDUCACION ESPECIAL',
  },
  {
    codigo: 265,
    descripcion: 'LIC. EN EDUCACION MEDIA',
  },
  {
    codigo: 266,
    descripcion: 'LIC. EN EDUCACION PRESCOLAR',
  },
  {
    codigo: 267,
    descripcion: 'LIC. EN ELECTRONICA Y SISTEMAS DIGITALES',
  },
  {
    codigo: 268,
    descripcion: 'LIC. EN ESTUDIOS INTERNACIONALES',
  },
  {
    codigo: 269,
    descripcion: 'LIC. EN FILOSOFIA',
  },
  {
    codigo: 270,
    descripcion: 'LIC. EN FILOSOFIA Y TEOLOGIA',
  },
  {
    codigo: 271,
    descripcion: 'LIC. EN FINANZAS',
  },
  {
    codigo: 272,
    descripcion: 'LIC. EN FINANZAS INTERNACIONALES',
  },
  {
    codigo: 273,
    descripcion: 'LIC. EN HISTORIA',
  },
  {
    codigo: 274,
    descripcion: 'LIC. EN HOTELERIA Y TURISMO',
  },
  {
    codigo: 275,
    descripcion: 'LIC. EN IDIOMAS',
  },
  {
    codigo: 276,
    descripcion: 'LIC. EN INFORMATICA',
  },
  {
    codigo: 277,
    descripcion: 'LIC. EN INFORMATICA ADMINISTRATIVA',
  },
  {
    codigo: 278,
    descripcion: 'LIC. EN INGENIERIA FINANCIERA',
  },
  {
    codigo: 279,
    descripcion: 'LIC. EN INGENIERIA Y ARQUITECTURA',
  },
  {
    codigo: 280,
    descripcion: 'LIC. EN LETRAS ESPAÑOLAS',
  },
  {
    codigo: 281,
    descripcion: 'LIC. EN LETRAS INGLESAS',
  },
  {
    codigo: 282,
    descripcion: 'LIC.EN LINGÜÍSTICA APLICADA',
  },
  {
    codigo: 283,
    descripcion: 'LIC. EN MATEMATICAS',
  },
  {
    codigo: 284,
    descripcion: 'LIC. EN MERCADOTECNIA',
  },
  {
    codigo: 309,
    descripcion: 'LIC. EN NEGOCIOS INTERNACIONALES',
  },
  {
    codigo: 285,
    descripcion: 'LIC. EN NUTRICION',
  },
  {
    codigo: 310,
    descripcion: 'LIC. EN ODONTOLOGIA',
  },
  {
    codigo: 286,
    descripcion: 'LIC. EN ORGANIZACIÓN DEPORTIVA',
  },
  {
    codigo: 287,
    descripcion: 'LIC. EN PEDAGOGIA',
  },
  {
    codigo: 288,
    descripcion: 'LIC. EN PERIODISMO',
  },
  {
    codigo: 289,
    descripcion: 'LIC. EN PERIODISMO Y COMUNICACIÓN COLECTIVA',
  },
  {
    codigo: 290,
    descripcion: 'LIC. EN PSICOLOGIA',
  },
  {
    codigo: 291,
    descripcion: 'LIC. EN PSICOLOGIA CLINICA',
  },
  {
    codigo: 292,
    descripcion: 'LIC. EN PSICOLOGIA INDUSTRIAL',
  },
  {
    codigo: 293,
    descripcion: 'LIC. EN PSICOLOGIA LABORAL',
  },
  {
    codigo: 294,
    descripcion: 'LIC. EN PUBLICIDAD',
  },
  {
    codigo: 295,
    descripcion: 'LIC. EN RECURSOS HUMANOS',
  },
  {
    codigo: 296,
    descripcion: 'LIC. EN RELACIONES COMERCIALES',
  },
  {
    codigo: 298,
    descripcion: 'LIC. EN RELACIONES INDUSTRIALES',
  },
  {
    codigo: 299,
    descripcion: 'LIC. EN RELACIONES INTERNACIONALES',
  },
  {
    codigo: 301,
    descripcion: 'LIC. EN RELACIONES PUBLICAS',
  },
  {
    codigo: 300,
    descripcion: 'LIC. EN RELACIONES PUBLICAS Y COMUNICACIÓN',
  },
  {
    codigo: 297,
    descripcion: 'LIC. EN RELACIONES HUMANAS',
  },
  {
    codigo: 302,
    descripcion: 'LIC. EN SISTEMAS',
  },
  {
    codigo: 303,
    descripcion: 'LIC. EN SISTEMAS COMPUTACIONALES',
  },
  {
    codigo: 304,
    descripcion: 'LIC. EN SISTEMAS DE COMPUTACION ADMINISTRATIVA',
  },
  {
    codigo: 305,
    descripcion: 'LIC. EN SOCIOLOGIA',
  },
  {
    codigo: 306,
    descripcion: 'LIC. EN TRABAJO SOCIAL',
  },
  {
    codigo: 307,
    descripcion: 'LIC. EN TURISMO',
  },
  {
    codigo: 308,
    descripcion: 'LIC. EN ZOOTECNIA',
  },
  {
    codigo: 312,
    descripcion: 'LICENCIADO EN DERECHO',
  },
  {
    codigo: 313,
    descripcion: 'LICENCIADO EN DISEÑO PUBLICITARIO',
  },
  {
    codigo: 314,
    descripcion: 'LICENCIADO EN ECONOMIA',
  },
  {
    codigo: 801,
    descripcion: 'MAESTRO EDUCACION PRIMARIA',
  },
  {
    codigo: 802,
    descripcion: 'MAESTRO NORMALISTA',
  },
  {
    codigo: 401,
    descripcion: 'MEDICO GENERAL',
  },
  {
    codigo: 402,
    descripcion: 'MEDICO HOMEOPATA CIRUJANO PARTERO',
  },
  {
    codigo: 403,
    descripcion: 'MEDICO ODONTOLOGO',
  },
  {
    codigo: 404,
    descripcion: 'MEDICO VETERINARIO',
  },
  {
    codigo: 405,
    descripcion: 'MEDICO VETERINARIO ZOOTECNISTA',
  },
  {
    codigo: 804,
    descripcion: 'NORMAL DE EDUCACION FISICA',
  },
  {
    codigo: 803,
    descripcion: 'NORMAL BASICA',
  },
  {
    codigo: 861,
    descripcion: 'OBISPO',
  },
  {
    codigo: 860,
    descripcion: 'PRESBITERO',
  },
  {
    codigo: 805,
    descripcion: 'PROFESOR DE EDUCACION PREESCOLAR',
  },
  {
    codigo: 850,
    descripcion: 'SEÑOR',
  },
  {
    codigo: 851,
    descripcion: 'SEÑORA',
  },
  {
    codigo: 852,
    descripcion: 'SEÑORITA',
  },
  {
    codigo: 871,
    descripcion: 'TENIENTE',
  },
  {
    codigo: 156,
    descripcion: 'INGENIERO',
  },
  {
    codigo: 315,
    descripcion: 'LICENCIADO',
  },
  {
    codigo: 410,
    descripcion: 'DOCTOR(A)',
  },
  {
    codigo: 810,
    descripcion: 'PROFESOR',
  },
];

export const tipoVivienda = [
  {
    codigo: '01',
    descripcion: 'Casa',
  },
  {
    codigo: '02',
    descripcion: 'Edificio',
  },
  {
    codigo: '03',
    descripcion: 'Apartamento / Departamento',
  },
  {
    codigo: '04',
    descripcion: 'Unidad Habitacional',
  },
  {
    codigo: '08',
    descripcion: 'Mercado',
  },
];

export const tipoEmpleado = [
  {
    codigo: 10,
    descripcion: 'Sindicalizado',
  },
  {
    codigo: '03',
    descripcion: 'De confianza',
  },
];

export const nacionalidad = [
  {
    codigo: '001',
    descripcion: 'Mexicana',
  },
  {
    codigo: '002',
    descripcion: 'Norteamericana',
  },
  {
    codigo: '004',
    descripcion: 'Libanesa',
  },
  {
    codigo: '005',
    descripcion: 'Sudamericana',
  },
  {
    codigo: '006',
    descripcion: 'Centroamericana',
  },
  {
    codigo: '007',
    descripcion: 'Europea',
  },
  {
    codigo: '008',
    descripcion: 'Israelita',
  },
  {
    codigo: '009',
    descripcion: 'Japonesa',
  },
  {
    codigo: '010',
    descripcion: 'Canadiense',
  },
  {
    codigo: '099',
    descripcion: 'Otras',
  },
];

export const estadoCivil = [
  {
    codigo: 'C',
    descripcion: 'Casado/a',
  },
  {
    codigo: 'D',
    descripcion: 'Divorciado/a',
  },
  {
    codigo: 'S',
    descripcion: 'Soltero/a',
  },
  {
    codigo: 'U',
    descripcion: 'Union Libre',
  },
  {
    codigo: 'V',
    descripcion: 'Viudo/a',
  },
  {
    codigo: 'X',
    descripcion: 'Separado/a',
  },
];

export const regimenMatrimonial = [
  {
    codigo: 'M',
    descripcion: 'Bienes Mancomunados',
  },
  {
    codigo: 'S',
    descripcion: 'Bienes Separados',
  },
];

export const escolaridad = [
  {
    codigo: '001',
    descripcion: 'Sin Escolaridad',
  },
  {
    codigo: '002',
    descripcion: 'Primaria',
  },
  {
    codigo: '003',
    descripcion: 'Secundaria',
  },
  {
    codigo: '004',
    descripcion: 'Preparatoria',
  },
  {
    codigo: '005',
    descripcion: 'Carrera Tecnica',
  },
  {
    codigo: '006',
    descripcion: 'Licenciatira (profesional)',
  },
  {
    codigo: '007',
    descripcion: 'Diplomado-especialidad',
  },
  {
    codigo: '008',
    descripcion: 'Postgrado(maestria,doctorado)',
  },
  {
    codigo: '009',
    descripcion: 'Otros',
  },
  {
    codigo: '099',
    descripcion: 'Sin Escolarizar',
  },
];

export const estado = [
  {
    codigo: '01',
    estado: 'CDMX',
  },
  {
    codigo: '02',
    estado: 'AGUASCALIENTES',
  },
  {
    codigo: '03',
    estado: 'BAJA_CALIFORNIA_NORTE',
  },
  {
    codigo: '04',
    estado: 'BAJA_CALIFORNIA_SUR',
  },
  {
    codigo: '05',
    estado: 'CAMPECHE',
  },
  {
    codigo: '06',
    estado: 'COAHUILA',
  },
  {
    codigo: '07',
    estado: 'COLIMA',
  },
  {
    codigo: '08',
    estado: 'CHIAPAS',
  },
  {
    codigo: '09',
    estado: 'CHIHUAHUA',
  },
  {
    codigo: 10,
    estado: 'DURANGO',
  },
  {
    codigo: 11,
    estado: 'GUANAJUATO',
  },
  {
    codigo: 12,
    estado: 'GUERRERO',
  },
  {
    codigo: 13,
    estado: 'HIDALGO',
  },
  {
    codigo: 14,
    estado: 'JALISCO',
  },
  {
    codigo: 15,
    estado: 'ESTADO_DE_MÉXICO',
  },
  {
    codigo: 16,
    estado: 'MICHOACÁN',
  },
  {
    codigo: 17,
    estado: 'MORELOS',
  },
  {
    codigo: 18,
    estado: 'NAYARIT',
  },
  {
    codigo: 19,
    estado: 'NUEVO_LEÓN',
  },
  {
    codigo: 20,
    estado: 'OAXACA',
  },
  {
    codigo: 21,
    estado: 'PUEBLA',
  },
  {
    codigo: 22,
    estado: 'QUERÉTARO',
  },
  {
    codigo: 23,
    estado: 'QUINTANA_ROO',
  },
  {
    codigo: 24,
    estado: 'SAN_LUIS_POTOSÍ',
  },
  {
    codigo: 25,
    estado: 'SINALOA',
  },
  {
    codigo: 26,
    estado: 'SONORA',
  },
  {
    codigo: 27,
    estado: 'TABASCO',
  },
  {
    codigo: 28,
    estado: 'TAMAULIPAS',
  },
  {
    codigo: 29,
    estado: 'TLAXCALA',
  },
  {
    codigo: 30,
    estado: 'VERACRUZ',
  },
  {
    codigo: 31,
    estado: 'YUCATÁN',
  },
  {
    codigo: 32,
    estado: 'ZACATECAS',
  },
];

export const delegacion = {
  '01': [
    {
      id: 1,
      name: 'ÁLVARO OBREGÓN',
    },
    {
      id: 2,
      name: 'AZCAPOTZALCO',
    },
    {
      id: 3,
      name: 'BENITO JUÁREZ',
    },
    {
      id: 4,
      name: 'COYOACÁN',
    },
    {
      id: 5,
      name: 'CUAJIMALPA DE MORELOS',
    },
    {
      id: 6,
      name: 'CUAUHTÉMOC',
    },
    {
      id: 7,
      name: 'GUSTAVO A. MADERO',
    },
    {
      id: 8,
      name: 'IZTACALCO',
    },
    {
      id: 9,
      name: 'IZTAPALAPA',
    },
    {
      id: 10,
      name: 'LA MAGDALENA CONTRERAS',
    },
    {
      id: 11,
      name: 'MIGUEL HIDALGO',
    },
    {
      id: 12,
      name: 'MILPA ALTA',
    },
    {
      id: 13,
      name: 'TLÁHUAC',
    },
    {
      id: 14,
      name: 'TLALPAN',
    },
    {
      id: 15,
      name: 'VENUSTIANO CARRANZA',
    },
    {
      id: 16,
      name: 'XOCHIMILCO',
    },
  ],
  '02': [
    {
      id: '001',
      name: 'AGUASCALIENTES',
    },
    {
      id: '002',
      name: 'ASIENTOS',
    },
    {
      id: '003',
      name: 'CALVILLO',
    },
    {
      id: '004',
      name: 'COSÍO',
    },
    {
      id: '005',
      name: 'JESÚS MARÍA',
    },
    {
      id: '006',
      name: 'PABELLÓN DE ARTEAGA',
    },
    {
      id: '007',
      name: 'RINCÓN DE ROMOS',
    },
    {
      id: '008',
      name: 'SAN JOSÉ DE GRACIA',
    },
    {
      id: '009',
      name: 'TEPEZALÁ',
    },
    {
      id: '010',
      name: 'EL LLANO',
    },
    {
      id: '011',
      name: 'SAN FRANCISCO DE LOS ROMO',
    },
  ],
  '03': [
    {
      codigo: '001',
      descripcion: 'ENSENADA',
    },
    {
      codigo: '002',
      descripcion: 'MEXICALI',
    },
    {
      codigo: '003',
      descripcion: 'TECATE',
    },
    {
      codigo: '004',
      descripcion: 'TIJUANA',
    },
    {
      codigo: '005',
      descripcion: 'PLAYAS DE ROSARITO',
    },
  ],
  '04': [
    {
      codigo: '001',
      descripcion: 'COMONDÚ',
    },
    {
      codigo: '002',
      descripcion: 'MULEGÉ',
    },
    {
      codigo: '003',
      descripcion: 'LA PAZ',
    },
    {
      codigo: '004',
      descripcion: 'LOS CABOS',
    },
  ],
  '05': [
    {
      codigo: '001',
      descripcion: 'CALKINÍ',
    },
    {
      codigo: '002',
      descripcion: 'CAMPECHE',
    },
    {
      codigo: '003',
      descripcion: 'CARMEN',
    },
    {
      codigo: '004',
      descripcion: 'CHAMPOTÓN',
    },
    {
      codigo: '005',
      descripcion: 'HECELCHAKÁN',
    },
    {
      codigo: '006',
      descripcion: 'HOPELCHÉN',
    },
    {
      codigo: '007',
      descripcion: 'PALIZADA',
    },
    {
      codigo: '008',
      descripcion: 'TENABO',
    },
    {
      codigo: '009',
      descripcion: 'ESCÁRCEGA',
    },
  ],
  '06': [
    {
      codigo: '001',
      descripcion: 'ABASOLO',
    },
    {
      codigo: '002',
      descripcion: 'ACUÑA',
    },
    {
      codigo: '003',
      descripcion: 'ALLENDE',
    },
    {
      codigo: '004',
      descripcion: 'ARTEAGA',
    },
    {
      codigo: '005',
      descripcion: 'CANDELA',
    },
    {
      codigo: '006',
      descripcion: 'CASTAÑOS',
    },
    {
      codigo: '007',
      descripcion: 'CUATRO CIÉNEGAS',
    },
    {
      codigo: '008',
      descripcion: 'ESCOBEDO',
    },
    {
      codigo: '009',
      descripcion: 'FRANCISCO I. MADERO',
    },
    {
      codigo: '010',
      descripcion: 'FRONTERA',
    },
    {
      codigo: '011',
      descripcion: 'GENERAL CEPEDA',
    },
    {
      codigo: '012',
      descripcion: 'GUERRERO',
    },
    {
      codigo: '013',
      descripcion: 'HIDALGO',
    },
    {
      codigo: '014',
      descripcion: 'JIMÉNEZ',
    },
    {
      codigo: '015',
      descripcion: 'JUÁREZ',
    },
    {
      codigo: '016',
      descripcion: 'LAMADRID',
    },
    {
      codigo: '017',
      descripcion: 'MATAMOROS',
    },
    {
      codigo: '018',
      descripcion: 'MONCLOVA',
    },
    {
      codigo: '019',
      descripcion: 'MORELOS',
    },
    {
      codigo: '020',
      descripcion: 'MÚZQUIZ',
    },
    {
      codigo: '021',
      descripcion: 'NADADORES',
    },
    {
      codigo: '022',
      descripcion: 'NAVA',
    },
    {
      codigo: '023',
      descripcion: 'OCAMPO',
    },
    {
      codigo: '024',
      descripcion: 'PARRAS',
    },
    {
      codigo: '025',
      descripcion: 'PIEDRAS NEGRAS',
    },
    {
      codigo: '026',
      descripcion: 'PROGRESO',
    },
    {
      codigo: '027',
      descripcion: 'RAMOS ARIZPE',
    },
    {
      codigo: '028',
      descripcion: 'SABINAS',
    },
    {
      codigo: '029',
      descripcion: 'SACRAMENTO',
    },
    {
      codigo: '030',
      descripcion: 'SALTILLO',
    },
    {
      codigo: '031',
      descripcion: 'SAN BUENAVENTURA',
    },
    {
      codigo: '032',
      descripcion: 'SAN JUAN DE SABINAS',
    },
    {
      codigo: '033',
      descripcion: 'SAN PEDRO',
    },
    {
      codigo: '034',
      descripcion: 'SIERRA MOJADA',
    },
    {
      codigo: '035',
      descripcion: 'TORREÓN',
    },
    {
      codigo: '036',
      descripcion: 'VIESCA',
    },
    {
      codigo: '037',
      descripcion: 'VILLA UNIÓN',
    },
    {
      codigo: '038',
      descripcion: 'ZARAGOZA',
    },
  ],
  '07': [
    {
      codigo: '001',
      descripcion: 'ARMERÍA',
    },
    {
      codigo: '002',
      descripcion: 'COLIMA',
    },
    {
      codigo: '003',
      descripcion: 'COMALA',
    },
    {
      codigo: '004',
      descripcion: 'COQUIMATLÁN',
    },
    {
      codigo: '005',
      descripcion: 'CUAUHTÉMOC',
    },
    {
      codigo: '006',
      descripcion: 'IXTLAHUACÁN',
    },
    {
      codigo: '007',
      descripcion: 'MANZANILLO',
    },
    {
      codigo: '008',
      descripcion: 'MINATITLÁN',
    },
    {
      codigo: '009',
      descripcion: 'TECOMÁN',
    },
    {
      codigo: '010',
      descripcion: 'VILLA DE ÁLVAREZ',
    },
  ],
  '08': [
    {
      codigo: '001',
      descripcion: 'ACACOYAGUA',
    },
    {
      codigo: '002',
      descripcion: 'ACALA',
    },
    {
      codigo: '003',
      descripcion: 'ACAPETAHUA',
    },
    {
      codigo: '004',
      descripcion: 'ALTAMIRANO',
    },
    {
      codigo: '005',
      descripcion: 'AMATÁN',
    },
    {
      codigo: '006',
      descripcion: 'AMATENANGO DE LA FRONTERA',
    },
    {
      codigo: '007',
      descripcion: 'AMATENANGO DEL VALLE',
    },
    {
      codigo: '008',
      descripcion: 'ANGEL ALBINO CORZO',
    },
    {
      codigo: '009',
      descripcion: 'ARRIAGA',
    },
    {
      codigo: '010',
      descripcion: 'BEJUCAL DE OCAMPO',
    },
    {
      codigo: '011',
      descripcion: 'BELLA VISTA',
    },
    {
      codigo: '012',
      descripcion: 'BERRIOZÁBAL',
    },
    {
      codigo: '013',
      descripcion: 'BOCHIL',
    },
    {
      codigo: '014',
      descripcion: 'EL BOSQUE',
    },
    {
      codigo: '015',
      descripcion: 'CACAHOATÁN',
    },
    {
      codigo: '016',
      descripcion: 'CATAZAJÁ',
    },
    {
      codigo: '017',
      descripcion: 'CINTALAPA',
    },
    {
      codigo: '018',
      descripcion: 'COAPILLA',
    },
    {
      codigo: '019',
      descripcion: 'COMITÁN DE DOMÍNGUEZ',
    },
    {
      codigo: '020',
      descripcion: 'LA CONCORDIA',
    },
    {
      codigo: '021',
      descripcion: 'COPAINALÁ',
    },
    {
      codigo: '022',
      descripcion: 'CHALCHIHUITÁN',
    },
    {
      codigo: '023',
      descripcion: 'CHAMULA',
    },
    {
      codigo: '024',
      descripcion: 'CHANAL',
    },
    {
      codigo: '025',
      descripcion: 'CHAPULTENANGO',
    },
    {
      codigo: '026',
      descripcion: 'CHENALHÓ',
    },
    {
      codigo: '027',
      descripcion: 'CHIAPA DE CORZO',
    },
    {
      codigo: '028',
      descripcion: 'CHIAPILLA',
    },
    {
      codigo: '029',
      descripcion: 'CHICOASÉN',
    },
    {
      codigo: '030',
      descripcion: 'CHICOMUSELO',
    },
    {
      codigo: '031',
      descripcion: 'CHILÓN',
    },
    {
      codigo: '032',
      descripcion: 'ESCUINTLA',
    },
    {
      codigo: '033',
      descripcion: 'FRANCISCO LEÓN',
    },
    {
      codigo: '034',
      descripcion: 'FRONTERA COMALAPA',
    },
    {
      codigo: '035',
      descripcion: 'FRONTERA HIDALGO',
    },
    {
      codigo: '036',
      descripcion: 'LA GRANDEZA',
    },
    {
      codigo: '037',
      descripcion: 'HUEHUETÁN',
    },
    {
      codigo: '038',
      descripcion: 'HUIXTÁN',
    },
    {
      codigo: '039',
      descripcion: 'HUITIUPÁN',
    },
    {
      codigo: '040',
      descripcion: 'HUIXTLA',
    },
    {
      codigo: '041',
      descripcion: 'LA INDEPENDENCIA',
    },
    {
      codigo: '042',
      descripcion: 'IXHUATÁN',
    },
    {
      codigo: '043',
      descripcion: 'IXTACOMITÁN',
    },
    {
      codigo: '044',
      descripcion: 'IXTAPA',
    },
    {
      codigo: '045',
      descripcion: 'IXTAPANGAJOYA',
    },
    {
      codigo: '046',
      descripcion: 'JIQUIPILAS',
    },
    {
      codigo: '047',
      descripcion: 'JITOTOL',
    },
    {
      codigo: '048',
      descripcion: 'JUÁREZ',
    },
    {
      codigo: '049',
      descripcion: 'LARRÁINZAR',
    },
    {
      codigo: '050',
      descripcion: 'LA LIBERTAD',
    },
    {
      codigo: '051',
      descripcion: 'MAPASTEPEC',
    },
    {
      codigo: '052',
      descripcion: 'LAS MARGARITAS',
    },
    {
      codigo: '053',
      descripcion: 'MAZAPA DE MADERO',
    },
    {
      codigo: '054',
      descripcion: 'MAZATÁN',
    },
    {
      codigo: '055',
      descripcion: 'METAPA',
    },
    {
      codigo: '056',
      descripcion: 'MITONTIC',
    },
    {
      codigo: '057',
      descripcion: 'MOTOZINTLA',
    },
    {
      codigo: '058',
      descripcion: 'NICOLÁS RUÍZ',
    },
    {
      codigo: '059',
      descripcion: 'OCOSINGO',
    },
    {
      codigo: '060',
      descripcion: 'OCOTEPEC',
    },
    {
      codigo: '061',
      descripcion: 'OCOZOCOAUTLA DE ESPINOSA',
    },
    {
      codigo: '062',
      descripcion: 'OSTUACÁN',
    },
    {
      codigo: '063',
      descripcion: 'OSUMACINTA',
    },
    {
      codigo: '064',
      descripcion: 'OXCHUC',
    },
    {
      codigo: '065',
      descripcion: 'PALENQUE',
    },
    {
      codigo: '066',
      descripcion: 'PANTELHÓ',
    },
    {
      codigo: '067',
      descripcion: 'PANTEPEC',
    },
    {
      codigo: '068',
      descripcion: 'PICHUCALCO',
    },
    {
      codigo: '069',
      descripcion: 'PIJIJIAPAN',
    },
    {
      codigo: '070',
      descripcion: 'EL PORVENIR',
    },
    {
      codigo: '071',
      descripcion: 'VILLA COMALTITLÁN',
    },
    {
      codigo: '072',
      descripcion: 'PUEBLO NUEVO SOLISTAHUACÁN',
    },
    {
      codigo: '073',
      descripcion: 'RAYÓN',
    },
    {
      codigo: '074',
      descripcion: 'REFORMA',
    },
    {
      codigo: '075',
      descripcion: 'LAS ROSAS',
    },
    {
      codigo: '076',
      descripcion: 'SABANILLA',
    },
    {
      codigo: '077',
      descripcion: 'SALTO DE AGUA',
    },
    {
      codigo: '078',
      descripcion: 'SAN CRISTÓBAL DE LAS CASAS',
    },
    {
      codigo: '079',
      descripcion: 'SAN FERNANDO',
    },
    {
      codigo: '080',
      descripcion: 'SILTEPEC',
    },
    {
      codigo: '081',
      descripcion: 'SIMOJOVEL',
    },
    {
      codigo: '082',
      descripcion: 'SITALÁ',
    },
    {
      codigo: '083',
      descripcion: 'SOCOLTENANGO',
    },
    {
      codigo: '084',
      descripcion: 'SOLOSUCHIAPA',
    },
    {
      codigo: '085',
      descripcion: 'SOYALÓ',
    },
    {
      codigo: '086',
      descripcion: 'SUCHIAPA',
    },
    {
      codigo: '087',
      descripcion: 'SUCHIATE',
    },
    {
      codigo: '088',
      descripcion: 'SUNUAPA',
    },
    {
      codigo: '089',
      descripcion: 'TAPACHULA',
    },
    {
      codigo: '090',
      descripcion: 'TAPALAPA',
    },
    {
      codigo: '091',
      descripcion: 'TAPILULA',
    },
    {
      codigo: '092',
      descripcion: 'TECPATÁN',
    },
    {
      codigo: '093',
      descripcion: 'TENEJAPA',
    },
    {
      codigo: '094',
      descripcion: 'TEOPISCA',
    },
    {
      codigo: '096',
      descripcion: 'TILA',
    },
    {
      codigo: '097',
      descripcion: 'TONALÁ',
    },
    {
      codigo: '098',
      descripcion: 'TOTOLAPA',
    },
    {
      codigo: '099',
      descripcion: 'LA TRINITARIA',
    },
    {
      codigo: 100,
      descripcion: 'TUMBALÁ',
    },
    {
      codigo: 101,
      descripcion: 'TUXTLA GUTIÉRREZ',
    },
    {
      codigo: 102,
      descripcion: 'TUXTLA CHICO',
    },
    {
      codigo: 103,
      descripcion: 'TUZANTÁN',
    },
    {
      codigo: 104,
      descripcion: 'TZIMOL',
    },
    {
      codigo: 105,
      descripcion: 'UNIÓN JUÁREZ',
    },
    {
      codigo: 106,
      descripcion: 'VENUSTIANO CARRANZA',
    },
    {
      codigo: 107,
      descripcion: 'VILLA CORZO',
    },
    {
      codigo: 108,
      descripcion: 'VILLAFLORES',
    },
    {
      codigo: 109,
      descripcion: 'YAJALÓN',
    },
    {
      codigo: 110,
      descripcion: 'SAN LUCAS',
    },
    {
      codigo: 111,
      descripcion: 'ZINACANTÁN',
    },
    {
      codigo: 112,
      descripcion: 'SAN JUAN CANCUC',
    },
  ],
  '09': [
    {
      codigo: '001',
      descripcion: 'AHUMADA',
    },
    {
      codigo: '002',
      descripcion: 'ALDAMA',
    },
    {
      codigo: '003',
      descripcion: 'ALLENDE',
    },
    {
      codigo: '004',
      descripcion: 'AQUILES SERDÁN',
    },
    {
      codigo: '005',
      descripcion: 'ASCENSIÓN',
    },
    {
      codigo: '006',
      descripcion: 'BACHÍNIVA',
    },
    {
      codigo: '007',
      descripcion: 'BALLEZA',
    },
    {
      codigo: '008',
      descripcion: 'BATOPILAS',
    },
    {
      codigo: '009',
      descripcion: 'BOCOYNA',
    },
    {
      codigo: '010',
      descripcion: 'BUENAVENTURA',
    },
    {
      codigo: '011',
      descripcion: 'CAMARGO',
    },
    {
      codigo: '012',
      descripcion: 'CARICHÍ',
    },
    {
      codigo: '013',
      descripcion: 'CASAS GRANDES',
    },
    {
      codigo: '014',
      descripcion: 'CORONADO',
    },
    {
      codigo: '015',
      descripcion: 'COYAME',
    },
    {
      codigo: '016',
      descripcion: 'LA CRUZ',
    },
    {
      codigo: '017',
      descripcion: 'CUAUHTÉMOC',
    },
    {
      codigo: '018',
      descripcion: 'CUSIHUIRIACHI',
    },
    {
      codigo: '019',
      descripcion: 'CHIHUAHUA',
    },
    {
      codigo: '020',
      descripcion: 'CHÍNIPAS',
    },
    {
      codigo: '021',
      descripcion: 'DELICIAS',
    },
    {
      codigo: '022',
      descripcion: 'DOCTOR BELISARIO DOMÍNGUEZ',
    },
    {
      codigo: '023',
      descripcion: 'GALEANA',
    },
    {
      codigo: '024',
      descripcion: 'GENERAL TRIAS',
    },
    {
      codigo: '025',
      descripcion: 'GÓMEZ FARÍAS',
    },
    {
      codigo: '026',
      descripcion: 'GRAN MORELOS',
    },
    {
      codigo: '027',
      descripcion: 'GUACHOCHI',
    },
    {
      codigo: '028',
      descripcion: 'GUADALUPE',
    },
    {
      codigo: '029',
      descripcion: 'GUADALUPE Y CALVO',
    },
    {
      codigo: '030',
      descripcion: 'GUAZAPARES',
    },
    {
      codigo: '031',
      descripcion: 'GUERRERO',
    },
    {
      codigo: '032',
      descripcion: 'HIDALGO DEL PARRAL',
    },
    {
      codigo: '033',
      descripcion: 'HUEJOTITÁN',
    },
    {
      codigo: '034',
      descripcion: 'IGNACIO ZARAGOZA',
    },
    {
      codigo: '035',
      descripcion: 'JANOS',
    },
    {
      codigo: '036',
      descripcion: 'JIMÉNEZ',
    },
    {
      codigo: '037',
      descripcion: 'JUÁREZ',
    },
    {
      codigo: '038',
      descripcion: 'JULIMES',
    },
    {
      codigo: '039',
      descripcion: 'LÓPEZ',
    },
    {
      codigo: '040',
      descripcion: 'MADERA',
    },
    {
      codigo: '041',
      descripcion: 'MAGUARICHI',
    },
    {
      codigo: '042',
      descripcion: 'MANUEL BENAVIDES',
    },
    {
      codigo: '043',
      descripcion: 'MATACHÍ',
    },
    {
      codigo: '044',
      descripcion: 'MATAMOROS',
    },
    {
      codigo: '045',
      descripcion: 'MEOQUI',
    },
    {
      codigo: '046',
      descripcion: 'MORELOS',
    },
    {
      codigo: '047',
      descripcion: 'MORIS',
    },
    {
      codigo: '048',
      descripcion: 'NAMIQUIPA',
    },
    {
      codigo: '049',
      descripcion: 'NONOAVA',
    },
    {
      codigo: '050',
      descripcion: 'NUEVO CASAS GRANDES',
    },
    {
      codigo: '051',
      descripcion: 'OCAMPO',
    },
    {
      codigo: '052',
      descripcion: 'OJINAGA',
    },
    {
      codigo: '053',
      descripcion: 'PRAXEDIS G. GUERRERO',
    },
    {
      codigo: '054',
      descripcion: 'RIVA PALACIO',
    },
    {
      codigo: '055',
      descripcion: 'ROSALES',
    },
    {
      codigo: '056',
      descripcion: 'ROSARIO',
    },
    {
      codigo: '057',
      descripcion: 'SAN FRANCISCO DE BORJA',
    },
    {
      codigo: '058',
      descripcion: 'SAN FRANCISCO DE CONCHOS',
    },
    {
      codigo: '059',
      descripcion: 'SAN FRANCISCO DEL ORO',
    },
    {
      codigo: '060',
      descripcion: 'SANTA BÁRBARA',
    },
    {
      codigo: '061',
      descripcion: 'SATEVÓ',
    },
    {
      codigo: '062',
      descripcion: 'SAUCILLO',
    },
    {
      codigo: '063',
      descripcion: 'TEMÓSACHI',
    },
    {
      codigo: '064',
      descripcion: 'EL TULE',
    },
    {
      codigo: '065',
      descripcion: 'URIQUE',
    },
    {
      codigo: '066',
      descripcion: 'URUACHI',
    },
    {
      codigo: '067',
      descripcion: 'VALLE DE ZARAGOZA',
    },
  ],
  10: [
    {
      codigo: '001',
      descripcion: 'CANATLÁN',
    },
    {
      codigo: '002',
      descripcion: 'CANELAS',
    },
    {
      codigo: '003',
      descripcion: 'CONETO DE COMONFORT',
    },
    {
      codigo: '004',
      descripcion: 'CUENCAMÉ',
    },
    {
      codigo: '005',
      descripcion: 'DURANGO',
    },
    {
      codigo: '006',
      descripcion: 'GENERAL SIMÓN BOLÍVAR',
    },
    {
      codigo: '007',
      descripcion: 'GÓMEZ PALACIO',
    },
    {
      codigo: '008',
      descripcion: 'GUADALUPE VICTORIA',
    },
    {
      codigo: '009',
      descripcion: 'GUANACEVÍ',
    },
    {
      codigo: '010',
      descripcion: 'HIDALGO',
    },
    {
      codigo: '011',
      descripcion: 'INDÉ',
    },
    {
      codigo: '012',
      descripcion: 'LERDO',
    },
    {
      codigo: '013',
      descripcion: 'MAPIMÍ',
    },
    {
      codigo: '014',
      descripcion: 'MEZQUITAL',
    },
    {
      codigo: '015',
      descripcion: 'NAZAS',
    },
    {
      codigo: '016',
      descripcion: 'NOMBRE DE DIOS',
    },
    {
      codigo: '017',
      descripcion: 'OCAMPO',
    },
    {
      codigo: '018',
      descripcion: 'EL ORO',
    },
    {
      codigo: '019',
      descripcion: 'OTÁEZ',
    },
    {
      codigo: '020',
      descripcion: 'PÁNUCO DE CORONADO',
    },
    {
      codigo: '021',
      descripcion: 'PEÑÓN BLANCO',
    },
    {
      codigo: '022',
      descripcion: 'POANAS',
    },
    {
      codigo: '023',
      descripcion: 'PUEBLO NUEVO',
    },
    {
      codigo: '024',
      descripcion: 'RODEO',
    },
    {
      codigo: '025',
      descripcion: 'SAN BERNARDO',
    },
    {
      codigo: '026',
      descripcion: 'SAN DIMAS',
    },
    {
      codigo: '027',
      descripcion: 'SAN JUAN DE GUADALUPE',
    },
    {
      codigo: '028',
      descripcion: 'SAN JUAN DEL RÍO',
    },
    {
      codigo: '029',
      descripcion: 'SAN LUIS DEL CORDERO',
    },
    {
      codigo: '030',
      descripcion: 'SAN PEDRO DEL GALLO',
    },
    {
      codigo: '031',
      descripcion: 'SANTA CLARA',
    },
    {
      codigo: '032',
      descripcion: 'SANTIAGO PAPASQUIARO',
    },
    {
      codigo: '033',
      descripcion: 'SÚCHIL',
    },
    {
      codigo: '034',
      descripcion: 'TAMAZULA',
    },
    {
      codigo: '035',
      descripcion: 'TEPEHUANES',
    },
    {
      codigo: '036',
      descripcion: 'TLAHUALILO',
    },
    {
      codigo: '037',
      descripcion: 'TOPIA',
    },
    {
      codigo: '038',
      descripcion: 'VICENTE GUERRERO',
    },
    {
      codigo: '039',
      descripcion: 'NUEVO IDEAL',
    },
  ],
  11: [
    {
      codigo: '001',
      descripcion: 'ABASOLO',
    },
    {
      codigo: '002',
      descripcion: 'ACÁMBARO',
    },
    {
      codigo: '003',
      descripcion: 'SAN MIGUEL DE ALLENDE',
    },
    {
      codigo: '004',
      descripcion: 'APASEO EL ALTO',
    },
    {
      codigo: '005',
      descripcion: 'APASEO EL GRANDE',
    },
    {
      codigo: '006',
      descripcion: 'ATARJEA',
    },
    {
      codigo: '007',
      descripcion: 'CELAYA',
    },
    {
      codigo: '008',
      descripcion: 'MANUEL DOBLADO',
    },
    {
      codigo: '009',
      descripcion: 'COMONFORT',
    },
    {
      codigo: '010',
      descripcion: 'CORONEO',
    },
    {
      codigo: '011',
      descripcion: 'CORTAZAR',
    },
    {
      codigo: '012',
      descripcion: 'CUERÁMARO',
    },
    {
      codigo: '013',
      descripcion: 'DOCTOR MORA',
    },
    {
      codigo: '014',
      descripcion: 'DOLORES HIDALGO',
    },
    {
      codigo: '015',
      descripcion: 'GUANAJUATO',
    },
    {
      codigo: '016',
      descripcion: 'HUANÍMARO',
    },
    {
      codigo: '017',
      descripcion: 'IRAPUATO',
    },
    {
      codigo: '018',
      descripcion: 'JARAL DEL PROGRESO',
    },
    {
      codigo: '019',
      descripcion: 'JERÉCUARO',
    },
    {
      codigo: '020',
      descripcion: 'LEÓN',
    },
    {
      codigo: '021',
      descripcion: 'MOROLEÓN',
    },
    {
      codigo: '022',
      descripcion: 'OCAMPO',
    },
    {
      codigo: '023',
      descripcion: 'PÉNJAMO',
    },
    {
      codigo: '024',
      descripcion: 'PUEBLO NUEVO',
    },
    {
      codigo: '025',
      descripcion: 'PURÍSIMA DEL RINCÓN',
    },
    {
      codigo: '026',
      descripcion: 'ROMITA',
    },
    {
      codigo: '027',
      descripcion: 'SALAMANCA',
    },
    {
      codigo: '028',
      descripcion: 'SALVATIERRA',
    },
    {
      codigo: '029',
      descripcion: 'SAN DIEGO DE LA UNIÓN',
    },
    {
      codigo: '030',
      descripcion: 'SAN FELIPE',
    },
    {
      codigo: '031',
      descripcion: 'SAN FRANCISCO DEL RINCÓN',
    },
    {
      codigo: '032',
      descripcion: 'SAN JOSÉ ITURBIDE',
    },
    {
      codigo: '033',
      descripcion: 'SAN LUIS DE LA PAZ',
    },
    {
      codigo: '034',
      descripcion: 'SANTA CATARINA',
    },
    {
      codigo: '035',
      descripcion: 'SANTA CRUZ DE JUVENTINO ROSAS',
    },
    {
      codigo: '036',
      descripcion: 'SANTIAGO MARAVATÍO',
    },
    {
      codigo: '037',
      descripcion: 'SILAO',
    },
    {
      codigo: '038',
      descripcion: 'TARANDACUAO',
    },
    {
      codigo: '039',
      descripcion: 'TARIMORO',
    },
    {
      codigo: '040',
      descripcion: 'TIERRA BLANCA',
    },
    {
      codigo: '041',
      descripcion: 'URIANGATO',
    },
    {
      codigo: '042',
      descripcion: 'VALLE DE SANTIAGO',
    },
    {
      codigo: '043',
      descripcion: 'VICTORIA',
    },
    {
      codigo: '044',
      descripcion: 'VILLAGRÁN',
    },
    {
      codigo: '045',
      descripcion: 'XICHÚ',
    },
    {
      codigo: '046',
      descripcion: 'YURIRIA',
    },
  ],
  12: [
    {
      codigo: '001',
      descripcion: 'ACAPULCO DE JUÁREZ',
    },
    {
      codigo: '002',
      descripcion: 'AHUACUOTZINGO',
    },
    {
      codigo: '003',
      descripcion: 'AJUCHITLÁN DEL PROGRESO',
    },
    {
      codigo: '004',
      descripcion: 'ALCOZAUCA DE GUERRERO',
    },
    {
      codigo: '005',
      descripcion: 'ALPOYECA',
    },
    {
      codigo: '006',
      descripcion: 'APAXTLA',
    },
    {
      codigo: '007',
      descripcion: 'ARCELIA',
    },
    {
      codigo: '008',
      descripcion: 'ATENANGO DEL RÍO',
    },
    {
      codigo: '009',
      descripcion: 'ATLAMAJALCINGO DEL MONTE',
    },
    {
      codigo: '010',
      descripcion: 'ATLIXTAC',
    },
    {
      codigo: '011',
      descripcion: 'ATOYAC DE ÁLVAREZ',
    },
    {
      codigo: '012',
      descripcion: 'AYUTLA DE LOS LIBRES',
    },
    {
      codigo: '013',
      descripcion: 'AZOYÚ',
    },
    {
      codigo: '014',
      descripcion: 'BENITO JUÁREZ',
    },
    {
      codigo: '015',
      descripcion: 'BUENAVISTA DE CUÉLLAR',
    },
    {
      codigo: '016',
      descripcion: 'COAHUAYUTLA DE JOSÉ MARÍA IZAZAGA',
    },
    {
      codigo: '017',
      descripcion: 'COCULA',
    },
    {
      codigo: '018',
      descripcion: 'COPALA',
    },
    {
      codigo: '019',
      descripcion: 'COPALILLO',
    },
    {
      codigo: '020',
      descripcion: 'COPANATOYAC',
    },
    {
      codigo: '021',
      descripcion: 'COYUCA DE BENÍTEZ',
    },
    {
      codigo: '022',
      descripcion: 'COYUCA DE CATALÁN',
    },
    {
      codigo: '023',
      descripcion: 'CUAJINICUILAPA',
    },
    {
      codigo: '024',
      descripcion: 'CUALÁC',
    },
    {
      codigo: '025',
      descripcion: 'CUAUTEPEC',
    },
    {
      codigo: '026',
      descripcion: 'CUETZALA DEL PROGRESO',
    },
    {
      codigo: '027',
      descripcion: 'CUTZAMALA DE PINZÓN',
    },
    {
      codigo: '028',
      descripcion: 'CHILAPA DE ÁLVAREZ',
    },
    {
      codigo: '029',
      descripcion: 'CHILPANCINGO DE LOS BRAVO',
    },
    {
      codigo: '030',
      descripcion: 'FLORENCIO VILLARREAL',
    },
    {
      codigo: '031',
      descripcion: 'GENERAL CANUTO A. NERI',
    },
    {
      codigo: '032',
      descripcion: 'GENERAL HELIODORO CASTILLO',
    },
    {
      codigo: '033',
      descripcion: 'HUAMUXTITLÁN',
    },
    {
      codigo: '034',
      descripcion: 'HUITZUCO DE LOS FIGUEROA',
    },
    {
      codigo: '035',
      descripcion: 'IGUALA DE LA INDEPENDENCIA',
    },
    {
      codigo: '036',
      descripcion: 'IGUALAPA',
    },
    {
      codigo: '037',
      descripcion: 'IXCATEOPAN DE CUAUHTÉMOC',
    },
    {
      codigo: '038',
      descripcion: 'ZIHUATANEJO DE AZUETA',
    },
    {
      codigo: '039',
      descripcion: 'JUAN R. ESCUDERO',
    },
    {
      codigo: '040',
      descripcion: 'LEONARDO BRAVO',
    },
    {
      codigo: '041',
      descripcion: 'MALINALTEPEC',
    },
    {
      codigo: '042',
      descripcion: 'MÁRTIR DE CUILAPAN',
    },
    {
      codigo: '043',
      descripcion: 'METLATÓNOC',
    },
    {
      codigo: '044',
      descripcion: 'MOCHITLÁN',
    },
    {
      codigo: '045',
      descripcion: 'OLINALÁ',
    },
    {
      codigo: '046',
      descripcion: 'OMETEPEC',
    },
    {
      codigo: '047',
      descripcion: 'PEDRO ASCENCIO ALQUISIRAS',
    },
    {
      codigo: '048',
      descripcion: 'PETATLÁN',
    },
    {
      codigo: '049',
      descripcion: 'PILCAYA',
    },
    {
      codigo: '050',
      descripcion: 'PUNGARABATO',
    },
    {
      codigo: '051',
      descripcion: 'QUECHULTENANGO',
    },
    {
      codigo: '052',
      descripcion: 'SAN LUIS ACATLÁN',
    },
    {
      codigo: '053',
      descripcion: 'SAN MARCOS',
    },
    {
      codigo: '054',
      descripcion: 'SAN MIGUEL TOTOLAPAN',
    },
    {
      codigo: '055',
      descripcion: 'TAXCO DE ALARCÓN',
    },
    {
      codigo: '056',
      descripcion: 'TECOANAPA',
    },
    {
      codigo: '057',
      descripcion: 'TÉCPAN DE GALEANA',
    },
    {
      codigo: '058',
      descripcion: 'TELOLOAPAN',
    },
    {
      codigo: '059',
      descripcion: 'TEPECOACUILCO DE TRUJANO',
    },
    {
      codigo: '060',
      descripcion: 'TETIPAC',
    },
    {
      codigo: '061',
      descripcion: 'TIXTLA DE GUERRERO',
    },
    {
      codigo: '062',
      descripcion: 'TLACOACHISTLAHUACA',
    },
    {
      codigo: '063',
      descripcion: 'TLACOAPA',
    },
    {
      codigo: '064',
      descripcion: 'TLALCHAPA',
    },
    {
      codigo: '065',
      descripcion: 'TLALIXTAQUILLA DE MALDONADO',
    },
    {
      codigo: '066',
      descripcion: 'TLAPA DE COMONFORT',
    },
    {
      codigo: '067',
      descripcion: 'TLAPEHUALA',
    },
    {
      codigo: '068',
      descripcion: 'LA UNIÓN',
    },
    {
      codigo: '069',
      descripcion: 'XALPATLÁHUAC',
    },
    {
      codigo: '070',
      descripcion: 'XOCHIHUEHUETLÁN',
    },
    {
      codigo: '071',
      descripcion: 'XOCHISTLAHUACA',
    },
    {
      codigo: '072',
      descripcion: 'ZAPOTITLÁN TABLAS',
    },
    {
      codigo: '073',
      descripcion: 'ZIRÁNDARO',
    },
    {
      codigo: '074',
      descripcion: 'ZITLALA',
    },
    {
      codigo: '075',
      descripcion: 'EDUARDO NERI',
    },
  ],
  13: [
    {
      codigo: '001',
      descripcion: 'ACATLÁN',
    },
    {
      codigo: '002',
      descripcion: 'ACAXOCHITLÁN',
    },
    {
      codigo: '003',
      descripcion: 'ACTOPAN',
    },
    {
      codigo: '004',
      descripcion: 'AGUA BLANCA DE ITURBIDE',
    },
    {
      codigo: '005',
      descripcion: 'AJACUBA',
    },
    {
      codigo: '006',
      descripcion: 'ALFAJAYUCAN',
    },
    {
      codigo: '007',
      descripcion: 'ALMOLOYA',
    },
    {
      codigo: '008',
      descripcion: 'APAN',
    },
    {
      codigo: '009',
      descripcion: 'EL ARENAL',
    },
    {
      codigo: '010',
      descripcion: 'ATITALAQUIA',
    },
    {
      codigo: '011',
      descripcion: 'ATLAPEXCO',
    },
    {
      codigo: '012',
      descripcion: 'ATOTONILCO EL GRANDE',
    },
    {
      codigo: '013',
      descripcion: 'ATOTONILCO DE TULA',
    },
    {
      codigo: '014',
      descripcion: 'CALNALI',
    },
    {
      codigo: '015',
      descripcion: 'CARDONAL',
    },
    {
      codigo: '016',
      descripcion: 'CUAUTEPEC DE HINOJOSA',
    },
    {
      codigo: '017',
      descripcion: 'CHAPANTONGO',
    },
    {
      codigo: '018',
      descripcion: 'CHAPULHUACÁN',
    },
    {
      codigo: '019',
      descripcion: 'CHILCUAUTLA',
    },
    {
      codigo: '020',
      descripcion: 'ELOXOCHITLÁN',
    },
    {
      codigo: '021',
      descripcion: 'EMILIANO ZAPATA',
    },
    {
      codigo: '022',
      descripcion: 'EPAZOYUCAN',
    },
    {
      codigo: '023',
      descripcion: 'FRANCISCO I. MADERO',
    },
    {
      codigo: '024',
      descripcion: 'HUASCA DE OCAMPO',
    },
    {
      codigo: '025',
      descripcion: 'HUAUTLA',
    },
    {
      codigo: '026',
      descripcion: 'HUAZALINGO',
    },
    {
      codigo: '027',
      descripcion: 'HUEHUETLA',
    },
    {
      codigo: '028',
      descripcion: 'HUEJUTLA DE REYES',
    },
    {
      codigo: '029',
      descripcion: 'HUICHAPAN',
    },
    {
      codigo: '030',
      descripcion: 'IXMIQUILPAN',
    },
    {
      codigo: '031',
      descripcion: 'JACALA DE LEDEZMA',
    },
    {
      codigo: '032',
      descripcion: 'JALTOCÁN',
    },
    {
      codigo: '033',
      descripcion: 'JUÁREZ HIDALGO',
    },
    {
      codigo: '034',
      descripcion: 'LOLOTLA',
    },
    {
      codigo: '035',
      descripcion: 'METEPEC',
    },
    {
      codigo: '036',
      descripcion: 'SAN AGUSTÍN METZQUITITLÁN',
    },
    {
      codigo: '037',
      descripcion: 'METZTITLÁN',
    },
    {
      codigo: '038',
      descripcion: 'MINERAL DEL CHICO',
    },
    {
      codigo: '039',
      descripcion: 'MINERAL DEL MONTE',
    },
    {
      codigo: '040',
      descripcion: 'LA MISIÓN',
    },
    {
      codigo: '041',
      descripcion: 'MIXQUIAHUALA DE JUÁREZ',
    },
    {
      codigo: '042',
      descripcion: 'MOLANGO DE ESCAMILLA',
    },
    {
      codigo: '043',
      descripcion: 'NICOLÁS FLORES',
    },
    {
      codigo: '044',
      descripcion: 'NOPALA DE VILLAGRÁN',
    },
    {
      codigo: '045',
      descripcion: 'OMITLÁN DE JUÁREZ',
    },
    {
      codigo: '046',
      descripcion: 'SAN FELIPE ORIZATLÁN',
    },
    {
      codigo: '047',
      descripcion: 'PACULA',
    },
    {
      codigo: '048',
      descripcion: 'PACHUCA DE SOTO',
    },
    {
      codigo: '049',
      descripcion: 'PISAFLORES',
    },
    {
      codigo: '050',
      descripcion: 'PROGRESO DE OBREGÓN',
    },
    {
      codigo: '051',
      descripcion: 'MINERAL DE LA REFORMA',
    },
    {
      codigo: '052',
      descripcion: 'SAN AGUSTÍN TLAXIACA',
    },
    {
      codigo: '053',
      descripcion: 'SAN BARTOLO TUTOTEPEC',
    },
    {
      codigo: '054',
      descripcion: 'SAN SALVADOR',
    },
    {
      codigo: '055',
      descripcion: 'SANTIAGO DE ANAYA',
    },
    {
      codigo: '056',
      descripcion: 'SANTIAGO TULANTEPEC DE LUGO GUERRERO',
    },
    {
      codigo: '057',
      descripcion: 'SINGUILUCAN',
    },
    {
      codigo: '058',
      descripcion: 'TASQUILLO',
    },
    {
      codigo: '059',
      descripcion: 'TECOZAUTLA',
    },
    {
      codigo: '060',
      descripcion: 'TENANGO DE DORIA',
    },
    {
      codigo: '061',
      descripcion: 'TEPEAPULCO',
    },
    {
      codigo: '062',
      descripcion: 'TEPEHUACÁN DE GUERRERO',
    },
    {
      codigo: '063',
      descripcion: 'TEPEJI DEL RÍO DE OCAMPO',
    },
    {
      codigo: '064',
      descripcion: 'TEPETITLÁN',
    },
    {
      codigo: '065',
      descripcion: 'TETEPANGO',
    },
    {
      codigo: '066',
      descripcion: 'VILLA DE TEZONTEPEC',
    },
    {
      codigo: '067',
      descripcion: 'TEZONTEPEC DE ALDAMA',
    },
    {
      codigo: '068',
      descripcion: 'TIANGUISTENGO',
    },
    {
      codigo: '069',
      descripcion: 'TIZAYUCA',
    },
    {
      codigo: '070',
      descripcion: 'TLAHUELILPAN',
    },
    {
      codigo: '071',
      descripcion: 'TLAHUILTEPA',
    },
    {
      codigo: '072',
      descripcion: 'TLANALAPA',
    },
    {
      codigo: '073',
      descripcion: 'TLANCHINOL',
    },
    {
      codigo: '074',
      descripcion: 'TLAXCOAPAN',
    },
    {
      codigo: '075',
      descripcion: 'TOLCAYUCA',
    },
    {
      codigo: '076',
      descripcion: 'TULA DE ALLENDE',
    },
    {
      codigo: '077',
      descripcion: 'TULANCINGO DE BRAVO',
    },
    {
      codigo: '078',
      descripcion: 'XOCHIATIPAN',
    },
    {
      codigo: '079',
      descripcion: 'XOCHICOATLÁN',
    },
    {
      codigo: '080',
      descripcion: 'YAHUALICA',
    },
    {
      codigo: '081',
      descripcion: 'ZACUALTIPÁN DE ÁNGELES',
    },
    {
      codigo: '082',
      descripcion: 'ZAPOTLÁN DE JUÁREZ',
    },
    {
      codigo: '083',
      descripcion: 'ZEMPOALA',
    },
    {
      codigo: '084',
      descripcion: 'ZIMAPÁN',
    },
  ],
  14: [
    {
      codigo: '001',
      descripcion: 'ACATIC',
    },
    {
      codigo: '002',
      descripcion: 'ACATLÁN DE JUÁREZ',
    },
    {
      codigo: '003',
      descripcion: 'AHUALULCO DE MERCADO',
    },
    {
      codigo: '004',
      descripcion: 'AMACUECA',
    },
    {
      codigo: '005',
      descripcion: 'AMATITÁN',
    },
    {
      codigo: '006',
      descripcion: 'AMECA',
    },
    {
      codigo: '007',
      descripcion: 'SAN JUANITO DE ESCOBEDO',
    },
    {
      codigo: '008',
      descripcion: 'ARANDAS',
    },
    {
      codigo: '009',
      descripcion: 'EL ARENAL',
    },
    {
      codigo: '010',
      descripcion: 'ATEMAJAC DE BRIZUELA',
    },
    {
      codigo: '011',
      descripcion: 'ATENGO',
    },
    {
      codigo: '012',
      descripcion: 'ATENGUILLO',
    },
    {
      codigo: '013',
      descripcion: 'ATOTONILCO EL ALTO',
    },
    {
      codigo: '014',
      descripcion: 'ATOYAC',
    },
    {
      codigo: '015',
      descripcion: 'AUTLÁN DE NAVARRO',
    },
    {
      codigo: '016',
      descripcion: 'AYOTLÁN',
    },
    {
      codigo: '017',
      descripcion: 'AYUTLA',
    },
    {
      codigo: '018',
      descripcion: 'LA BARCA',
    },
    {
      codigo: '019',
      descripcion: 'BOLAÑOS',
    },
    {
      codigo: '020',
      descripcion: 'CABO CORRIENTES',
    },
    {
      codigo: '021',
      descripcion: 'CASIMIRO CASTILLO',
    },
    {
      codigo: '022',
      descripcion: 'CIHUATLÁN',
    },
    {
      codigo: '023',
      descripcion: 'CIUDAD GUZMAN',
    },
    {
      codigo: '024',
      descripcion: 'COCULA',
    },
    {
      codigo: '025',
      descripcion: 'COLOTLÁN',
    },
    {
      codigo: '026',
      descripcion: 'CONCEPCIÓN DE BUENOS AIRES',
    },
    {
      codigo: '027',
      descripcion: 'CUAUTITLÁN DE GARCÍA BARRAGÁN',
    },
    {
      codigo: '028',
      descripcion: 'CUAUTLA',
    },
    {
      codigo: '029',
      descripcion: 'CUQUÍO',
    },
    {
      codigo: '030',
      descripcion: 'CHAPALA',
    },
    {
      codigo: '031',
      descripcion: 'CHIMALTITÁN',
    },
    {
      codigo: '032',
      descripcion: 'CHIQUILISTLÁN',
    },
    {
      codigo: '033',
      descripcion: 'DEGOLLADO',
    },
    {
      codigo: '034',
      descripcion: 'EJUTLA',
    },
    {
      codigo: '035',
      descripcion: 'ENCARNACIÓN DE DÍAZ',
    },
    {
      codigo: '036',
      descripcion: 'ETZATLÁN',
    },
    {
      codigo: '037',
      descripcion: 'EL GRULLO',
    },
    {
      codigo: '038',
      descripcion: 'GUACHINANGO',
    },
    {
      codigo: '039',
      descripcion: 'GUADALAJARA',
    },
    {
      codigo: '040',
      descripcion: 'HOSTOTIPAQUILLO',
    },
    {
      codigo: '041',
      descripcion: 'HUEJÚCAR',
    },
    {
      codigo: '042',
      descripcion: 'HUEJUQUILLA EL ALTO',
    },
    {
      codigo: '043',
      descripcion: 'LA HUERTA',
    },
    {
      codigo: '044',
      descripcion: 'IXTLAHUACÁN DE LOS MEMBRILLOS',
    },
    {
      codigo: '045',
      descripcion: 'IXTLAHUACÁN DEL RÍO',
    },
    {
      codigo: '046',
      descripcion: 'JALOSTOTITLÁN',
    },
    {
      codigo: '047',
      descripcion: 'JAMAY',
    },
    {
      codigo: '048',
      descripcion: 'JESÚS MARÍA',
    },
    {
      codigo: '049',
      descripcion: 'JILOTLÁN DE LOS DOLORES',
    },
    {
      codigo: '050',
      descripcion: 'JOCOTEPEC',
    },
    {
      codigo: '051',
      descripcion: 'JUANACATLÁN',
    },
    {
      codigo: '052',
      descripcion: 'JUCHITLÁN',
    },
    {
      codigo: '053',
      descripcion: 'LAGOS DE MORENO',
    },
    {
      codigo: '054',
      descripcion: 'EL LIMÓN',
    },
    {
      codigo: '055',
      descripcion: 'MAGDALENA',
    },
    {
      codigo: '056',
      descripcion: 'SANTA MARÍA DEL ORO',
    },
    {
      codigo: '057',
      descripcion: 'LA MANZANILLA DE LA PAZ',
    },
    {
      codigo: '058',
      descripcion: 'MASCOTA',
    },
    {
      codigo: '059',
      descripcion: 'MAZAMITLA',
    },
    {
      codigo: '060',
      descripcion: 'MEXTICACÁN',
    },
    {
      codigo: '061',
      descripcion: 'MEZQUITIC',
    },
    {
      codigo: '062',
      descripcion: 'MIXTLÁN',
    },
    {
      codigo: '063',
      descripcion: 'OCOTLÁN',
    },
    {
      codigo: '064',
      descripcion: 'OJUELOS DE JALISCO',
    },
    {
      codigo: '065',
      descripcion: 'PIHUAMO',
    },
    {
      codigo: '066',
      descripcion: 'PONCITLÁN',
    },
    {
      codigo: '067',
      descripcion: 'PUERTO VALLARTA',
    },
    {
      codigo: '068',
      descripcion: 'VILLA PURIFICACIÓN',
    },
    {
      codigo: '069',
      descripcion: 'QUITUPAN',
    },
    {
      codigo: '070',
      descripcion: 'EL SALTO',
    },
    {
      codigo: '071',
      descripcion: 'SAN CRISTÓBAL DE LA BARRANCA',
    },
    {
      codigo: '072',
      descripcion: 'SAN DIEGO DE ALEJANDRÍA',
    },
    {
      codigo: '073',
      descripcion: 'SAN JUAN DE LOS LAGOS',
    },
    {
      codigo: '074',
      descripcion: 'SAN JULIÁN',
    },
    {
      codigo: '075',
      descripcion: 'SAN MARCOS',
    },
    {
      codigo: '076',
      descripcion: 'SAN MARTÍN DE BOLAÑOS',
    },
    {
      codigo: '077',
      descripcion: 'SAN MARTÍN HIDALGO',
    },
    {
      codigo: '078',
      descripcion: 'SAN MIGUEL EL ALTO',
    },
    {
      codigo: '079',
      descripcion: 'GÓMEZ FARÍAS',
    },
    {
      codigo: '080',
      descripcion: 'SAN SEBASTIÁN DEL OESTE',
    },
    {
      codigo: '081',
      descripcion: 'SANTA MARÍA DE LOS ÁNGELES',
    },
    {
      codigo: '082',
      descripcion: 'SAYULA',
    },
    {
      codigo: '083',
      descripcion: 'TALA',
    },
    {
      codigo: '084',
      descripcion: 'TALPA DE ALLENDE',
    },
    {
      codigo: '085',
      descripcion: 'TAMAZULA DE GORDIANO',
    },
    {
      codigo: '086',
      descripcion: 'TAPALPA',
    },
    {
      codigo: '087',
      descripcion: 'TECALITLÁN',
    },
    {
      codigo: '088',
      descripcion: 'TECOLOTLÁN',
    },
    {
      codigo: '089',
      descripcion: 'TECHALUTA DE MONTENEGRO',
    },
    {
      codigo: '090',
      descripcion: 'TENAMAXTLÁN',
    },
    {
      codigo: '091',
      descripcion: 'TEOCALTICHE',
    },
    {
      codigo: '092',
      descripcion: 'TEOCUITATLÁN DE CORONA',
    },
    {
      codigo: '093',
      descripcion: 'TEPATITLÁN DE MORELOS',
    },
    {
      codigo: '094',
      descripcion: 'TEQUILA',
    },
    {
      codigo: '095',
      descripcion: 'TEUCHITLÁN',
    },
    {
      codigo: '096',
      descripcion: 'TIZAPÁN EL ALTO',
    },
    {
      codigo: '097',
      descripcion: 'TLAJOMULCO DE ZÚÑIGA',
    },
    {
      codigo: '098',
      descripcion: 'SAN PEDRO TLAQUEPAQUE',
    },
    {
      codigo: '099',
      descripcion: 'TOLIMÁN',
    },
    {
      codigo: 100,
      descripcion: 'TOMATLÁN',
    },
    {
      codigo: 101,
      descripcion: 'TONALÁ',
    },
    {
      codigo: 102,
      descripcion: 'TONAYA',
    },
    {
      codigo: 103,
      descripcion: 'TONILA',
    },
    {
      codigo: 104,
      descripcion: 'TOTATICHE',
    },
    {
      codigo: 105,
      descripcion: 'TOTOTLÁN',
    },
    {
      codigo: 106,
      descripcion: 'TUXCACUESCO',
    },
    {
      codigo: 107,
      descripcion: 'TUXCUECA',
    },
    {
      codigo: 108,
      descripcion: 'TUXPAN',
    },
    {
      codigo: 109,
      descripcion: 'UNIÓN DE SAN ANTONIO',
    },
    {
      codigo: 110,
      descripcion: 'UNIÓN DE TULA',
    },
    {
      codigo: 111,
      descripcion: 'VALLE DE GUADALUPE',
    },
    {
      codigo: 112,
      descripcion: 'VALLE DE JUÁREZ',
    },
    {
      codigo: 113,
      descripcion: 'CIUDAD VENUSTIANO CARRANZA',
    },
    {
      codigo: 114,
      descripcion: 'VILLA CORONA',
    },
    {
      codigo: 115,
      descripcion: 'VILLA GUERRERO',
    },
    {
      codigo: 116,
      descripcion: 'VILLA HIDALGO',
    },
    {
      codigo: 117,
      descripcion: 'CAÑADAS DE OBREGÓN',
    },
    {
      codigo: 118,
      descripcion: 'YAHUALICA DE GONZÁLEZ GALLO',
    },
    {
      codigo: 119,
      descripcion: 'ZACOALCO DE TORRES',
    },
    {
      codigo: 120,
      descripcion: 'ZAPOPAN',
    },
    {
      codigo: 121,
      descripcion: 'ZAPOTILTIC',
    },
    {
      codigo: 122,
      descripcion: 'ZAPOTITLÁN DE VADILLO',
    },
    {
      codigo: 123,
      descripcion: 'ZAPOTLÁN DEL REY',
    },
    {
      codigo: 124,
      descripcion: 'ZAPOTLANEJO',
    },
  ],
  15: [
    {
      codigo: '001',
      descripcion: 'ACAMBAY',
    },
    {
      codigo: '002',
      descripcion: 'ACOLMAN',
    },
    {
      codigo: '003',
      descripcion: 'ACULCO',
    },
    {
      codigo: '004',
      descripcion: 'ALMOLOYA DE ALQUISIRAS',
    },
    {
      codigo: '005',
      descripcion: 'ALMOLOYA DE JUÁREZ',
    },
    {
      codigo: '006',
      descripcion: 'ALMOLOYA DEL RÍO',
    },
    {
      codigo: '007',
      descripcion: 'AMANALCO',
    },
    {
      codigo: '008',
      descripcion: 'AMATEPEC',
    },
    {
      codigo: '009',
      descripcion: 'AMECAMECA',
    },
    {
      codigo: '010',
      descripcion: 'APAXCO',
    },
    {
      codigo: '011',
      descripcion: 'ATENCO',
    },
    {
      codigo: '012',
      descripcion: 'ATIZAPÁN',
    },
    {
      codigo: '013',
      descripcion: 'ATIZAPÁN DE ZARAGOZA',
    },
    {
      codigo: '014',
      descripcion: 'ATLACOMULCO',
    },
    {
      codigo: '015',
      descripcion: 'ATLAUTLA',
    },
    {
      codigo: '016',
      descripcion: 'AXAPUSCO',
    },
    {
      codigo: '017',
      descripcion: 'AYAPANGO',
    },
    {
      codigo: '018',
      descripcion: 'CALIMAYA',
    },
    {
      codigo: '019',
      descripcion: 'CAPULHUAC',
    },
    {
      codigo: '020',
      descripcion: 'COACALCO',
    },
    {
      codigo: '021',
      descripcion: 'COATEPEC HARINAS',
    },
    {
      codigo: '022',
      descripcion: 'COCOTITLÁN',
    },
    {
      codigo: '023',
      descripcion: 'COYOTEPEC',
    },
    {
      codigo: '024',
      descripcion: 'CUAUTITLÁN',
    },
    {
      codigo: '025',
      descripcion: 'CHALCO',
    },
    {
      codigo: '026',
      descripcion: 'CHAPA DE MOTA',
    },
    {
      codigo: '027',
      descripcion: 'CHAPULTEPEC',
    },
    {
      codigo: '028',
      descripcion: 'CHIAUTLA',
    },
    {
      codigo: '029',
      descripcion: 'CHICOLOAPAN',
    },
    {
      codigo: '030',
      descripcion: 'CHICONCUAC',
    },
    {
      codigo: '031',
      descripcion: 'CHIMALHUACÁN',
    },
    {
      codigo: '032',
      descripcion: 'DONATO GUERRA',
    },
    {
      codigo: '033',
      descripcion: 'ECATEPEC DE MORELOS',
    },
    {
      codigo: '034',
      descripcion: 'ECATZINGO',
    },
    {
      codigo: '035',
      descripcion: 'HUEHUETOCA',
    },
    {
      codigo: '036',
      descripcion: 'HUEYPOXTLA',
    },
    {
      codigo: '037',
      descripcion: 'HUIXQUILUCAN',
    },
    {
      codigo: '038',
      descripcion: 'ISIDRO FABELA',
    },
    {
      codigo: '039',
      descripcion: 'IXTAPALUCA',
    },
    {
      codigo: '040',
      descripcion: 'IXTAPAN DE LA SAL',
    },
    {
      codigo: '041',
      descripcion: 'IXTAPAN DEL ORO',
    },
    {
      codigo: '042',
      descripcion: 'IXTLAHUACA',
    },
    {
      codigo: '043',
      descripcion: 'JALATLACO',
    },
    {
      codigo: '044',
      descripcion: 'JALTENCO',
    },
    {
      codigo: '045',
      descripcion: 'JILOTEPEC',
    },
    {
      codigo: '046',
      descripcion: 'JILOTZINGO',
    },
    {
      codigo: '047',
      descripcion: 'JIQUIPILCO',
    },
    {
      codigo: '048',
      descripcion: 'JOCOTITLÁN',
    },
    {
      codigo: '049',
      descripcion: 'JOQUICINGO',
    },
    {
      codigo: '050',
      descripcion: 'JUCHITEPEC',
    },
    {
      codigo: '051',
      descripcion: 'LERMA',
    },
    {
      codigo: '052',
      descripcion: 'MALINALCO',
    },
    {
      codigo: '053',
      descripcion: 'MELCHOR OCAMPO',
    },
    {
      codigo: '054',
      descripcion: 'METEPEC',
    },
    {
      codigo: '055',
      descripcion: 'MEXICALTZINGO',
    },
    {
      codigo: '056',
      descripcion: 'MORELOS',
    },
    {
      codigo: '057',
      descripcion: 'NAUCALPAN DE JUÁREZ',
    },
    {
      codigo: '058',
      descripcion: 'NEZAHUALCÓYOTL',
    },
    {
      codigo: '059',
      descripcion: 'NEXTLALPAN',
    },
    {
      codigo: '060',
      descripcion: 'NICOLÁS ROMERO',
    },
    {
      codigo: '061',
      descripcion: 'NOPALTEPEC',
    },
    {
      codigo: '062',
      descripcion: 'OCOYOACAC',
    },
    {
      codigo: '063',
      descripcion: 'OCUILAN',
    },
    {
      codigo: '064',
      descripcion: 'EL ORO',
    },
    {
      codigo: '065',
      descripcion: 'OTUMBA',
    },
    {
      codigo: '066',
      descripcion: 'OTZOLOAPAN',
    },
    {
      codigo: '067',
      descripcion: 'OTZOLOTEPEC',
    },
    {
      codigo: '068',
      descripcion: 'OZUMBA',
    },
    {
      codigo: '069',
      descripcion: 'PAPALOTLA',
    },
    {
      codigo: '070',
      descripcion: 'LA PAZ',
    },
    {
      codigo: '071',
      descripcion: 'POLOTITLÁN',
    },
    {
      codigo: '072',
      descripcion: 'RAYÓN',
    },
    {
      codigo: '073',
      descripcion: 'SAN ANTONIO LA ISLA',
    },
    {
      codigo: '074',
      descripcion: 'SAN FELIPE DEL PROGRESO',
    },
    {
      codigo: '075',
      descripcion: 'SAN MARTÍN DE LAS PIRÁMIDES',
    },
    {
      codigo: '076',
      descripcion: 'SAN MATEO ATENCO',
    },
    {
      codigo: '077',
      descripcion: 'SAN SIMÓN DE GUERRERO',
    },
    {
      codigo: '078',
      descripcion: 'SANTO TOMÁS',
    },
    {
      codigo: '079',
      descripcion: 'SOYANIQUILPAN DE JUÁREZ',
    },
    {
      codigo: '080',
      descripcion: 'SULTEPEC',
    },
    {
      codigo: '081',
      descripcion: 'TECÁMAC',
    },
    {
      codigo: '082',
      descripcion: 'TEJUPILCO',
    },
    {
      codigo: '083',
      descripcion: 'TEMAMATLA',
    },
    {
      codigo: '084',
      descripcion: 'TEMASCALAPA',
    },
    {
      codigo: '085',
      descripcion: 'TEMASCALCINGO',
    },
    {
      codigo: '086',
      descripcion: 'TEMASCALTEPEC',
    },
    {
      codigo: '087',
      descripcion: 'TEMOAYA',
    },
    {
      codigo: '088',
      descripcion: 'TENANCINGO',
    },
    {
      codigo: '089',
      descripcion: 'TENANGO DEL AIRE',
    },
    {
      codigo: '090',
      descripcion: 'TENANGO DEL VALLE',
    },
    {
      codigo: '091',
      descripcion: 'TEOLOYUCAN',
    },
    {
      codigo: '092',
      descripcion: 'TEOTIHUACÁN',
    },
    {
      codigo: '093',
      descripcion: 'TEPETLAOXTOC',
    },
    {
      codigo: '094',
      descripcion: 'TEPETLIXPA',
    },
    {
      codigo: '095',
      descripcion: 'TEPOTZOTLÁN',
    },
    {
      codigo: '096',
      descripcion: 'TEQUIXQUIAC',
    },
    {
      codigo: '097',
      descripcion: 'TEXCALTITLÁN',
    },
    {
      codigo: '098',
      descripcion: 'TEXCALYACAC',
    },
    {
      codigo: '099',
      descripcion: 'TEXCOCO',
    },
    {
      codigo: 100,
      descripcion: 'TEZOYUCA',
    },
    {
      codigo: 101,
      descripcion: 'TIANGUISTENCO',
    },
    {
      codigo: 102,
      descripcion: 'TIMILPAN',
    },
    {
      codigo: 103,
      descripcion: 'TLALMANALCO',
    },
    {
      codigo: 104,
      descripcion: 'TLALNEPANTLA DE BAZ',
    },
    {
      codigo: 105,
      descripcion: 'TLATLAYA',
    },
    {
      codigo: 106,
      descripcion: 'TOLUCA',
    },
    {
      codigo: 107,
      descripcion: 'TONATICO',
    },
    {
      codigo: 108,
      descripcion: 'TULTEPEC',
    },
    {
      codigo: 109,
      descripcion: 'TULTITLÁN',
    },
    {
      codigo: 110,
      descripcion: 'VALLE DE BRAVO',
    },
    {
      codigo: 111,
      descripcion: 'VILLA DE ALLENDE',
    },
    {
      codigo: 112,
      descripcion: 'VILLA DEL CARBÓN',
    },
    {
      codigo: 113,
      descripcion: 'VILLA GUERRERO',
    },
    {
      codigo: 114,
      descripcion: 'VILLA VICTORIA',
    },
    {
      codigo: 115,
      descripcion: 'XONACATLÁN',
    },
    {
      codigo: 116,
      descripcion: 'ZACAZONAPAN',
    },
    {
      codigo: 117,
      descripcion: 'ZACUALPAN',
    },
    {
      codigo: 118,
      descripcion: 'ZINACANTEPEC',
    },
    {
      codigo: 119,
      descripcion: 'ZUMPAHUACÁN',
    },
    {
      codigo: 120,
      descripcion: 'ZUMPANGO',
    },
    {
      codigo: 121,
      descripcion: 'CUAUTITLÁN IZCALLI',
    },
    {
      codigo: 122,
      descripcion: 'VALLE DE CHALCO SOLIDARIDAD',
    },
  ],
  16: [
    {
      codigo: '001',
      descripcion: 'ACUITZIO',
    },
    {
      codigo: '002',
      descripcion: 'AGUILILLA',
    },
    {
      codigo: '003',
      descripcion: 'ÁLVARO OBREGÓN',
    },
    {
      codigo: '004',
      descripcion: 'ANGAMACUTIRO',
    },
    {
      codigo: '005',
      descripcion: 'ANGANGUEO',
    },
    {
      codigo: '006',
      descripcion: 'APATZINGÁN',
    },
    {
      codigo: '007',
      descripcion: 'APORO',
    },
    {
      codigo: '008',
      descripcion: 'AQUILA',
    },
    {
      codigo: '009',
      descripcion: 'ARIO',
    },
    {
      codigo: '010',
      descripcion: 'ARTEAGA',
    },
    {
      codigo: '011',
      descripcion: 'BRISEÑAS',
    },
    {
      codigo: '012',
      descripcion: 'BUENAVISTA',
    },
    {
      codigo: '013',
      descripcion: 'CARÁCUARO',
    },
    {
      codigo: '014',
      descripcion: 'COAHUAYANA',
    },
    {
      codigo: '015',
      descripcion: 'COALCOMÁN DE VÁZQUEZ PALLARES',
    },
    {
      codigo: '016',
      descripcion: 'COENEO',
    },
    {
      codigo: '017',
      descripcion: 'CONTEPEC',
    },
    {
      codigo: '018',
      descripcion: 'COPÁNDARO',
    },
    {
      codigo: '019',
      descripcion: 'COTIJA',
    },
    {
      codigo: '020',
      descripcion: 'CUITZEO',
    },
    {
      codigo: '021',
      descripcion: 'CHARAPAN',
    },
    {
      codigo: '022',
      descripcion: 'CHARO',
    },
    {
      codigo: '023',
      descripcion: 'CHAVINDA',
    },
    {
      codigo: '024',
      descripcion: 'CHERÁN',
    },
    {
      codigo: '025',
      descripcion: 'CHILCHOTA',
    },
    {
      codigo: '026',
      descripcion: 'CHINICUILA',
    },
    {
      codigo: '027',
      descripcion: 'CHUCÁNDIRO',
    },
    {
      codigo: '028',
      descripcion: 'CHURINTZIO',
    },
    {
      codigo: '029',
      descripcion: 'CHURUMUCO',
    },
    {
      codigo: '030',
      descripcion: 'ECUANDUREO',
    },
    {
      codigo: '031',
      descripcion: 'EPITACIO HUERTA',
    },
    {
      codigo: '032',
      descripcion: 'ERONGARÍCUARO',
    },
    {
      codigo: '033',
      descripcion: 'GABRIEL ZAMORA',
    },
    {
      codigo: '034',
      descripcion: 'HIDALGO',
    },
    {
      codigo: '035',
      descripcion: 'LA HUACANA',
    },
    {
      codigo: '036',
      descripcion: 'HUANDACAREO',
    },
    {
      codigo: '037',
      descripcion: 'HUANIQUEO',
    },
    {
      codigo: '038',
      descripcion: 'HUETAMO',
    },
    {
      codigo: '039',
      descripcion: 'HUIRAMBA',
    },
    {
      codigo: '040',
      descripcion: 'INDAPARAPEO',
    },
    {
      codigo: '041',
      descripcion: 'IRIMBO',
    },
    {
      codigo: '042',
      descripcion: 'IXTLÁN',
    },
    {
      codigo: '043',
      descripcion: 'JACONA',
    },
    {
      codigo: '044',
      descripcion: 'JIMÉNEZ',
    },
    {
      codigo: '045',
      descripcion: 'JIQUILPAN',
    },
    {
      codigo: '046',
      descripcion: 'JUÁREZ',
    },
    {
      codigo: '047',
      descripcion: 'JUNGAPEO',
    },
    {
      codigo: '048',
      descripcion: 'LAGUNILLAS',
    },
    {
      codigo: '049',
      descripcion: 'MADERO',
    },
    {
      codigo: '050',
      descripcion: 'MARAVATÍO',
    },
    {
      codigo: '051',
      descripcion: 'MARCOS CASTELLANOS',
    },
    {
      codigo: '052',
      descripcion: 'LÁZARO CÁRDENAS',
    },
    {
      codigo: '053',
      descripcion: 'MORELIA',
    },
    {
      codigo: '054',
      descripcion: 'MORELOS',
    },
    {
      codigo: '055',
      descripcion: 'MÚGICA',
    },
    {
      codigo: '056',
      descripcion: 'NAHUATZEN',
    },
    {
      codigo: '057',
      descripcion: 'NOCUPÉTARO',
    },
    {
      codigo: '058',
      descripcion: 'NUEVO PARANGARICUTIRO',
    },
    {
      codigo: '059',
      descripcion: 'NUEVO URECHO',
    },
    {
      codigo: '060',
      descripcion: 'NUMARÁN',
    },
    {
      codigo: '061',
      descripcion: 'OCAMPO',
    },
    {
      codigo: '062',
      descripcion: 'PAJACUARÁN',
    },
    {
      codigo: '063',
      descripcion: 'PANINDÍCUARO',
    },
    {
      codigo: '064',
      descripcion: 'PARÁCUARO',
    },
    {
      codigo: '065',
      descripcion: 'PARACHO',
    },
    {
      codigo: '066',
      descripcion: 'PÁTZCUARO',
    },
    {
      codigo: '067',
      descripcion: 'PENJAMILLO',
    },
    {
      codigo: '068',
      descripcion: 'PERIBÁN',
    },
    {
      codigo: '069',
      descripcion: 'LA PIEDAD',
    },
    {
      codigo: '070',
      descripcion: 'PURÉPERO',
    },
    {
      codigo: '071',
      descripcion: 'PURUÁNDIRO',
    },
    {
      codigo: '072',
      descripcion: 'QUERÉNDARO',
    },
    {
      codigo: '073',
      descripcion: 'QUIROGA',
    },
    {
      codigo: '074',
      descripcion: 'COJUMATLÁN DE RÉGULES',
    },
    {
      codigo: '075',
      descripcion: 'LOS REYES',
    },
    {
      codigo: '076',
      descripcion: 'SAHUAYO',
    },
    {
      codigo: '077',
      descripcion: 'SAN LUCAS',
    },
    {
      codigo: '078',
      descripcion: 'SANTA ANA MAYA',
    },
    {
      codigo: '079',
      descripcion: 'SALVADOR ESCALANTE',
    },
    {
      codigo: '080',
      descripcion: 'SENGUIO',
    },
    {
      codigo: '081',
      descripcion: 'SUSUPUATO',
    },
    {
      codigo: '082',
      descripcion: 'TACÁMBARO',
    },
    {
      codigo: '083',
      descripcion: 'TANCÍTARO',
    },
    {
      codigo: '084',
      descripcion: 'TANGAMANDAPIO',
    },
    {
      codigo: '085',
      descripcion: 'TANGANCÍCUARO',
    },
    {
      codigo: '086',
      descripcion: 'TANHUATO',
    },
    {
      codigo: '087',
      descripcion: 'TARETAN',
    },
    {
      codigo: '088',
      descripcion: 'TARÍMBARO',
    },
    {
      codigo: '089',
      descripcion: 'TEPALCATEPEC',
    },
    {
      codigo: '090',
      descripcion: 'TINGAMBATO',
    },
    {
      codigo: '091',
      descripcion: 'TINGÜINDÍN',
    },
    {
      codigo: '092',
      descripcion: 'TIQUICHEO DE NICOLÁS ROMERO',
    },
    {
      codigo: '093',
      descripcion: 'TLALPUJAHUA',
    },
    {
      codigo: '094',
      descripcion: 'TLAZAZALCA',
    },
    {
      codigo: '095',
      descripcion: 'TOCUMBO',
    },
    {
      codigo: '096',
      descripcion: 'TUMBISCATÍO',
    },
    {
      codigo: '097',
      descripcion: 'TURICATO',
    },
    {
      codigo: '098',
      descripcion: 'TUXPAN',
    },
    {
      codigo: '099',
      descripcion: 'TUZANTLA',
    },
    {
      codigo: 100,
      descripcion: 'TZINTZUNTZAN',
    },
    {
      codigo: 101,
      descripcion: 'TZITZIO',
    },
    {
      codigo: 102,
      descripcion: 'URUAPAN',
    },
    {
      codigo: 103,
      descripcion: 'VENUSTIANO CARRANZA',
    },
    {
      codigo: 104,
      descripcion: 'VILLAMAR',
    },
    {
      codigo: 105,
      descripcion: 'VISTA HERMOSA',
    },
    {
      codigo: 106,
      descripcion: 'YURÉCUARO',
    },
    {
      codigo: 107,
      descripcion: 'ZACAPU',
    },
    {
      codigo: 108,
      descripcion: 'ZAMORA',
    },
    {
      codigo: 109,
      descripcion: 'ZINÁPARO',
    },
    {
      codigo: 110,
      descripcion: 'ZINAPÉCUARO',
    },
    {
      codigo: 111,
      descripcion: 'ZIRACUARETIRO',
    },
    {
      codigo: 112,
      descripcion: 'ZITÁCUARO',
    },
    {
      codigo: 113,
      descripcion: 'JOSÉ SIXTO VERDUZCO',
    },
  ],
  17: [
    {
      codigo: '001',
      descripcion: 'AMACUZAC',
    },
    {
      codigo: '002',
      descripcion: 'ATLATLAHUCAN',
    },
    {
      codigo: '003',
      descripcion: 'AXOCHIAPAN',
    },
    {
      codigo: '004',
      descripcion: 'AYALA',
    },
    {
      codigo: '005',
      descripcion: 'COATLÁN DEL RÍO',
    },
    {
      codigo: '006',
      descripcion: 'CUAUTLA',
    },
    {
      codigo: '007',
      descripcion: 'CUERNAVACA',
    },
    {
      codigo: '008',
      descripcion: 'EMILIANO ZAPATA',
    },
    {
      codigo: '009',
      descripcion: 'HUITZILAC',
    },
    {
      codigo: '010',
      descripcion: 'JANTETELCO',
    },
    {
      codigo: '011',
      descripcion: 'JIUTEPEC',
    },
    {
      codigo: '012',
      descripcion: 'JOJUTLA',
    },
    {
      codigo: '013',
      descripcion: 'JONACATEPEC',
    },
    {
      codigo: '014',
      descripcion: 'MAZATEPEC',
    },
    {
      codigo: '015',
      descripcion: 'MIACATLÁN',
    },
    {
      codigo: '016',
      descripcion: 'OCUITUCO',
    },
    {
      codigo: '017',
      descripcion: 'PUENTE DE IXTLA',
    },
    {
      codigo: '018',
      descripcion: 'TEMIXCO',
    },
    {
      codigo: '019',
      descripcion: 'TEPALCINGO',
    },
    {
      codigo: '020',
      descripcion: 'TEPOZTLÁN',
    },
    {
      codigo: '021',
      descripcion: 'TETECALA',
    },
    {
      codigo: '022',
      descripcion: 'TETELA DEL VOLCÁN',
    },
    {
      codigo: '023',
      descripcion: 'TLALNEPANTLA',
    },
    {
      codigo: '024',
      descripcion: 'TLALTIZAPÁN DE ZAPATA',
    },
    {
      codigo: '025',
      descripcion: 'TLAQUILTENANGO',
    },
    {
      codigo: '026',
      descripcion: 'TLAYACAPAN',
    },
    {
      codigo: '027',
      descripcion: 'TOTOLAPAN',
    },
    {
      codigo: '028',
      descripcion: 'XOCHITEPEC',
    },
    {
      codigo: '029',
      descripcion: 'YAUTEPEC',
    },
    {
      codigo: '030',
      descripcion: 'YECAPIXTLA',
    },
    {
      codigo: '031',
      descripcion: 'ZACATEPEC',
    },
    {
      codigo: '032',
      descripcion: 'ZACUALPAN DE AMILPAS',
    },
    {
      codigo: '033',
      descripcion: 'TEMOAC',
    },
  ],
  18: [
    {
      codigo: '001',
      descripcion: 'ACAPONETA',
    },
    {
      codigo: '002',
      descripcion: 'AHUACATLÁN',
    },
    {
      codigo: '003',
      descripcion: 'AMATLÁN DE CAÑAS',
    },
    {
      codigo: '004',
      descripcion: 'COMPOSTELA',
    },
    {
      codigo: '005',
      descripcion: 'HUAJICORI',
    },
    {
      codigo: '006',
      descripcion: 'IXTLÁN DEL RÍO',
    },
    {
      codigo: '007',
      descripcion: 'JALA',
    },
    {
      codigo: '008',
      descripcion: 'XALISCO',
    },
    {
      codigo: '009',
      descripcion: 'DEL NAYAR',
    },
    {
      codigo: '010',
      descripcion: 'ROSAMORADA',
    },
    {
      codigo: '011',
      descripcion: 'RUÍZ',
    },
    {
      codigo: '012',
      descripcion: 'SAN BLAS',
    },
    {
      codigo: '013',
      descripcion: 'SAN PEDRO LAGUNILLAS',
    },
    {
      codigo: '014',
      descripcion: 'SANTA MARÍA DEL ORO',
    },
    {
      codigo: '015',
      descripcion: 'SANTIAGO IXCUINTLA',
    },
    {
      codigo: '016',
      descripcion: 'TECUALA',
    },
    {
      codigo: '017',
      descripcion: 'TEPIC',
    },
    {
      codigo: '018',
      descripcion: 'TUXPAN',
    },
    {
      codigo: '019',
      descripcion: 'LA YESCA',
    },
    {
      codigo: '020',
      descripcion: 'BAHÍA DE BANDERAS',
    },
  ],
  19: [
    {
      codigo: '001',
      descripcion: 'ABASOLO',
    },
    {
      codigo: '002',
      descripcion: 'AGUALEGUAS',
    },
    {
      codigo: '003',
      descripcion: 'LOS ALDAMAS',
    },
    {
      codigo: '004',
      descripcion: 'ALLENDE',
    },
    {
      codigo: '005',
      descripcion: 'ANÁHUAC',
    },
    {
      codigo: '006',
      descripcion: 'APODACA',
    },
    {
      codigo: '007',
      descripcion: 'ARAMBERRI',
    },
    {
      codigo: '008',
      descripcion: 'BUSTAMANTE',
    },
    {
      codigo: '009',
      descripcion: 'CADEREYTA JIMÉNEZ',
    },
    {
      codigo: '010',
      descripcion: 'EL CARMEN',
    },
    {
      codigo: '011',
      descripcion: 'CERRALVO',
    },
    {
      codigo: '012',
      descripcion: 'CIÉNEGA DE FLORES',
    },
    {
      codigo: '013',
      descripcion: 'CHINA',
    },
    {
      codigo: '014',
      descripcion: 'DOCTOR ARROYO',
    },
    {
      codigo: '015',
      descripcion: 'DOCTOR COSS',
    },
    {
      codigo: '016',
      descripcion: 'DOCTOR GONZÁLEZ',
    },
    {
      codigo: '017',
      descripcion: 'GALEANA',
    },
    {
      codigo: '018',
      descripcion: 'GARCÍA',
    },
    {
      codigo: '019',
      descripcion: 'SAN PEDRO GARZA GARCÍA',
    },
    {
      codigo: '020',
      descripcion: 'GENERAL BRAVO',
    },
    {
      codigo: '021',
      descripcion: 'GENERAL ESCOBEDO',
    },
    {
      codigo: '022',
      descripcion: 'GENERAL TERÁN',
    },
    {
      codigo: '023',
      descripcion: 'GENERAL TREVIÑO',
    },
    {
      codigo: '024',
      descripcion: 'GENERAL ZARAGOZA',
    },
    {
      codigo: '025',
      descripcion: 'GENERAL ZUAZUA',
    },
    {
      codigo: '026',
      descripcion: 'GUADALUPE',
    },
    {
      codigo: '027',
      descripcion: 'LOS HERRERAS',
    },
    {
      codigo: '028',
      descripcion: 'HIGUERAS',
    },
    {
      codigo: '029',
      descripcion: 'HUALAHUISES',
    },
    {
      codigo: '030',
      descripcion: 'ITURBIDE',
    },
    {
      codigo: '031',
      descripcion: 'JUÁREZ',
    },
    {
      codigo: '032',
      descripcion: 'LAMPAZOS DE NARANJO',
    },
    {
      codigo: '033',
      descripcion: 'LINARES',
    },
    {
      codigo: '034',
      descripcion: 'MARÍN',
    },
    {
      codigo: '035',
      descripcion: 'MELCHOR OCAMPO',
    },
    {
      codigo: '036',
      descripcion: 'MIER Y NORIEGA',
    },
    {
      codigo: '037',
      descripcion: 'MINA',
    },
    {
      codigo: '038',
      descripcion: 'MONTEMORELOS',
    },
    {
      codigo: '039',
      descripcion: 'MONTERREY',
    },
    {
      codigo: '040',
      descripcion: 'PARÁS',
    },
    {
      codigo: '041',
      descripcion: 'PESQUERÍA',
    },
    {
      codigo: '042',
      descripcion: 'LOS RAMONES',
    },
    {
      codigo: '043',
      descripcion: 'RAYONES',
    },
    {
      codigo: '044',
      descripcion: 'SABINAS HIDALGO',
    },
    {
      codigo: '045',
      descripcion: 'SALINAS VICTORIA',
    },
    {
      codigo: '046',
      descripcion: 'SAN NICOLÁS DE LOS GARZA',
    },
    {
      codigo: '047',
      descripcion: 'HIDALGO',
    },
    {
      codigo: '048',
      descripcion: 'SANTA CATARINA',
    },
    {
      codigo: '049',
      descripcion: 'SANTIAGO',
    },
    {
      codigo: '050',
      descripcion: 'VALLECILLO',
    },
    {
      codigo: '051',
      descripcion: 'VILLALDAMA',
    },
  ],
  20: [
    {
      codigo: '001',
      descripcion: 'ABEJONES',
    },
    {
      codigo: '002',
      descripcion: 'ACATLÁN DE PÉREZ FIGUEROA',
    },
    {
      codigo: '003',
      descripcion: 'ASUNCIÓN CACALOTEPEC',
    },
    {
      codigo: '004',
      descripcion: 'ASUNCIÓN CUYOTEPEJI',
    },
    {
      codigo: '005',
      descripcion: 'ASUNCIÓN IXTALTEPEC',
    },
    {
      codigo: '006',
      descripcion: 'ASUNCIÓN NOCHIXTLÁN',
    },
    {
      codigo: '007',
      descripcion: 'ASUNCIÓN OCOTLÁN',
    },
    {
      codigo: '008',
      descripcion: 'ASUNCIÓN TLACOLULITA',
    },
    {
      codigo: '009',
      descripcion: 'AYOTZINTEPEC',
    },
    {
      codigo: '010',
      descripcion: 'EL BARRIO DE LA SOLEDAD',
    },
    {
      codigo: '011',
      descripcion: 'CALIHUALÁ',
    },
    {
      codigo: '012',
      descripcion: 'CANDELARIA LOXICHA',
    },
    {
      codigo: '013',
      descripcion: 'CIÉNEGA DE ZIMATLÁN',
    },
    {
      codigo: '014',
      descripcion: 'CIUDAD IXTEPEC',
    },
    {
      codigo: '015',
      descripcion: 'COATECAS ALTAS',
    },
    {
      codigo: '016',
      descripcion: 'COICOYÁN DE LAS FLORES',
    },
    {
      codigo: '017',
      descripcion: 'LA COMPAÑÍA',
    },
    {
      codigo: '018',
      descripcion: 'CONCEPCIÓN BUENAVISTA',
    },
    {
      codigo: '019',
      descripcion: 'CONCEPCIÓN PÁPALO',
    },
    {
      codigo: '020',
      descripcion: 'CONSTANCIA DEL ROSARIO',
    },
    {
      codigo: '021',
      descripcion: 'COSOLAPA',
    },
    {
      codigo: '022',
      descripcion: 'COSOLTEPEC',
    },
    {
      codigo: '023',
      descripcion: 'CUILÁPAM DE GUERRERO',
    },
    {
      codigo: '024',
      descripcion: 'CUYAMECALCO VILLA DE ZARAGOZA',
    },
    {
      codigo: '025',
      descripcion: 'CHAHUITES',
    },
    {
      codigo: '026',
      descripcion: 'CHALCATONGO DE HIDALGO',
    },
    {
      codigo: '027',
      descripcion: 'CHIQUIHUITLÁN DE BENITO JUÁREZ',
    },
    {
      codigo: '028',
      descripcion: 'EJUTLA DE CRESPO',
    },
    {
      codigo: '029',
      descripcion: 'ELOXOCHITLÁN DE FLORES MAGÓN',
    },
    {
      codigo: '030',
      descripcion: 'EL ESPINAL',
    },
    {
      codigo: '031',
      descripcion: 'TAMAZULÁPAM DEL ESPÍRITU SANTO',
    },
    {
      codigo: '032',
      descripcion: 'FRESNILLO DE TRUJANO',
    },
    {
      codigo: '033',
      descripcion: 'GUADALUPE ETLA',
    },
    {
      codigo: '034',
      descripcion: 'GUADALUPE DE RAMÍREZ',
    },
    {
      codigo: '035',
      descripcion: 'GUELATAO DE JUÁREZ',
    },
    {
      codigo: '036',
      descripcion: 'GUEVEA DE HUMBOLDT',
    },
    {
      codigo: '037',
      descripcion: 'MESONES HIDALGO',
    },
    {
      codigo: '038',
      descripcion: 'VILLA HIDALGO',
    },
    {
      codigo: '039',
      descripcion: 'HUAJUAPAN DE LEÓN',
    },
    {
      codigo: '040',
      descripcion: 'HUAUTEPEC',
    },
    {
      codigo: '041',
      descripcion: 'HUAUTLA DE JIMÉNEZ',
    },
    {
      codigo: '042',
      descripcion: 'IXTLÁN DE JUÁREZ',
    },
    {
      codigo: '043',
      descripcion: 'JUCHITÁN DE ZARAGOZA',
    },
    {
      codigo: '044',
      descripcion: 'LOMA BONITA',
    },
    {
      codigo: '045',
      descripcion: 'MAGDALENA APASCO',
    },
    {
      codigo: '046',
      descripcion: 'MAGDALENA JALTEPEC',
    },
    {
      codigo: '047',
      descripcion: 'SANTA MAGDALENA JICOTLÁN',
    },
    {
      codigo: '048',
      descripcion: 'MAGDALENA MIXTEPEC',
    },
    {
      codigo: '049',
      descripcion: 'MAGDALENA OCOTLÁN',
    },
    {
      codigo: '050',
      descripcion: 'MAGDALENA PEÑASCO',
    },
    {
      codigo: '051',
      descripcion: 'MAGDALENA TEITIPAC',
    },
    {
      codigo: '052',
      descripcion: 'MAGDALENA TEQUISISTLÁN',
    },
    {
      codigo: '053',
      descripcion: 'MAGDALENA TLACOTEPEC',
    },
    {
      codigo: '054',
      descripcion: 'MAGDALENA ZAHUATLÁN',
    },
    {
      codigo: '055',
      descripcion: 'MARISCALA DE JUÁREZ',
    },
    {
      codigo: '056',
      descripcion: 'MÁRTIRES DE TACUBAYA',
    },
    {
      codigo: '057',
      descripcion: 'MATÍAS ROMERO AVENDAÑO',
    },
    {
      codigo: '058',
      descripcion: 'MAZATLÁN VILLA DE FLORES',
    },
    {
      codigo: '059',
      descripcion: 'MIAHUATLÁN DE PORFIRIO DÍAZ',
    },
    {
      codigo: '060',
      descripcion: 'MIXISTLÁN DE LA REFORMA',
    },
    {
      codigo: '061',
      descripcion: 'MONJAS',
    },
    {
      codigo: '062',
      descripcion: 'NATIVIDAD',
    },
    {
      codigo: '063',
      descripcion: 'NAZARENO ETLA',
    },
    {
      codigo: '064',
      descripcion: 'NEJAPA DE MADERO',
    },
    {
      codigo: '065',
      descripcion: 'IXPANTEPEC NIEVES',
    },
    {
      codigo: '066',
      descripcion: 'SANTIAGO NILTEPEC',
    },
    {
      codigo: '067',
      descripcion: 'OAXACA DE JUÁREZ',
    },
    {
      codigo: '068',
      descripcion: 'OCOTLÁN DE MORELOS',
    },
    {
      codigo: '069',
      descripcion: 'LA PE',
    },
    {
      codigo: '070',
      descripcion: 'PINOTEPA DE DON LUIS',
    },
    {
      codigo: '071',
      descripcion: 'PLUMA HIDALGO',
    },
    {
      codigo: '072',
      descripcion: 'SAN JOSÉ DEL PROGRESO',
    },
    {
      codigo: '073',
      descripcion: 'PUTLA VILLA DE GUERRERO',
    },
    {
      codigo: '074',
      descripcion: 'SANTA CATARINA QUIOQUITANI',
    },
    {
      codigo: '075',
      descripcion: 'REFORMA DE PINEDA',
    },
    {
      codigo: '076',
      descripcion: 'LA REFORMA',
    },
    {
      codigo: '077',
      descripcion: 'REYES ETLA',
    },
    {
      codigo: '078',
      descripcion: 'ROJAS DE CUAUHTÉMOC',
    },
    {
      codigo: '079',
      descripcion: 'SALINA CRUZ',
    },
    {
      codigo: '080',
      descripcion: 'SAN AGUSTÍN AMATENGO',
    },
    {
      codigo: '081',
      descripcion: 'SAN AGUSTÍN ATENANGO',
    },
    {
      codigo: '082',
      descripcion: 'SAN AGUSTÍN CHAYUCO',
    },
    {
      codigo: '083',
      descripcion: 'SAN AGUSTÍN DE LAS JUNTAS',
    },
    {
      codigo: '084',
      descripcion: 'SAN AGUSTÍN ETLA',
    },
    {
      codigo: '085',
      descripcion: 'SAN AGUSTÍN LOXICHA',
    },
    {
      codigo: '086',
      descripcion: 'SAN AGUSTÍN TLACOTEPEC',
    },
    {
      codigo: '087',
      descripcion: 'SAN AGUSTÍN YATARENI',
    },
    {
      codigo: '088',
      descripcion: 'SAN ANDRÉS CABECERA NUEVA',
    },
    {
      codigo: '089',
      descripcion: 'SAN ANDRÉS DINICUITI',
    },
    {
      codigo: '090',
      descripcion: 'SAN ANDRÉS HUAXPALTEPEC',
    },
    {
      codigo: '091',
      descripcion: 'SAN ANDRÉS HUAYÁPAM',
    },
    {
      codigo: '092',
      descripcion: 'SAN ANDRÉS IXTLAHUACA',
    },
    {
      codigo: '093',
      descripcion: 'SAN ANDRÉS LAGUNAS',
    },
    {
      codigo: '094',
      descripcion: 'SAN ANDRÉS NUXIÑO',
    },
    {
      codigo: '095',
      descripcion: 'SAN ANDRÉS PAXTLÁN',
    },
    {
      codigo: '096',
      descripcion: 'SAN ANDRÉS SINAXTLA',
    },
    {
      codigo: '097',
      descripcion: 'SAN ANDRÉS SOLAGA',
    },
    {
      codigo: '098',
      descripcion: 'SAN ANDRÉS TEOTILÁLPAM',
    },
    {
      codigo: '099',
      descripcion: 'SAN ANDRÉS TEPETLAPA',
    },
    {
      codigo: 100,
      descripcion: 'SAN ANDRÉS YAÁ',
    },
    {
      codigo: 101,
      descripcion: 'SAN ANDRÉS ZABACHE',
    },
    {
      codigo: 102,
      descripcion: 'SAN ANDRÉS ZAUTLA',
    },
    {
      codigo: 103,
      descripcion: 'SAN ANTONINO CASTILLO VELASCO',
    },
    {
      codigo: 104,
      descripcion: 'SAN ANTONINO EL ALTO',
    },
    {
      codigo: 105,
      descripcion: 'SAN ANTONINO MONTE VERDE',
    },
    {
      codigo: 106,
      descripcion: 'SAN ANTONIO ACUTLA',
    },
    {
      codigo: 107,
      descripcion: 'SAN ANTONIO DE LA CAL',
    },
    {
      codigo: 108,
      descripcion: 'SAN ANTONIO HUITEPEC',
    },
    {
      codigo: 109,
      descripcion: 'SAN ANTONIO NANAHUATÍPAM',
    },
    {
      codigo: 110,
      descripcion: 'SAN ANTONIO SINICAHUA',
    },
    {
      codigo: 111,
      descripcion: 'SAN ANTONIO TEPETLAPA',
    },
    {
      codigo: 112,
      descripcion: 'SAN BALTAZAR CHICHICÁPAM',
    },
    {
      codigo: 113,
      descripcion: 'SAN BALTAZAR LOXICHA',
    },
    {
      codigo: 114,
      descripcion: 'SAN BALTAZAR YATZACHI EL BAJO',
    },
    {
      codigo: 115,
      descripcion: 'SAN BARTOLO COYOTEPEC',
    },
    {
      codigo: 116,
      descripcion: 'SAN BARTOLOMÉ AYAUTLA',
    },
    {
      codigo: 117,
      descripcion: 'SAN BARTOLOMÉ LOXICHA',
    },
    {
      codigo: 118,
      descripcion: 'SAN BARTOLOMÉ QUIALANA',
    },
    {
      codigo: 119,
      descripcion: 'SAN BARTOLOMÉ YUCUAÑE',
    },
    {
      codigo: 120,
      descripcion: 'SAN BARTOLOMÉ ZOOGOCHO',
    },
    {
      codigo: 121,
      descripcion: 'SAN BARTOLO SOYALTEPEC',
    },
    {
      codigo: 122,
      descripcion: 'SAN BARTOLO YAUTEPEC',
    },
    {
      codigo: 123,
      descripcion: 'SAN BERNARDO MIXTEPEC',
    },
    {
      codigo: 124,
      descripcion: 'SAN BLAS ATEMPA',
    },
    {
      codigo: 125,
      descripcion: 'SAN CARLOS YAUTEPEC',
    },
    {
      codigo: 126,
      descripcion: 'SAN CRISTÓBAL AMATLÁN',
    },
    {
      codigo: 127,
      descripcion: 'SAN CRISTÓBAL AMOLTEPEC',
    },
    {
      codigo: 128,
      descripcion: 'SAN CRISTÓBAL LACHIRIOAG',
    },
    {
      codigo: 129,
      descripcion: 'SAN CRISTÓBAL SUCHIXTLAHUACA',
    },
    {
      codigo: 130,
      descripcion: 'SAN DIONISIO DEL MAR',
    },
    {
      codigo: 131,
      descripcion: 'SAN DIONISIO OCOTEPEC',
    },
    {
      codigo: 132,
      descripcion: 'SAN DIONISIO OCOTLÁN',
    },
    {
      codigo: 133,
      descripcion: 'SAN ESTEBAN ATATLAHUCA',
    },
    {
      codigo: 134,
      descripcion: 'SAN FELIPE JALAPA DE DÍAZ',
    },
    {
      codigo: 135,
      descripcion: 'SAN FELIPE TEJALÁPAM',
    },
    {
      codigo: 136,
      descripcion: 'SAN FELIPE USILA',
    },
    {
      codigo: 137,
      descripcion: 'SAN FRANCISCO CAHUACUÁ',
    },
    {
      codigo: 138,
      descripcion: 'SAN FRANCISCO CAJONOS',
    },
    {
      codigo: 139,
      descripcion: 'SAN FRANCISCO CHAPULAPA',
    },
    {
      codigo: 140,
      descripcion: 'SAN FRANCISCO CHINDÚA',
    },
    {
      codigo: 141,
      descripcion: 'SAN FRANCISCO DEL MAR',
    },
    {
      codigo: 142,
      descripcion: 'SAN FRANCISCO HUEHUETLÁN',
    },
    {
      codigo: 143,
      descripcion: 'SAN FRANCISCO IXHUATÁN',
    },
    {
      codigo: 144,
      descripcion: 'SAN FRANCISCO JALTEPETONGO',
    },
    {
      codigo: 145,
      descripcion: 'SAN FRANCISCO LACHIGOLÓ',
    },
    {
      codigo: 146,
      descripcion: 'SAN FRANCISCO LOGUECHE',
    },
    {
      codigo: 147,
      descripcion: 'SAN FRANCISCO NUXAÑO',
    },
    {
      codigo: 148,
      descripcion: 'SAN FRANCISCO OZOLOTEPEC',
    },
    {
      codigo: 149,
      descripcion: 'SAN FRANCISCO SOLA',
    },
    {
      codigo: 150,
      descripcion: 'SAN FRANCISCO TELIXTLAHUACA',
    },
    {
      codigo: 151,
      descripcion: 'SAN FRANCISCO TEOPAN',
    },
    {
      codigo: 152,
      descripcion: 'SAN FRANCISCO TLAPANCINGO',
    },
    {
      codigo: 153,
      descripcion: 'SAN GABRIEL MIXTEPEC',
    },
    {
      codigo: 154,
      descripcion: 'SAN ILDEFONSO AMATLÁN',
    },
    {
      codigo: 155,
      descripcion: 'SAN ILDEFONSO SOLA',
    },
    {
      codigo: 156,
      descripcion: 'SAN ILDEFONSO VILLA ALTA',
    },
    {
      codigo: 157,
      descripcion: 'SAN JACINTO AMILPAS',
    },
    {
      codigo: 158,
      descripcion: 'SAN JACINTO TLACOTEPEC',
    },
    {
      codigo: 159,
      descripcion: 'SAN JERÓNIMO COATLÁN',
    },
    {
      codigo: 160,
      descripcion: 'SAN JERÓNIMO SILACAYOAPILLA',
    },
    {
      codigo: 161,
      descripcion: 'SAN JERÓNIMO SOSOLA',
    },
    {
      codigo: 162,
      descripcion: 'SAN JERÓNIMO TAVICHE',
    },
    {
      codigo: 163,
      descripcion: 'SAN JERÓNIMO TECÓATL',
    },
    {
      codigo: 164,
      descripcion: 'SAN JORGE NUCHITA',
    },
    {
      codigo: 165,
      descripcion: 'SAN JOSÉ AYUQUILA',
    },
    {
      codigo: 166,
      descripcion: 'SAN JOSÉ CHILTEPEC',
    },
    {
      codigo: 167,
      descripcion: 'SAN JOSÉ DEL PEÑASCO',
    },
    {
      codigo: 168,
      descripcion: 'SAN JOSÉ ESTANCIA GRANDE',
    },
    {
      codigo: 169,
      descripcion: 'SAN JOSÉ INDEPENDENCIA',
    },
    {
      codigo: 170,
      descripcion: 'SAN JOSÉ LACHIGUIRI',
    },
    {
      codigo: 171,
      descripcion: 'SAN JOSÉ TENANGO',
    },
    {
      codigo: 172,
      descripcion: 'SAN JUAN ACHIUTLA',
    },
    {
      codigo: 173,
      descripcion: 'SAN JUAN ATEPEC',
    },
    {
      codigo: 174,
      descripcion: 'ÁNIMAS TRUJANO',
    },
    {
      codigo: 175,
      descripcion: 'SAN JUAN BAUTISTA ATATLAHUCA',
    },
    {
      codigo: 176,
      descripcion: 'SAN JUAN BAUTISTA COIXTLAHUACA',
    },
    {
      codigo: 177,
      descripcion: 'SAN JUAN BAUTISTA CUICATLÁN',
    },
    {
      codigo: 178,
      descripcion: 'SAN JUAN BAUTISTA GUELACHE',
    },
    {
      codigo: 179,
      descripcion: 'SAN JUAN BAUTISTA JAYACATLÁN',
    },
    {
      codigo: 180,
      descripcion: 'SAN JUAN BAUTISTA LO DE SOTO',
    },
    {
      codigo: 181,
      descripcion: 'SAN JUAN BAUTISTA SUCHITEPEC',
    },
    {
      codigo: 182,
      descripcion: 'SAN JUAN BAUTISTA TLACOATZINTEPEC',
    },
    {
      codigo: 183,
      descripcion: 'SAN JUAN BAUTISTA TLACHICHILCO',
    },
    {
      codigo: 184,
      descripcion: 'SAN JUAN BAUTISTA TUXTEPEC',
    },
    {
      codigo: 185,
      descripcion: 'SAN JUAN CACAHUATEPEC',
    },
    {
      codigo: 186,
      descripcion: 'SAN JUAN CIENEGUILLA',
    },
    {
      codigo: 187,
      descripcion: 'SAN JUAN COATZÓSPAM',
    },
    {
      codigo: 188,
      descripcion: 'SAN JUAN COLORADO',
    },
    {
      codigo: 189,
      descripcion: 'SAN JUAN COMALTEPEC',
    },
    {
      codigo: 190,
      descripcion: 'SAN JUAN COTZOCÓN',
    },
    {
      codigo: 191,
      descripcion: 'SAN JUAN CHICOMEZÚCHIL',
    },
    {
      codigo: 192,
      descripcion: 'SAN JUAN CHILATECA',
    },
    {
      codigo: 193,
      descripcion: 'SAN JUAN DEL ESTADO',
    },
    {
      codigo: 194,
      descripcion: 'SAN JUAN DEL RÍO',
    },
    {
      codigo: 195,
      descripcion: 'SAN JUAN DIUXI',
    },
    {
      codigo: 196,
      descripcion: 'SAN JUAN EVANGELISTA ANALCO',
    },
    {
      codigo: 197,
      descripcion: 'SAN JUAN GUELAVÍA',
    },
    {
      codigo: 198,
      descripcion: 'SAN JUAN GUICHICOVI',
    },
    {
      codigo: 199,
      descripcion: 'SAN JUAN IHUALTEPEC',
    },
    {
      codigo: 200,
      descripcion: 'SAN JUAN JUQUILA MIXES',
    },
    {
      codigo: 201,
      descripcion: 'SAN JUAN JUQUILA VIJANOS',
    },
    {
      codigo: 202,
      descripcion: 'SAN JUAN LACHAO',
    },
    {
      codigo: 203,
      descripcion: 'SAN JUAN LACHIGALLA',
    },
    {
      codigo: 204,
      descripcion: 'SAN JUAN LAJARCIA',
    },
    {
      codigo: 205,
      descripcion: 'SAN JUAN LALANA',
    },
    {
      codigo: 206,
      descripcion: 'SAN JUAN DE LOS CUÉS',
    },
    {
      codigo: 207,
      descripcion: 'SAN JUAN MAZATLÁN',
    },
    {
      codigo: 208,
      descripcion: 'SAN JUAN MIXTEPEC JUXTLAHUACA',
    },
    {
      codigo: 209,
      descripcion: 'SAN JUAN MIXTEPEC MIAHUATLAN',
    },
    {
      codigo: 210,
      descripcion: 'SAN JUAN ÑUMÍ',
    },
    {
      codigo: 211,
      descripcion: 'SAN JUAN OZOLOTEPEC',
    },
    {
      codigo: 212,
      descripcion: 'SAN JUAN PETLAPA',
    },
    {
      codigo: 213,
      descripcion: 'SAN JUAN QUIAHIJE',
    },
    {
      codigo: 214,
      descripcion: 'SAN JUAN QUIOTEPEC',
    },
    {
      codigo: 215,
      descripcion: 'SAN JUAN SAYULTEPEC',
    },
    {
      codigo: 216,
      descripcion: 'SAN JUAN TABAÁ',
    },
    {
      codigo: 217,
      descripcion: 'SAN JUAN TAMAZOLA',
    },
    {
      codigo: 218,
      descripcion: 'SAN JUAN TEITA',
    },
    {
      codigo: 219,
      descripcion: 'SAN JUAN TEITIPAC',
    },
    {
      codigo: 220,
      descripcion: 'SAN JUAN TEPEUXILA',
    },
    {
      codigo: 221,
      descripcion: 'SAN JUAN TEPOSCOLULA',
    },
    {
      codigo: 222,
      descripcion: 'SAN JUAN YAEÉ',
    },
    {
      codigo: 223,
      descripcion: 'SAN JUAN YATZONA',
    },
    {
      codigo: 224,
      descripcion: 'SAN JUAN YUCUITA',
    },
    {
      codigo: 225,
      descripcion: 'SAN LORENZO',
    },
    {
      codigo: 226,
      descripcion: 'SAN LORENZO ALBARRADAS',
    },
    {
      codigo: 227,
      descripcion: 'SAN LORENZO CACAOTEPEC',
    },
    {
      codigo: 228,
      descripcion: 'SAN LORENZO CUAUNECUILTITLA',
    },
    {
      codigo: 229,
      descripcion: 'SAN LORENZO TEXMELÚCAN',
    },
    {
      codigo: 230,
      descripcion: 'SAN LORENZO VICTORIA',
    },
    {
      codigo: 231,
      descripcion: 'SAN LUCAS CAMOTLÁN',
    },
    {
      codigo: 232,
      descripcion: 'SAN LUCAS OJITLÁN',
    },
    {
      codigo: 233,
      descripcion: 'SAN LUCAS QUIAVINÍ',
    },
    {
      codigo: 234,
      descripcion: 'SAN LUCAS ZOQUIÁPAM',
    },
    {
      codigo: 235,
      descripcion: 'SAN LUIS AMATLÁN',
    },
    {
      codigo: 236,
      descripcion: 'SAN MARCIAL OZOLOTEPEC',
    },
    {
      codigo: 237,
      descripcion: 'SAN MARCOS ARTEAGA',
    },
    {
      codigo: 238,
      descripcion: 'SAN MARTÍN DE LOS CANSECOS',
    },
    {
      codigo: 239,
      descripcion: 'SAN MARTÍN HUAMELÚLPAM',
    },
    {
      codigo: 240,
      descripcion: 'SAN MARTÍN ITUNYOSO',
    },
    {
      codigo: 241,
      descripcion: 'SAN MARTÍN LACHILÁ',
    },
    {
      codigo: 242,
      descripcion: 'SAN MARTÍN PERAS',
    },
    {
      codigo: 243,
      descripcion: 'SAN MARTÍN TILCAJETE',
    },
    {
      codigo: 244,
      descripcion: 'SAN MARTÍN TOXPALAN',
    },
    {
      codigo: 245,
      descripcion: 'SAN MARTÍN ZACATEPEC',
    },
    {
      codigo: 246,
      descripcion: 'SAN MATEO CAJONOS',
    },
    {
      codigo: 247,
      descripcion: 'CAPULÁLPAM DE MÉNDEZ',
    },
    {
      codigo: 248,
      descripcion: 'SAN MATEO DEL MAR',
    },
    {
      codigo: 249,
      descripcion: 'SAN MATEO YOLOXOCHITLÁN',
    },
    {
      codigo: 250,
      descripcion: 'SAN MATEO ETLATONGO',
    },
    {
      codigo: 251,
      descripcion: 'SAN MATEO NEJÁPAM',
    },
    {
      codigo: 252,
      descripcion: 'SAN MATEO PEÑASCO',
    },
    {
      codigo: 253,
      descripcion: 'SAN MATEO PIÑAS',
    },
    {
      codigo: 254,
      descripcion: 'SAN MATEO RÍO HONDO',
    },
    {
      codigo: 255,
      descripcion: 'SAN MATEO SINDIHUI',
    },
    {
      codigo: 256,
      descripcion: 'SAN MATEO TLAPILTEPEC',
    },
    {
      codigo: 257,
      descripcion: 'SAN MELCHOR BETAZA',
    },
    {
      codigo: 258,
      descripcion: 'SAN MIGUEL ACHIUTLA',
    },
    {
      codigo: 259,
      descripcion: 'SAN MIGUEL AHUEHUETITLÁN',
    },
    {
      codigo: 260,
      descripcion: 'SAN MIGUEL ALOÁPAM',
    },
    {
      codigo: 261,
      descripcion: 'SAN MIGUEL AMATITLÁN',
    },
    {
      codigo: 262,
      descripcion: 'SAN MIGUEL AMATLÁN',
    },
    {
      codigo: 263,
      descripcion: 'SAN MIGUEL COATLÁN',
    },
    {
      codigo: 264,
      descripcion: 'SAN MIGUEL CHICAHUA',
    },
    {
      codigo: 265,
      descripcion: 'SAN MIGUEL CHIMALAPA',
    },
    {
      codigo: 266,
      descripcion: 'SAN MIGUEL DEL PUERTO',
    },
    {
      codigo: 267,
      descripcion: 'SAN MIGUEL DEL RÍO',
    },
    {
      codigo: 268,
      descripcion: 'SAN MIGUEL EJUTLA',
    },
    {
      codigo: 269,
      descripcion: 'SAN MIGUEL EL GRANDE',
    },
    {
      codigo: 270,
      descripcion: 'SAN MIGUEL HUAUTLA',
    },
    {
      codigo: 271,
      descripcion: 'SAN MIGUEL MIXTEPEC',
    },
    {
      codigo: 272,
      descripcion: 'SAN MIGUEL PANIXTLAHUACA',
    },
    {
      codigo: 273,
      descripcion: 'SAN MIGUEL PERAS',
    },
    {
      codigo: 274,
      descripcion: 'SAN MIGUEL PIEDRAS',
    },
    {
      codigo: 275,
      descripcion: 'SAN MIGUEL QUETZALTEPEC',
    },
    {
      codigo: 276,
      descripcion: 'SAN MIGUEL SANTA FLOR',
    },
    {
      codigo: 277,
      descripcion: 'VILLA SOLA DE VEGA',
    },
    {
      codigo: 278,
      descripcion: 'SAN MIGUEL SOYALTEPEC',
    },
    {
      codigo: 279,
      descripcion: 'SAN MIGUEL SUCHIXTEPEC',
    },
    {
      codigo: 280,
      descripcion: 'VILLA TALEA DE CASTRO',
    },
    {
      codigo: 281,
      descripcion: 'SAN MIGUEL TECOMATLÁN',
    },
    {
      codigo: 282,
      descripcion: 'SAN MIGUEL TENANGO',
    },
    {
      codigo: 283,
      descripcion: 'SAN MIGUEL TEQUIXTEPEC',
    },
    {
      codigo: 284,
      descripcion: 'SAN MIGUEL TILQUIÁPAM',
    },
    {
      codigo: 285,
      descripcion: 'SAN MIGUEL TLACAMAMA',
    },
    {
      codigo: 286,
      descripcion: 'SAN MIGUEL TLACOTEPEC',
    },
    {
      codigo: 287,
      descripcion: 'SAN MIGUEL TULANCINGO',
    },
    {
      codigo: 288,
      descripcion: 'SAN MIGUEL YOTAO',
    },
    {
      codigo: 289,
      descripcion: 'SAN NICOLÁS',
    },
    {
      codigo: 290,
      descripcion: 'SAN NICOLÁS HIDALGO',
    },
    {
      codigo: 291,
      descripcion: 'SAN PABLO COATLÁN',
    },
    {
      codigo: 292,
      descripcion: 'SAN PABLO CUATRO VENADOS',
    },
    {
      codigo: 293,
      descripcion: 'SAN PABLO ETLA',
    },
    {
      codigo: 294,
      descripcion: 'SAN PABLO HUITZO',
    },
    {
      codigo: 295,
      descripcion: 'SAN PABLO HUIXTEPEC',
    },
    {
      codigo: 296,
      descripcion: 'SAN PABLO MACUILTIANGUIS',
    },
    {
      codigo: 297,
      descripcion: 'SAN PABLO TIJALTEPEC',
    },
    {
      codigo: 298,
      descripcion: 'SAN PABLO VILLA DE MITLA',
    },
    {
      codigo: 299,
      descripcion: 'SAN PABLO YAGANIZA',
    },
    {
      codigo: 300,
      descripcion: 'SAN PEDRO AMUZGOS',
    },
    {
      codigo: 301,
      descripcion: 'SAN PEDRO APÓSTOL',
    },
    {
      codigo: 302,
      descripcion: 'SAN PEDRO ATOYAC',
    },
    {
      codigo: 303,
      descripcion: 'SAN PEDRO CAJONOS',
    },
    {
      codigo: 304,
      descripcion: 'SAN PEDRO COXCALTEPEC CÁNTAROS',
    },
    {
      codigo: 305,
      descripcion: 'SAN PEDRO COMITANCILLO',
    },
    {
      codigo: 306,
      descripcion: 'SAN PEDRO EL ALTO',
    },
    {
      codigo: 307,
      descripcion: 'SAN PEDRO HUAMELULA',
    },
    {
      codigo: 308,
      descripcion: 'SAN PEDRO HUILOTEPEC',
    },
    {
      codigo: 309,
      descripcion: 'SAN PEDRO IXCATLÁN',
    },
    {
      codigo: 310,
      descripcion: 'SAN PEDRO IXTLAHUACA',
    },
    {
      codigo: 311,
      descripcion: 'SAN PEDRO JALTEPETONGO',
    },
    {
      codigo: 312,
      descripcion: 'SAN PEDRO JICAYÁN',
    },
    {
      codigo: 313,
      descripcion: 'SAN PEDRO JOCOTIPAC',
    },
    {
      codigo: 314,
      descripcion: 'SAN PEDRO JUCHATENGO',
    },
    {
      codigo: 315,
      descripcion: 'SAN PEDRO MÁRTIR',
    },
    {
      codigo: 316,
      descripcion: 'SAN PEDRO MÁRTIR QUIECHAPA',
    },
    {
      codigo: 317,
      descripcion: 'SAN PEDRO MÁRTIR YUCUXACO',
    },
    {
      codigo: 318,
      descripcion: 'SAN PEDRO MIXTEPEC JUQUILA',
    },
    {
      codigo: 319,
      descripcion: 'SAN PEDRO MIXTEPEC MIAHUATLAN',
    },
    {
      codigo: 320,
      descripcion: 'SAN PEDRO MOLINOS',
    },
    {
      codigo: 321,
      descripcion: 'SAN PEDRO NOPALA',
    },
    {
      codigo: 322,
      descripcion: 'SAN PEDRO OCOPETATILLO',
    },
    {
      codigo: 323,
      descripcion: 'SAN PEDRO OCOTEPEC',
    },
    {
      codigo: 324,
      descripcion: 'SAN PEDRO POCHUTLA',
    },
    {
      codigo: 325,
      descripcion: 'SAN PEDRO QUIATONI',
    },
    {
      codigo: 326,
      descripcion: 'SAN PEDRO SOCHIÁPAM',
    },
    {
      codigo: 327,
      descripcion: 'SAN PEDRO TAPANATEPEC',
    },
    {
      codigo: 328,
      descripcion: 'SAN PEDRO TAVICHE',
    },
    {
      codigo: 329,
      descripcion: 'SAN PEDRO TEOZACOALCO',
    },
    {
      codigo: 330,
      descripcion: 'SAN PEDRO TEUTILA',
    },
    {
      codigo: 331,
      descripcion: 'SAN PEDRO TIDAÁ',
    },
    {
      codigo: 332,
      descripcion: 'SAN PEDRO TOPILTEPEC',
    },
    {
      codigo: 333,
      descripcion: 'SAN PEDRO TOTOLÁPAM',
    },
    {
      codigo: 334,
      descripcion: 'SAN PEDRO TUTUTEPEC',
    },
    {
      codigo: 335,
      descripcion: 'SAN PEDRO YANERI',
    },
    {
      codigo: 336,
      descripcion: 'SAN PEDRO YÓLOX',
    },
    {
      codigo: 337,
      descripcion: 'SAN PEDRO Y SAN PABLO AYUTLA',
    },
    {
      codigo: 338,
      descripcion: 'VILLA DE ETLA',
    },
    {
      codigo: 339,
      descripcion: 'SAN PEDRO Y SAN PABLO TEPOSCOLULA',
    },
    {
      codigo: 340,
      descripcion: 'SAN PEDRO Y SAN PABLO TEQUIXTEPEC',
    },
    {
      codigo: 341,
      descripcion: 'SAN PEDRO YUCUNAMA',
    },
    {
      codigo: 342,
      descripcion: 'SAN RAYMUNDO JALPAN',
    },
    {
      codigo: 343,
      descripcion: 'SAN SEBASTIÁN ABASOLO',
    },
    {
      codigo: 344,
      descripcion: 'SAN SEBASTIÁN COATLÁN',
    },
    {
      codigo: 345,
      descripcion: 'SAN SEBASTIÁN IXCAPA',
    },
    {
      codigo: 346,
      descripcion: 'SAN SEBASTIÁN NICANANDUTA',
    },
    {
      codigo: 347,
      descripcion: 'SAN SEBASTIÁN RÍO HONDO',
    },
    {
      codigo: 348,
      descripcion: 'SAN SEBASTIÁN TECOMAXTLAHUACA',
    },
    {
      codigo: 349,
      descripcion: 'SAN SEBASTIÁN TEITIPAC',
    },
    {
      codigo: 350,
      descripcion: 'SAN SEBASTIÁN TUTLA',
    },
    {
      codigo: 351,
      descripcion: 'SAN SIMÓN ALMOLONGAS',
    },
    {
      codigo: 352,
      descripcion: 'SAN SIMÓN ZAHUATLÁN',
    },
    {
      codigo: 353,
      descripcion: 'SANTA ANA',
    },
    {
      codigo: 354,
      descripcion: 'SANTA ANA ATEIXTLAHUACA',
    },
    {
      codigo: 355,
      descripcion: 'SANTA ANA CUAUHTÉMOC',
    },
    {
      codigo: 356,
      descripcion: 'SANTA ANA DEL VALLE',
    },
    {
      codigo: 357,
      descripcion: 'SANTA ANA TAVELA',
    },
    {
      codigo: 358,
      descripcion: 'SANTA ANA TLAPACOYAN',
    },
    {
      codigo: 359,
      descripcion: 'SANTA ANA YARENI',
    },
    {
      codigo: 360,
      descripcion: 'SANTA ANA ZEGACHE',
    },
    {
      codigo: 361,
      descripcion: 'SANTA CATALINA QUIERÍ',
    },
    {
      codigo: 362,
      descripcion: 'SANTA CATARINA CUIXTLA',
    },
    {
      codigo: 363,
      descripcion: 'SANTA CATARINA IXTEPEJI',
    },
    {
      codigo: 364,
      descripcion: 'SANTA CATARINA JUQUILA',
    },
    {
      codigo: 365,
      descripcion: 'SANTA CATARINA LACHATAO',
    },
    {
      codigo: 366,
      descripcion: 'SANTA CATARINA LOXICHA',
    },
    {
      codigo: 367,
      descripcion: 'SANTA CATARINA MECHOACÁN',
    },
    {
      codigo: 368,
      descripcion: 'SANTA CATARINA MINAS',
    },
    {
      codigo: 369,
      descripcion: 'SANTA CATARINA QUIANÉ',
    },
    {
      codigo: 370,
      descripcion: 'SANTA CATARINA TAYATA',
    },
    {
      codigo: 371,
      descripcion: 'SANTA CATARINA TICUÁ',
    },
    {
      codigo: 372,
      descripcion: 'SANTA CATARINA YOSONOTÚ',
    },
    {
      codigo: 373,
      descripcion: 'SANTA CATARINA ZAPOQUILA',
    },
    {
      codigo: 374,
      descripcion: 'SANTA CRUZ ACATEPEC',
    },
    {
      codigo: 375,
      descripcion: 'SANTA CRUZ AMILPAS',
    },
    {
      codigo: 376,
      descripcion: 'SANTA CRUZ DE BRAVO',
    },
    {
      codigo: 377,
      descripcion: 'SANTA CRUZ ITUNDUJIA',
    },
    {
      codigo: 378,
      descripcion: 'SANTA CRUZ MIXTEPEC',
    },
    {
      codigo: 379,
      descripcion: 'SANTA CRUZ NUNDACO',
    },
    {
      codigo: 380,
      descripcion: 'SANTA CRUZ PAPALUTLA',
    },
    {
      codigo: 381,
      descripcion: 'SANTA CRUZ TACACHE DE MINA',
    },
    {
      codigo: 382,
      descripcion: 'SANTA CRUZ TACAHUA',
    },
    {
      codigo: 383,
      descripcion: 'SANTA CRUZ TAYATA',
    },
    {
      codigo: 384,
      descripcion: 'SANTA CRUZ XITLA',
    },
    {
      codigo: 385,
      descripcion: 'SANTA CRUZ XOXOCOTLÁN',
    },
    {
      codigo: 386,
      descripcion: 'SANTA CRUZ ZENZONTEPEC',
    },
    {
      codigo: 387,
      descripcion: 'SANTA GERTRUDIS',
    },
    {
      codigo: 388,
      descripcion: 'SANTA INÉS DEL MONTE',
    },
    {
      codigo: 389,
      descripcion: 'SANTA INÉS YATZECHE',
    },
    {
      codigo: 390,
      descripcion: 'SANTA LUCÍA DEL CAMINO',
    },
    {
      codigo: 391,
      descripcion: 'SANTA LUCÍA MIAHUATLÁN',
    },
    {
      codigo: 392,
      descripcion: 'SANTA LUCÍA MONTEVERDE',
    },
    {
      codigo: 393,
      descripcion: 'SANTA LUCÍA OCOTLÁN',
    },
    {
      codigo: 394,
      descripcion: 'SANTA MARÍA ALOTEPEC',
    },
    {
      codigo: 395,
      descripcion: 'SANTA MARÍA APAZCO',
    },
    {
      codigo: 396,
      descripcion: 'SANTA MARÍA LA ASUNCIÓN',
    },
    {
      codigo: 397,
      descripcion: 'HEROICA CIUDAD DE TLAXIACO',
    },
    {
      codigo: 398,
      descripcion: 'AYOQUEZCO DE ALDAMA',
    },
    {
      codigo: 399,
      descripcion: 'SANTA MARÍA ATZOMPA',
    },
    {
      codigo: 400,
      descripcion: 'SANTA MARÍA CAMOTLÁN',
    },
    {
      codigo: 401,
      descripcion: 'SANTA MARÍA COLOTEPEC',
    },
    {
      codigo: 402,
      descripcion: 'SANTA MARÍA CORTIJO',
    },
    {
      codigo: 403,
      descripcion: 'SANTA MARÍA COYOTEPEC',
    },
    {
      codigo: 404,
      descripcion: 'SANTA MARÍA CHACHOÁPAM',
    },
    {
      codigo: 405,
      descripcion: 'VILLA DE CHILAPA DE DÍAZ',
    },
    {
      codigo: 406,
      descripcion: 'SANTA MARÍA CHILCHOTLA',
    },
    {
      codigo: 407,
      descripcion: 'SANTA MARÍA CHIMALAPA',
    },
    {
      codigo: 408,
      descripcion: 'SANTA MARÍA DEL ROSARIO',
    },
    {
      codigo: 409,
      descripcion: 'SANTA MARÍA DEL TULE',
    },
    {
      codigo: 410,
      descripcion: 'SANTA MARÍA ECATEPEC',
    },
    {
      codigo: 411,
      descripcion: 'SANTA MARÍA GUELACÉ',
    },
    {
      codigo: 412,
      descripcion: 'SANTA MARÍA GUIENAGATI',
    },
    {
      codigo: 413,
      descripcion: 'SANTA MARÍA HUATULCO',
    },
    {
      codigo: 414,
      descripcion: 'SANTA MARÍA HUAZOLOTITLÁN',
    },
    {
      codigo: 415,
      descripcion: 'SANTA MARÍA IPALAPA',
    },
    {
      codigo: 416,
      descripcion: 'SANTA MARÍA IXCATLÁN',
    },
    {
      codigo: 417,
      descripcion: 'SANTA MARÍA JACATEPEC',
    },
    {
      codigo: 418,
      descripcion: 'SANTA MARÍA JALAPA DEL MARQUÉS',
    },
    {
      codigo: 419,
      descripcion: 'SANTA MARÍA JALTIANGUIS',
    },
    {
      codigo: 420,
      descripcion: 'SANTA MARÍA LACHIXÍO',
    },
    {
      codigo: 421,
      descripcion: 'SANTA MARÍA MIXTEQUILLA',
    },
    {
      codigo: 422,
      descripcion: 'SANTA MARÍA NATIVITAS',
    },
    {
      codigo: 423,
      descripcion: 'SANTA MARÍA NDUAYACO',
    },
    {
      codigo: 424,
      descripcion: 'SANTA MARÍA OZOLOTEPEC',
    },
    {
      codigo: 425,
      descripcion: 'SANTA MARÍA PÁPALO',
    },
    {
      codigo: 426,
      descripcion: 'SANTA MARÍA PEÑOLES',
    },
    {
      codigo: 427,
      descripcion: 'SANTA MARÍA PETAPA',
    },
    {
      codigo: 428,
      descripcion: 'SANTA MARÍA QUIEGOLANI',
    },
    {
      codigo: 429,
      descripcion: 'SANTA MARÍA SOLA',
    },
    {
      codigo: 430,
      descripcion: 'SANTA MARÍA TATALTEPEC',
    },
    {
      codigo: 431,
      descripcion: 'SANTA MARÍA TECOMAVACA',
    },
    {
      codigo: 432,
      descripcion: 'SANTA MARÍA TEMAXCALAPA',
    },
    {
      codigo: 433,
      descripcion: 'SANTA MARÍA TEMAXCALTEPEC',
    },
    {
      codigo: 434,
      descripcion: 'SANTA MARÍA TEOPOXCO',
    },
    {
      codigo: 435,
      descripcion: 'SANTA MARÍA TEPANTLALI',
    },
    {
      codigo: 436,
      descripcion: 'SANTA MARÍA TEXCATITLÁN',
    },
    {
      codigo: 437,
      descripcion: 'SANTA MARÍA TLAHUITOLTEPEC',
    },
    {
      codigo: 438,
      descripcion: 'SANTA MARÍA TLALIXTAC',
    },
    {
      codigo: 439,
      descripcion: 'SANTA MARÍA TONAMECA',
    },
    {
      codigo: 440,
      descripcion: 'SANTA MARÍA TOTOLAPILLA',
    },
    {
      codigo: 441,
      descripcion: 'SANTA MARÍA XADANI',
    },
    {
      codigo: 442,
      descripcion: 'SANTA MARÍA YALINA',
    },
    {
      codigo: 443,
      descripcion: 'SANTA MARÍA YAVESÍA',
    },
    {
      codigo: 444,
      descripcion: 'SANTA MARÍA YOLOTEPEC',
    },
    {
      codigo: 445,
      descripcion: 'SANTA MARÍA YOSOYÚA',
    },
    {
      codigo: 446,
      descripcion: 'SANTA MARÍA YUCUHITI',
    },
    {
      codigo: 447,
      descripcion: 'SANTA MARÍA ZACATEPEC',
    },
    {
      codigo: 448,
      descripcion: 'SANTA MARÍA ZANIZA',
    },
    {
      codigo: 449,
      descripcion: 'SANTA MARÍA ZOQUITLÁN',
    },
    {
      codigo: 450,
      descripcion: 'SANTIAGO AMOLTEPEC',
    },
    {
      codigo: 451,
      descripcion: 'SANTIAGO APOALA',
    },
    {
      codigo: 452,
      descripcion: 'SANTIAGO APÓSTOL',
    },
    {
      codigo: 453,
      descripcion: 'SANTIAGO ASTATA',
    },
    {
      codigo: 454,
      descripcion: 'SANTIAGO ATITLÁN',
    },
    {
      codigo: 455,
      descripcion: 'SANTIAGO AYUQUILILLA',
    },
    {
      codigo: 456,
      descripcion: 'SANTIAGO CACALOXTEPEC',
    },
    {
      codigo: 457,
      descripcion: 'SANTIAGO CAMOTLÁN',
    },
    {
      codigo: 458,
      descripcion: 'SANTIAGO COMALTEPEC',
    },
    {
      codigo: 459,
      descripcion: 'SANTIAGO CHAZUMBA',
    },
    {
      codigo: 460,
      descripcion: 'SANTIAGO CHOÁPAM',
    },
    {
      codigo: 461,
      descripcion: 'SANTIAGO DEL RÍO',
    },
    {
      codigo: 462,
      descripcion: 'SANTIAGO HUAJOLOTITLÁN',
    },
    {
      codigo: 463,
      descripcion: 'SANTIAGO HUAUCLILLA',
    },
    {
      codigo: 464,
      descripcion: 'SANTIAGO IHUITLÁN PLUMAS',
    },
    {
      codigo: 465,
      descripcion: 'SANTIAGO IXCUINTEPEC',
    },
    {
      codigo: 466,
      descripcion: 'SANTIAGO IXTAYUTLA',
    },
    {
      codigo: 467,
      descripcion: 'SANTIAGO JAMILTEPEC',
    },
    {
      codigo: 468,
      descripcion: 'SANTIAGO JOCOTEPEC',
    },
    {
      codigo: 469,
      descripcion: 'SANTIAGO JUXTLAHUACA',
    },
    {
      codigo: 470,
      descripcion: 'SANTIAGO LACHIGUIRI',
    },
    {
      codigo: 471,
      descripcion: 'SANTIAGO LALOPA',
    },
    {
      codigo: 472,
      descripcion: 'SANTIAGO LAOLLAGA',
    },
    {
      codigo: 473,
      descripcion: 'SANTIAGO LAXOPA',
    },
    {
      codigo: 474,
      descripcion: 'SANTIAGO LLANO GRANDE',
    },
    {
      codigo: 475,
      descripcion: 'SANTIAGO MATATLÁN',
    },
    {
      codigo: 476,
      descripcion: 'SANTIAGO MILTEPEC',
    },
    {
      codigo: 477,
      descripcion: 'SANTIAGO MINAS',
    },
    {
      codigo: 478,
      descripcion: 'SANTIAGO NACALTEPEC',
    },
    {
      codigo: 479,
      descripcion: 'SANTIAGO NEJAPILLA',
    },
    {
      codigo: 480,
      descripcion: 'SANTIAGO NUNDICHE',
    },
    {
      codigo: 481,
      descripcion: 'SANTIAGO NUYOÓ',
    },
    {
      codigo: 482,
      descripcion: 'SANTIAGO PINOTEPA NACIONAL',
    },
    {
      codigo: 483,
      descripcion: 'SANTIAGO SUCHILQUITONGO',
    },
    {
      codigo: 484,
      descripcion: 'SANTIAGO TAMAZOLA',
    },
    {
      codigo: 485,
      descripcion: 'SANTIAGO TAPEXTLA',
    },
    {
      codigo: 486,
      descripcion: 'VILLA TEJÚPAM DE LA UNIÓN',
    },
    {
      codigo: 487,
      descripcion: 'SANTIAGO TENANGO',
    },
    {
      codigo: 488,
      descripcion: 'SANTIAGO TEPETLAPA',
    },
    {
      codigo: 489,
      descripcion: 'SANTIAGO TETEPEC',
    },
    {
      codigo: 490,
      descripcion: 'SANTIAGO TEXCALCINGO',
    },
    {
      codigo: 491,
      descripcion: 'SANTIAGO TEXTITLÁN',
    },
    {
      codigo: 492,
      descripcion: 'SANTIAGO TILANTONGO',
    },
    {
      codigo: 493,
      descripcion: 'SANTIAGO TILLO',
    },
    {
      codigo: 494,
      descripcion: 'SANTIAGO TLAZOYALTEPEC',
    },
    {
      codigo: 495,
      descripcion: 'SANTIAGO XANICA',
    },
    {
      codigo: 496,
      descripcion: 'SANTIAGO XIACUÍ',
    },
    {
      codigo: 497,
      descripcion: 'SANTIAGO YAITEPEC',
    },
    {
      codigo: 498,
      descripcion: 'SANTIAGO YAVEO',
    },
    {
      codigo: 499,
      descripcion: 'SANTIAGO YOLOMÉCATL',
    },
    {
      codigo: 500,
      descripcion: 'SANTIAGO YOSONDÚA',
    },
    {
      codigo: 501,
      descripcion: 'SANTIAGO YUCUYACHI',
    },
    {
      codigo: 502,
      descripcion: 'SANTIAGO ZACATEPEC',
    },
    {
      codigo: 503,
      descripcion: 'SANTIAGO ZOOCHILA',
    },
    {
      codigo: 504,
      descripcion: 'NUEVO ZOQUIÁPAM',
    },
    {
      codigo: 505,
      descripcion: 'SANTO DOMINGO INGENIO',
    },
    {
      codigo: 506,
      descripcion: 'SANTO DOMINGO ALBARRADAS',
    },
    {
      codigo: 507,
      descripcion: 'SANTO DOMINGO ARMENTA',
    },
    {
      codigo: 508,
      descripcion: 'SANTO DOMINGO CHIHUITÁN',
    },
    {
      codigo: 509,
      descripcion: 'SANTO DOMINGO DE MORELOS',
    },
    {
      codigo: 510,
      descripcion: 'SANTO DOMINGO IXCATLÁN',
    },
    {
      codigo: 511,
      descripcion: 'SANTO DOMINGO NUXAÁ',
    },
    {
      codigo: 512,
      descripcion: 'SANTO DOMINGO OZOLOTEPEC',
    },
    {
      codigo: 513,
      descripcion: 'SANTO DOMINGO PETAPA',
    },
    {
      codigo: 514,
      descripcion: 'SANTO DOMINGO ROAYAGA',
    },
    {
      codigo: 515,
      descripcion: 'SANTO DOMINGO TEHUANTEPEC',
    },
    {
      codigo: 516,
      descripcion: 'SANTO DOMINGO TEOJOMULCO',
    },
    {
      codigo: 517,
      descripcion: 'SANTO DOMINGO TEPUXTEPEC',
    },
    {
      codigo: 518,
      descripcion: 'SANTO DOMINGO TLATAYÁPAM',
    },
    {
      codigo: 519,
      descripcion: 'SANTO DOMINGO TOMALTEPEC',
    },
    {
      codigo: 520,
      descripcion: 'SANTO DOMINGO TONALÁ',
    },
    {
      codigo: 521,
      descripcion: 'SANTO DOMINGO TONALTEPEC',
    },
    {
      codigo: 522,
      descripcion: 'SANTO DOMINGO XAGACÍA',
    },
    {
      codigo: 523,
      descripcion: 'SANTO DOMINGO YANHUITLÁN',
    },
    {
      codigo: 524,
      descripcion: 'SANTO DOMINGO YODOHINO',
    },
    {
      codigo: 525,
      descripcion: 'SANTO DOMINGO ZANATEPEC',
    },
    {
      codigo: 526,
      descripcion: 'SANTOS REYES NOPALA',
    },
    {
      codigo: 527,
      descripcion: 'SANTOS REYES PÁPALO',
    },
    {
      codigo: 528,
      descripcion: 'SANTOS REYES TEPEJILLO',
    },
    {
      codigo: 529,
      descripcion: 'SANTOS REYES YUCUNÁ',
    },
    {
      codigo: 530,
      descripcion: 'SANTO TOMÁS JALIEZA',
    },
    {
      codigo: 531,
      descripcion: 'SANTO TOMÁS MAZALTEPEC',
    },
    {
      codigo: 532,
      descripcion: 'SANTO TOMÁS OCOTEPEC',
    },
    {
      codigo: 533,
      descripcion: 'SANTO TOMÁS TAMAZULAPAN',
    },
    {
      codigo: 534,
      descripcion: 'SAN VICENTE COATLÁN',
    },
    {
      codigo: 535,
      descripcion: 'SAN VICENTE LACHIXÍO',
    },
    {
      codigo: 536,
      descripcion: 'SAN VICENTE NUÑÚ',
    },
    {
      codigo: 537,
      descripcion: 'SILACAYOÁPAM',
    },
    {
      codigo: 538,
      descripcion: 'SITIO DE XITLAPEHUA',
    },
    {
      codigo: 539,
      descripcion: 'SOLEDAD ETLA',
    },
    {
      codigo: 540,
      descripcion: 'VILLA DE TAMAZULÁPAM DEL PROGRESO',
    },
    {
      codigo: 541,
      descripcion: 'TANETZE DE ZARAGOZA',
    },
    {
      codigo: 542,
      descripcion: 'TANICHE',
    },
    {
      codigo: 543,
      descripcion: 'TATALTEPEC DE VALDÉS',
    },
    {
      codigo: 544,
      descripcion: 'TEOCOCUILCO DE MARCOS PÉREZ',
    },
    {
      codigo: 545,
      descripcion: 'TEOTITLÁN DE FLORES MAGÓN',
    },
    {
      codigo: 546,
      descripcion: 'TEOTITLÁN DEL VALLE',
    },
    {
      codigo: 547,
      descripcion: 'TEOTONGO',
    },
    {
      codigo: 548,
      descripcion: 'TEPELMEME VILLA DE MORELOS',
    },
    {
      codigo: 549,
      descripcion: 'VILLA TEZOATLÁN DE SEGURA Y LUNA',
    },
    {
      codigo: 550,
      descripcion: 'SAN JERÓNIMO TLACOCHAHUAYA',
    },
    {
      codigo: 551,
      descripcion: 'TLACOLULA DE MATAMOROS',
    },
    {
      codigo: 552,
      descripcion: 'TLACOTEPEC PLUMAS',
    },
    {
      codigo: 553,
      descripcion: 'TLALIXTAC DE CABRERA',
    },
    {
      codigo: 554,
      descripcion: 'TOTONTEPEC VILLA DE MORELOS',
    },
    {
      codigo: 555,
      descripcion: 'TRINIDAD ZAACHILA',
    },
    {
      codigo: 556,
      descripcion: 'LA TRINIDAD VISTA HERMOSA',
    },
    {
      codigo: 557,
      descripcion: 'UNIÓN HIDALGO',
    },
    {
      codigo: 558,
      descripcion: 'VALERIO TRUJANO',
    },
    {
      codigo: 559,
      descripcion: 'SAN JUAN BAUTISTA VALLE NACIONAL',
    },
    {
      codigo: 560,
      descripcion: 'VILLA DÍAZ ORDAZ',
    },
    {
      codigo: 561,
      descripcion: 'YAXE',
    },
    {
      codigo: 562,
      descripcion: 'MAGDALENA YODOCONO DE PORFIRIO DÍAZ',
    },
    {
      codigo: 563,
      descripcion: 'YOGANA',
    },
    {
      codigo: 564,
      descripcion: 'YUTANDUCHI DE GUERRERO',
    },
    {
      codigo: 565,
      descripcion: 'VILLA DE ZAACHILA',
    },
    {
      codigo: 566,
      descripcion: 'SAN MATEO YUCUTINDOO',
    },
    {
      codigo: 567,
      descripcion: 'ZAPOTITLÁN LAGUNAS',
    },
    {
      codigo: 568,
      descripcion: 'ZAPOTITLÁN PALMAS',
    },
    {
      codigo: 569,
      descripcion: 'SANTA INÉS DE ZARAGOZA',
    },
    {
      codigo: 570,
      descripcion: 'ZIMATLÁN DE ÁLVAREZ',
    },
  ],
  21: [
    {
      codigo: '001',
      descripcion: 'ACAJETE',
    },
    {
      codigo: '002',
      descripcion: 'ACATENO',
    },
    {
      codigo: '003',
      descripcion: 'ACATLÁN',
    },
    {
      codigo: '004',
      descripcion: 'ACATZINGO',
    },
    {
      codigo: '005',
      descripcion: 'ACTEOPAN',
    },
    {
      codigo: '006',
      descripcion: 'AHUACATLÁN',
    },
    {
      codigo: '007',
      descripcion: 'AHUATLÁN',
    },
    {
      codigo: '008',
      descripcion: 'AHUAZOTEPEC',
    },
    {
      codigo: '009',
      descripcion: 'AHUEHUETITLA',
    },
    {
      codigo: '010',
      descripcion: 'AJALPAN',
    },
    {
      codigo: '011',
      descripcion: 'ALBINO ZERTUCHE',
    },
    {
      codigo: '012',
      descripcion: 'ALJOJUCA',
    },
    {
      codigo: '013',
      descripcion: 'ALTEPEXI',
    },
    {
      codigo: '014',
      descripcion: 'AMIXTLÁN',
    },
    {
      codigo: '015',
      descripcion: 'AMOZOC',
    },
    {
      codigo: '016',
      descripcion: 'AQUIXTLA',
    },
    {
      codigo: '017',
      descripcion: 'ATEMPAN',
    },
    {
      codigo: '018',
      descripcion: 'ATEXCAL',
    },
    {
      codigo: '019',
      descripcion: 'ATLIXCO',
    },
    {
      codigo: '020',
      descripcion: 'ATOYATEMPAN',
    },
    {
      codigo: '021',
      descripcion: 'ATZALA',
    },
    {
      codigo: '022',
      descripcion: 'ATZITZIHUACÁN',
    },
    {
      codigo: '023',
      descripcion: 'ATZITZINTLA',
    },
    {
      codigo: '024',
      descripcion: 'AXUTLA',
    },
    {
      codigo: '025',
      descripcion: 'AYOTOXCO DE GUERRERO',
    },
    {
      codigo: '026',
      descripcion: 'CALPAN',
    },
    {
      codigo: '027',
      descripcion: 'CALTEPEC',
    },
    {
      codigo: '028',
      descripcion: 'CAMOCUAUTLA',
    },
    {
      codigo: '029',
      descripcion: 'CAXHUACAN',
    },
    {
      codigo: '030',
      descripcion: 'COATEPEC',
    },
    {
      codigo: '031',
      descripcion: 'COATZINGO',
    },
    {
      codigo: '032',
      descripcion: 'COHETZALA',
    },
    {
      codigo: '033',
      descripcion: 'COHUECAN',
    },
    {
      codigo: '034',
      descripcion: 'CORONANGO',
    },
    {
      codigo: '035',
      descripcion: 'COXCATLÁN',
    },
    {
      codigo: '036',
      descripcion: 'COYOMEAPAN',
    },
    {
      codigo: '037',
      descripcion: 'COYOTEPEC',
    },
    {
      codigo: '038',
      descripcion: 'CUAPIAXTLA DE MADERO',
    },
    {
      codigo: '039',
      descripcion: 'CUAUTEMPAN',
    },
    {
      codigo: '040',
      descripcion: 'CUAUTINCHÁN',
    },
    {
      codigo: '041',
      descripcion: 'CUAUTLANCINGO',
    },
    {
      codigo: '042',
      descripcion: 'CUAYUCA DE ANDRADE',
    },
    {
      codigo: '043',
      descripcion: 'CUETZALAN DEL PROGRESO',
    },
    {
      codigo: '044',
      descripcion: 'CUYOACO',
    },
    {
      codigo: '045',
      descripcion: 'CHALCHICOMULA DE SESMA',
    },
    {
      codigo: '046',
      descripcion: 'CHAPULCO',
    },
    {
      codigo: '047',
      descripcion: 'CHIAUTLA',
    },
    {
      codigo: '048',
      descripcion: 'CHIAUTZINGO',
    },
    {
      codigo: '049',
      descripcion: 'CHICONCUAUTLA',
    },
    {
      codigo: '050',
      descripcion: 'CHICHIQUILA',
    },
    {
      codigo: '051',
      descripcion: 'CHIETLA',
    },
    {
      codigo: '052',
      descripcion: 'CHIGMECATITLÁN',
    },
    {
      codigo: '053',
      descripcion: 'CHIGNAHUAPAN',
    },
    {
      codigo: '054',
      descripcion: 'CHIGNAUTLA',
    },
    {
      codigo: '055',
      descripcion: 'CHILA',
    },
    {
      codigo: '056',
      descripcion: 'CHILA DE LA SAL',
    },
    {
      codigo: '057',
      descripcion: 'CHILA HONEY',
    },
    {
      codigo: '058',
      descripcion: 'CHILCHOTLA',
    },
    {
      codigo: '059',
      descripcion: 'CHINANTLA',
    },
    {
      codigo: '060',
      descripcion: 'DOMINGO ARENAS',
    },
    {
      codigo: '061',
      descripcion: 'ELOXOCHITLÁN',
    },
    {
      codigo: '062',
      descripcion: 'EPATLÁN',
    },
    {
      codigo: '063',
      descripcion: 'ESPERANZA',
    },
    {
      codigo: '064',
      descripcion: 'FRANCISCO Z. MENA',
    },
    {
      codigo: '065',
      descripcion: 'GENERAL FELIPE ÁNGELES',
    },
    {
      codigo: '066',
      descripcion: 'GUADALUPE',
    },
    {
      codigo: '067',
      descripcion: 'GUADALUPE VICTORIA',
    },
    {
      codigo: '068',
      descripcion: 'HERMENEGILDO GALEANA',
    },
    {
      codigo: '069',
      descripcion: 'HUAQUECHULA',
    },
    {
      codigo: '070',
      descripcion: 'HUATLATLAUCA',
    },
    {
      codigo: '071',
      descripcion: 'HUAUCHINANGO',
    },
    {
      codigo: '072',
      descripcion: 'HUEHUETLA',
    },
    {
      codigo: '073',
      descripcion: 'HUEHUETLÁN EL CHICO',
    },
    {
      codigo: '074',
      descripcion: 'HUEJOTZINGO',
    },
    {
      codigo: '075',
      descripcion: 'HUEYAPAN',
    },
    {
      codigo: '076',
      descripcion: 'HUEYTAMALCO',
    },
    {
      codigo: '077',
      descripcion: 'HUEYTLALPAN',
    },
    {
      codigo: '078',
      descripcion: 'HUITZILAN DE SERDÁN',
    },
    {
      codigo: '079',
      descripcion: 'HUITZILTEPEC',
    },
    {
      codigo: '080',
      descripcion: 'IGNACIO ALLENDE',
    },
    {
      codigo: '081',
      descripcion: 'IXCAMILPA DE GUERRERO',
    },
    {
      codigo: '082',
      descripcion: 'IXCAQUIXTLA',
    },
    {
      codigo: '083',
      descripcion: 'IXTACAMAXTITLÁN',
    },
    {
      codigo: '084',
      descripcion: 'IXTEPEC',
    },
    {
      codigo: '085',
      descripcion: 'IZÚCAR DE MATAMOROS',
    },
    {
      codigo: '086',
      descripcion: 'JALPAN',
    },
    {
      codigo: '087',
      descripcion: 'JOLALPAN',
    },
    {
      codigo: '088',
      descripcion: 'JONOTLA',
    },
    {
      codigo: '089',
      descripcion: 'JOPALA',
    },
    {
      codigo: '090',
      descripcion: 'JUAN C. BONILLA',
    },
    {
      codigo: '091',
      descripcion: 'JUAN GALINDO',
    },
    {
      codigo: '092',
      descripcion: 'JUAN N. MÉNDEZ',
    },
    {
      codigo: '093',
      descripcion: 'LAFRAGUA',
    },
    {
      codigo: '094',
      descripcion: 'LIBRES',
    },
    {
      codigo: '095',
      descripcion: 'LA MAGDALENA TLATLAUQUITEPEC',
    },
    {
      codigo: '096',
      descripcion: 'MAZAPILTEPEC DE JUÁREZ',
    },
    {
      codigo: '097',
      descripcion: 'MIXTLA',
    },
    {
      codigo: '098',
      descripcion: 'MOLCAXAC',
    },
    {
      codigo: '099',
      descripcion: 'CAÑADA MORELOS',
    },
    {
      codigo: 100,
      descripcion: 'NAUPAN',
    },
    {
      codigo: 101,
      descripcion: 'NAUZONTLA',
    },
    {
      codigo: 102,
      descripcion: 'NEALTICAN',
    },
    {
      codigo: 103,
      descripcion: 'NICOLÁS BRAVO',
    },
    {
      codigo: 104,
      descripcion: 'NOPALUCAN',
    },
    {
      codigo: 105,
      descripcion: 'OCOTEPEC',
    },
    {
      codigo: 106,
      descripcion: 'OCOYUCAN',
    },
    {
      codigo: 107,
      descripcion: 'OLINTLA',
    },
    {
      codigo: 108,
      descripcion: 'ORIENTAL',
    },
    {
      codigo: 109,
      descripcion: 'PAHUATLÁN',
    },
    {
      codigo: 110,
      descripcion: 'PALMAR DE BRAVO',
    },
    {
      codigo: 111,
      descripcion: 'PANTEPEC',
    },
    {
      codigo: 112,
      descripcion: 'PETLALCINGO',
    },
    {
      codigo: 113,
      descripcion: 'PIAXTLA',
    },
    {
      codigo: 114,
      descripcion: 'PUEBLA',
    },
    {
      codigo: 115,
      descripcion: 'QUECHOLAC',
    },
    {
      codigo: 116,
      descripcion: 'QUIMIXTLÁN',
    },
    {
      codigo: 117,
      descripcion: 'RAFAEL LARA GRAJALES',
    },
    {
      codigo: 118,
      descripcion: 'LOS REYES DE JUÁREZ',
    },
    {
      codigo: 119,
      descripcion: 'SAN ANDRÉS CHOLULA',
    },
    {
      codigo: 120,
      descripcion: 'SAN ANTONIO CAÑADA',
    },
    {
      codigo: 121,
      descripcion: 'SAN DIEGO LA MESA TOCHIMILTZINGO',
    },
    {
      codigo: 122,
      descripcion: 'SAN FELIPE TEOTLALCINGO',
    },
    {
      codigo: 123,
      descripcion: 'SAN FELIPE TEPATLÁN',
    },
    {
      codigo: 124,
      descripcion: 'SAN GABRIEL CHILAC',
    },
    {
      codigo: 125,
      descripcion: 'SAN GREGORIO ATZOMPA',
    },
    {
      codigo: 126,
      descripcion: 'SAN JERÓNIMO TECUANIPAN',
    },
    {
      codigo: 127,
      descripcion: 'SAN JERÓNIMO XAYACATLÁN',
    },
    {
      codigo: 128,
      descripcion: 'SAN JOSÉ CHIAPA',
    },
    {
      codigo: 129,
      descripcion: 'SAN JOSÉ MIAHUATLÁN',
    },
    {
      codigo: 130,
      descripcion: 'SAN JUAN ATENCO',
    },
    {
      codigo: 131,
      descripcion: 'SAN JUAN ATZOMPA',
    },
    {
      codigo: 132,
      descripcion: 'SAN MARTÍN TEXMELUCAN',
    },
    {
      codigo: 133,
      descripcion: 'SAN MARTÍN TOTOLTEPEC',
    },
    {
      codigo: 134,
      descripcion: 'SAN MATÍAS TLALANCALECA',
    },
    {
      codigo: 135,
      descripcion: 'SAN MIGUEL IXITLÁN',
    },
    {
      codigo: 136,
      descripcion: 'SAN MIGUEL XOXTLA',
    },
    {
      codigo: 137,
      descripcion: 'SAN NICOLÁS BUENOS AIRES',
    },
    {
      codigo: 138,
      descripcion: 'SAN NICOLÁS DE LOS RANCHOS',
    },
    {
      codigo: 139,
      descripcion: 'SAN PABLO ANICANO',
    },
    {
      codigo: 140,
      descripcion: 'SAN PEDRO CHOLULA',
    },
    {
      codigo: 141,
      descripcion: 'SAN PEDRO YELOIXTLAHUACA',
    },
    {
      codigo: 142,
      descripcion: 'SAN SALVADOR EL SECO',
    },
    {
      codigo: 143,
      descripcion: 'SAN SALVADOR EL VERDE',
    },
    {
      codigo: 144,
      descripcion: 'SAN SALVADOR HUIXCOLOTLA',
    },
    {
      codigo: 145,
      descripcion: 'SAN SEBASTIÁN TLACOTEPEC',
    },
    {
      codigo: 146,
      descripcion: 'SANTA CATARINA TLALTEMPAN',
    },
    {
      codigo: 147,
      descripcion: 'SANTA INÉS AHUATEMPAN',
    },
    {
      codigo: 148,
      descripcion: 'SANTA ISABEL CHOLULA',
    },
    {
      codigo: 149,
      descripcion: 'SANTIAGO MIAHUATLÁN',
    },
    {
      codigo: 150,
      descripcion: 'HUEHUETLÁN EL GRANDE',
    },
    {
      codigo: 151,
      descripcion: 'SANTO TOMÁS HUEYOTLIPAN',
    },
    {
      codigo: 152,
      descripcion: 'SOLTEPEC',
    },
    {
      codigo: 153,
      descripcion: 'TECALI DE HERRERA',
    },
    {
      codigo: 154,
      descripcion: 'TECAMACHALCO',
    },
    {
      codigo: 155,
      descripcion: 'TECOMATLÁN',
    },
    {
      codigo: 156,
      descripcion: 'TEHUACÁN',
    },
    {
      codigo: 157,
      descripcion: 'TEHUITZINGO',
    },
    {
      codigo: 158,
      descripcion: 'TENAMPULCO',
    },
    {
      codigo: 159,
      descripcion: 'TEOPANTLÁN',
    },
    {
      codigo: 160,
      descripcion: 'TEOTLALCO',
    },
    {
      codigo: 161,
      descripcion: 'TEPANCO DE LÓPEZ',
    },
    {
      codigo: 162,
      descripcion: 'TEPANGO DE RODRÍGUEZ',
    },
    {
      codigo: 163,
      descripcion: 'TEPATLAXCO DE HIDALGO',
    },
    {
      codigo: 164,
      descripcion: 'TEPEACA',
    },
    {
      codigo: 165,
      descripcion: 'TEPEMAXALCO',
    },
    {
      codigo: 166,
      descripcion: 'TEPEOJUMA',
    },
    {
      codigo: 167,
      descripcion: 'TEPETZINTLA',
    },
    {
      codigo: 168,
      descripcion: 'TEPEXCO',
    },
    {
      codigo: 169,
      descripcion: 'TEPEXI DE RODRÍGUEZ',
    },
    {
      codigo: 170,
      descripcion: 'TEPEYAHUALCO',
    },
    {
      codigo: 171,
      descripcion: 'TEPEYAHUALCO DE CUAUHTÉMOC',
    },
    {
      codigo: 172,
      descripcion: 'TETELA DE OCAMPO',
    },
    {
      codigo: 173,
      descripcion: 'TETELES DE AVILA CASTILLO',
    },
    {
      codigo: 174,
      descripcion: 'TEZIUTLÁN',
    },
    {
      codigo: 175,
      descripcion: 'TIANGUISMANALCO',
    },
    {
      codigo: 176,
      descripcion: 'TILAPA',
    },
    {
      codigo: 177,
      descripcion: 'TLACOTEPEC DE BENITO JUÁREZ',
    },
    {
      codigo: 178,
      descripcion: 'TLACUILOTEPEC',
    },
    {
      codigo: 179,
      descripcion: 'TLACHICHUCA',
    },
    {
      codigo: 180,
      descripcion: 'TLAHUAPAN',
    },
    {
      codigo: 181,
      descripcion: 'TLALTENANGO',
    },
    {
      codigo: 182,
      descripcion: 'TLANEPANTLA',
    },
    {
      codigo: 183,
      descripcion: 'TLAOLA',
    },
    {
      codigo: 184,
      descripcion: 'TLAPACOYA',
    },
    {
      codigo: 185,
      descripcion: 'TLAPANALÁ',
    },
    {
      codigo: 186,
      descripcion: 'TLATLAUQUITEPEC',
    },
    {
      codigo: 187,
      descripcion: 'TLAXCO',
    },
    {
      codigo: 188,
      descripcion: 'TOCHIMILCO',
    },
    {
      codigo: 189,
      descripcion: 'TOCHTEPEC',
    },
    {
      codigo: 190,
      descripcion: 'TOTOLTEPEC DE GUERRERO',
    },
    {
      codigo: 191,
      descripcion: 'TULCINGO',
    },
    {
      codigo: 192,
      descripcion: 'TUZAMAPAN DE GALEANA',
    },
    {
      codigo: 193,
      descripcion: 'TZICATLACOYAN',
    },
    {
      codigo: 194,
      descripcion: 'VENUSTIANO CARRANZA',
    },
    {
      codigo: 195,
      descripcion: 'VICENTE GUERRERO',
    },
    {
      codigo: 196,
      descripcion: 'XAYACATLÁN DE BRAVO',
    },
    {
      codigo: 197,
      descripcion: 'XICOTEPEC',
    },
    {
      codigo: 198,
      descripcion: 'XICOTLÁN',
    },
    {
      codigo: 199,
      descripcion: 'XIUTETELCO',
    },
    {
      codigo: 200,
      descripcion: 'XOCHIAPULCO',
    },
    {
      codigo: 201,
      descripcion: 'XOCHILTEPEC',
    },
    {
      codigo: 202,
      descripcion: 'XOCHITLÁN DE VICENTE SUÁREZ',
    },
    {
      codigo: 203,
      descripcion: 'XOCHITLÁN TODOS SANTOS',
    },
    {
      codigo: 204,
      descripcion: 'YAONÁHUAC',
    },
    {
      codigo: 205,
      descripcion: 'YEHUALTEPEC',
    },
    {
      codigo: 206,
      descripcion: 'ZACAPALA',
    },
    {
      codigo: 207,
      descripcion: 'ZACAPOAXTLA',
    },
    {
      codigo: 208,
      descripcion: 'ZACATLÁN',
    },
    {
      codigo: 209,
      descripcion: 'ZAPOTITLÁN',
    },
    {
      codigo: 210,
      descripcion: 'ZAPOTITLÁN DE MÉNDEZ',
    },
    {
      codigo: 211,
      descripcion: 'ZARAGOZA',
    },
    {
      codigo: 212,
      descripcion: 'ZAUTLA',
    },
    {
      codigo: 213,
      descripcion: 'ZIHUATEUTLA',
    },
    {
      codigo: 214,
      descripcion: 'ZINACATEPEC',
    },
    {
      codigo: 215,
      descripcion: 'ZONGOZOTLA',
    },
    {
      codigo: 216,
      descripcion: 'ZOQUIAPAN',
    },
    {
      codigo: 217,
      descripcion: 'ZOQUITLÁN',
    },
  ],
  22: [
    {
      codigo: '001',
      descripcion: 'AMEALCO DE BONFIL',
    },
    {
      codigo: '002',
      descripcion: 'PINAL DE AMOLES',
    },
    {
      codigo: '003',
      descripcion: 'ARROYO SECO',
    },
    {
      codigo: '004',
      descripcion: 'CADEREYTA DE MONTES',
    },
    {
      codigo: '005',
      descripcion: 'COLÓN',
    },
    {
      codigo: '006',
      descripcion: 'CORREGIDORA',
    },
    {
      codigo: '007',
      descripcion: 'EZEQUIEL MONTES',
    },
    {
      codigo: '008',
      descripcion: 'HUIMILPAN',
    },
    {
      codigo: '009',
      descripcion: 'JALPAN DE SERRA',
    },
    {
      codigo: '010',
      descripcion: 'LANDA DE MATAMOROS',
    },
    {
      codigo: '011',
      descripcion: 'EL MARQUÉS',
    },
    {
      codigo: '012',
      descripcion: 'PEDRO ESCOBEDO',
    },
    {
      codigo: '013',
      descripcion: 'PEÑAMILLER',
    },
    {
      codigo: '014',
      descripcion: 'QUERÉTARO',
    },
    {
      codigo: '015',
      descripcion: 'SAN JOAQUÍN',
    },
    {
      codigo: '016',
      descripcion: 'SAN JUAN DEL RÍO',
    },
    {
      codigo: '017',
      descripcion: 'TEQUISQUIAPAN',
    },
    {
      codigo: '018',
      descripcion: 'TOLIMÁN',
    },
  ],
  23: [
    {
      codigo: '001',
      descripcion: 'COZUMEL',
    },
    {
      codigo: '002',
      descripcion: 'FELIPE CARRILLO PUERTO',
    },
    {
      codigo: '003',
      descripcion: 'ISLA MUJERES',
    },
    {
      codigo: '004',
      descripcion: 'OTHÓN P. BLANCO',
    },
    {
      codigo: '005',
      descripcion: 'BENITO JUÁREZ',
    },
    {
      codigo: '006',
      descripcion: 'JOSÉ MARÍA MORELOS',
    },
    {
      codigo: '007',
      descripcion: 'LÁZARO CÁRDENAS',
    },
    {
      codigo: '008',
      descripcion: 'SOLIDARIDAD',
    },
  ],
  24: [
    {
      codigo: '001',
      descripcion: 'AHUALULCO',
    },
    {
      codigo: '002',
      descripcion: 'ALAQUINES',
    },
    {
      codigo: '003',
      descripcion: 'AQUISMÓN',
    },
    {
      codigo: '004',
      descripcion: 'ARMADILLO DE LOS INFANTE',
    },
    {
      codigo: '005',
      descripcion: 'CÁRDENAS',
    },
    {
      codigo: '006',
      descripcion: 'CATORCE',
    },
    {
      codigo: '007',
      descripcion: 'CEDRAL',
    },
    {
      codigo: '008',
      descripcion: 'CERRITOS',
    },
    {
      codigo: '009',
      descripcion: 'CERRO DE SAN PEDRO',
    },
    {
      codigo: '010',
      descripcion: 'CIUDAD DEL MAÍZ',
    },
    {
      codigo: '011',
      descripcion: 'CIUDAD FERNÁNDEZ',
    },
    {
      codigo: '012',
      descripcion: 'TANCANHUITZ DE SANTOS',
    },
    {
      codigo: '013',
      descripcion: 'CIUDAD VALLES',
    },
    {
      codigo: '014',
      descripcion: 'COXCATLÁN',
    },
    {
      codigo: '015',
      descripcion: 'CHARCAS',
    },
    {
      codigo: '016',
      descripcion: 'ÉBANO',
    },
    {
      codigo: '017',
      descripcion: 'GUADALCÁZAR',
    },
    {
      codigo: '018',
      descripcion: 'HUEHUETLÁN',
    },
    {
      codigo: '019',
      descripcion: 'LAGUNILLAS',
    },
    {
      codigo: '020',
      descripcion: 'MATEHUALA',
    },
    {
      codigo: '021',
      descripcion: 'MEXQUITIC DE CARMONA',
    },
    {
      codigo: '022',
      descripcion: 'MOCTEZUMA',
    },
    {
      codigo: '023',
      descripcion: 'RAYÓN',
    },
    {
      codigo: '024',
      descripcion: 'RIOVERDE',
    },
    {
      codigo: '025',
      descripcion: 'SALINAS',
    },
    {
      codigo: '026',
      descripcion: 'SAN ANTONIO',
    },
    {
      codigo: '027',
      descripcion: 'SAN CIRO DE ACOSTA',
    },
    {
      codigo: '028',
      descripcion: 'SAN LUIS POTOSÍ',
    },
    {
      codigo: '029',
      descripcion: 'SAN MARTÍN CHALCHICUAUTLA',
    },
    {
      codigo: '030',
      descripcion: 'SAN NICOLÁS TOLENTINO',
    },
    {
      codigo: '031',
      descripcion: 'SANTA CATARINA',
    },
    {
      codigo: '032',
      descripcion: 'SANTA MARÍA DEL RÍO',
    },
    {
      codigo: '033',
      descripcion: 'SANTO DOMINGO',
    },
    {
      codigo: '034',
      descripcion: 'SAN VICENTE TANCUAYALAB',
    },
    {
      codigo: '035',
      descripcion: 'SOLEDAD DE GRACIANO SÁNCHEZ',
    },
    {
      codigo: '036',
      descripcion: 'TAMASOPO',
    },
    {
      codigo: '037',
      descripcion: 'TAMAZUNCHALE',
    },
    {
      codigo: '038',
      descripcion: 'TAMPACÁN',
    },
    {
      codigo: '039',
      descripcion: 'TAMPAMOLÓN CORONA',
    },
    {
      codigo: '040',
      descripcion: 'TAMUÍN',
    },
    {
      codigo: '041',
      descripcion: 'TANLAJÁS',
    },
    {
      codigo: '042',
      descripcion: 'TANQUIÁN DE ESCOBEDO',
    },
    {
      codigo: '043',
      descripcion: 'TIERRA NUEVA',
    },
    {
      codigo: '044',
      descripcion: 'VANEGAS',
    },
    {
      codigo: '045',
      descripcion: 'VENADO',
    },
    {
      codigo: '046',
      descripcion: 'VILLA DE ARRIAGA',
    },
    {
      codigo: '047',
      descripcion: 'VILLA DE GUADALUPE',
    },
    {
      codigo: '048',
      descripcion: 'VILLA DE LA PAZ',
    },
    {
      codigo: '049',
      descripcion: 'VILLA DE RAMOS',
    },
    {
      codigo: '050',
      descripcion: 'VILLA DE REYES',
    },
    {
      codigo: '051',
      descripcion: 'VILLA HIDALGO',
    },
    {
      codigo: '052',
      descripcion: 'VILLA JUÁREZ',
    },
    {
      codigo: '053',
      descripcion: 'AXTLA DE TERRAZAS',
    },
    {
      codigo: '054',
      descripcion: 'XILITLA',
    },
    {
      codigo: '055',
      descripcion: 'ZARAGOZA',
    },
    {
      codigo: '056',
      descripcion: 'VILLA DE ARISTA',
    },
  ],
  25: [
    {
      codigo: '001',
      descripcion: 'AHOME',
    },
    {
      codigo: '002',
      descripcion: 'ANGOSTURA',
    },
    {
      codigo: '003',
      descripcion: 'BADIRAGUATO',
    },
    {
      codigo: '004',
      descripcion: 'CONCORDIA',
    },
    {
      codigo: '005',
      descripcion: 'COSALÁ',
    },
    {
      codigo: '006',
      descripcion: 'CULIACÁN',
    },
    {
      codigo: '007',
      descripcion: 'CHOIX',
    },
    {
      codigo: '008',
      descripcion: 'ELOTA',
    },
    {
      codigo: '009',
      descripcion: 'ESCUINAPA',
    },
    {
      codigo: '010',
      descripcion: 'EL FUERTE',
    },
    {
      codigo: '011',
      descripcion: 'GUASAVE',
    },
    {
      codigo: '012',
      descripcion: 'MAZATLÁN',
    },
    {
      codigo: '013',
      descripcion: 'MOCORITO',
    },
    {
      codigo: '014',
      descripcion: 'ROSARIO',
    },
    {
      codigo: '015',
      descripcion: 'SALVADOR ALVARADO',
    },
    {
      codigo: '016',
      descripcion: 'SAN IGNACIO',
    },
    {
      codigo: '017',
      descripcion: 'SINALOA',
    },
    {
      codigo: '018',
      descripcion: 'NAVOLATO',
    },
  ],
  26: [
    {
      codigo: '001',
      descripcion: 'ACONCHI',
    },
    {
      codigo: '002',
      descripcion: 'AGUA PRIETA',
    },
    {
      codigo: '003',
      descripcion: 'ALAMOS',
    },
    {
      codigo: '004',
      descripcion: 'ALTAR',
    },
    {
      codigo: '005',
      descripcion: 'ARIVECHI',
    },
    {
      codigo: '006',
      descripcion: 'ARIZPE',
    },
    {
      codigo: '007',
      descripcion: 'ATIL',
    },
    {
      codigo: '008',
      descripcion: 'BACADÉHUACHI',
    },
    {
      codigo: '009',
      descripcion: 'BACANORA',
    },
    {
      codigo: '010',
      descripcion: 'BACERAC',
    },
    {
      codigo: '011',
      descripcion: 'BACOACHI',
    },
    {
      codigo: '012',
      descripcion: 'BÁCUM',
    },
    {
      codigo: '013',
      descripcion: 'BANÁMICHI',
    },
    {
      codigo: '014',
      descripcion: 'BAVIÁCORA',
    },
    {
      codigo: '015',
      descripcion: 'BAVISPE',
    },
    {
      codigo: '016',
      descripcion: 'BENJAMÍN HILL',
    },
    {
      codigo: '017',
      descripcion: 'CABORCA',
    },
    {
      codigo: '018',
      descripcion: 'CAJEME',
    },
    {
      codigo: '019',
      descripcion: 'CANANEA',
    },
    {
      codigo: '020',
      descripcion: 'CARBÓ',
    },
    {
      codigo: '021',
      descripcion: 'LA COLORADA',
    },
    {
      codigo: '022',
      descripcion: 'CUCURPE',
    },
    {
      codigo: '023',
      descripcion: 'CUMPAS',
    },
    {
      codigo: '024',
      descripcion: 'DIVISADEROS',
    },
    {
      codigo: '025',
      descripcion: 'EMPALME',
    },
    {
      codigo: '026',
      descripcion: 'ETCHOJOA',
    },
    {
      codigo: '027',
      descripcion: 'FRONTERAS',
    },
    {
      codigo: '028',
      descripcion: 'GRANADOS',
    },
    {
      codigo: '029',
      descripcion: 'GUAYMAS',
    },
    {
      codigo: '030',
      descripcion: 'HERMOSILLO',
    },
    {
      codigo: '031',
      descripcion: 'HUACHINERA',
    },
    {
      codigo: '032',
      descripcion: 'HUÁSABAS',
    },
    {
      codigo: '033',
      descripcion: 'HUATABAMPO',
    },
    {
      codigo: '034',
      descripcion: 'HUÉPAC',
    },
    {
      codigo: '035',
      descripcion: 'IMURIS',
    },
    {
      codigo: '036',
      descripcion: 'MAGDALENA',
    },
    {
      codigo: '037',
      descripcion: 'MAZATÁN',
    },
    {
      codigo: '038',
      descripcion: 'MOCTEZUMA',
    },
    {
      codigo: '039',
      descripcion: 'NACO',
    },
    {
      codigo: '040',
      descripcion: 'NÁCORI CHICO',
    },
    {
      codigo: '041',
      descripcion: 'NACOZARI DE GARCÍA',
    },
    {
      codigo: '042',
      descripcion: 'NAVOJOA',
    },
    {
      codigo: '043',
      descripcion: 'NOGALES',
    },
    {
      codigo: '044',
      descripcion: 'ONAVAS',
    },
    {
      codigo: '045',
      descripcion: 'OPODEPE',
    },
    {
      codigo: '046',
      descripcion: 'OQUITOA',
    },
    {
      codigo: '047',
      descripcion: 'PITIQUITO',
    },
    {
      codigo: '048',
      descripcion: 'PUERTO PEÑASCO',
    },
    {
      codigo: '049',
      descripcion: 'QUIRIEGO',
    },
    {
      codigo: '050',
      descripcion: 'RAYÓN',
    },
    {
      codigo: '051',
      descripcion: 'ROSARIO',
    },
    {
      codigo: '052',
      descripcion: 'SAHUARIPA',
    },
    {
      codigo: '053',
      descripcion: 'SAN FELIPE DE JESÚS',
    },
    {
      codigo: '054',
      descripcion: 'SAN JAVIER',
    },
    {
      codigo: '055',
      descripcion: 'SAN LUIS RÍO COLORADO',
    },
    {
      codigo: '056',
      descripcion: 'SAN MIGUEL DE HORCASITAS',
    },
    {
      codigo: '057',
      descripcion: 'SAN PEDRO DE LA CUEVA',
    },
    {
      codigo: '058',
      descripcion: 'SANTA ANA',
    },
    {
      codigo: '059',
      descripcion: 'SANTA CRUZ',
    },
    {
      codigo: '060',
      descripcion: 'SÁRIC',
    },
    {
      codigo: '061',
      descripcion: 'SOYOPA',
    },
    {
      codigo: '062',
      descripcion: 'SUAQUI GRANDE',
    },
    {
      codigo: '063',
      descripcion: 'TEPACHE',
    },
    {
      codigo: '064',
      descripcion: 'TRINCHERAS',
    },
    {
      codigo: '065',
      descripcion: 'TUBUTAMA',
    },
    {
      codigo: '066',
      descripcion: 'URES',
    },
    {
      codigo: '067',
      descripcion: 'VILLA HIDALGO',
    },
    {
      codigo: '068',
      descripcion: 'VILLA PESQUEIRA',
    },
    {
      codigo: '069',
      descripcion: 'YÉCORA',
    },
    {
      codigo: '070',
      descripcion: 'GENERAL PLUTARCO ELÍAS CALLES',
    },
  ],
  27: [
    {
      codigo: '001',
      descripcion: 'BALANCÁN',
    },
    {
      codigo: '002',
      descripcion: 'CÁRDENAS',
    },
    {
      codigo: '003',
      descripcion: 'CENTLA',
    },
    {
      codigo: '004',
      descripcion: 'CENTRO VILLAHERMOSA',
    },
    {
      codigo: '005',
      descripcion: 'COMALCALCO',
    },
    {
      codigo: '006',
      descripcion: 'CUNDUACÁN',
    },
    {
      codigo: '007',
      descripcion: 'EMILIANO ZAPATA',
    },
    {
      codigo: '008',
      descripcion: 'HUIMANGUILLO',
    },
    {
      codigo: '009',
      descripcion: 'JALAPA',
    },
    {
      codigo: '010',
      descripcion: 'JALPA DE MÉNDEZ',
    },
    {
      codigo: '011',
      descripcion: 'JONUTA',
    },
    {
      codigo: '012',
      descripcion: 'MACUSPANA',
    },
    {
      codigo: '013',
      descripcion: 'NACAJUCA',
    },
    {
      codigo: '014',
      descripcion: 'PARAÍSO',
    },
    {
      codigo: '015',
      descripcion: 'TACOTALPA',
    },
    {
      codigo: '016',
      descripcion: 'TEAPA',
    },
    {
      codigo: '017',
      descripcion: 'TENOSIQUE',
    },
  ],
  28: [
    {
      codigo: '001',
      descripcion: 'ABASOLO',
    },
    {
      codigo: '002',
      descripcion: 'ALDAMA',
    },
    {
      codigo: '003',
      descripcion: 'ALTAMIRA',
    },
    {
      codigo: '004',
      descripcion: 'ANTIGUO MORELOS',
    },
    {
      codigo: '005',
      descripcion: 'BURGOS',
    },
    {
      codigo: '006',
      descripcion: 'BUSTAMANTE',
    },
    {
      codigo: '007',
      descripcion: 'CAMARGO',
    },
    {
      codigo: '008',
      descripcion: 'CASAS',
    },
    {
      codigo: '009',
      descripcion: 'CIUDAD MADERO',
    },
    {
      codigo: '010',
      descripcion: 'CRUILLAS',
    },
    {
      codigo: '011',
      descripcion: 'GÓMEZ FARÍAS',
    },
    {
      codigo: '012',
      descripcion: 'GONZÁLEZ',
    },
    {
      codigo: '013',
      descripcion: 'GÜÉMEZ',
    },
    {
      codigo: '014',
      descripcion: 'GUERRERO',
    },
    {
      codigo: '015',
      descripcion: 'GUSTAVO DÍAZ ORDAZ',
    },
    {
      codigo: '016',
      descripcion: 'HIDALGO',
    },
    {
      codigo: '017',
      descripcion: 'JAUMAVE',
    },
    {
      codigo: '018',
      descripcion: 'JIMÉNEZ',
    },
    {
      codigo: '019',
      descripcion: 'LLERA',
    },
    {
      codigo: '020',
      descripcion: 'MAINERO',
    },
    {
      codigo: '021',
      descripcion: 'EL MANTE',
    },
    {
      codigo: '022',
      descripcion: 'MATAMOROS',
    },
    {
      codigo: '023',
      descripcion: 'MÉNDEZ',
    },
    {
      codigo: '024',
      descripcion: 'MIER',
    },
    {
      codigo: '025',
      descripcion: 'MIGUEL ALEMÁN',
    },
    {
      codigo: '026',
      descripcion: 'MIQUIHUANA',
    },
    {
      codigo: '027',
      descripcion: 'NUEVO LAREDO',
    },
    {
      codigo: '028',
      descripcion: 'NUEVO MORELOS',
    },
    {
      codigo: '029',
      descripcion: 'OCAMPO',
    },
    {
      codigo: '030',
      descripcion: 'PADILLA',
    },
    {
      codigo: '031',
      descripcion: 'PALMILLAS',
    },
    {
      codigo: '032',
      descripcion: 'REYNOSA',
    },
    {
      codigo: '033',
      descripcion: 'RÍO BRAVO',
    },
    {
      codigo: '034',
      descripcion: 'SAN CARLOS',
    },
    {
      codigo: '035',
      descripcion: 'SAN FERNANDO',
    },
    {
      codigo: '036',
      descripcion: 'SAN NICOLÁS',
    },
    {
      codigo: '037',
      descripcion: 'SOTO LA MARINA',
    },
    {
      codigo: '038',
      descripcion: 'TAMPICO',
    },
    {
      codigo: '039',
      descripcion: 'TULA',
    },
    {
      codigo: '040',
      descripcion: 'VALLE HERMOSO',
    },
    {
      codigo: '041',
      descripcion: 'VICTORIA',
    },
    {
      codigo: '042',
      descripcion: 'VILLAGRÁN',
    },
    {
      codigo: '043',
      descripcion: 'XICOTÉNCATL',
    },
  ],
  29: [
    {
      codigo: '001',
      descripcion: 'AMAXAC DE GUERRERO',
    },
    {
      codigo: '002',
      descripcion: 'APETATITLÁN DE ANTONIO CARVAJAL',
    },
    {
      codigo: '003',
      descripcion: 'ATLANGATEPEC',
    },
    {
      codigo: '004',
      descripcion: 'ATLTZAYANCA',
    },
    {
      codigo: '005',
      descripcion: 'APIZACO',
    },
    {
      codigo: '006',
      descripcion: 'CALPULALPAN',
    },
    {
      codigo: '007',
      descripcion: 'EL CARMEN TEQUEXQUITLA',
    },
    {
      codigo: '008',
      descripcion: 'CUAPIAXTLA',
    },
    {
      codigo: '009',
      descripcion: 'CUAXOMULCO',
    },
    {
      codigo: '010',
      descripcion: 'CHIAUTEMPAN',
    },
    {
      codigo: '011',
      descripcion: 'MUÑOZ DE DOMINGO ARENAS',
    },
    {
      codigo: '012',
      descripcion: 'ESPAÑITA',
    },
    {
      codigo: '013',
      descripcion: 'HUAMANTLA',
    },
    {
      codigo: '014',
      descripcion: 'HUEYOTLIPAN',
    },
    {
      codigo: '015',
      descripcion: 'IXTACUIXTLA DE MARIANO MATAMOROS',
    },
    {
      codigo: '016',
      descripcion: 'IXTENCO',
    },
    {
      codigo: '017',
      descripcion: 'MAZATECOCHCO DE JOSÉ MARÍA MORELOS',
    },
    {
      codigo: '018',
      descripcion: 'CONTLA DE JUAN CUAMATZI',
    },
    {
      codigo: '019',
      descripcion: 'TEPETITLA DE LARDIZÁBAL',
    },
    {
      codigo: '020',
      descripcion: 'SANCTÓRUM DE LÁZARO CÁRDENAS',
    },
    {
      codigo: '021',
      descripcion: 'NANACAMILPA DE MARIANO ARISTA',
    },
    {
      codigo: '022',
      descripcion: 'ACUAMANALA DE MIGUEL HIDALGO',
    },
    {
      codigo: '023',
      descripcion: 'NATÍVITAS',
    },
    {
      codigo: '024',
      descripcion: 'PANOTLA',
    },
    {
      codigo: '025',
      descripcion: 'SAN PABLO DEL MONTE',
    },
    {
      codigo: '026',
      descripcion: 'SANTA CRUZ TLAXCALA',
    },
    {
      codigo: '027',
      descripcion: 'TENANCINGO',
    },
    {
      codigo: '028',
      descripcion: 'TEOLOCHOLCO',
    },
    {
      codigo: '029',
      descripcion: 'TEPEYANCO',
    },
    {
      codigo: '030',
      descripcion: 'TERRENATE',
    },
    {
      codigo: '031',
      descripcion: 'TETLA',
    },
    {
      codigo: '032',
      descripcion: 'TETLATLAHUCA',
    },
    {
      codigo: '033',
      descripcion: 'TLAXCALA',
    },
    {
      codigo: '034',
      descripcion: 'TLAXCO',
    },
    {
      codigo: '035',
      descripcion: 'TOCATLÁN',
    },
    {
      codigo: '036',
      descripcion: 'TOTOLAC',
    },
    {
      codigo: '037',
      descripcion: 'ZILTLALTÉPEC DE TRINIDAD SÁNCHEZ SANTOS',
    },
    {
      codigo: '038',
      descripcion: 'TZOMPANTEPEC',
    },
    {
      codigo: '039',
      descripcion: 'XALOZTOC',
    },
    {
      codigo: '040',
      descripcion: 'XALTOCAN',
    },
    {
      codigo: '041',
      descripcion: 'PAPALOTLA DE XICOHTÉNCATL',
    },
    {
      codigo: '042',
      descripcion: 'XICOHTZINCO',
    },
    {
      codigo: '043',
      descripcion: 'YAUHQUEMEHCAN',
    },
    {
      codigo: '044',
      descripcion: 'ZACATELCO',
    },
  ],
  30: [
    {
      codigo: '001',
      descripcion: 'ACAJETE',
    },
    {
      codigo: '002',
      descripcion: 'ACATLÁN',
    },
    {
      codigo: '003',
      descripcion: 'ACAYUCAN',
    },
    {
      codigo: '004',
      descripcion: 'ACTOPAN',
    },
    {
      codigo: '005',
      descripcion: 'ACULA',
    },
    {
      codigo: '006',
      descripcion: 'ACULTZINGO',
    },
    {
      codigo: '007',
      descripcion: 'CAMARÓN DE TEJEDA',
    },
    {
      codigo: '008',
      descripcion: 'ALPATLÁHUAC',
    },
    {
      codigo: '009',
      descripcion: 'ALTO LUCERO',
    },
    {
      codigo: '010',
      descripcion: 'ALTOTONGA',
    },
    {
      codigo: '011',
      descripcion: 'ALVARADO',
    },
    {
      codigo: '012',
      descripcion: 'AMATITLÁN',
    },
    {
      codigo: '013',
      descripcion: 'AMATLÁN TUXPAN',
    },
    {
      codigo: '014',
      descripcion: 'AMATLÁN DE LOS REYES',
    },
    {
      codigo: '015',
      descripcion: 'ANGEL R. CABADA',
    },
    {
      codigo: '016',
      descripcion: 'LA ANTIGUA',
    },
    {
      codigo: '017',
      descripcion: 'APAZAPAN',
    },
    {
      codigo: '018',
      descripcion: 'AQUILA',
    },
    {
      codigo: '019',
      descripcion: 'ASTACINGA',
    },
    {
      codigo: '020',
      descripcion: 'ATLAHUILCO',
    },
    {
      codigo: '021',
      descripcion: 'ATOYAC',
    },
    {
      codigo: '022',
      descripcion: 'ATZACAN',
    },
    {
      codigo: '023',
      descripcion: 'ATZALAN',
    },
    {
      codigo: '024',
      descripcion: 'TLALTETELA',
    },
    {
      codigo: '025',
      descripcion: 'AYAHUALULCO',
    },
    {
      codigo: '026',
      descripcion: 'BANDERILLA',
    },
    {
      codigo: '027',
      descripcion: 'BENITO JUÁREZ',
    },
    {
      codigo: '028',
      descripcion: 'BOCA DEL RÍO',
    },
    {
      codigo: '029',
      descripcion: 'CALCAHUALCO',
    },
    {
      codigo: '030',
      descripcion: 'CAMERINO Z. MENDOZA',
    },
    {
      codigo: '031',
      descripcion: 'CARRILLO PUERTO',
    },
    {
      codigo: '032',
      descripcion: 'CATEMACO',
    },
    {
      codigo: '033',
      descripcion: 'CAZONES DE HERRERA',
    },
    {
      codigo: '034',
      descripcion: 'CERRO AZUL',
    },
    {
      codigo: '035',
      descripcion: 'CITLALTÉPETL',
    },
    {
      codigo: '036',
      descripcion: 'COACOATZINTLA',
    },
    {
      codigo: '037',
      descripcion: 'COAHUITLÁN',
    },
    {
      codigo: '038',
      descripcion: 'COATEPEC',
    },
    {
      codigo: '039',
      descripcion: 'COATZACOALCOS',
    },
    {
      codigo: '040',
      descripcion: 'COATZINTLA',
    },
    {
      codigo: '041',
      descripcion: 'COETZALA',
    },
    {
      codigo: '042',
      descripcion: 'COLIPA',
    },
    {
      codigo: '043',
      descripcion: 'COMAPA',
    },
    {
      codigo: '044',
      descripcion: 'CÓRDOBA',
    },
    {
      codigo: '045',
      descripcion: 'COSAMALOAPAN',
    },
    {
      codigo: '046',
      descripcion: 'COSAUTLÁN DE CARVAJAL',
    },
    {
      codigo: '047',
      descripcion: 'COSCOMATEPEC',
    },
    {
      codigo: '048',
      descripcion: 'COSOLEACAQUE',
    },
    {
      codigo: '049',
      descripcion: 'COTAXTLA',
    },
    {
      codigo: '050',
      descripcion: 'COXQUIHUI',
    },
    {
      codigo: '051',
      descripcion: 'COYUTLA',
    },
    {
      codigo: '052',
      descripcion: 'CUICHAPA',
    },
    {
      codigo: '053',
      descripcion: 'CUITLÁHUAC',
    },
    {
      codigo: '054',
      descripcion: 'CHACALTIANGUIS',
    },
    {
      codigo: '055',
      descripcion: 'CHALMA',
    },
    {
      codigo: '056',
      descripcion: 'CHICONAMEL',
    },
    {
      codigo: '057',
      descripcion: 'CHICONQUIACO',
    },
    {
      codigo: '058',
      descripcion: 'CHICONTEPEC',
    },
    {
      codigo: '059',
      descripcion: 'CHINAMECA',
    },
    {
      codigo: '060',
      descripcion: 'CHINAMPA DE GOROSTIZA',
    },
    {
      codigo: '061',
      descripcion: 'LAS CHOAPAS',
    },
    {
      codigo: '062',
      descripcion: 'CHOCAMÁN',
    },
    {
      codigo: '063',
      descripcion: 'CHONTLA',
    },
    {
      codigo: '064',
      descripcion: 'CHUMATLÁN',
    },
    {
      codigo: '065',
      descripcion: 'EMILIANO ZAPATA',
    },
    {
      codigo: '066',
      descripcion: 'ESPINAL',
    },
    {
      codigo: '067',
      descripcion: 'FILOMENO MATA',
    },
    {
      codigo: '068',
      descripcion: 'FORTÍN',
    },
    {
      codigo: '069',
      descripcion: 'GUTIÉRREZ ZAMORA',
    },
    {
      codigo: '070',
      descripcion: 'HIDALGOTITLÁN',
    },
    {
      codigo: '071',
      descripcion: 'HUATUSCO',
    },
    {
      codigo: '072',
      descripcion: 'HUAYACOCOTLA',
    },
    {
      codigo: '073',
      descripcion: 'HUEYAPAN DE OCAMPO',
    },
    {
      codigo: '074',
      descripcion: 'HUILOAPAN DE CUAUHTÉMOC',
    },
    {
      codigo: '075',
      descripcion: 'IGNACIO DE LA LLAVE',
    },
    {
      codigo: '076',
      descripcion: 'ILAMATLÁN',
    },
    {
      codigo: '077',
      descripcion: 'ISLA',
    },
    {
      codigo: '078',
      descripcion: 'IXCATEPEC',
    },
    {
      codigo: '079',
      descripcion: 'IXHUACÁN DE LOS REYES',
    },
    {
      codigo: '080',
      descripcion: 'IXHUATLÁN DEL CAFÉ',
    },
    {
      codigo: '081',
      descripcion: 'IXHUATLANCILLO',
    },
    {
      codigo: '082',
      descripcion: 'IXHUATLÁN DEL SURESTE',
    },
    {
      codigo: '083',
      descripcion: 'IXHUATLÁN DE MADERO',
    },
    {
      codigo: '084',
      descripcion: 'IXMATLAHUACAN',
    },
    {
      codigo: '085',
      descripcion: 'IXTACZOQUITLÁN',
    },
    {
      codigo: '086',
      descripcion: 'JALACINGO',
    },
    {
      codigo: '087',
      descripcion: 'XALAPA',
    },
    {
      codigo: '088',
      descripcion: 'JALCOMULCO',
    },
    {
      codigo: '089',
      descripcion: 'JÁLTIPAN',
    },
    {
      codigo: '090',
      descripcion: 'JAMAPA',
    },
    {
      codigo: '091',
      descripcion: 'JESÚS CARRANZA',
    },
    {
      codigo: '092',
      descripcion: 'XICO',
    },
    {
      codigo: '093',
      descripcion: 'JILOTEPEC',
    },
    {
      codigo: '094',
      descripcion: 'JUAN RODRÍGUEZ CLARA',
    },
    {
      codigo: '095',
      descripcion: 'JUCHIQUE DE FERRER',
    },
    {
      codigo: '096',
      descripcion: 'LANDERO Y COSS',
    },
    {
      codigo: '097',
      descripcion: 'LERDO DE TEJADA',
    },
    {
      codigo: '098',
      descripcion: 'MAGDALENA',
    },
    {
      codigo: '099',
      descripcion: 'MALTRATA',
    },
    {
      codigo: 100,
      descripcion: 'MANLIO FABIO ALTAMIRANO',
    },
    {
      codigo: 101,
      descripcion: 'MARIANO ESCOBEDO',
    },
    {
      codigo: 102,
      descripcion: 'MARTÍNEZ DE LA TORRE',
    },
    {
      codigo: 103,
      descripcion: 'MECATLÁN',
    },
    {
      codigo: 104,
      descripcion: 'MECAYAPAN',
    },
    {
      codigo: 105,
      descripcion: 'MEDELLÍN',
    },
    {
      codigo: 106,
      descripcion: 'MIAHUATLÁN',
    },
    {
      codigo: 107,
      descripcion: 'LAS MINAS',
    },
    {
      codigo: 108,
      descripcion: 'MINATITLÁN',
    },
    {
      codigo: 109,
      descripcion: 'MISANTLA',
    },
    {
      codigo: 110,
      descripcion: 'MIXTLA DE ALTAMIRANO',
    },
    {
      codigo: 111,
      descripcion: 'MOLOACÁN',
    },
    {
      codigo: 112,
      descripcion: 'NAOLINCO',
    },
    {
      codigo: 113,
      descripcion: 'NARANJAL',
    },
    {
      codigo: 114,
      descripcion: 'NAUTLA',
    },
    {
      codigo: 115,
      descripcion: 'NOGALES',
    },
    {
      codigo: 116,
      descripcion: 'OLUTA',
    },
    {
      codigo: 117,
      descripcion: 'OMEALCA',
    },
    {
      codigo: 118,
      descripcion: 'ORIZABA',
    },
    {
      codigo: 119,
      descripcion: 'OTATITLÁN',
    },
    {
      codigo: 120,
      descripcion: 'OTEAPAN',
    },
    {
      codigo: 121,
      descripcion: 'OZULUAMA DE MASCAREÑAS',
    },
    {
      codigo: 122,
      descripcion: 'PAJAPAN',
    },
    {
      codigo: 123,
      descripcion: 'PÁNUCO',
    },
    {
      codigo: 124,
      descripcion: 'PAPANTLA',
    },
    {
      codigo: 125,
      descripcion: 'PASO DEL MACHO',
    },
    {
      codigo: 126,
      descripcion: 'PASO DE OVEJAS',
    },
    {
      codigo: 127,
      descripcion: 'LA PERLA',
    },
    {
      codigo: 128,
      descripcion: 'PEROTE',
    },
    {
      codigo: 129,
      descripcion: 'PLATÓN SÁNCHEZ',
    },
    {
      codigo: 130,
      descripcion: 'PLAYA VICENTE',
    },
    {
      codigo: 131,
      descripcion: 'POZA RICA DE HIDALGO',
    },
    {
      codigo: 132,
      descripcion: 'LAS VIGAS DE RAMÍREZ',
    },
    {
      codigo: 133,
      descripcion: 'PUEBLO VIEJO',
    },
    {
      codigo: 134,
      descripcion: 'PUENTE NACIONAL',
    },
    {
      codigo: 135,
      descripcion: 'RAFAEL DELGADO',
    },
    {
      codigo: 136,
      descripcion: 'RAFAEL LUCIO',
    },
    {
      codigo: 137,
      descripcion: 'LOS REYES',
    },
    {
      codigo: 138,
      descripcion: 'RÍO BLANCO',
    },
    {
      codigo: 139,
      descripcion: 'SALTABARRANCA',
    },
    {
      codigo: 140,
      descripcion: 'SAN ANDRÉS TENEJAPAN',
    },
    {
      codigo: 141,
      descripcion: 'SAN ANDRÉS TUXTLA',
    },
    {
      codigo: 142,
      descripcion: 'SAN JUAN EVANGELISTA',
    },
    {
      codigo: 143,
      descripcion: 'SANTIAGO TUXTLA',
    },
    {
      codigo: 144,
      descripcion: 'SAYULA DE ALEMÁN',
    },
    {
      codigo: 145,
      descripcion: 'SOCONUSCO',
    },
    {
      codigo: 146,
      descripcion: 'SOCHIAPA',
    },
    {
      codigo: 147,
      descripcion: 'SOLEDAD ATZOMPA',
    },
    {
      codigo: 148,
      descripcion: 'SOLEDAD DE DOBLADO',
    },
    {
      codigo: 149,
      descripcion: 'SOTEAPAN',
    },
    {
      codigo: 150,
      descripcion: 'TAMALÍN',
    },
    {
      codigo: 151,
      descripcion: 'TAMIAHUA',
    },
    {
      codigo: 152,
      descripcion: 'TAMPICO ALTO',
    },
    {
      codigo: 153,
      descripcion: 'TANCOCO',
    },
    {
      codigo: 154,
      descripcion: 'TANTIMA',
    },
    {
      codigo: 155,
      descripcion: 'TANTOYUCA',
    },
    {
      codigo: 156,
      descripcion: 'TATATILA',
    },
    {
      codigo: 157,
      descripcion: 'CASTILLO DE TEAYO',
    },
    {
      codigo: 158,
      descripcion: 'TECOLUTLA',
    },
    {
      codigo: 159,
      descripcion: 'TEHUIPANGO',
    },
    {
      codigo: 160,
      descripcion: 'TEMAPACHE',
    },
    {
      codigo: 161,
      descripcion: 'TEMPOAL',
    },
    {
      codigo: 162,
      descripcion: 'TENAMPA',
    },
    {
      codigo: 163,
      descripcion: 'TENOCHTITLÁN',
    },
    {
      codigo: 164,
      descripcion: 'TEOCELO',
    },
    {
      codigo: 165,
      descripcion: 'TEPATLAXCO',
    },
    {
      codigo: 166,
      descripcion: 'TEPETLÁN',
    },
    {
      codigo: 167,
      descripcion: 'TEPETZINTLA',
    },
    {
      codigo: 168,
      descripcion: 'TEQUILA',
    },
    {
      codigo: 169,
      descripcion: 'JOSÉ AZUETA',
    },
    {
      codigo: 170,
      descripcion: 'TEXCATEPEC',
    },
    {
      codigo: 171,
      descripcion: 'TEXHUACÁN',
    },
    {
      codigo: 172,
      descripcion: 'TEXISTEPEC',
    },
    {
      codigo: 173,
      descripcion: 'TEZONAPA',
    },
    {
      codigo: 174,
      descripcion: 'TIERRA BLANCA',
    },
    {
      codigo: 175,
      descripcion: 'TIHUATLÁN',
    },
    {
      codigo: 176,
      descripcion: 'TLACOJALPAN',
    },
    {
      codigo: 177,
      descripcion: 'TLACOLULAN',
    },
    {
      codigo: 178,
      descripcion: 'TLACOTALPAN',
    },
    {
      codigo: 179,
      descripcion: 'TLACOTEPEC DE MEJÍA',
    },
    {
      codigo: 180,
      descripcion: 'TLACHICHILCO',
    },
    {
      codigo: 181,
      descripcion: 'TLALIXCOYAN',
    },
    {
      codigo: 182,
      descripcion: 'TLALNELHUAYOCAN',
    },
    {
      codigo: 183,
      descripcion: 'TLAPACOYAN',
    },
    {
      codigo: 184,
      descripcion: 'TLAQUILPA',
    },
    {
      codigo: 185,
      descripcion: 'TLILAPAN',
    },
    {
      codigo: 186,
      descripcion: 'TOMATLÁN',
    },
    {
      codigo: 187,
      descripcion: 'TONAYÁN',
    },
    {
      codigo: 188,
      descripcion: 'TOTUTLA',
    },
    {
      codigo: 189,
      descripcion: 'TUXPAN',
    },
    {
      codigo: 190,
      descripcion: 'TUXTILLA',
    },
    {
      codigo: 191,
      descripcion: 'URSULO GALVÁN',
    },
    {
      codigo: 192,
      descripcion: 'VEGA DE ALATORRE',
    },
    {
      codigo: 193,
      descripcion: 'VERACRUZ',
    },
    {
      codigo: 194,
      descripcion: 'VILLA ALDAMA',
    },
    {
      codigo: 195,
      descripcion: 'XOXOCOTLA',
    },
    {
      codigo: 196,
      descripcion: 'YANGA',
    },
    {
      codigo: 197,
      descripcion: 'YECUATLAN',
    },
    {
      codigo: 198,
      descripcion: 'ZACUALPAN',
    },
    {
      codigo: 199,
      descripcion: 'ZARAGOZA',
    },
    {
      codigo: 200,
      descripcion: 'ZENTLA',
    },
    {
      codigo: 201,
      descripcion: 'ZONGOLICA',
    },
    {
      codigo: 202,
      descripcion: 'ZONTECOMATLÁN',
    },
    {
      codigo: 203,
      descripcion: 'ZOZOCOLCO DE HIDALGO',
    },
    {
      codigo: 204,
      descripcion: 'AGUA DULCE',
    },
    {
      codigo: 205,
      descripcion: 'EL HIGO',
    },
    {
      codigo: 206,
      descripcion: 'NANCHITAL DE LÁZARO CÁRDENAS DEL RÍO',
    },
    {
      codigo: 207,
      descripcion: 'TRES VALLES',
    },
  ],
  31: [
    {
      codigo: '001',
      descripcion: 'ABALÁ',
    },
    {
      codigo: '002',
      descripcion: 'ACANCEH',
    },
    {
      codigo: '003',
      descripcion: 'AKIL',
    },
    {
      codigo: '004',
      descripcion: 'BACA',
    },
    {
      codigo: '005',
      descripcion: 'BOKOBÁ',
    },
    {
      codigo: '006',
      descripcion: 'BUCTZOTZ',
    },
    {
      codigo: '007',
      descripcion: 'CACALCHÉN',
    },
    {
      codigo: '008',
      descripcion: 'CALOTMUL',
    },
    {
      codigo: '009',
      descripcion: 'CANSAHCAB',
    },
    {
      codigo: '010',
      descripcion: 'CANTAMAYEC',
    },
    {
      codigo: '011',
      descripcion: 'CELESTÚN',
    },
    {
      codigo: '012',
      descripcion: 'CENOTILLO',
    },
    {
      codigo: '013',
      descripcion: 'CONKAL',
    },
    {
      codigo: '014',
      descripcion: 'CUNCUNUL',
    },
    {
      codigo: '015',
      descripcion: 'CUZAMÁ',
    },
    {
      codigo: '016',
      descripcion: 'CHACSINKÍN',
    },
    {
      codigo: '017',
      descripcion: 'CHANKOM',
    },
    {
      codigo: '018',
      descripcion: 'CHAPAB',
    },
    {
      codigo: '019',
      descripcion: 'CHEMAX',
    },
    {
      codigo: '020',
      descripcion: 'CHICXULUB PUEBLO',
    },
    {
      codigo: '021',
      descripcion: 'CHICHIMILÁ',
    },
    {
      codigo: '022',
      descripcion: 'CHIKINDZONOT',
    },
    {
      codigo: '023',
      descripcion: 'CHOCHOLÁ',
    },
    {
      codigo: '024',
      descripcion: 'CHUMAYEL',
    },
    {
      codigo: '025',
      descripcion: 'DZÁN',
    },
    {
      codigo: '026',
      descripcion: 'DZEMUL',
    },
    {
      codigo: '027',
      descripcion: 'DZIDZANTÚN',
    },
    {
      codigo: '028',
      descripcion: 'DZILAM DE BRAVO',
    },
    {
      codigo: '029',
      descripcion: 'DZILAM GONZÁLEZ',
    },
    {
      codigo: '030',
      descripcion: 'DZITÁS',
    },
    {
      codigo: '031',
      descripcion: 'DZONCAUICH',
    },
    {
      codigo: '032',
      descripcion: 'ESPITA',
    },
    {
      codigo: '033',
      descripcion: 'HALACHÓ',
    },
    {
      codigo: '034',
      descripcion: 'HOCABÁ',
    },
    {
      codigo: '035',
      descripcion: 'HOCTÚN',
    },
    {
      codigo: '036',
      descripcion: 'HOMÚN',
    },
    {
      codigo: '037',
      descripcion: 'HUHÍ',
    },
    {
      codigo: '038',
      descripcion: 'HUNUCMÁ',
    },
    {
      codigo: '039',
      descripcion: 'IXIL',
    },
    {
      codigo: '040',
      descripcion: 'IZAMAL',
    },
    {
      codigo: '041',
      descripcion: 'KANASÍN',
    },
    {
      codigo: '042',
      descripcion: 'KANTUNIL',
    },
    {
      codigo: '043',
      descripcion: 'KAUA',
    },
    {
      codigo: '044',
      descripcion: 'KINCHIL',
    },
    {
      codigo: '045',
      descripcion: 'KOPOMÁ',
    },
    {
      codigo: '046',
      descripcion: 'MAMA',
    },
    {
      codigo: '047',
      descripcion: 'MANÍ',
    },
    {
      codigo: '048',
      descripcion: 'MAXCANÚ',
    },
    {
      codigo: '049',
      descripcion: 'MAYAPÁN',
    },
    {
      codigo: '050',
      descripcion: 'MÉRIDA',
    },
    {
      codigo: '051',
      descripcion: 'MOCOCHÁ',
    },
    {
      codigo: '052',
      descripcion: 'MOTUL',
    },
    {
      codigo: '053',
      descripcion: 'MUNA',
    },
    {
      codigo: '054',
      descripcion: 'MUXUPIP',
    },
    {
      codigo: '055',
      descripcion: 'OPICHÉN',
    },
    {
      codigo: '056',
      descripcion: 'OXKUTZCAB',
    },
    {
      codigo: '057',
      descripcion: 'PANABÁ',
    },
    {
      codigo: '058',
      descripcion: 'PETO',
    },
    {
      codigo: '059',
      descripcion: 'PROGRESO',
    },
    {
      codigo: '060',
      descripcion: 'QUINTANA ROO',
    },
    {
      codigo: '061',
      descripcion: 'RÍO LAGARTOS',
    },
    {
      codigo: '062',
      descripcion: 'SACALUM',
    },
    {
      codigo: '063',
      descripcion: 'SAMAHIL',
    },
    {
      codigo: '064',
      descripcion: 'SANAHCAT',
    },
    {
      codigo: '065',
      descripcion: 'SAN FELIPE',
    },
    {
      codigo: '066',
      descripcion: 'SANTA ELENA',
    },
    {
      codigo: '067',
      descripcion: 'SEYÉ',
    },
    {
      codigo: '068',
      descripcion: 'SINANCHÉ',
    },
    {
      codigo: '069',
      descripcion: 'SOTUTA',
    },
    {
      codigo: '070',
      descripcion: 'SUCILÁ',
    },
    {
      codigo: '071',
      descripcion: 'SUDZAL',
    },
    {
      codigo: '072',
      descripcion: 'SUMA',
    },
    {
      codigo: '073',
      descripcion: 'TAHDZIÚ',
    },
    {
      codigo: '074',
      descripcion: 'TAHMEK',
    },
    {
      codigo: '075',
      descripcion: 'TEABO',
    },
    {
      codigo: '076',
      descripcion: 'TECOH',
    },
    {
      codigo: '077',
      descripcion: 'TEKAL DE VENEGAS',
    },
    {
      codigo: '078',
      descripcion: 'TEKANTÓ',
    },
    {
      codigo: '079',
      descripcion: 'TEKAX',
    },
    {
      codigo: '080',
      descripcion: 'TEKIT',
    },
    {
      codigo: '081',
      descripcion: 'TEKOM',
    },
    {
      codigo: '082',
      descripcion: 'TELCHAC PUEBLO',
    },
    {
      codigo: '083',
      descripcion: 'TELCHAC PUERTO',
    },
    {
      codigo: '084',
      descripcion: 'TEMAX',
    },
    {
      codigo: '085',
      descripcion: 'TEMOZÓN',
    },
    {
      codigo: '086',
      descripcion: 'TEPAKÁN',
    },
    {
      codigo: '087',
      descripcion: 'TETIZ',
    },
    {
      codigo: '088',
      descripcion: 'TEYA',
    },
    {
      codigo: '089',
      descripcion: 'TICUL',
    },
    {
      codigo: '090',
      descripcion: 'TIMUCUY',
    },
    {
      codigo: '091',
      descripcion: 'TINUM',
    },
    {
      codigo: '092',
      descripcion: 'TIXCACALCUPUL',
    },
    {
      codigo: '093',
      descripcion: 'TIXKOKOB',
    },
    {
      codigo: '094',
      descripcion: 'TIXMEHUAC',
    },
    {
      codigo: '095',
      descripcion: 'TIXPÉHUAL',
    },
    {
      codigo: '096',
      descripcion: 'TIZIMÍN',
    },
    {
      codigo: '097',
      descripcion: 'TUNKÁS',
    },
    {
      codigo: '098',
      descripcion: 'TZUCACAB',
    },
    {
      codigo: '099',
      descripcion: 'UAYMA',
    },
    {
      codigo: 100,
      descripcion: 'UCÚ',
    },
    {
      codigo: 101,
      descripcion: 'UMÁN',
    },
    {
      codigo: 102,
      descripcion: 'VALLADOLID',
    },
    {
      codigo: 103,
      descripcion: 'XOCCHEL',
    },
    {
      codigo: 104,
      descripcion: 'YAXCABÁ',
    },
    {
      codigo: 105,
      descripcion: 'YAXKUKUL',
    },
    {
      codigo: 106,
      descripcion: 'YOBAÍN',
    },
  ],
  32: [
    {
      codigo: '001',
      descripcion: 'APOZOL',
    },
    {
      codigo: '002',
      descripcion: 'APULCO',
    },
    {
      codigo: '003',
      descripcion: 'ATOLINGA',
    },
    {
      codigo: '004',
      descripcion: 'BENITO JUÁREZ',
    },
    {
      codigo: '005',
      descripcion: 'CALERA',
    },
    {
      codigo: '006',
      descripcion: 'CAÑITAS DE FELIPE PESCADOR',
    },
    {
      codigo: '007',
      descripcion: 'CONCEPCIÓN DEL ORO',
    },
    {
      codigo: '008',
      descripcion: 'CUAUHTÉMOC',
    },
    {
      codigo: '009',
      descripcion: 'CHALCHIHUITES',
    },
    {
      codigo: '010',
      descripcion: 'FRESNILLO',
    },
    {
      codigo: '011',
      descripcion: 'TRINIDAD GARCÍA DE LA CADENA',
    },
    {
      codigo: '012',
      descripcion: 'GENARO CODINA',
    },
    {
      codigo: '013',
      descripcion: 'GENERAL ENRIQUE ESTRADA',
    },
    {
      codigo: '014',
      descripcion: 'GENERAL FRANCISCO R. MURGUÍA',
    },
    {
      codigo: '015',
      descripcion: 'EL PLATEADO DE JOAQUÍN AMARO',
    },
    {
      codigo: '016',
      descripcion: 'GENERAL PÁNFILO NATERA',
    },
    {
      codigo: '017',
      descripcion: 'GUADALUPE',
    },
    {
      codigo: '018',
      descripcion: 'HUANUSCO',
    },
    {
      codigo: '019',
      descripcion: 'JALPA',
    },
    {
      codigo: '020',
      descripcion: 'JEREZ',
    },
    {
      codigo: '021',
      descripcion: 'JIMÉNEZ DEL TEUL',
    },
    {
      codigo: '022',
      descripcion: 'JUAN ALDAMA',
    },
    {
      codigo: '023',
      descripcion: 'JUCHIPILA',
    },
    {
      codigo: '024',
      descripcion: 'LORETO',
    },
    {
      codigo: '025',
      descripcion: 'LUIS MOYA',
    },
    {
      codigo: '026',
      descripcion: 'MAZAPIL',
    },
    {
      codigo: '027',
      descripcion: 'MELCHOR OCAMPO',
    },
    {
      codigo: '028',
      descripcion: 'MEZQUITAL DEL ORO',
    },
    {
      codigo: '029',
      descripcion: 'MIGUEL AUZA',
    },
    {
      codigo: '030',
      descripcion: 'MOMAX',
    },
    {
      codigo: '031',
      descripcion: 'MONTE ESCOBEDO',
    },
    {
      codigo: '032',
      descripcion: 'MORELOS',
    },
    {
      codigo: '033',
      descripcion: 'MOYAHUA DE ESTRADA',
    },
    {
      codigo: '034',
      descripcion: 'NOCHISTLÁN DE MEJÍA',
    },
    {
      codigo: '035',
      descripcion: 'NORIA DE ÁNGELES',
    },
    {
      codigo: '036',
      descripcion: 'OJOCALIENTE',
    },
    {
      codigo: '037',
      descripcion: 'PÁNUCO',
    },
    {
      codigo: '038',
      descripcion: 'PINOS',
    },
    {
      codigo: '039',
      descripcion: 'RÍO GRANDE',
    },
    {
      codigo: '040',
      descripcion: 'SAIN ALTO',
    },
    {
      codigo: '041',
      descripcion: 'EL SALVADOR',
    },
    {
      codigo: '042',
      descripcion: 'SOMBRERETE',
    },
    {
      codigo: '043',
      descripcion: 'SUSTICACÁN',
    },
    {
      codigo: '044',
      descripcion: 'TABASCO',
    },
    {
      codigo: '045',
      descripcion: 'TEPECHITLÁN',
    },
    {
      codigo: '046',
      descripcion: 'TEPETONGO',
    },
    {
      codigo: '047',
      descripcion: 'TEÚL DE GONZÁLEZ ORTEGA',
    },
    {
      codigo: '048',
      descripcion: 'TLALTENANGO DE SÁNCHEZ ROMÁN',
    },
    {
      codigo: '049',
      descripcion: 'VALPARAÍSO',
    },
    {
      codigo: '050',
      descripcion: 'VETAGRANDE',
    },
    {
      codigo: '051',
      descripcion: 'VILLA DE COS',
    },
    {
      codigo: '052',
      descripcion: 'VILLA GARCÍA',
    },
    {
      codigo: '053',
      descripcion: 'VILLA GONZÁLEZ ORTEGA',
    },
    {
      codigo: '054',
      descripcion: 'VILLA HIDALGO',
    },
    {
      codigo: '055',
      descripcion: 'VILLANUEVA',
    },
    {
      codigo: '056',
      descripcion: 'ZACATECAS',
    },
  ],
};
