// in src/authProvider.js
import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS,
} from 'react-admin';

import URL from './url';

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    // USE OF ENDPOINT
    const { username, password } = params;
    const request = new Request(`${URL()}/auth`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        localStorage.setItem('id', response.id);
        localStorage.setItem('username', response.usuario);
        localStorage.setItem('empresa', response.empresa);
        localStorage.setItem('agrupamiento', response.agrupamiento);
        localStorage.setItem('sucursal', response.sucursal);
        localStorage.setItem('regional', response.regional);
        localStorage.setItem('permiso', response.permiso);
        localStorage.setItem('appV', response.appV);
        localStorage.setItem('token', response.token);
        localStorage.setItem('cc', response.cc);
      });
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('id');
    localStorage.removeItem('username');
    localStorage.removeItem('agrupamiento');
    localStorage.removeItem('empresa');
    localStorage.removeItem('sucursal');
    localStorage.removeItem('regional');
    localStorage.removeItem('permiso');
    localStorage.removeItem('token');
    localStorage.removeItem('appV');
    localStorage.removeItem('cc');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const permiso = localStorage.getItem('permiso');
    return permiso ? Promise.resolve(permiso) : Promise.reject();
  }
  return Promise.resolve();

  // return Promise.reject('Unknown method');
};
