// in ./updateConfirmado.js
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Confirm, crudUpdate, crudUpdateMany,
} from 'react-admin';

class CierreCapturaButton extends Component {
    state = {
      isOpen: false,
      titulo: '',
    }

    titleString = '';

    handleClick = () => {
      this.setState({
        isOpen: true,
        titulo: 'Marcar captura como cerrada',
      });
    };

    handleDialogClose = () => {
      this.setState({ isOpen: false });
      // console.log(this.state.seleccionados);
    };

    handleConfirm = () => {
      const {
        basePath, crudUpdate, resource, selectedIds,
      } = this.props;
      const idusuario = localStorage.getItem('id');
      //   crudUpdateMany(resource, selectedIds, { corregido: 1 }, basePath);
      //   crudUpdateMany()
      crudUpdate('auth', idusuario, { cc: 'tgx' }, { cc: '' }, basePath);
      this.setState({ isOpen: true });
    };

    render() {
      return (
        <Fragment>
          <Button label="Cierre Captura" onClick={this.handleClick} />
          <Confirm
            isOpen={this.state.isOpen}
            title={this.state.titulo}
            content="La captura se marcara como cerrada, ya no podra editar este listado"
            onConfirm={this.handleConfirm}
            onClose={this.handleDialogClose}
          />
        </Fragment>
      );
    }
}

export default connect(undefined, { crudUpdate })(CierreCapturaButton);
