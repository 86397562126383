import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  SelectInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

// import { EditButton } from 'ra-ui-materialui/lib/button';

// const CustomTableCell = withStyles(theme => ({
//   head: {
//     backgroundColor: 'red',
//     color: theme.palette.common.white,
//   },
//   body: {
//     backgroundColor: 'red',
//     color: theme.palette.common.white,
//     fontSize: 14,
//   },
// }))(TableCell);

// const empleadoListRowStyle = record => ({
//   backgroundColor: record.montoBaja === 2 ? 'blue' : 'red',
// });

// const MyDatagridRow = ({
//   record, resource, id, onToggleItem, children, selected, basePath,
// }) => (
//   <TableRow key={id}>
//     {/* first column: selection checkbox */}
//     {/* <TableCell padding="none">
//       {record.status !== 'pending' && (
//         <Checkbox
//           checked={selected}
//           onClick={() => onToggleItem(id)}
//         />
//       )}
//     </TableCell> */}
//     {/* data columns based on children */}
//     {React.Children.map(children, field => (
//       <TableCell key={`${id}-${field.props.source}`} classes={{ backgroundColor: 'pink' }}>
//         {React.cloneElement(field, {
//           record,
//           basePath,
//           resource,
//         })}
//       </TableCell>
//     ))}
//   </TableRow>
// );

// const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
// const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  drawerContent: {
    width: 300,
  },
});

// const PostBulkActionButtons = () => (
//   <Fragment>
//     {/* <ResetViewsButton label="Confirmar" {...props} /> */}
//   </Fragment>
// );

// const PostFilter = props => (
//   <Filter {...props}>
//     {/* <TextInput label="Search" source="q" alwaysOn /> */}
//     <TextInput label="Paterno" source="paterno" defaultValue="" />
//   </Filter>
// );

// const MetasEmpleadosListActions = ({ basePath }) => (
//   <CardActions>
//     <CreateButton basePath={basePath} />
//   </CardActions>
// );

// const NameRender = record => `${record.nombres} ${record.paterno}`;

class MetasEmpleadosList extends React.Component {
  handleClose = () => {
    this.props.push('/metae');
  };

  render() {
    const { push, classes, ...props } = this.props;

    const PostFilter = props => (
      <Filter {...props}>
        <SelectInput
          source="mes"
          choices={[
            { id: 'Enero', name: 'Enero' },
            { id: 'Febrero', name: 'Febrero' },
            { id: 'Marzo', name: 'Marzo' },
            { id: 'Abril', name: 'Abril' },
            { id: 'Mayo', name: 'Mayo' },
            { id: 'Junio', name: 'Junio' },
            { id: 'Julio', name: 'Julio' },
            { id: 'Agosto', name: 'Agosto' },
            { id: 'Septiembre', name: 'Septiembre' },
            { id: 'Octubre', name: 'Octubre' },
            { id: 'Noviembre', name: 'Noviembre' },
            { id: 'Diciembre', name: 'Diciembre' },
          ]}
          alwaysOn
        />
        <SelectInput
          source="ano"
          choices={[
            { id: '2020', name: '2020' },
            { id: '2021', name: '2021' },
            { id: '2022', name: '2022' },
          ]}
          alwaysOn
        />

      </Filter>
    );
    return (
      <Fragment>
        <List
          {...props}
          bulkActionButtons={false}
          title="Detalle de nomina de sucursal"
          filter={{
            agrupamiento: localStorage.getItem('empresa'),
            sucursal: localStorage.getItem('sucursal'),
          }}
          filters={<PostFilter />}
        >
          <Responsive
            medium={(
              <Datagrid>
                <TextField source="noEmpleado" label="No de Empleado" />
                <FunctionField label="Empleado" render={record => ((record) ? `${record.paterno} ${record.materno} ${record.nombres} ` : null)} />
                <TextField source="dni" label="CURP" />
                <TextField source="periodo" label="Periodo" />
                <TextField source="cargasocial" label="Carga Social" />
                <TextField source="ordinario" label="Ordinario" />
                <TextField source="otras" label="Otros" />
                <TextField source="productividad" label="Productividad" />
                <TextField source="valortiempoextradoble" label="Tiempo extra doble" />
                <TextField source="valortiempoextratriple" label="Tiempo extra triple" />
                <TextField source="valordescansotrabajado" label="Descanso Trabajado" />
                <TextField source="totaltiempoextra" label="Total Tiempo Extra" />
                <TextField source="total" label="Total" />
                <TextField source="mes" label="Mes" />
                <TextField source="periodoNomina" label="Periodo Nomina" />
                {/* <TextField source="montoBaja" label="Reduccion de Horas extra" /> */}
                {/* <TextField source="MetasEmpleados[0].enero" label="Enero" />
                <TextField source="MetasEmpleados[0].febrero" label="Febrero" />
                <TextField source="MetasEmpleados[0].marzo" label="Marzo" />
                <TextField source="MetasEmpleados[0].abril" label="Abril" />
                <TextField source="MetasEmpleados[0].mayo" label="Mayo" />
                <TextField source="MetasEmpleados[0].junio" label="Junio" />
                <TextField source="MetasEmpleados[0].julio" label="Julio" /> */}
                {/* <EditButton label="Meta" /> */}
              </Datagrid>
            )}
          />
        </List>
      </Fragment>

    );
  }
}


export default compose(
  connect(
    undefined,
    { push },
  ),
  withStyles(styles),
)(MetasEmpleadosList);


// export default withStyles(styles)(MetasEmpleadosList);
// export default EmpleadoList;
