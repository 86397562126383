import React from 'react';
import {
  Layout, AppBar, UserMenu, MenuItemLink,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import MyMenu from './Menu/Menu';

const MyUserMenu = props => (
  <UserMenu {...props}>
    <MenuItemLink
      to="/passwordchange"
      primaryText="Cambiar Password"
      leftIcon={<SettingsIcon />}
    />
  </UserMenu>
);

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = props => (
  <Layout
    {...props}
    appBar={MyAppBar}
    menu={MyMenu}
  />
);

export default MyLayout;
