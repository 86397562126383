import React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
} from 'react-admin';

export const SubeBajasCreate = props => (
  <Create {...props} title="Subir Archivo de Bajas">
    <SimpleForm redirect="list">
      <TextInput source="empresa" label="Empresa" />
      <TextInput source="mes" label="Mes" />
      <FileInput source="files" label="Related files" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" multiple={false}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export { SubeBajasCreate as default };
