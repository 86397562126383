import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  NumberInput,
  FileInput,
  FileField,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
      Seleccionar el no de empleado a dar de baja y el la fecha en que fue aplicada.
      </Typography>
    </Paper>
  </div>
);

export const FHBajaCreate = props => (
  <div>
    <Aside />
    <Create {...props} title="Captura de empleados que se quieren dar de baja de SAP">
      <SimpleForm redirect="list">
        <ReferenceInput
          label="Empleado:"
          source="empleadoId"
          reference="epe"
          filter={{ agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
        >
          <AutocompleteInput
            optionText={choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`}
            optionValue="id"
          />
        </ReferenceInput>
        <DateInput source="fechaBaja" />
        <FileInput source="moper" label="Scan del moper firmado" accept="application/pdf,image/jpeg,image/png,image/jpg" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  </div>
);

export { FHBajaCreate as default };
