// in src/Dashboard.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import MUIDataTable from 'mui-datatables';
import URL from '../url';


const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const columns = ['No Empleado', 'Nombres', 'Paterno', 'Materno', 'Sucursal', 'Puesto', 'CURP'];

const options = {
  selectableRows: 'none', // <===== will turn off checkboxes in rows,
  rowsPerPage: 100,
};

class PersonalActivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agrupamiento: localStorage.getItem('empresa'),
      sucursal: localStorage.getItem('sucursal'),
      permiso: localStorage.getItem('permiso'),
      appV: localStorage.getItem('appV'),
      dataPersonal: [],
    };
  }

  componentDidMount() {
    const {
      appV, agrupamiento, sucursal, permiso,
    } = this.state;
    if (appV === '1') {
      showNotification('Favor de cambiar su password.');
      push('/passwordchange');
    }

    if (permiso === '1') {
      fetch(`${URL()}/reportes/ea/${agrupamiento}/ALL`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    } else {
      fetch(`${URL()}/reportes/ea/${agrupamiento}/${sucursal}`)
        .then(response => response.json())
        .then(dataPersonal => this.setState({ dataPersonal }));
    }
  }

  render() {
    const { dataPersonal } = this.state;
    return (
      <Fragment>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.singleCol} />

            <MUIDataTable
              title="Personal Activo"
              data={dataPersonal}
              columns={columns}
              options={options}
            />

          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  showNotification,
  push,
})(PersonalActivo);
