import React, { Fragment } from 'react';
import {
  required,
  TextInput,
  SelectInput,
  DateInput,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import {
  estado, delegacion, codigoNacionalOcupacion, tipoVivienda, nacionalidad, estadoCivil, regimenMatrimonial, escolaridad, tituloProfesional, tipoEmpleado,
} from './tablasBanorte';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
});

const Vrequerido = [required()];

const BanorteExtra = ({ formData, ...rest }) => (
  <Fragment>
    <div>
      <SelectInput
        source="tipoOperacion"
        choices={[
          { id: 'Registro', name: 'Registro' },
          { id: 'Operacion', name: 'Operacion' },
        ]}
      />
    </div>
    <div>
      <SelectInput
        source="tipoEmpleado"
        choices={[
          { id: 'Sindicalizado', name: 'Sindicalizado' },
          { id: 'De Confianza', name: 'De Confianza' },
        ]}
      />
    </div>
    <div>
      <DateInput
        source="fechaNacimiento"
        label="Fecha Nacimiento"
        validate={Vrequerido}
      />
    </div>
    <div>
      <TextInput
        label="Entidad Federativa"
        source="entidadFederativa"
        validate={Vrequerido}
      />
    </div>
    <div>
      <TextInput
        label="RFC"
        source="rfc"
        validate={Vrequerido}
      />
    </div>
    <div>
      <SelectInput
        source="sexo"
        choices={[
          { id: 'Femenino', name: 'Femenino' },
          { id: 'Masculino', name: 'Masculino' },
        ]}
      />
    </div>
    {/* <div>
      <SelectInput
        source="nacionalidad"
        choices={nacionalidad}
        optionText="descripcion"
        optionValue="codigo"
      />
    </div>
    <div>
      <SelectInput
        source="estadoCivil"
        choices={estadoCivil}
        optionText="descripcion"
        optionValue="codigo"
      />
      <SelectInput
        source="regimenMatrimonial"
        choices={regimenMatrimonial}
        optionText="descripcion"
        optionValue="codigo"
      />
    </div>
    <div>
      <SelectInput
        source="escolaridad"
        choices={escolaridad}
        optionText="descripcion"
        optionValue="codigo"
      />
      <SelectInput
        source="tituloProfesional"
        choices={tituloProfesional}
        optionText="descripcion"
        optionValue="codigo"
      />
    </div>
    <div>
      <SelectInput
        source="tipoEmpleado"
        choices={tipoEmpleado}
        optionText="descripcion"
        optionValue="codigo"
      />
    </div>
    <div>
      <NumberInput
        label="Sueldo"
        source="Sueldo"
      />
    </div>
    <div>
      <SelectInput
        source="ocupacion"
        choices={codigoNacionalOcupacion}
        optionText="descripcion"
        optionValue="codigo"
      />
    </div>
    <div>
      <TextInput
        label="Nombre Corto"
        source="nombreCorto"
        validate={Vrequerido}
      />
    </div>
    <div>
      <SelectInput
        source="tipoVivienda"
        choices={tipoVivienda}
        optionText="descripcion"
        optionValue="codigo"
      />
    </div>
    <div>
      <TextInput
        label="Entrada"
        source="entrada"
        validate={Vrequerido}
      />
      <TextInput
        label="Piso"
        source="piso"
        validate={Vrequerido}
      />
      <TextInput
        label="Departamento"
        source="departamento"
        validate={Vrequerido}
      />
    </div> */}
    <TextInput
      label="Nombre de la calle"
      source="nombreCalle"
      validate={Vrequerido}
    />
    <TextInput
      label="Numero"
      source="numeroCalle"
      validate={Vrequerido}
    />
    <TextInput
      label="Colonia"
      source="colonia"
      validate={Vrequerido}
    />
    <div>
      <SelectInput
        source="estado"
        choices={estado}
        optionText="estado"
        optionValue="codigo"
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => (formData.estado !== 'undefined')
                  && (
                    <SelectInput
                      source="delegacion"
                      choices={delegacion[formData.estado]}
                      {...rest}
                    />
                  )
             }
      </FormDataConsumer>
    </div>
    <NumberInput
      label="Codigo Postal"
      source="codigoPostal"
      validate={Vrequerido}
    />
    <div>
      <NumberInput
        label="Lada"
        source="lada"
        validate={Vrequerido}
      />
      <NumberInput
        label="Telefono"
        source="telefono"
        validate={Vrequerido}
      />
      <div>
        <NumberInput
          label="Tarjeta"
          source="tarjeta"
          validate={Vrequerido}
        />
      </div>
    </div>
  </Fragment>
);

export default withStyles(styles)(BanorteExtra);
