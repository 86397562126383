import { TarjetasGasolinaCreate } from './tarjetasgasolinacreate';
import { TarjetasGasolinaList } from './tarjetasgasolinalist';
import { TarjetasGasolinaEdit } from './tarjetasgasolinaedit';

export default {
  create: TarjetasGasolinaCreate,
  list: TarjetasGasolinaList,
  edit: TarjetasGasolinaEdit
};
// export * from './altasmesshow';
// export * from './altasmescreate';
// export * from './altasmesedit';
