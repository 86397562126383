/* eslint-disable */
import React from 'react';
import { Admin, Resource } from 'react-admin';
import URL from './url';
import Dashboard from './dashboard';
import authProvider from './authProvider';
import spanishMessages from 'aor-language-spanish';
import simpleRestProvider from 'ra-data-simple-rest';
import addUploadFeature from './addUploadFeature';
import EmpleadoIcon from '@material-ui/icons/AddBox';
import { EmpleadoShow, EmpleadoList, EmpleadoCreate, EmpleadoEdit } from './Views/Empleados';
import { EmpleadoCorregidoShow, EmpleadoCorregidoList, EmpleadoCorregidoCreate, EmpleadoCorregidoEdit } from './Views/EmpleadosCorregidos';
import { EmpleadoCorregidoGrupoShow, EmpleadoCorregidoGrupoList, EmpleadoCorregidoGrupoCreate, EmpleadoCorregidoGrupoEdit } from './Views/EmpleadosCorregidosGrupo';
import { GasolinaShow, GasolinaList, GasolinaCreate, GasolinaEdit } from './Views/Gasolinas';
import { CelularCorregidoList, CelularCorregidoCreate } from './Views/CelularesCorregido';
import { GasolinaCorregidaList, GasolinaCorregidaCreate } from './Views/GasolinasCorregidos';
import { CelularShow, CelularList, CelularCreate, CelularEdit } from './Views/Celulares';
import { AltasMesList } from './Views/AltasMes';
import { BajasMesList } from './Views/BajasMes';
import { AltasNoReconocidasList } from './Views/AltasNoReconocidas';
import { BajasNoReconocidasList } from './Views/BajasNoReconocidas';
import metascelulares from './Views/MetasCelulares';
import metasgasolinas from './Views/MetasGasolinas';
import metasempleados from './Views/MetasEmpleados';
import gastocelularmes from './Views/GastoCelularMes';
import gastogasolinames from './Views/GastoGasolinaMes';
import reportenomina from './Views/ReporteNomina';
import reportenominate from './Views/ReporteNominaTiempoExtra';
import reportenominap from './Views/ReporteNominaProductividad';
import reportecelulares from './Views/ReporteCelulares';
import solicitudbajas from './Views/SolicitudDeBajas';
import empleadosnoreconocidos from './Views/REmpleadosNoReconocidos';
import empleadoscapturados from './Views/REmpleadosCapturados';
import ideasgenerales from './Views/ideasGenerales';
import ideasinversion from './Views/IdeasInversion';
import ideasoperativos from './Views/IdeasOperativos';
import ideaspersonal from './Views/IdeasPersonal';
import ideasviaje from './Views/IdeasViaje';
import ideasculturaahorro from './Views/IdeasCulturaAhorro';
import ideasnuevosproyectos from './Views/IdeasNuevosProyectos';
import ideasportal from './Views/IdeasPortal';
import empleadosdisputados from './Views/EmpleadosDisputados';
import empleadoscuentas from './Views/EmpleadosCuentasBancos';
import empleadoscuentasgsi from './Views/EmpleadosCuentasBancosGuardias';
import subeactivos from './Views/SubeActivos';
import subealtas from './Views/SubeAltas';
import subebajas from './Views/SubeBajas';
import subenomina from './Views/SubeNomina';
import subeedenred from './Views/SubeEdenred';
import subetelcel from './Views/SubeTelcel';
import subesivale from './Views/SubeSiVale';
import subebancos from './Views/SubeBancos';
import subebancomer from './Views/SubeBancomer';
import subebanamex from './Views/SubeBanamex';
import subesantander from './Views/SubeSantander';
import subebanorte from './Views/SubeBanorte';
import subehsbc from './Views/SubeHSBC';
import fhbaja from './Views/FHBaja';
import validafh from './Views/ValidaFHCapturaBancos';
import validat from './Views/ValidaTCapturaBancos';
// import validatb from './Views/ValidaBajaBancos';
import validatbanorte from './Views/ValidaTBanorte';
import altasmesmov from './Views/AltasMesMov';
import altasmess from './Views/AltasMesSueldo';
import altasmessa from './Views/AltasMesSueldoAceptadas';
import altasmessr from './Views/AltasMesSueldoRechazadas';
import altasmesa from './Views/AltasMesAceptadas';
import altasmesr from './Views/AltasMesRechazadas';
import bajasmesa from './Views/BajasMesAceptadas';
import bajasmesr from './Views/BajasMesRechazadas';
import vehiculos from './Views/Vehiculos';
import tarjetasg from './Views/TarjetasGasolina';
import altasscb from './Views/REmpleadosSinCuentaBancaria';
import guardiascb from './Views/GuardiasCuentasBancos';
import MyLayout from './myLayout';
import rutas from './rutas';
import empleadossap from './Views/EmpleadosSap';
import empleadossapsc from './Views/EmpleadosSapSinCuenta';
import validabajat from './Views/ValidaTBajas';
import comparatesoreria from './Views/ComparaTesoreria';

const messages = {
  es: spanishMessages,
};

const i18nProvider = locale => messages[locale];

const dataProvider = simpleRestProvider(URL());
const uploadClient = addUploadFeature(dataProvider);
const App = () => (
  <Admin appLayout={MyLayout}
         customRoutes={rutas}
         title="Captura" 
         dashboard={Dashboard} 
         authProvider={authProvider} 
         dataProvider={uploadClient} 
         locale="es" 
         i18nProvider={i18nProvider}>
    <Resource name="esap" {...empleadossap} />
    <Resource name="esapsc" {...empleadossapsc} />
    <Resource name="epe" options={{ label: 'Validacion de Personal' }} show={EmpleadoShow} list={EmpleadoList} create={EmpleadoCreate} edit={EmpleadoEdit} icon={EmpleadoIcon} />
    <Resource name="epec" options={{ label: 'Personal No reconocido' }} show={EmpleadoCorregidoShow} list={EmpleadoCorregidoList} create={EmpleadoCorregidoCreate} edit={EmpleadoCorregidoEdit} icon={EmpleadoIcon} />
    <Resource name="epece" options={{ label: 'Personal No reconocido GRUPO' }} show={EmpleadoCorregidoGrupoShow} list={EmpleadoCorregidoGrupoList} create={EmpleadoCorregidoGrupoCreate} edit={EmpleadoCorregidoGrupoEdit} icon={EmpleadoIcon} />
    <Resource name="gasolinas" options={{ label: 'Parque Vehicular' }} show={GasolinaShow} list={GasolinaList} create={GasolinaCreate} edit={GasolinaEdit} icon={EmpleadoIcon} />
    <Resource name="gasolinasc" options={{ label: 'Parque Vehicular de Baja' }} list={GasolinaCorregidaList} create={GasolinaCorregidaCreate} icon={EmpleadoIcon} />
    <Resource name="celulares" options={{ label: 'Telefonia Celular' }} show={CelularShow} list={CelularList} create={CelularCreate} edit={CelularEdit} icon={EmpleadoIcon} />
    <Resource name="celularesc" options={{ label: 'Celulares de Baja' }} list={CelularCorregidoList} create={CelularCorregidoCreate} icon={EmpleadoIcon} />
    <Resource name="ames" options={{ label: 'Altas del Mes' }} list={AltasMesList} />
    <Resource name="bmes" options={{ label: 'Bajas del Mes' }} list={BajasMesList} />
    <Resource name="anr" options={{ label: 'Altas no Reconocidas' }} list={AltasNoReconocidasList} />
    <Resource name="bnr" options={{ label: 'Bajas no Reconocidas' }} list={BajasNoReconocidasList} />
    <Resource name="sbajas" {...solicitudbajas} />
    <Resource name="metac" {...metascelulares} />
    <Resource name="metag" {...metasgasolinas} />
    <Resource name="metae" {...metasempleados} />
    <Resource name="rgc" {...gastocelularmes} />
    <Resource name="rgg" {...gastogasolinames} />
    <Resource name="repnomina" {...reportenomina} />
    <Resource name="repnominate" {...reportenominate} />
    <Resource name="repnominap" {...reportenominap} />
    <Resource name="rcelmes" {...reportecelulares} />
    <Resource name="enr" {...empleadosnoreconocidos} />
    <Resource name="ecns" {...empleadoscapturados} />
    <Resource name="igenerales" {...ideasgenerales} />
    <Resource name="iinversion" {...ideasinversion} />
    <Resource name="ioperativos" {...ideasoperativos} />
    <Resource name="ipersonal" {...ideaspersonal} />
    <Resource name="iviaje" {...ideasviaje} />
    <Resource name="icahorro" {...ideasculturaahorro} />
    <Resource name="inuevosp" {...ideasnuevosproyectos} />
    <Resource name="iportal" {...ideasportal} />
    <Resource name="cepe" {...empleadosdisputados} />
    <Resource name="sac" {...subeactivos} />
    <Resource name="sb" {...subebajas} />
    <Resource name="sa" {...subealtas} />
    <Resource name="sn" {...subenomina} />
    <Resource name="se" {...subeedenred} />
    <Resource name="st" {...subetelcel} />
    <Resource name="comparatesoreria" {...comparatesoreria} />
    <Resource name="ssv" {...subesivale} />
    <Resource name="tv"/> 
    <Resource name="tc"/> 
    <Resource name="auth"/> 
    <Resource name="tarjetasg" {...tarjetasg} /> 
    <Resource name="sbancos" {...subebancos} />
    <Resource name="shsbc" {...subehsbc} />
    <Resource name="sbanamex" {...subebanamex} />
    <Resource name="sbbva" {...subebancomer} />
    <Resource name="ssantander" {...subesantander} />
    <Resource name="sbanorte" {...subebanorte} />
    <Resource name="epecu" {...empleadoscuentas} />
    <Resource name="epecugsi" {...empleadoscuentasgsi} />
    <Resource name="fhbaja" {...fhbaja} />
    <Resource name="validafh" {...validafh} />
    <Resource name="validat" {...validat} />
    {/* <Resource name="validatb" {...validatb} /> */}
    <Resource name="validatbanorte" {...validatbanorte} />
    <Resource name="altasmesmov" {...altasmesmov} />
    <Resource name="altasmess" {...altasmess} />
    <Resource name="altasmessa" {...altasmessa} />
    <Resource name="altasmessr" {...altasmessr} />
    <Resource name="altasmesa" {...altasmesa} />
    <Resource name="altasmesr" {...altasmesr} />
    <Resource name="bajasmesa" {...bajasmesa} />
    <Resource name="bajasmesr" {...bajasmesr} />
    <Resource name="vehiculos" {...vehiculos} />
    <Resource name="altasscb" {...altasscb} />
    <Resource name="movco" />
    <Resource name="guardiascb" {...guardiascb} />
    <Resource name="validabt" {...validabajat} />
    {/* <Resource name="bmesr" {...bmesr} /> */}

    
  </Admin>
);
export default App;
