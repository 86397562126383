export const codigoNacionalOcupacion = [
	{
		codigo: '04',
		descripcion: 'Trabajadores De La E',
	},
];

export const tituloProfesional = [
	{
		codigo: 605,
		descripcion: 'ANTROPOLOGO FISICO',
	},
	{
		codigo: 606,
		descripcion: 'ANTROPOLOGO SOCIAL',
	},
	{
		codigo: 701,
		descripcion: 'ARQUITECTO',
	},
	{
		codigo: 604,
		descripcion: 'BIOLOGO',
	},
	{
		codigo: 862,
		descripcion: 'CARDENAL',
	},
	{
		codigo: 406,
		descripcion: 'CIRUJANO DENTISTA',
	},
	{
		codigo: 502,
		descripcion: 'CONTADOR PUBLICO',
	},
	{
		codigo: 501,
		descripcion: 'CONTADOR PUBLICO AUDITOR',
	},
	{
		codigo: 603,
		descripcion: 'ETNOLOGO',
	},
	{
		codigo: 602,
		descripcion: 'FISICO',
	},
	{
		codigo: 601,
		descripcion: 'FISICO MATEMATICO',
	},
	{
		codigo: 870,
		descripcion: 'GENERAL',
	},
	{
		codigo: 101,
		descripcion: 'ING. ADMINISTRADOR DE SISTEMAS',
	},
	{
		codigo: 103,
		descripcion: 'ING.AGRONOMO',
	},
	{
		codigo: 102,
		descripcion: 'ING. AGROINDUSTRIAL',
	},
	{
		codigo: 104,
		descripcion: 'ING.AGRONOMO ADMINISTRADOR',
	},
	{
		codigo: 105,
		descripcion: 'ING. AGRONOMO FITOTECNISTA',
	},
	{
		codigo: 106,
		descripcion: 'ING. AGRONOMO FRUTICULTOR',
	},
	{
		codigo: 107,
		descripcion: 'ING. AGRONOMO HORTICULTOR',
	},
	{
		codigo: 108,
		descripcion: 'ING. AGRONOMO PARASITOLOGO',
	},
	{
		codigo: 109,
		descripcion: 'ING. AGRONOMO ZOOTECNISTA',
	},
	{
		codigo: 110,
		descripcion: 'ING. BIOMEDICA',
	},
	{
		codigo: 111,
		descripcion: 'ING. BIOQUIMICO',
	},
	{
		codigo: 112,
		descripcion: 'ING. BIOQUIMICO EN ALIMENTOS MARINOS',
	},
	{
		codigo: 113,
		descripcion: 'ING. CIVIL',
	},
	{
		codigo: 114,
		descripcion: 'ING. COMPUTACION ADMTVA Y DE PRODUCCION',
	},
	{
		codigo: 115,
		descripcion: 'ING. ELECTRICISTA',
	},
	{
		codigo: 116,
		descripcion: 'ING. ELECTRICO ADMINISTRATIVO',
	},
	{
		codigo: 117,
		descripcion: 'ING. ELECTRONICA Y COMUNICACIONES',
	},
	{
		codigo: 118,
		descripcion: 'ING. EN ARQUITECTURA',
	},
	{
		codigo: 119,
		descripcion: 'ING. EN AUTOMATIZACION Y CONTROL',
	},
	{
		codigo: 120,
		descripcion: 'ING. EN CIBERNETICA Y C DE LA COMUNICACIÓN',
	},
	{
		codigo: 121,
		descripcion: 'ING. EN CIENCIAS COMPUTACIONALES',
	},
	{
		codigo: 122,
		descripcion: 'ING. EN COMPUTACION',
	},
	{
		codigo: 123,
		descripcion: 'ING. EN COMUNICACIONES',
	},
	{
		codigo: 124,
		descripcion: 'ING. EN CONTROL Y COMPUTACION',
	},
	{
		codigo: 125,
		descripcion: 'ING. EN ELECTRONICA',
	},
	{
		codigo: 126,
		descripcion: 'ING. EN INDUSTRIA ALIMENTARIA',
	},
	{
		codigo: 127,
		descripcion: 'ING. EN PLANEACION',
	},
	{
		codigo: 128,
		descripcion: 'ING. EN PLANEACION Y DISEÑO',
	},
	{
		codigo: 129,
		descripcion: 'ING. EN PRODUCCION',
	},
	{
		codigo: 130,
		descripcion: 'ING. EN SISTEMAS',
	},
	{
		codigo: 131,
		descripcion: 'ING. EN SISTEMAS COMPUTACIONALES',
	},
	{
		codigo: 132,
		descripcion: 'ING. EN SISTEMAS ELECTRONICOS',
	},
	{
		codigo: 133,
		descripcion: 'ING. INDUSTRIAL',
	},
	{
		codigo: 134,
		descripcion: 'ING. INDUSTRIAL ADMINISTRADOR',
	},
	{
		codigo: 135,
		descripcion: 'ING. EN PRODUCION',
	},
	{
		codigo: 136,
		descripcion: 'ING. INDUSTRIAL Y DE SISTEMAS',
	},
	{
		codigo: 137,
		descripcion: 'ING. INDUSTRIAL Y ELECTRONICA',
	},
	{
		codigo: 138,
		descripcion: 'ING. MECANICO',
	},
	{
		codigo: 139,
		descripcion: 'ING. MECANICO ADMINISTRADOR',
	},
	{
		codigo: 140,
		descripcion: 'ING. MECANICO ELECTRICISTA',
	},
	{
		codigo: 141,
		descripcion: 'ING. MECANICO ELECTRICO',
	},
	{
		codigo: 142,
		descripcion: 'ING. METALURGICO',
	},
	{
		codigo: 143,
		descripcion: 'ING. QUIMICO',
	},
	{
		codigo: 144,
		descripcion: 'ING. QUIMICO ADMINISTRADOR',
	},
	{
		codigo: 145,
		descripcion: 'ING. QUIMICO BACTERIOLOGO PARASITOLOGO',
	},
	{
		codigo: 146,
		descripcion: 'ING. QUIMICO BIOLOGO',
	},
	{
		codigo: 147,
		descripcion: 'ING. QUIMICO DE PROCESO',
	},
	{
		codigo: 148,
		descripcion: 'ING. QUIMICO FARMACO-BIOLOGO',
	},
	{
		codigo: 149,
		descripcion: 'ING. QUIMICO INDUSTRIAL',
	},
	{
		codigo: 150,
		descripcion: 'ING. QUIMICO Y DE SISTEMAS',
	},
	{
		codigo: 151,
		descripcion: 'ING. ZOOTECNISTA',
	},
	{
		codigo: 152,
		descripcion: 'INGENIERIA EN SISTEMAS D EINFORMACION',
	},
	{
		codigo: 153,
		descripcion: 'INGENIERO ELECTRONICO',
	},
	{
		codigo: 154,
		descripcion: 'INGENIERO EN ACUSTICA',
	},
	{
		codigo: 155,
		descripcion: 'INGENIERO EN INFORMATICA',
	},
	{
		codigo: 606,
		descripcion: 'INGLES TECNICO INDUSTRIAL',
	},
	{
		codigo: 607,
		descripcion: 'INSTRUCTOR DE LENGUA INGLESA',
	},
	{
		codigo: 202,
		descripcion: 'LIC. ADMINISTRACION BANCARIA',
	},
	{
		codigo: 203,
		descripcion: 'LIC. ADMINISTRACION EMPRESAS TURISTICAS',
	},
	{
		codigo: 204,
		descripcion: 'LIC. ADMINISTRACION INDUSTRIAL',
	},
	{
		codigo: 205,
		descripcion: 'LIC. CIENCIAS ECONOMICAS Y EMPRESARIALES',
	},
	{
		codigo: 206,
		descripcion: 'LIC. COMERCIO INTERNACIONAL',
	},
	{
		codigo: 207,
		descripcion: 'LIC. COMUNICACIÓN SOCIAL',
	},
	{
		codigo: 208,
		descripcion: 'LIC. EN ACTUARIA',
	},
	{
		codigo: 209,
		descripcion: 'LIC. EN ADMINISTRACION',
	},
	{
		codigo: 210,
		descripcion: 'LIC. EN ADMINISTRACION AGROPECUARIA',
	},
	{
		codigo: 211,
		descripcion: 'LIC. EN ADMINISTRACION DE EMPRESAS',
	},
	{
		codigo: 212,
		descripcion: 'LIC. EN ADMINISTRACION DE NEGOCIOS',
	},
	{
		codigo: 213,
		descripcion: 'LIC. EN ADMINISTRACION DE PERSONAL',
	},
	{
		codigo: 214,
		descripcion: 'LIC. EN ADMINISTARCION DE RECURSOS HUMANOS',
	},
	{
		codigo: 215,
		descripcion: 'LIC. EN ADMINISTRACION DEL TIE',
	},
	{
		codigo: 216,
		descripcion: 'LIC. EN ADMINISTRACION EDUCATIVA',
	},
	{
		codigo: 217,
		descripcion: 'LIC. EN ADMINISTRACION FINANCIERA',
	},
	{
		codigo: 218,
		descripcion: 'LIC. EN ADMINISTRACION PROFESIONAL',
	},
	{
		codigo: 219,
		descripcion: 'LIC. EN ADMINISTRACION PUBLICA',
	},
	{
		codigo: 220,
		descripcion: 'LIC. EN ADMINISTRACION Y FINANZAS',
	},
	{
		codigo: 221,
		descripcion: 'LIC. EN ADMON PUBLICA Y CIENCIAS POLITICAS',
	},
	{
		codigo: 222,
		descripcion: 'LIC. EN ADMON Y GESTION DE ORGANIZACIONES',
	},
	{
		codigo: 223,
		descripcion: 'LIC. EN AGRONEGOCIOS',
	},
	{
		codigo: 224,
		descripcion: 'LIC. EN ARTES VISUALES',
	},
	{
		codigo: 225,
		descripcion: 'LIC. EN ASUNTOS INTERNACIONALES',
	},
	{
		codigo: 226,
		descripcion: 'LIC. EN BANCA Y FINANZAS',
	},
	{
		codigo: 228,
		descripcion: 'LIC. EN CIENCIAS ADMINISTRATIVAS',
	},
	{
		codigo: 229,
		descripcion: 'LIC. EN CIENCIAS BIOLOGICAS',
	},
	{
		codigo: 230,
		descripcion: 'LIC. EN CIENCIAS COMPUTACIONALES',
	},
	{
		codigo: 231,
		descripcion: 'LIC. EN CIENCIAS DE EDUCACION',
	},
	{
		codigo: 232,
		descripcion: 'LIC. EN CIENCIAS DE LA COMUNICACIÓN',
	},
	{
		codigo: 227,
		descripcion: 'LIC. EN CIENCIAS DE LA COMUNICACIÓN Y PERIODISMO',
	},
	{
		codigo: 233,
		descripcion: 'LIC. EN CIENCIAS DE LA COMUNIDAD',
	},
	{
		codigo: 234,
		descripcion: 'LIC. EN CIENCIAS DE LA INFORMATICA',
	},
	{
		codigo: 235,
		descripcion: 'LIC. EN CIENCIAS JURIDICAS',
	},
	{
		codigo: 236,
		descripcion: 'LIC. EN CIENCIAS MARITIMAS',
	},
	{
		codigo: 237,
		descripcion: 'LIC. EN CIENCIAS NATURALES',
	},
	{
		codigo: 238,
		descripcion: 'LIC. EN CIENCIAS POLITICAS',
	},
	{
		codigo: 239,
		descripcion: 'LIC. EN CIENCIAS QUIMICAS',
	},
	{
		codigo: 240,
		descripcion: 'LIC. EN CIENCIAS SOCIALES',
	},
	{
		codigo: 241,
		descripcion: 'LIC. EN CIENCIAS Y HUMANIDADES',
	},
	{
		codigo: 242,
		descripcion: 'LIC. EN COMERCIO EXTERIOR',
	},
	{
		codigo: 243,
		descripcion: 'LIC. EN COMPUTACION ADMINISTRATIVA',
	},
	{
		codigo: 244,
		descripcion: 'LIC. EN COMUNICACIÓN GRAFICA',
	},
	{
		codigo: 245,
		descripcion: 'LIC. EN COMUNICACIÓN Y DESARROLLO ORG.',
	},
	{
		codigo: 201,
		descripcion: 'LIC. EN COMUNICACIÓN Y RELACIONES PUBLICAS',
	},
	{
		codigo: 246,
		descripcion: 'LIC. EN CONTABILIDAD',
	},
	{
		codigo: 247,
		descripcion: 'LIC. EN CONTADURIA PUBLICA',
	},
	{
		codigo: 311,
		descripcion: 'LIC. EN CONTADURIA PUBLICA',
	},
	{
		codigo: 248,
		descripcion: 'LIC. EN CONTADURIA PUBLICA Y AUDITORIA',
	},
	{
		codigo: 249,
		descripcion: 'LIC. EN CONTADURIA PUBLICA Y FINANZAS',
	},
	{
		codigo: 250,
		descripcion: 'LIC. EN CRIMINOLOGIA',
	},
	{
		codigo: 251,
		descripcion: 'LIC. EN DEPORTES',
	},
	{
		codigo: 252,
		descripcion: 'LIC. EN DERECHO',
	},
	{
		codigo: 253,
		descripcion: 'LIC. EN DERECHO PENAL',
	},
	{
		codigo: 254,
		descripcion: 'LIC. EN DERECHO Y CIENCIAS JURIDICAS',
	},
	{
		codigo: 255,
		descripcion: 'LIC. EN DERECHO Y CIENCIAS SOCIALES',
	},
	{
		codigo: 256,
		descripcion: 'LIC. EN DIBUJO GRAFICO',
	},
	{
		codigo: 257,
		descripcion: 'LIC. EN DISEÑO DE INTERIORES',
	},
	{
		codigo: 258,
		descripcion: 'LIC. EN DISEÑO DE MODAS',
	},
	{
		codigo: 259,
		descripcion: 'LIC. EN DISEÑO GRAFICO',
	},
	{
		codigo: 260,
		descripcion: 'LIC. EN DISEÑO INDUSTRIAL',
	},
	{
		codigo: 261,
		descripcion: 'LIC. EN ECONOMIA',
	},
	{
		codigo: 262,
		descripcion: 'LIC. EN ECONOMIA INTERNACIONAL',
	},
	{
		codigo: 263,
		descripcion: 'LIC. EN EDUCACION',
	},
	{
		codigo: 264,
		descripcion: 'LIC. EN EDUCACION ESPECIAL',
	},
	{
		codigo: 265,
		descripcion: 'LIC. EN EDUCACION MEDIA',
	},
	{
		codigo: 266,
		descripcion: 'LIC. EN EDUCACION PRESCOLAR',
	},
	{
		codigo: 267,
		descripcion: 'LIC. EN ELECTRONICA Y SISTEMAS DIGITALES',
	},
	{
		codigo: 268,
		descripcion: 'LIC. EN ESTUDIOS INTERNACIONALES',
	},
	{
		codigo: 269,
		descripcion: 'LIC. EN FILOSOFIA',
	},
	{
		codigo: 270,
		descripcion: 'LIC. EN FILOSOFIA Y TEOLOGIA',
	},
	{
		codigo: 271,
		descripcion: 'LIC. EN FINANZAS',
	},
	{
		codigo: 272,
		descripcion: 'LIC. EN FINANZAS INTERNACIONALES',
	},
	{
		codigo: 273,
		descripcion: 'LIC. EN HISTORIA',
	},
	{
		codigo: 274,
		descripcion: 'LIC. EN HOTELERIA Y TURISMO',
	},
	{
		codigo: 275,
		descripcion: 'LIC. EN IDIOMAS',
	},
	{
		codigo: 276,
		descripcion: 'LIC. EN INFORMATICA',
	},
	{
		codigo: 277,
		descripcion: 'LIC. EN INFORMATICA ADMINISTRATIVA',
	},
	{
		codigo: 278,
		descripcion: 'LIC. EN INGENIERIA FINANCIERA',
	},
	{
		codigo: 279,
		descripcion: 'LIC. EN INGENIERIA Y ARQUITECTURA',
	},
	{
		codigo: 280,
		descripcion: 'LIC. EN LETRAS ESPAÑOLAS',
	},
	{
		codigo: 281,
		descripcion: 'LIC. EN LETRAS INGLESAS',
	},
	{
		codigo: 282,
		descripcion: 'LIC.EN LINGÜÍSTICA APLICADA',
	},
	{
		codigo: 283,
		descripcion: 'LIC. EN MATEMATICAS',
	},
	{
		codigo: 284,
		descripcion: 'LIC. EN MERCADOTECNIA',
	},
	{
		codigo: 309,
		descripcion: 'LIC. EN NEGOCIOS INTERNACIONALES',
	},
	{
		codigo: 285,
		descripcion: 'LIC. EN NUTRICION',
	},
	{
		codigo: 310,
		descripcion: 'LIC. EN ODONTOLOGIA',
	},
	{
		codigo: 286,
		descripcion: 'LIC. EN ORGANIZACIÓN DEPORTIVA',
	},
	{
		codigo: 287,
		descripcion: 'LIC. EN PEDAGOGIA',
	},
	{
		codigo: 288,
		descripcion: 'LIC. EN PERIODISMO',
	},
	{
		codigo: 289,
		descripcion: 'LIC. EN PERIODISMO Y COMUNICACIÓN COLECTIVA',
	},
	{
		codigo: 290,
		descripcion: 'LIC. EN PSICOLOGIA',
	},
	{
		codigo: 291,
		descripcion: 'LIC. EN PSICOLOGIA CLINICA',
	},
	{
		codigo: 292,
		descripcion: 'LIC. EN PSICOLOGIA INDUSTRIAL',
	},
	{
		codigo: 293,
		descripcion: 'LIC. EN PSICOLOGIA LABORAL',
	},
	{
		codigo: 294,
		descripcion: 'LIC. EN PUBLICIDAD',
	},
	{
		codigo: 295,
		descripcion: 'LIC. EN RECURSOS HUMANOS',
	},
	{
		codigo: 296,
		descripcion: 'LIC. EN RELACIONES COMERCIALES',
	},
	{
		codigo: 298,
		descripcion: 'LIC. EN RELACIONES INDUSTRIALES',
	},
	{
		codigo: 299,
		descripcion: 'LIC. EN RELACIONES INTERNACIONALES',
	},
	{
		codigo: 301,
		descripcion: 'LIC. EN RELACIONES PUBLICAS',
	},
	{
		codigo: 300,
		descripcion: 'LIC. EN RELACIONES PUBLICAS Y COMUNICACIÓN',
	},
	{
		codigo: 297,
		descripcion: 'LIC. EN RELACIONES HUMANAS',
	},
	{
		codigo: 302,
		descripcion: 'LIC. EN SISTEMAS',
	},
	{
		codigo: 303,
		descripcion: 'LIC. EN SISTEMAS COMPUTACIONALES',
	},
	{
		codigo: 304,
		descripcion: 'LIC. EN SISTEMAS DE COMPUTACION ADMINISTRATIVA',
	},
	{
		codigo: 305,
		descripcion: 'LIC. EN SOCIOLOGIA',
	},
	{
		codigo: 306,
		descripcion: 'LIC. EN TRABAJO SOCIAL',
	},
	{
		codigo: 307,
		descripcion: 'LIC. EN TURISMO',
	},
	{
		codigo: 308,
		descripcion: 'LIC. EN ZOOTECNIA',
	},
	{
		codigo: 312,
		descripcion: 'LICENCIADO EN DERECHO',
	},
	{
		codigo: 313,
		descripcion: 'LICENCIADO EN DISEÑO PUBLICITARIO',
	},
	{
		codigo: 314,
		descripcion: 'LICENCIADO EN ECONOMIA',
	},
	{
		codigo: 801,
		descripcion: 'MAESTRO EDUCACION PRIMARIA',
	},
	{
		codigo: 802,
		descripcion: 'MAESTRO NORMALISTA',
	},
	{
		codigo: 401,
		descripcion: 'MEDICO GENERAL',
	},
	{
		codigo: 402,
		descripcion: 'MEDICO HOMEOPATA CIRUJANO PARTERO',
	},
	{
		codigo: 403,
		descripcion: 'MEDICO ODONTOLOGO',
	},
	{
		codigo: 404,
		descripcion: 'MEDICO VETERINARIO',
	},
	{
		codigo: 405,
		descripcion: 'MEDICO VETERINARIO ZOOTECNISTA',
	},
	{
		codigo: 804,
		descripcion: 'NORMAL DE EDUCACION FISICA',
	},
	{
		codigo: 803,
		descripcion: 'NORMAL BASICA',
	},
	{
		codigo: 861,
		descripcion: 'OBISPO',
	},
	{
		codigo: 860,
		descripcion: 'PRESBITERO',
	},
	{
		codigo: 805,
		descripcion: 'PROFESOR DE EDUCACION PREESCOLAR',
	},
	{
		codigo: 850,
		descripcion: 'SEÑOR',
	},
	{
		codigo: 851,
		descripcion: 'SEÑORA',
	},
	{
		codigo: 852,
		descripcion: 'SEÑORITA',
	},
	{
		codigo: 871,
		descripcion: 'TENIENTE',
	},
	{
		codigo: 156,
		descripcion: 'INGENIERO',
	},
	{
		codigo: 315,
		descripcion: 'LICENCIADO',
	},
	{
		codigo: 410,
		descripcion: 'DOCTOR(A)',
	},
	{
		codigo: 810,
		descripcion: 'PROFESOR',
	},
];

export const tipoVivienda = [
	{
		codigo: '01',
		descripcion: 'Casa',
	},
	{
		codigo: '02',
		descripcion: 'Edificio',
	},
	{
		codigo: '03',
		descripcion: 'Apartamento / Departamento',
	},
	{
		codigo: '04',
		descripcion: 'Unidad Habitacional',
	},
	{
		codigo: '08',
		descripcion: 'Mercado',
	},
];

export const tipoEmpleado = [
	{
		codigo: 10,
		descripcion: 'Sindicalizado',
	},
	{
		codigo: '03',
		descripcion: 'De confianza',
	},
];

export const nacionalidad = [
	{
		codigo: 1,
		descripcion: 'Mexicana',
	},
	{
		codigo: 2,
		descripcion: 'Norteamericana',
	},
	{
		codigo: 4,
		descripcion: 'Libanesa',
	},
	{
		codigo: 5,
		descripcion: 'Sudamericana',
	},
	{
		codigo: 6,
		descripcion: 'Centroamericana',
	},
	{
		codigo: 7,
		descripcion: 'Europea',
	},
	{
		codigo: 8,
		descripcion: 'Israelita',
	},
	{
		codigo: 9,
		descripcion: 'Japonesa',
	},
	{
		codigo: 10,
		descripcion: 'Canadiense',
	},
	{
		codigo: 99,
		descripcion: 'Otras',
	},
];

export const estadoCivil = [
	{
		codigo: 'C',
		descripcion: 'Casado/a',
	},
	{
		codigo: 'D',
		descripcion: 'Divorciado/a',
	},
	{
		codigo: 'S',
		descripcion: 'Soltero/a',
	},
	{
		codigo: 'U',
		descripcion: 'Union Libre',
	},
	{
		codigo: 'V',
		descripcion: 'Viudo/a',
	},
	{
		codigo: 'X',
		descripcion: 'Separado/a',
	},
];

export const regimenMatrimonial = [
	{
		codigo: 'M',
		descripcion: 'Bienes Mancomunados',
	},
	{
		codigo: 'S',
		descripcion: 'Bienes Separados',
	},
];

export const escolaridad = [
	{
		codigo: 1,
		descripcion: 'Sin Escolaridad',
	},
	{
		codigo: 2,
		descripcion: 'Primaria',
	},
	{
		codigo: 3,
		descripcion: 'Secundaria',
	},
	{
		codigo: 4,
		descripcion: 'Preparatoria',
	},
	{
		codigo: 5,
		descripcion: 'Carrera Tecnica',
	},
	{
		codigo: 6,
		descripcion: 'Licenciatira (profesional)',
	},
	{
		codigo: 7,
		descripcion: 'Diplomado-especialidad',
	},
	{
		codigo: 8,
		descripcion: 'Postgrado(maestria,doctorado)',
	},
	{
		codigo: 9,
		descripcion: 'Otros',
	},
	{
		codigo: 99,
		descripcion: 'Sin Escolarizar',
	},
];

export const estado = [
	{
		codigo: '01',
		estado: 'CDMX',
	},
	{
		codigo: '02',
		estado: 'AGUASCALIENTES',
	},
	{
		codigo: '03',
		estado: 'BAJA_CALIFORNIA_NORTE',
	},
	{
		codigo: '04',
		estado: 'BAJA_CALIFORNIA_SUR',
	},
	{
		codigo: '05',
		estado: 'CAMPECHE',
	},
	{
		codigo: '06',
		estado: 'COAHUILA',
	},
	{
		codigo: '07',
		estado: 'COLIMA',
	},
	{
		codigo: '08',
		estado: 'CHIAPAS',
	},
	{
		codigo: '09',
		estado: 'CHIHUAHUA',
	},
	{
		codigo: 10,
		estado: 'DURANGO',
	},
	{
		codigo: 11,
		estado: 'GUANAJUATO',
	},
	{
		codigo: 12,
		estado: 'GUERRERO',
	},
	{
		codigo: 13,
		estado: 'HIDALGO',
	},
	{
		codigo: 14,
		estado: 'JALISCO',
	},
	{
		codigo: 15,
		estado: 'ESTADO_DE_MÉXICO',
	},
	{
		codigo: 16,
		estado: 'MICHOACÁN',
	},
	{
		codigo: 17,
		estado: 'MORELOS',
	},
	{
		codigo: 18,
		estado: 'NAYARIT',
	},
	{
		codigo: 19,
		estado: 'NUEVO_LEÓN',
	},
	{
		codigo: 20,
		estado: 'OAXACA',
	},
	{
		codigo: 21,
		estado: 'PUEBLA',
	},
	{
		codigo: 22,
		estado: 'QUERÉTARO',
	},
	{
		codigo: 23,
		estado: 'QUINTANA_ROO',
	},
	{
		codigo: 24,
		estado: 'SAN_LUIS_POTOSÍ',
	},
	{
		codigo: 25,
		estado: 'SINALOA',
	},
	{
		codigo: 26,
		estado: 'SONORA',
	},
	{
		codigo: 27,
		estado: 'TABASCO',
	},
	{
		codigo: 28,
		estado: 'TAMAULIPAS',
	},
	{
		codigo: 29,
		estado: 'TLAXCALA',
	},
	{
		codigo: 30,
		estado: 'VERACRUZ',
	},
	{
		codigo: 31,
		estado: 'YUCATÁN',
	},
	{
		codigo: 32,
		estado: 'ZACATECAS',
	},
];

export const delegacion = {
	'01': [
		{
			id: 1,
			name: 'ÁLVARO OBREGÓN',
		},
		{
			id: 2,
			name: 'AZCAPOTZALCO',
		},
		{
			id: 3,
			name: 'BENITO JUÁREZ',
		},
		{
			id: 4,
			name: 'COYOACÁN',
		},
		{
			id: 5,
			name: 'CUAJIMALPA DE MORELOS',
		},
		{
			id: 6,
			name: 'CUAUHTÉMOC',
		},
		{
			id: 7,
			name: 'GUSTAVO A. MADERO',
		},
		{
			id: 8,
			name: 'IZTACALCO',
		},
		{
			id: 9,
			name: 'IZTAPALAPA',
		},
		{
			id: 10,
			name: 'LA MAGDALENA CONTRERAS',
		},
		{
			id: 11,
			name: 'MIGUEL HIDALGO',
		},
		{
			id: 12,
			name: 'MILPA ALTA',
		},
		{
			id: 13,
			name: 'TLÁHUAC',
		},
		{
			id: 14,
			name: 'TLALPAN',
		},
		{
			id: 15,
			name: 'VENUSTIANO CARRANZA',
		},
		{
			id: 16,
			name: 'XOCHIMILCO',
		},
	],
	'02': [
		{
			id: 1,
			name: 'AGUASCALIENTES',
		},
		{
			id: 2,
			name: 'ASIENTOS',
		},
		{
			id: 3,
			name: 'CALVILLO',
		},
		{
			id: 4,
			name: 'COSÍO',
		},
		{
			id: 5,
			name: 'JESÚS MARÍA',
		},
		{
			id: 6,
			name: 'PABELLÓN DE ARTEAGA',
		},
		{
			id: 7,
			name: 'RINCÓN DE ROMOS',
		},
		{
			id: 8,
			name: 'SAN JOSÉ DE GRACIA',
		},
		{
			id: 9,
			name: 'TEPEZALÁ',
		},
		{
			id: 10,
			name: 'EL LLANO',
		},
		{
			id: 11,
			name: 'SAN FRANCISCO DE LOS ROMO',
		},
	],
	'03': [
		{
			codigo: 1,
			descripcion: 'ENSENADA',
		},
		{
			codigo: 2,
			descripcion: 'MEXICALI',
		},
		{
			codigo: 3,
			descripcion: 'TECATE',
		},
		{
			codigo: 4,
			descripcion: 'TIJUANA',
		},
		{
			codigo: 5,
			descripcion: 'PLAYAS DE ROSARITO',
		},
	],
	'04': [
		{
			codigo: 1,
			descripcion: 'COMONDÚ',
		},
		{
			codigo: 2,
			descripcion: 'MULEGÉ',
		},
		{
			codigo: 3,
			descripcion: 'LA PAZ',
		},
		{
			codigo: 4,
			descripcion: 'LOS CABOS',
		},
	],
	'05': [
		{
			codigo: 1,
			descripcion: 'CALKINÍ',
		},
		{
			codigo: 2,
			descripcion: 'CAMPECHE',
		},
		{
			codigo: 3,
			descripcion: 'CARMEN',
		},
		{
			codigo: 4,
			descripcion: 'CHAMPOTÓN',
		},
		{
			codigo: 5,
			descripcion: 'HECELCHAKÁN',
		},
		{
			codigo: 6,
			descripcion: 'HOPELCHÉN',
		},
		{
			codigo: 7,
			descripcion: 'PALIZADA',
		},
		{
			codigo: 8,
			descripcion: 'TENABO',
		},
		{
			codigo: 9,
			descripcion: 'ESCÁRCEGA',
		},
	],
	'06': [
		{
			codigo: 1,
			descripcion: 'ABASOLO',
		},
		{
			codigo: 2,
			descripcion: 'ACUÑA',
		},
		{
			codigo: 3,
			descripcion: 'ALLENDE',
		},
		{
			codigo: 4,
			descripcion: 'ARTEAGA',
		},
		{
			codigo: 5,
			descripcion: 'CANDELA',
		},
		{
			codigo: 6,
			descripcion: 'CASTAÑOS',
		},
		{
			codigo: 7,
			descripcion: 'CUATRO CIÉNEGAS',
		},
		{
			codigo: 8,
			descripcion: 'ESCOBEDO',
		},
		{
			codigo: 9,
			descripcion: 'FRANCISCO I. MADERO',
		},
		{
			codigo: 10,
			descripcion: 'FRONTERA',
		},
		{
			codigo: 11,
			descripcion: 'GENERAL CEPEDA',
		},
		{
			codigo: 12,
			descripcion: 'GUERRERO',
		},
		{
			codigo: 13,
			descripcion: 'HIDALGO',
		},
		{
			codigo: 14,
			descripcion: 'JIMÉNEZ',
		},
		{
			codigo: 15,
			descripcion: 'JUÁREZ',
		},
		{
			codigo: 16,
			descripcion: 'LAMADRID',
		},
		{
			codigo: 17,
			descripcion: 'MATAMOROS',
		},
		{
			codigo: 18,
			descripcion: 'MONCLOVA',
		},
		{
			codigo: 19,
			descripcion: 'MORELOS',
		},
		{
			codigo: 20,
			descripcion: 'MÚZQUIZ',
		},
		{
			codigo: 21,
			descripcion: 'NADADORES',
		},
		{
			codigo: 22,
			descripcion: 'NAVA',
		},
		{
			codigo: 23,
			descripcion: 'OCAMPO',
		},
		{
			codigo: 24,
			descripcion: 'PARRAS',
		},
		{
			codigo: 25,
			descripcion: 'PIEDRAS NEGRAS',
		},
		{
			codigo: 26,
			descripcion: 'PROGRESO',
		},
		{
			codigo: 27,
			descripcion: 'RAMOS ARIZPE',
		},
		{
			codigo: 28,
			descripcion: 'SABINAS',
		},
		{
			codigo: 29,
			descripcion: 'SACRAMENTO',
		},
		{
			codigo: 30,
			descripcion: 'SALTILLO',
		},
		{
			codigo: 31,
			descripcion: 'SAN BUENAVENTURA',
		},
		{
			codigo: 32,
			descripcion: 'SAN JUAN DE SABINAS',
		},
		{
			codigo: 33,
			descripcion: 'SAN PEDRO',
		},
		{
			codigo: 34,
			descripcion: 'SIERRA MOJADA',
		},
		{
			codigo: 35,
			descripcion: 'TORREÓN',
		},
		{
			codigo: 36,
			descripcion: 'VIESCA',
		},
		{
			codigo: 37,
			descripcion: 'VILLA UNIÓN',
		},
		{
			codigo: 38,
			descripcion: 'ZARAGOZA',
		},
	],
	'07': [
		{
			codigo: 1,
			descripcion: 'ARMERÍA',
		},
		{
			codigo: 2,
			descripcion: 'COLIMA',
		},
		{
			codigo: 3,
			descripcion: 'COMALA',
		},
		{
			codigo: 4,
			descripcion: 'COQUIMATLÁN',
		},
		{
			codigo: 5,
			descripcion: 'CUAUHTÉMOC',
		},
		{
			codigo: 6,
			descripcion: 'IXTLAHUACÁN',
		},
		{
			codigo: 7,
			descripcion: 'MANZANILLO',
		},
		{
			codigo: 8,
			descripcion: 'MINATITLÁN',
		},
		{
			codigo: 9,
			descripcion: 'TECOMÁN',
		},
		{
			codigo: 10,
			descripcion: 'VILLA DE ÁLVAREZ',
		},
	],
	'08': [
		{
			codigo: 1,
			descripcion: 'ACACOYAGUA',
		},
		{
			codigo: 2,
			descripcion: 'ACALA',
		},
		{
			codigo: 3,
			descripcion: 'ACAPETAHUA',
		},
		{
			codigo: 4,
			descripcion: 'ALTAMIRANO',
		},
		{
			codigo: 5,
			descripcion: 'AMATÁN',
		},
		{
			codigo: 6,
			descripcion: 'AMATENANGO DE LA FRONTERA',
		},
		{
			codigo: 7,
			descripcion: 'AMATENANGO DEL VALLE',
		},
		{
			codigo: 8,
			descripcion: 'ANGEL ALBINO CORZO',
		},
		{
			codigo: 9,
			descripcion: 'ARRIAGA',
		},
		{
			codigo: 10,
			descripcion: 'BEJUCAL DE OCAMPO',
		},
		{
			codigo: 11,
			descripcion: 'BELLA VISTA',
		},
		{
			codigo: 12,
			descripcion: 'BERRIOZÁBAL',
		},
		{
			codigo: 13,
			descripcion: 'BOCHIL',
		},
		{
			codigo: 14,
			descripcion: 'EL BOSQUE',
		},
		{
			codigo: 15,
			descripcion: 'CACAHOATÁN',
		},
		{
			codigo: 16,
			descripcion: 'CATAZAJÁ',
		},
		{
			codigo: 17,
			descripcion: 'CINTALAPA',
		},
		{
			codigo: 18,
			descripcion: 'COAPILLA',
		},
		{
			codigo: 19,
			descripcion: 'COMITÁN DE DOMÍNGUEZ',
		},
		{
			codigo: 20,
			descripcion: 'LA CONCORDIA',
		},
		{
			codigo: 21,
			descripcion: 'COPAINALÁ',
		},
		{
			codigo: 22,
			descripcion: 'CHALCHIHUITÁN',
		},
		{
			codigo: 23,
			descripcion: 'CHAMULA',
		},
		{
			codigo: 24,
			descripcion: 'CHANAL',
		},
		{
			codigo: 25,
			descripcion: 'CHAPULTENANGO',
		},
		{
			codigo: 26,
			descripcion: 'CHENALHÓ',
		},
		{
			codigo: 27,
			descripcion: 'CHIAPA DE CORZO',
		},
		{
			codigo: 28,
			descripcion: 'CHIAPILLA',
		},
		{
			codigo: 29,
			descripcion: 'CHICOASÉN',
		},
		{
			codigo: 30,
			descripcion: 'CHICOMUSELO',
		},
		{
			codigo: 31,
			descripcion: 'CHILÓN',
		},
		{
			codigo: 32,
			descripcion: 'ESCUINTLA',
		},
		{
			codigo: 33,
			descripcion: 'FRANCISCO LEÓN',
		},
		{
			codigo: 34,
			descripcion: 'FRONTERA COMALAPA',
		},
		{
			codigo: 35,
			descripcion: 'FRONTERA HIDALGO',
		},
		{
			codigo: 36,
			descripcion: 'LA GRANDEZA',
		},
		{
			codigo: 37,
			descripcion: 'HUEHUETÁN',
		},
		{
			codigo: 38,
			descripcion: 'HUIXTÁN',
		},
		{
			codigo: 39,
			descripcion: 'HUITIUPÁN',
		},
		{
			codigo: 40,
			descripcion: 'HUIXTLA',
		},
		{
			codigo: 41,
			descripcion: 'LA INDEPENDENCIA',
		},
		{
			codigo: 42,
			descripcion: 'IXHUATÁN',
		},
		{
			codigo: 43,
			descripcion: 'IXTACOMITÁN',
		},
		{
			codigo: 44,
			descripcion: 'IXTAPA',
		},
		{
			codigo: 45,
			descripcion: 'IXTAPANGAJOYA',
		},
		{
			codigo: 46,
			descripcion: 'JIQUIPILAS',
		},
		{
			codigo: 47,
			descripcion: 'JITOTOL',
		},
		{
			codigo: 48,
			descripcion: 'JUÁREZ',
		},
		{
			codigo: 49,
			descripcion: 'LARRÁINZAR',
		},
		{
			codigo: 50,
			descripcion: 'LA LIBERTAD',
		},
		{
			codigo: 51,
			descripcion: 'MAPASTEPEC',
		},
		{
			codigo: 52,
			descripcion: 'LAS MARGARITAS',
		},
		{
			codigo: 53,
			descripcion: 'MAZAPA DE MADERO',
		},
		{
			codigo: 54,
			descripcion: 'MAZATÁN',
		},
		{
			codigo: 55,
			descripcion: 'METAPA',
		},
		{
			codigo: 56,
			descripcion: 'MITONTIC',
		},
		{
			codigo: 57,
			descripcion: 'MOTOZINTLA',
		},
		{
			codigo: 58,
			descripcion: 'NICOLÁS RUÍZ',
		},
		{
			codigo: 59,
			descripcion: 'OCOSINGO',
		},
		{
			codigo: 60,
			descripcion: 'OCOTEPEC',
		},
		{
			codigo: 61,
			descripcion: 'OCOZOCOAUTLA DE ESPINOSA',
		},
		{
			codigo: 62,
			descripcion: 'OSTUACÁN',
		},
		{
			codigo: 63,
			descripcion: 'OSUMACINTA',
		},
		{
			codigo: 64,
			descripcion: 'OXCHUC',
		},
		{
			codigo: 65,
			descripcion: 'PALENQUE',
		},
		{
			codigo: 66,
			descripcion: 'PANTELHÓ',
		},
		{
			codigo: 67,
			descripcion: 'PANTEPEC',
		},
		{
			codigo: 68,
			descripcion: 'PICHUCALCO',
		},
		{
			codigo: 69,
			descripcion: 'PIJIJIAPAN',
		},
		{
			codigo: 70,
			descripcion: 'EL PORVENIR',
		},
		{
			codigo: 71,
			descripcion: 'VILLA COMALTITLÁN',
		},
		{
			codigo: 72,
			descripcion: 'PUEBLO NUEVO SOLISTAHUACÁN',
		},
		{
			codigo: 73,
			descripcion: 'RAYÓN',
		},
		{
			codigo: 74,
			descripcion: 'REFORMA',
		},
		{
			codigo: 75,
			descripcion: 'LAS ROSAS',
		},
		{
			codigo: 76,
			descripcion: 'SABANILLA',
		},
		{
			codigo: 77,
			descripcion: 'SALTO DE AGUA',
		},
		{
			codigo: 78,
			descripcion: 'SAN CRISTÓBAL DE LAS CASAS',
		},
		{
			codigo: 79,
			descripcion: 'SAN FERNANDO',
		},
		{
			codigo: 80,
			descripcion: 'SILTEPEC',
		},
		{
			codigo: 81,
			descripcion: 'SIMOJOVEL',
		},
		{
			codigo: 82,
			descripcion: 'SITALÁ',
		},
		{
			codigo: 83,
			descripcion: 'SOCOLTENANGO',
		},
		{
			codigo: 84,
			descripcion: 'SOLOSUCHIAPA',
		},
		{
			codigo: 85,
			descripcion: 'SOYALÓ',
		},
		{
			codigo: 86,
			descripcion: 'SUCHIAPA',
		},
		{
			codigo: 87,
			descripcion: 'SUCHIATE',
		},
		{
			codigo: 88,
			descripcion: 'SUNUAPA',
		},
		{
			codigo: 89,
			descripcion: 'TAPACHULA',
		},
		{
			codigo: 90,
			descripcion: 'TAPALAPA',
		},
		{
			codigo: 91,
			descripcion: 'TAPILULA',
		},
		{
			codigo: 92,
			descripcion: 'TECPATÁN',
		},
		{
			codigo: 93,
			descripcion: 'TENEJAPA',
		},
		{
			codigo: 94,
			descripcion: 'TEOPISCA',
		},
		{
			codigo: 96,
			descripcion: 'TILA',
		},
		{
			codigo: 97,
			descripcion: 'TONALÁ',
		},
		{
			codigo: 98,
			descripcion: 'TOTOLAPA',
		},
		{
			codigo: 99,
			descripcion: 'LA TRINITARIA',
		},
		{
			codigo: 100,
			descripcion: 'TUMBALÁ',
		},
		{
			codigo: 101,
			descripcion: 'TUXTLA GUTIÉRREZ',
		},
		{
			codigo: 102,
			descripcion: 'TUXTLA CHICO',
		},
		{
			codigo: 103,
			descripcion: 'TUZANTÁN',
		},
		{
			codigo: 104,
			descripcion: 'TZIMOL',
		},
		{
			codigo: 105,
			descripcion: 'UNIÓN JUÁREZ',
		},
		{
			codigo: 106,
			descripcion: 'VENUSTIANO CARRANZA',
		},
		{
			codigo: 107,
			descripcion: 'VILLA CORZO',
		},
		{
			codigo: 108,
			descripcion: 'VILLAFLORES',
		},
		{
			codigo: 109,
			descripcion: 'YAJALÓN',
		},
		{
			codigo: 110,
			descripcion: 'SAN LUCAS',
		},
		{
			codigo: 111,
			descripcion: 'ZINACANTÁN',
		},
		{
			codigo: 112,
			descripcion: 'SAN JUAN CANCUC',
		},
	],
	'09': [
		{
			codigo: 1,
			descripcion: 'AHUMADA',
		},
		{
			codigo: 2,
			descripcion: 'ALDAMA',
		},
		{
			codigo: 3,
			descripcion: 'ALLENDE',
		},
		{
			codigo: 4,
			descripcion: 'AQUILES SERDÁN',
		},
		{
			codigo: 5,
			descripcion: 'ASCENSIÓN',
		},
		{
			codigo: 6,
			descripcion: 'BACHÍNIVA',
		},
		{
			codigo: 7,
			descripcion: 'BALLEZA',
		},
		{
			codigo: 8,
			descripcion: 'BATOPILAS',
		},
		{
			codigo: 9,
			descripcion: 'BOCOYNA',
		},
		{
			codigo: 10,
			descripcion: 'BUENAVENTURA',
		},
		{
			codigo: 11,
			descripcion: 'CAMARGO',
		},
		{
			codigo: 12,
			descripcion: 'CARICHÍ',
		},
		{
			codigo: 13,
			descripcion: 'CASAS GRANDES',
		},
		{
			codigo: 14,
			descripcion: 'CORONADO',
		},
		{
			codigo: 15,
			descripcion: 'COYAME',
		},
		{
			codigo: 16,
			descripcion: 'LA CRUZ',
		},
		{
			codigo: 17,
			descripcion: 'CUAUHTÉMOC',
		},
		{
			codigo: 18,
			descripcion: 'CUSIHUIRIACHI',
		},
		{
			codigo: 19,
			descripcion: 'CHIHUAHUA',
		},
		{
			codigo: 20,
			descripcion: 'CHÍNIPAS',
		},
		{
			codigo: 21,
			descripcion: 'DELICIAS',
		},
		{
			codigo: 22,
			descripcion: 'DOCTOR BELISARIO DOMÍNGUEZ',
		},
		{
			codigo: 23,
			descripcion: 'GALEANA',
		},
		{
			codigo: 24,
			descripcion: 'GENERAL TRIAS',
		},
		{
			codigo: 25,
			descripcion: 'GÓMEZ FARÍAS',
		},
		{
			codigo: 26,
			descripcion: 'GRAN MORELOS',
		},
		{
			codigo: 27,
			descripcion: 'GUACHOCHI',
		},
		{
			codigo: 28,
			descripcion: 'GUADALUPE',
		},
		{
			codigo: 29,
			descripcion: 'GUADALUPE Y CALVO',
		},
		{
			codigo: 30,
			descripcion: 'GUAZAPARES',
		},
		{
			codigo: 31,
			descripcion: 'GUERRERO',
		},
		{
			codigo: 32,
			descripcion: 'HIDALGO DEL PARRAL',
		},
		{
			codigo: 33,
			descripcion: 'HUEJOTITÁN',
		},
		{
			codigo: 34,
			descripcion: 'IGNACIO ZARAGOZA',
		},
		{
			codigo: 35,
			descripcion: 'JANOS',
		},
		{
			codigo: 36,
			descripcion: 'JIMÉNEZ',
		},
		{
			codigo: 37,
			descripcion: 'JUÁREZ',
		},
		{
			codigo: 38,
			descripcion: 'JULIMES',
		},
		{
			codigo: 39,
			descripcion: 'LÓPEZ',
		},
		{
			codigo: 40,
			descripcion: 'MADERA',
		},
		{
			codigo: 41,
			descripcion: 'MAGUARICHI',
		},
		{
			codigo: 42,
			descripcion: 'MANUEL BENAVIDES',
		},
		{
			codigo: 43,
			descripcion: 'MATACHÍ',
		},
		{
			codigo: 44,
			descripcion: 'MATAMOROS',
		},
		{
			codigo: 45,
			descripcion: 'MEOQUI',
		},
		{
			codigo: 46,
			descripcion: 'MORELOS',
		},
		{
			codigo: 47,
			descripcion: 'MORIS',
		},
		{
			codigo: 48,
			descripcion: 'NAMIQUIPA',
		},
		{
			codigo: 49,
			descripcion: 'NONOAVA',
		},
		{
			codigo: 50,
			descripcion: 'NUEVO CASAS GRANDES',
		},
		{
			codigo: 51,
			descripcion: 'OCAMPO',
		},
		{
			codigo: 52,
			descripcion: 'OJINAGA',
		},
		{
			codigo: 53,
			descripcion: 'PRAXEDIS G. GUERRERO',
		},
		{
			codigo: 54,
			descripcion: 'RIVA PALACIO',
		},
		{
			codigo: 55,
			descripcion: 'ROSALES',
		},
		{
			codigo: 56,
			descripcion: 'ROSARIO',
		},
		{
			codigo: 57,
			descripcion: 'SAN FRANCISCO DE BORJA',
		},
		{
			codigo: 58,
			descripcion: 'SAN FRANCISCO DE CONCHOS',
		},
		{
			codigo: 59,
			descripcion: 'SAN FRANCISCO DEL ORO',
		},
		{
			codigo: 60,
			descripcion: 'SANTA BÁRBARA',
		},
		{
			codigo: 61,
			descripcion: 'SATEVÓ',
		},
		{
			codigo: 62,
			descripcion: 'SAUCILLO',
		},
		{
			codigo: 63,
			descripcion: 'TEMÓSACHI',
		},
		{
			codigo: 64,
			descripcion: 'EL TULE',
		},
		{
			codigo: 65,
			descripcion: 'URIQUE',
		},
		{
			codigo: 66,
			descripcion: 'URUACHI',
		},
		{
			codigo: 67,
			descripcion: 'VALLE DE ZARAGOZA',
		},
	],
	10: [
		{
			codigo: 1,
			descripcion: 'CANATLÁN',
		},
		{
			codigo: 2,
			descripcion: 'CANELAS',
		},
		{
			codigo: 3,
			descripcion: 'CONETO DE COMONFORT',
		},
		{
			codigo: 4,
			descripcion: 'CUENCAMÉ',
		},
		{
			codigo: 5,
			descripcion: 'DURANGO',
		},
		{
			codigo: 6,
			descripcion: 'GENERAL SIMÓN BOLÍVAR',
		},
		{
			codigo: 7,
			descripcion: 'GÓMEZ PALACIO',
		},
		{
			codigo: 8,
			descripcion: 'GUADALUPE VICTORIA',
		},
		{
			codigo: 9,
			descripcion: 'GUANACEVÍ',
		},
		{
			codigo: 10,
			descripcion: 'HIDALGO',
		},
		{
			codigo: 11,
			descripcion: 'INDÉ',
		},
		{
			codigo: 12,
			descripcion: 'LERDO',
		},
		{
			codigo: 13,
			descripcion: 'MAPIMÍ',
		},
		{
			codigo: 14,
			descripcion: 'MEZQUITAL',
		},
		{
			codigo: 15,
			descripcion: 'NAZAS',
		},
		{
			codigo: 16,
			descripcion: 'NOMBRE DE DIOS',
		},
		{
			codigo: 17,
			descripcion: 'OCAMPO',
		},
		{
			codigo: 18,
			descripcion: 'EL ORO',
		},
		{
			codigo: 19,
			descripcion: 'OTÁEZ',
		},
		{
			codigo: 20,
			descripcion: 'PÁNUCO DE CORONADO',
		},
		{
			codigo: 21,
			descripcion: 'PEÑÓN BLANCO',
		},
		{
			codigo: 22,
			descripcion: 'POANAS',
		},
		{
			codigo: 23,
			descripcion: 'PUEBLO NUEVO',
		},
		{
			codigo: 24,
			descripcion: 'RODEO',
		},
		{
			codigo: 25,
			descripcion: 'SAN BERNARDO',
		},
		{
			codigo: 26,
			descripcion: 'SAN DIMAS',
		},
		{
			codigo: 27,
			descripcion: 'SAN JUAN DE GUADALUPE',
		},
		{
			codigo: 28,
			descripcion: 'SAN JUAN DEL RÍO',
		},
		{
			codigo: 29,
			descripcion: 'SAN LUIS DEL CORDERO',
		},
		{
			codigo: 30,
			descripcion: 'SAN PEDRO DEL GALLO',
		},
		{
			codigo: 31,
			descripcion: 'SANTA CLARA',
		},
		{
			codigo: 32,
			descripcion: 'SANTIAGO PAPASQUIARO',
		},
		{
			codigo: 33,
			descripcion: 'SÚCHIL',
		},
		{
			codigo: 34,
			descripcion: 'TAMAZULA',
		},
		{
			codigo: 35,
			descripcion: 'TEPEHUANES',
		},
		{
			codigo: 36,
			descripcion: 'TLAHUALILO',
		},
		{
			codigo: 37,
			descripcion: 'TOPIA',
		},
		{
			codigo: 38,
			descripcion: 'VICENTE GUERRERO',
		},
		{
			codigo: 39,
			descripcion: 'NUEVO IDEAL',
		},
	],
	11: [
		{
			codigo: 1,
			descripcion: 'ABASOLO',
		},
		{
			codigo: 2,
			descripcion: 'ACÁMBARO',
		},
		{
			codigo: 3,
			descripcion: 'SAN MIGUEL DE ALLENDE',
		},
		{
			codigo: 4,
			descripcion: 'APASEO EL ALTO',
		},
		{
			codigo: 5,
			descripcion: 'APASEO EL GRANDE',
		},
		{
			codigo: 6,
			descripcion: 'ATARJEA',
		},
		{
			codigo: 7,
			descripcion: 'CELAYA',
		},
		{
			codigo: 8,
			descripcion: 'MANUEL DOBLADO',
		},
		{
			codigo: 9,
			descripcion: 'COMONFORT',
		},
		{
			codigo: 10,
			descripcion: 'CORONEO',
		},
		{
			codigo: 11,
			descripcion: 'CORTAZAR',
		},
		{
			codigo: 12,
			descripcion: 'CUERÁMARO',
		},
		{
			codigo: 13,
			descripcion: 'DOCTOR MORA',
		},
		{
			codigo: 14,
			descripcion: 'DOLORES HIDALGO',
		},
		{
			codigo: 15,
			descripcion: 'GUANAJUATO',
		},
		{
			codigo: 16,
			descripcion: 'HUANÍMARO',
		},
		{
			codigo: 17,
			descripcion: 'IRAPUATO',
		},
		{
			codigo: 18,
			descripcion: 'JARAL DEL PROGRESO',
		},
		{
			codigo: 19,
			descripcion: 'JERÉCUARO',
		},
		{
			codigo: 20,
			descripcion: 'LEÓN',
		},
		{
			codigo: 21,
			descripcion: 'MOROLEÓN',
		},
		{
			codigo: 22,
			descripcion: 'OCAMPO',
		},
		{
			codigo: 23,
			descripcion: 'PÉNJAMO',
		},
		{
			codigo: 24,
			descripcion: 'PUEBLO NUEVO',
		},
		{
			codigo: 25,
			descripcion: 'PURÍSIMA DEL RINCÓN',
		},
		{
			codigo: 26,
			descripcion: 'ROMITA',
		},
		{
			codigo: 27,
			descripcion: 'SALAMANCA',
		},
		{
			codigo: 28,
			descripcion: 'SALVATIERRA',
		},
		{
			codigo: 29,
			descripcion: 'SAN DIEGO DE LA UNIÓN',
		},
		{
			codigo: 30,
			descripcion: 'SAN FELIPE',
		},
		{
			codigo: 31,
			descripcion: 'SAN FRANCISCO DEL RINCÓN',
		},
		{
			codigo: 32,
			descripcion: 'SAN JOSÉ ITURBIDE',
		},
		{
			codigo: 33,
			descripcion: 'SAN LUIS DE LA PAZ',
		},
		{
			codigo: 34,
			descripcion: 'SANTA CATARINA',
		},
		{
			codigo: 35,
			descripcion: 'SANTA CRUZ DE JUVENTINO ROSAS',
		},
		{
			codigo: 36,
			descripcion: 'SANTIAGO MARAVATÍO',
		},
		{
			codigo: 37,
			descripcion: 'SILAO',
		},
		{
			codigo: 38,
			descripcion: 'TARANDACUAO',
		},
		{
			codigo: 39,
			descripcion: 'TARIMORO',
		},
		{
			codigo: 40,
			descripcion: 'TIERRA BLANCA',
		},
		{
			codigo: 41,
			descripcion: 'URIANGATO',
		},
		{
			codigo: 42,
			descripcion: 'VALLE DE SANTIAGO',
		},
		{
			codigo: 43,
			descripcion: 'VICTORIA',
		},
		{
			codigo: 44,
			descripcion: 'VILLAGRÁN',
		},
		{
			codigo: 45,
			descripcion: 'XICHÚ',
		},
		{
			codigo: 46,
			descripcion: 'YURIRIA',
		},
	],
	12: [
		{
			codigo: 1,
			descripcion: 'ACAPULCO DE JUÁREZ',
		},
		{
			codigo: 2,
			descripcion: 'AHUACUOTZINGO',
		},
		{
			codigo: 3,
			descripcion: 'AJUCHITLÁN DEL PROGRESO',
		},
		{
			codigo: 4,
			descripcion: 'ALCOZAUCA DE GUERRERO',
		},
		{
			codigo: 5,
			descripcion: 'ALPOYECA',
		},
		{
			codigo: 6,
			descripcion: 'APAXTLA',
		},
		{
			codigo: 7,
			descripcion: 'ARCELIA',
		},
		{
			codigo: 8,
			descripcion: 'ATENANGO DEL RÍO',
		},
		{
			codigo: 9,
			descripcion: 'ATLAMAJALCINGO DEL MONTE',
		},
		{
			codigo: 10,
			descripcion: 'ATLIXTAC',
		},
		{
			codigo: 11,
			descripcion: 'ATOYAC DE ÁLVAREZ',
		},
		{
			codigo: 12,
			descripcion: 'AYUTLA DE LOS LIBRES',
		},
		{
			codigo: 13,
			descripcion: 'AZOYÚ',
		},
		{
			codigo: 14,
			descripcion: 'BENITO JUÁREZ',
		},
		{
			codigo: 15,
			descripcion: 'BUENAVISTA DE CUÉLLAR',
		},
		{
			codigo: 16,
			descripcion: 'COAHUAYUTLA DE JOSÉ MARÍA IZAZAGA',
		},
		{
			codigo: 17,
			descripcion: 'COCULA',
		},
		{
			codigo: 18,
			descripcion: 'COPALA',
		},
		{
			codigo: 19,
			descripcion: 'COPALILLO',
		},
		{
			codigo: 20,
			descripcion: 'COPANATOYAC',
		},
		{
			codigo: 21,
			descripcion: 'COYUCA DE BENÍTEZ',
		},
		{
			codigo: 22,
			descripcion: 'COYUCA DE CATALÁN',
		},
		{
			codigo: 23,
			descripcion: 'CUAJINICUILAPA',
		},
		{
			codigo: 24,
			descripcion: 'CUALÁC',
		},
		{
			codigo: 25,
			descripcion: 'CUAUTEPEC',
		},
		{
			codigo: 26,
			descripcion: 'CUETZALA DEL PROGRESO',
		},
		{
			codigo: 27,
			descripcion: 'CUTZAMALA DE PINZÓN',
		},
		{
			codigo: 28,
			descripcion: 'CHILAPA DE ÁLVAREZ',
		},
		{
			codigo: 29,
			descripcion: 'CHILPANCINGO DE LOS BRAVO',
		},
		{
			codigo: 30,
			descripcion: 'FLORENCIO VILLARREAL',
		},
		{
			codigo: 31,
			descripcion: 'GENERAL CANUTO A. NERI',
		},
		{
			codigo: 32,
			descripcion: 'GENERAL HELIODORO CASTILLO',
		},
		{
			codigo: 33,
			descripcion: 'HUAMUXTITLÁN',
		},
		{
			codigo: 34,
			descripcion: 'HUITZUCO DE LOS FIGUEROA',
		},
		{
			codigo: 35,
			descripcion: 'IGUALA DE LA INDEPENDENCIA',
		},
		{
			codigo: 36,
			descripcion: 'IGUALAPA',
		},
		{
			codigo: 37,
			descripcion: 'IXCATEOPAN DE CUAUHTÉMOC',
		},
		{
			codigo: 38,
			descripcion: 'ZIHUATANEJO DE AZUETA',
		},
		{
			codigo: 39,
			descripcion: 'JUAN R. ESCUDERO',
		},
		{
			codigo: 40,
			descripcion: 'LEONARDO BRAVO',
		},
		{
			codigo: 41,
			descripcion: 'MALINALTEPEC',
		},
		{
			codigo: 42,
			descripcion: 'MÁRTIR DE CUILAPAN',
		},
		{
			codigo: 43,
			descripcion: 'METLATÓNOC',
		},
		{
			codigo: 44,
			descripcion: 'MOCHITLÁN',
		},
		{
			codigo: 45,
			descripcion: 'OLINALÁ',
		},
		{
			codigo: 46,
			descripcion: 'OMETEPEC',
		},
		{
			codigo: 47,
			descripcion: 'PEDRO ASCENCIO ALQUISIRAS',
		},
		{
			codigo: 48,
			descripcion: 'PETATLÁN',
		},
		{
			codigo: 49,
			descripcion: 'PILCAYA',
		},
		{
			codigo: 50,
			descripcion: 'PUNGARABATO',
		},
		{
			codigo: 51,
			descripcion: 'QUECHULTENANGO',
		},
		{
			codigo: 52,
			descripcion: 'SAN LUIS ACATLÁN',
		},
		{
			codigo: 53,
			descripcion: 'SAN MARCOS',
		},
		{
			codigo: 54,
			descripcion: 'SAN MIGUEL TOTOLAPAN',
		},
		{
			codigo: 55,
			descripcion: 'TAXCO DE ALARCÓN',
		},
		{
			codigo: 56,
			descripcion: 'TECOANAPA',
		},
		{
			codigo: 57,
			descripcion: 'TÉCPAN DE GALEANA',
		},
		{
			codigo: 58,
			descripcion: 'TELOLOAPAN',
		},
		{
			codigo: 59,
			descripcion: 'TEPECOACUILCO DE TRUJANO',
		},
		{
			codigo: 60,
			descripcion: 'TETIPAC',
		},
		{
			codigo: 61,
			descripcion: 'TIXTLA DE GUERRERO',
		},
		{
			codigo: 62,
			descripcion: 'TLACOACHISTLAHUACA',
		},
		{
			codigo: 63,
			descripcion: 'TLACOAPA',
		},
		{
			codigo: 64,
			descripcion: 'TLALCHAPA',
		},
		{
			codigo: 65,
			descripcion: 'TLALIXTAQUILLA DE MALDONADO',
		},
		{
			codigo: 66,
			descripcion: 'TLAPA DE COMONFORT',
		},
		{
			codigo: 67,
			descripcion: 'TLAPEHUALA',
		},
		{
			codigo: 68,
			descripcion: 'LA UNIÓN',
		},
		{
			codigo: 69,
			descripcion: 'XALPATLÁHUAC',
		},
		{
			codigo: 70,
			descripcion: 'XOCHIHUEHUETLÁN',
		},
		{
			codigo: 71,
			descripcion: 'XOCHISTLAHUACA',
		},
		{
			codigo: 72,
			descripcion: 'ZAPOTITLÁN TABLAS',
		},
		{
			codigo: 73,
			descripcion: 'ZIRÁNDARO',
		},
		{
			codigo: 74,
			descripcion: 'ZITLALA',
		},
		{
			codigo: 75,
			descripcion: 'EDUARDO NERI',
		},
	],
	13: [
		{
			codigo: 1,
			descripcion: 'ACATLÁN',
		},
		{
			codigo: 2,
			descripcion: 'ACAXOCHITLÁN',
		},
		{
			codigo: 3,
			descripcion: 'ACTOPAN',
		},
		{
			codigo: 4,
			descripcion: 'AGUA BLANCA DE ITURBIDE',
		},
		{
			codigo: 5,
			descripcion: 'AJACUBA',
		},
		{
			codigo: 6,
			descripcion: 'ALFAJAYUCAN',
		},
		{
			codigo: 7,
			descripcion: 'ALMOLOYA',
		},
		{
			codigo: 8,
			descripcion: 'APAN',
		},
		{
			codigo: 9,
			descripcion: 'EL ARENAL',
		},
		{
			codigo: 10,
			descripcion: 'ATITALAQUIA',
		},
		{
			codigo: 11,
			descripcion: 'ATLAPEXCO',
		},
		{
			codigo: 12,
			descripcion: 'ATOTONILCO EL GRANDE',
		},
		{
			codigo: 13,
			descripcion: 'ATOTONILCO DE TULA',
		},
		{
			codigo: 14,
			descripcion: 'CALNALI',
		},
		{
			codigo: 15,
			descripcion: 'CARDONAL',
		},
		{
			codigo: 16,
			descripcion: 'CUAUTEPEC DE HINOJOSA',
		},
		{
			codigo: 17,
			descripcion: 'CHAPANTONGO',
		},
		{
			codigo: 18,
			descripcion: 'CHAPULHUACÁN',
		},
		{
			codigo: 19,
			descripcion: 'CHILCUAUTLA',
		},
		{
			codigo: 20,
			descripcion: 'ELOXOCHITLÁN',
		},
		{
			codigo: 21,
			descripcion: 'EMILIANO ZAPATA',
		},
		{
			codigo: 22,
			descripcion: 'EPAZOYUCAN',
		},
		{
			codigo: 23,
			descripcion: 'FRANCISCO I. MADERO',
		},
		{
			codigo: 24,
			descripcion: 'HUASCA DE OCAMPO',
		},
		{
			codigo: 25,
			descripcion: 'HUAUTLA',
		},
		{
			codigo: 26,
			descripcion: 'HUAZALINGO',
		},
		{
			codigo: 27,
			descripcion: 'HUEHUETLA',
		},
		{
			codigo: 28,
			descripcion: 'HUEJUTLA DE REYES',
		},
		{
			codigo: 29,
			descripcion: 'HUICHAPAN',
		},
		{
			codigo: 30,
			descripcion: 'IXMIQUILPAN',
		},
		{
			codigo: 31,
			descripcion: 'JACALA DE LEDEZMA',
		},
		{
			codigo: 32,
			descripcion: 'JALTOCÁN',
		},
		{
			codigo: 33,
			descripcion: 'JUÁREZ HIDALGO',
		},
		{
			codigo: 34,
			descripcion: 'LOLOTLA',
		},
		{
			codigo: 35,
			descripcion: 'METEPEC',
		},
		{
			codigo: 36,
			descripcion: 'SAN AGUSTÍN METZQUITITLÁN',
		},
		{
			codigo: 37,
			descripcion: 'METZTITLÁN',
		},
		{
			codigo: 38,
			descripcion: 'MINERAL DEL CHICO',
		},
		{
			codigo: 39,
			descripcion: 'MINERAL DEL MONTE',
		},
		{
			codigo: 40,
			descripcion: 'LA MISIÓN',
		},
		{
			codigo: 41,
			descripcion: 'MIXQUIAHUALA DE JUÁREZ',
		},
		{
			codigo: 42,
			descripcion: 'MOLANGO DE ESCAMILLA',
		},
		{
			codigo: 43,
			descripcion: 'NICOLÁS FLORES',
		},
		{
			codigo: 44,
			descripcion: 'NOPALA DE VILLAGRÁN',
		},
		{
			codigo: 45,
			descripcion: 'OMITLÁN DE JUÁREZ',
		},
		{
			codigo: 46,
			descripcion: 'SAN FELIPE ORIZATLÁN',
		},
		{
			codigo: 47,
			descripcion: 'PACULA',
		},
		{
			codigo: 48,
			descripcion: 'PACHUCA DE SOTO',
		},
		{
			codigo: 49,
			descripcion: 'PISAFLORES',
		},
		{
			codigo: 50,
			descripcion: 'PROGRESO DE OBREGÓN',
		},
		{
			codigo: 51,
			descripcion: 'MINERAL DE LA REFORMA',
		},
		{
			codigo: 52,
			descripcion: 'SAN AGUSTÍN TLAXIACA',
		},
		{
			codigo: 53,
			descripcion: 'SAN BARTOLO TUTOTEPEC',
		},
		{
			codigo: 54,
			descripcion: 'SAN SALVADOR',
		},
		{
			codigo: 55,
			descripcion: 'SANTIAGO DE ANAYA',
		},
		{
			codigo: 56,
			descripcion: 'SANTIAGO TULANTEPEC DE LUGO GUERRERO',
		},
		{
			codigo: 57,
			descripcion: 'SINGUILUCAN',
		},
		{
			codigo: 58,
			descripcion: 'TASQUILLO',
		},
		{
			codigo: 59,
			descripcion: 'TECOZAUTLA',
		},
		{
			codigo: 60,
			descripcion: 'TENANGO DE DORIA',
		},
		{
			codigo: 61,
			descripcion: 'TEPEAPULCO',
		},
		{
			codigo: 62,
			descripcion: 'TEPEHUACÁN DE GUERRERO',
		},
		{
			codigo: 63,
			descripcion: 'TEPEJI DEL RÍO DE OCAMPO',
		},
		{
			codigo: 64,
			descripcion: 'TEPETITLÁN',
		},
		{
			codigo: 65,
			descripcion: 'TETEPANGO',
		},
		{
			codigo: 66,
			descripcion: 'VILLA DE TEZONTEPEC',
		},
		{
			codigo: 67,
			descripcion: 'TEZONTEPEC DE ALDAMA',
		},
		{
			codigo: 68,
			descripcion: 'TIANGUISTENGO',
		},
		{
			codigo: 69,
			descripcion: 'TIZAYUCA',
		},
		{
			codigo: 70,
			descripcion: 'TLAHUELILPAN',
		},
		{
			codigo: 71,
			descripcion: 'TLAHUILTEPA',
		},
		{
			codigo: 72,
			descripcion: 'TLANALAPA',
		},
		{
			codigo: 73,
			descripcion: 'TLANCHINOL',
		},
		{
			codigo: 74,
			descripcion: 'TLAXCOAPAN',
		},
		{
			codigo: 75,
			descripcion: 'TOLCAYUCA',
		},
		{
			codigo: 76,
			descripcion: 'TULA DE ALLENDE',
		},
		{
			codigo: 77,
			descripcion: 'TULANCINGO DE BRAVO',
		},
		{
			codigo: 78,
			descripcion: 'XOCHIATIPAN',
		},
		{
			codigo: 79,
			descripcion: 'XOCHICOATLÁN',
		},
		{
			codigo: 80,
			descripcion: 'YAHUALICA',
		},
		{
			codigo: 81,
			descripcion: 'ZACUALTIPÁN DE ÁNGELES',
		},
		{
			codigo: 82,
			descripcion: 'ZAPOTLÁN DE JUÁREZ',
		},
		{
			codigo: 83,
			descripcion: 'ZEMPOALA',
		},
		{
			codigo: 84,
			descripcion: 'ZIMAPÁN',
		},
	],
	14: [
		{
			codigo: 1,
			descripcion: 'ACATIC',
		},
		{
			codigo: 2,
			descripcion: 'ACATLÁN DE JUÁREZ',
		},
		{
			codigo: 3,
			descripcion: 'AHUALULCO DE MERCADO',
		},
		{
			codigo: 4,
			descripcion: 'AMACUECA',
		},
		{
			codigo: 5,
			descripcion: 'AMATITÁN',
		},
		{
			codigo: 6,
			descripcion: 'AMECA',
		},
		{
			codigo: 7,
			descripcion: 'SAN JUANITO DE ESCOBEDO',
		},
		{
			codigo: 8,
			descripcion: 'ARANDAS',
		},
		{
			codigo: 9,
			descripcion: 'EL ARENAL',
		},
		{
			codigo: 10,
			descripcion: 'ATEMAJAC DE BRIZUELA',
		},
		{
			codigo: 11,
			descripcion: 'ATENGO',
		},
		{
			codigo: 12,
			descripcion: 'ATENGUILLO',
		},
		{
			codigo: 13,
			descripcion: 'ATOTONILCO EL ALTO',
		},
		{
			codigo: 14,
			descripcion: 'ATOYAC',
		},
		{
			codigo: 15,
			descripcion: 'AUTLÁN DE NAVARRO',
		},
		{
			codigo: 16,
			descripcion: 'AYOTLÁN',
		},
		{
			codigo: 17,
			descripcion: 'AYUTLA',
		},
		{
			codigo: 18,
			descripcion: 'LA BARCA',
		},
		{
			codigo: 19,
			descripcion: 'BOLAÑOS',
		},
		{
			codigo: 20,
			descripcion: 'CABO CORRIENTES',
		},
		{
			codigo: 21,
			descripcion: 'CASIMIRO CASTILLO',
		},
		{
			codigo: 22,
			descripcion: 'CIHUATLÁN',
		},
		{
			codigo: 23,
			descripcion: 'CIUDAD GUZMAN',
		},
		{
			codigo: 24,
			descripcion: 'COCULA',
		},
		{
			codigo: 25,
			descripcion: 'COLOTLÁN',
		},
		{
			codigo: 26,
			descripcion: 'CONCEPCIÓN DE BUENOS AIRES',
		},
		{
			codigo: 27,
			descripcion: 'CUAUTITLÁN DE GARCÍA BARRAGÁN',
		},
		{
			codigo: 28,
			descripcion: 'CUAUTLA',
		},
		{
			codigo: 29,
			descripcion: 'CUQUÍO',
		},
		{
			codigo: 30,
			descripcion: 'CHAPALA',
		},
		{
			codigo: 31,
			descripcion: 'CHIMALTITÁN',
		},
		{
			codigo: 32,
			descripcion: 'CHIQUILISTLÁN',
		},
		{
			codigo: 33,
			descripcion: 'DEGOLLADO',
		},
		{
			codigo: 34,
			descripcion: 'EJUTLA',
		},
		{
			codigo: 35,
			descripcion: 'ENCARNACIÓN DE DÍAZ',
		},
		{
			codigo: 36,
			descripcion: 'ETZATLÁN',
		},
		{
			codigo: 37,
			descripcion: 'EL GRULLO',
		},
		{
			codigo: 38,
			descripcion: 'GUACHINANGO',
		},
		{
			codigo: 39,
			descripcion: 'GUADALAJARA',
		},
		{
			codigo: 40,
			descripcion: 'HOSTOTIPAQUILLO',
		},
		{
			codigo: 41,
			descripcion: 'HUEJÚCAR',
		},
		{
			codigo: 42,
			descripcion: 'HUEJUQUILLA EL ALTO',
		},
		{
			codigo: 43,
			descripcion: 'LA HUERTA',
		},
		{
			codigo: 44,
			descripcion: 'IXTLAHUACÁN DE LOS MEMBRILLOS',
		},
		{
			codigo: 45,
			descripcion: 'IXTLAHUACÁN DEL RÍO',
		},
		{
			codigo: 46,
			descripcion: 'JALOSTOTITLÁN',
		},
		{
			codigo: 47,
			descripcion: 'JAMAY',
		},
		{
			codigo: 48,
			descripcion: 'JESÚS MARÍA',
		},
		{
			codigo: 49,
			descripcion: 'JILOTLÁN DE LOS DOLORES',
		},
		{
			codigo: 50,
			descripcion: 'JOCOTEPEC',
		},
		{
			codigo: 51,
			descripcion: 'JUANACATLÁN',
		},
		{
			codigo: 52,
			descripcion: 'JUCHITLÁN',
		},
		{
			codigo: 53,
			descripcion: 'LAGOS DE MORENO',
		},
		{
			codigo: 54,
			descripcion: 'EL LIMÓN',
		},
		{
			codigo: 55,
			descripcion: 'MAGDALENA',
		},
		{
			codigo: 56,
			descripcion: 'SANTA MARÍA DEL ORO',
		},
		{
			codigo: 57,
			descripcion: 'LA MANZANILLA DE LA PAZ',
		},
		{
			codigo: 58,
			descripcion: 'MASCOTA',
		},
		{
			codigo: 59,
			descripcion: 'MAZAMITLA',
		},
		{
			codigo: 60,
			descripcion: 'MEXTICACÁN',
		},
		{
			codigo: 61,
			descripcion: 'MEZQUITIC',
		},
		{
			codigo: 62,
			descripcion: 'MIXTLÁN',
		},
		{
			codigo: 63,
			descripcion: 'OCOTLÁN',
		},
		{
			codigo: 64,
			descripcion: 'OJUELOS DE JALISCO',
		},
		{
			codigo: 65,
			descripcion: 'PIHUAMO',
		},
		{
			codigo: 66,
			descripcion: 'PONCITLÁN',
		},
		{
			codigo: 67,
			descripcion: 'PUERTO VALLARTA',
		},
		{
			codigo: 68,
			descripcion: 'VILLA PURIFICACIÓN',
		},
		{
			codigo: 69,
			descripcion: 'QUITUPAN',
		},
		{
			codigo: 70,
			descripcion: 'EL SALTO',
		},
		{
			codigo: 71,
			descripcion: 'SAN CRISTÓBAL DE LA BARRANCA',
		},
		{
			codigo: 72,
			descripcion: 'SAN DIEGO DE ALEJANDRÍA',
		},
		{
			codigo: 73,
			descripcion: 'SAN JUAN DE LOS LAGOS',
		},
		{
			codigo: 74,
			descripcion: 'SAN JULIÁN',
		},
		{
			codigo: 75,
			descripcion: 'SAN MARCOS',
		},
		{
			codigo: 76,
			descripcion: 'SAN MARTÍN DE BOLAÑOS',
		},
		{
			codigo: 77,
			descripcion: 'SAN MARTÍN HIDALGO',
		},
		{
			codigo: 78,
			descripcion: 'SAN MIGUEL EL ALTO',
		},
		{
			codigo: 79,
			descripcion: 'GÓMEZ FARÍAS',
		},
		{
			codigo: 80,
			descripcion: 'SAN SEBASTIÁN DEL OESTE',
		},
		{
			codigo: 81,
			descripcion: 'SANTA MARÍA DE LOS ÁNGELES',
		},
		{
			codigo: 82,
			descripcion: 'SAYULA',
		},
		{
			codigo: 83,
			descripcion: 'TALA',
		},
		{
			codigo: 84,
			descripcion: 'TALPA DE ALLENDE',
		},
		{
			codigo: 85,
			descripcion: 'TAMAZULA DE GORDIANO',
		},
		{
			codigo: 86,
			descripcion: 'TAPALPA',
		},
		{
			codigo: 87,
			descripcion: 'TECALITLÁN',
		},
		{
			codigo: 88,
			descripcion: 'TECOLOTLÁN',
		},
		{
			codigo: 89,
			descripcion: 'TECHALUTA DE MONTENEGRO',
		},
		{
			codigo: 90,
			descripcion: 'TENAMAXTLÁN',
		},
		{
			codigo: 91,
			descripcion: 'TEOCALTICHE',
		},
		{
			codigo: 92,
			descripcion: 'TEOCUITATLÁN DE CORONA',
		},
		{
			codigo: 93,
			descripcion: 'TEPATITLÁN DE MORELOS',
		},
		{
			codigo: 94,
			descripcion: 'TEQUILA',
		},
		{
			codigo: 95,
			descripcion: 'TEUCHITLÁN',
		},
		{
			codigo: 96,
			descripcion: 'TIZAPÁN EL ALTO',
		},
		{
			codigo: 97,
			descripcion: 'TLAJOMULCO DE ZÚÑIGA',
		},
		{
			codigo: 98,
			descripcion: 'SAN PEDRO TLAQUEPAQUE',
		},
		{
			codigo: 99,
			descripcion: 'TOLIMÁN',
		},
		{
			codigo: 100,
			descripcion: 'TOMATLÁN',
		},
		{
			codigo: 101,
			descripcion: 'TONALÁ',
		},
		{
			codigo: 102,
			descripcion: 'TONAYA',
		},
		{
			codigo: 103,
			descripcion: 'TONILA',
		},
		{
			codigo: 104,
			descripcion: 'TOTATICHE',
		},
		{
			codigo: 105,
			descripcion: 'TOTOTLÁN',
		},
		{
			codigo: 106,
			descripcion: 'TUXCACUESCO',
		},
		{
			codigo: 107,
			descripcion: 'TUXCUECA',
		},
		{
			codigo: 108,
			descripcion: 'TUXPAN',
		},
		{
			codigo: 109,
			descripcion: 'UNIÓN DE SAN ANTONIO',
		},
		{
			codigo: 110,
			descripcion: 'UNIÓN DE TULA',
		},
		{
			codigo: 111,
			descripcion: 'VALLE DE GUADALUPE',
		},
		{
			codigo: 112,
			descripcion: 'VALLE DE JUÁREZ',
		},
		{
			codigo: 113,
			descripcion: 'CIUDAD VENUSTIANO CARRANZA',
		},
		{
			codigo: 114,
			descripcion: 'VILLA CORONA',
		},
		{
			codigo: 115,
			descripcion: 'VILLA GUERRERO',
		},
		{
			codigo: 116,
			descripcion: 'VILLA HIDALGO',
		},
		{
			codigo: 117,
			descripcion: 'CAÑADAS DE OBREGÓN',
		},
		{
			codigo: 118,
			descripcion: 'YAHUALICA DE GONZÁLEZ GALLO',
		},
		{
			codigo: 119,
			descripcion: 'ZACOALCO DE TORRES',
		},
		{
			codigo: 120,
			descripcion: 'ZAPOPAN',
		},
		{
			codigo: 121,
			descripcion: 'ZAPOTILTIC',
		},
		{
			codigo: 122,
			descripcion: 'ZAPOTITLÁN DE VADILLO',
		},
		{
			codigo: 123,
			descripcion: 'ZAPOTLÁN DEL REY',
		},
		{
			codigo: 124,
			descripcion: 'ZAPOTLANEJO',
		},
	],
	15: [
		{
			codigo: 1,
			descripcion: 'ACAMBAY',
		},
		{
			codigo: 2,
			descripcion: 'ACOLMAN',
		},
		{
			codigo: 3,
			descripcion: 'ACULCO',
		},
		{
			codigo: 4,
			descripcion: 'ALMOLOYA DE ALQUISIRAS',
		},
		{
			codigo: 5,
			descripcion: 'ALMOLOYA DE JUÁREZ',
		},
		{
			codigo: 6,
			descripcion: 'ALMOLOYA DEL RÍO',
		},
		{
			codigo: 7,
			descripcion: 'AMANALCO',
		},
		{
			codigo: 8,
			descripcion: 'AMATEPEC',
		},
		{
			codigo: 9,
			descripcion: 'AMECAMECA',
		},
		{
			codigo: 10,
			descripcion: 'APAXCO',
		},
		{
			codigo: 11,
			descripcion: 'ATENCO',
		},
		{
			codigo: 12,
			descripcion: 'ATIZAPÁN',
		},
		{
			codigo: 13,
			descripcion: 'ATIZAPÁN DE ZARAGOZA',
		},
		{
			codigo: 14,
			descripcion: 'ATLACOMULCO',
		},
		{
			codigo: 15,
			descripcion: 'ATLAUTLA',
		},
		{
			codigo: 16,
			descripcion: 'AXAPUSCO',
		},
		{
			codigo: 17,
			descripcion: 'AYAPANGO',
		},
		{
			codigo: 18,
			descripcion: 'CALIMAYA',
		},
		{
			codigo: 19,
			descripcion: 'CAPULHUAC',
		},
		{
			codigo: 20,
			descripcion: 'COACALCO',
		},
		{
			codigo: 21,
			descripcion: 'COATEPEC HARINAS',
		},
		{
			codigo: 22,
			descripcion: 'COCOTITLÁN',
		},
		{
			codigo: 23,
			descripcion: 'COYOTEPEC',
		},
		{
			codigo: 24,
			descripcion: 'CUAUTITLÁN',
		},
		{
			codigo: 25,
			descripcion: 'CHALCO',
		},
		{
			codigo: 26,
			descripcion: 'CHAPA DE MOTA',
		},
		{
			codigo: 27,
			descripcion: 'CHAPULTEPEC',
		},
		{
			codigo: 28,
			descripcion: 'CHIAUTLA',
		},
		{
			codigo: 29,
			descripcion: 'CHICOLOAPAN',
		},
		{
			codigo: 30,
			descripcion: 'CHICONCUAC',
		},
		{
			codigo: 31,
			descripcion: 'CHIMALHUACÁN',
		},
		{
			codigo: 32,
			descripcion: 'DONATO GUERRA',
		},
		{
			codigo: 33,
			descripcion: 'ECATEPEC DE MORELOS',
		},
		{
			codigo: 34,
			descripcion: 'ECATZINGO',
		},
		{
			codigo: 35,
			descripcion: 'HUEHUETOCA',
		},
		{
			codigo: 36,
			descripcion: 'HUEYPOXTLA',
		},
		{
			codigo: 37,
			descripcion: 'HUIXQUILUCAN',
		},
		{
			codigo: 38,
			descripcion: 'ISIDRO FABELA',
		},
		{
			codigo: 39,
			descripcion: 'IXTAPALUCA',
		},
		{
			codigo: 40,
			descripcion: 'IXTAPAN DE LA SAL',
		},
		{
			codigo: 41,
			descripcion: 'IXTAPAN DEL ORO',
		},
		{
			codigo: 42,
			descripcion: 'IXTLAHUACA',
		},
		{
			codigo: 43,
			descripcion: 'JALATLACO',
		},
		{
			codigo: 44,
			descripcion: 'JALTENCO',
		},
		{
			codigo: 45,
			descripcion: 'JILOTEPEC',
		},
		{
			codigo: 46,
			descripcion: 'JILOTZINGO',
		},
		{
			codigo: 47,
			descripcion: 'JIQUIPILCO',
		},
		{
			codigo: 48,
			descripcion: 'JOCOTITLÁN',
		},
		{
			codigo: 49,
			descripcion: 'JOQUICINGO',
		},
		{
			codigo: 50,
			descripcion: 'JUCHITEPEC',
		},
		{
			codigo: 51,
			descripcion: 'LERMA',
		},
		{
			codigo: 52,
			descripcion: 'MALINALCO',
		},
		{
			codigo: 53,
			descripcion: 'MELCHOR OCAMPO',
		},
		{
			codigo: 54,
			descripcion: 'METEPEC',
		},
		{
			codigo: 55,
			descripcion: 'MEXICALTZINGO',
		},
		{
			codigo: 56,
			descripcion: 'MORELOS',
		},
		{
			codigo: 57,
			descripcion: 'NAUCALPAN DE JUÁREZ',
		},
		{
			codigo: 58,
			descripcion: 'NEZAHUALCÓYOTL',
		},
		{
			codigo: 59,
			descripcion: 'NEXTLALPAN',
		},
		{
			codigo: 60,
			descripcion: 'NICOLÁS ROMERO',
		},
		{
			codigo: 61,
			descripcion: 'NOPALTEPEC',
		},
		{
			codigo: 62,
			descripcion: 'OCOYOACAC',
		},
		{
			codigo: 63,
			descripcion: 'OCUILAN',
		},
		{
			codigo: 64,
			descripcion: 'EL ORO',
		},
		{
			codigo: 65,
			descripcion: 'OTUMBA',
		},
		{
			codigo: 66,
			descripcion: 'OTZOLOAPAN',
		},
		{
			codigo: 67,
			descripcion: 'OTZOLOTEPEC',
		},
		{
			codigo: 68,
			descripcion: 'OZUMBA',
		},
		{
			codigo: 69,
			descripcion: 'PAPALOTLA',
		},
		{
			codigo: 70,
			descripcion: 'LA PAZ',
		},
		{
			codigo: 71,
			descripcion: 'POLOTITLÁN',
		},
		{
			codigo: 72,
			descripcion: 'RAYÓN',
		},
		{
			codigo: 73,
			descripcion: 'SAN ANTONIO LA ISLA',
		},
		{
			codigo: 74,
			descripcion: 'SAN FELIPE DEL PROGRESO',
		},
		{
			codigo: 75,
			descripcion: 'SAN MARTÍN DE LAS PIRÁMIDES',
		},
		{
			codigo: 76,
			descripcion: 'SAN MATEO ATENCO',
		},
		{
			codigo: 77,
			descripcion: 'SAN SIMÓN DE GUERRERO',
		},
		{
			codigo: 78,
			descripcion: 'SANTO TOMÁS',
		},
		{
			codigo: 79,
			descripcion: 'SOYANIQUILPAN DE JUÁREZ',
		},
		{
			codigo: 80,
			descripcion: 'SULTEPEC',
		},
		{
			codigo: 81,
			descripcion: 'TECÁMAC',
		},
		{
			codigo: 82,
			descripcion: 'TEJUPILCO',
		},
		{
			codigo: 83,
			descripcion: 'TEMAMATLA',
		},
		{
			codigo: 84,
			descripcion: 'TEMASCALAPA',
		},
		{
			codigo: 85,
			descripcion: 'TEMASCALCINGO',
		},
		{
			codigo: 86,
			descripcion: 'TEMASCALTEPEC',
		},
		{
			codigo: 87,
			descripcion: 'TEMOAYA',
		},
		{
			codigo: 88,
			descripcion: 'TENANCINGO',
		},
		{
			codigo: 89,
			descripcion: 'TENANGO DEL AIRE',
		},
		{
			codigo: 90,
			descripcion: 'TENANGO DEL VALLE',
		},
		{
			codigo: 91,
			descripcion: 'TEOLOYUCAN',
		},
		{
			codigo: 92,
			descripcion: 'TEOTIHUACÁN',
		},
		{
			codigo: 93,
			descripcion: 'TEPETLAOXTOC',
		},
		{
			codigo: 94,
			descripcion: 'TEPETLIXPA',
		},
		{
			codigo: 95,
			descripcion: 'TEPOTZOTLÁN',
		},
		{
			codigo: 96,
			descripcion: 'TEQUIXQUIAC',
		},
		{
			codigo: 97,
			descripcion: 'TEXCALTITLÁN',
		},
		{
			codigo: 98,
			descripcion: 'TEXCALYACAC',
		},
		{
			codigo: 99,
			descripcion: 'TEXCOCO',
		},
		{
			codigo: 100,
			descripcion: 'TEZOYUCA',
		},
		{
			codigo: 101,
			descripcion: 'TIANGUISTENCO',
		},
		{
			codigo: 102,
			descripcion: 'TIMILPAN',
		},
		{
			codigo: 103,
			descripcion: 'TLALMANALCO',
		},
		{
			codigo: 104,
			descripcion: 'TLALNEPANTLA DE BAZ',
		},
		{
			codigo: 105,
			descripcion: 'TLATLAYA',
		},
		{
			codigo: 106,
			descripcion: 'TOLUCA',
		},
		{
			codigo: 107,
			descripcion: 'TONATICO',
		},
		{
			codigo: 108,
			descripcion: 'TULTEPEC',
		},
		{
			codigo: 109,
			descripcion: 'TULTITLÁN',
		},
		{
			codigo: 110,
			descripcion: 'VALLE DE BRAVO',
		},
		{
			codigo: 111,
			descripcion: 'VILLA DE ALLENDE',
		},
		{
			codigo: 112,
			descripcion: 'VILLA DEL CARBÓN',
		},
		{
			codigo: 113,
			descripcion: 'VILLA GUERRERO',
		},
		{
			codigo: 114,
			descripcion: 'VILLA VICTORIA',
		},
		{
			codigo: 115,
			descripcion: 'XONACATLÁN',
		},
		{
			codigo: 116,
			descripcion: 'ZACAZONAPAN',
		},
		{
			codigo: 117,
			descripcion: 'ZACUALPAN',
		},
		{
			codigo: 118,
			descripcion: 'ZINACANTEPEC',
		},
		{
			codigo: 119,
			descripcion: 'ZUMPAHUACÁN',
		},
		{
			codigo: 120,
			descripcion: 'ZUMPANGO',
		},
		{
			codigo: 121,
			descripcion: 'CUAUTITLÁN IZCALLI',
		},
		{
			codigo: 122,
			descripcion: 'VALLE DE CHALCO SOLIDARIDAD',
		},
	],
	16: [
		{
			codigo: 1,
			descripcion: 'ACUITZIO',
		},
		{
			codigo: 2,
			descripcion: 'AGUILILLA',
		},
		{
			codigo: 3,
			descripcion: 'ÁLVARO OBREGÓN',
		},
		{
			codigo: 4,
			descripcion: 'ANGAMACUTIRO',
		},
		{
			codigo: 5,
			descripcion: 'ANGANGUEO',
		},
		{
			codigo: 6,
			descripcion: 'APATZINGÁN',
		},
		{
			codigo: 7,
			descripcion: 'APORO',
		},
		{
			codigo: 8,
			descripcion: 'AQUILA',
		},
		{
			codigo: 9,
			descripcion: 'ARIO',
		},
		{
			codigo: 10,
			descripcion: 'ARTEAGA',
		},
		{
			codigo: 11,
			descripcion: 'BRISEÑAS',
		},
		{
			codigo: 12,
			descripcion: 'BUENAVISTA',
		},
		{
			codigo: 13,
			descripcion: 'CARÁCUARO',
		},
		{
			codigo: 14,
			descripcion: 'COAHUAYANA',
		},
		{
			codigo: 15,
			descripcion: 'COALCOMÁN DE VÁZQUEZ PALLARES',
		},
		{
			codigo: 16,
			descripcion: 'COENEO',
		},
		{
			codigo: 17,
			descripcion: 'CONTEPEC',
		},
		{
			codigo: 18,
			descripcion: 'COPÁNDARO',
		},
		{
			codigo: 19,
			descripcion: 'COTIJA',
		},
		{
			codigo: 20,
			descripcion: 'CUITZEO',
		},
		{
			codigo: 21,
			descripcion: 'CHARAPAN',
		},
		{
			codigo: 22,
			descripcion: 'CHARO',
		},
		{
			codigo: 23,
			descripcion: 'CHAVINDA',
		},
		{
			codigo: 24,
			descripcion: 'CHERÁN',
		},
		{
			codigo: 25,
			descripcion: 'CHILCHOTA',
		},
		{
			codigo: 26,
			descripcion: 'CHINICUILA',
		},
		{
			codigo: 27,
			descripcion: 'CHUCÁNDIRO',
		},
		{
			codigo: 28,
			descripcion: 'CHURINTZIO',
		},
		{
			codigo: 29,
			descripcion: 'CHURUMUCO',
		},
		{
			codigo: 30,
			descripcion: 'ECUANDUREO',
		},
		{
			codigo: 31,
			descripcion: 'EPITACIO HUERTA',
		},
		{
			codigo: 32,
			descripcion: 'ERONGARÍCUARO',
		},
		{
			codigo: 33,
			descripcion: 'GABRIEL ZAMORA',
		},
		{
			codigo: 34,
			descripcion: 'HIDALGO',
		},
		{
			codigo: 35,
			descripcion: 'LA HUACANA',
		},
		{
			codigo: 36,
			descripcion: 'HUANDACAREO',
		},
		{
			codigo: 37,
			descripcion: 'HUANIQUEO',
		},
		{
			codigo: 38,
			descripcion: 'HUETAMO',
		},
		{
			codigo: 39,
			descripcion: 'HUIRAMBA',
		},
		{
			codigo: 40,
			descripcion: 'INDAPARAPEO',
		},
		{
			codigo: 41,
			descripcion: 'IRIMBO',
		},
		{
			codigo: 42,
			descripcion: 'IXTLÁN',
		},
		{
			codigo: 43,
			descripcion: 'JACONA',
		},
		{
			codigo: 44,
			descripcion: 'JIMÉNEZ',
		},
		{
			codigo: 45,
			descripcion: 'JIQUILPAN',
		},
		{
			codigo: 46,
			descripcion: 'JUÁREZ',
		},
		{
			codigo: 47,
			descripcion: 'JUNGAPEO',
		},
		{
			codigo: 48,
			descripcion: 'LAGUNILLAS',
		},
		{
			codigo: 49,
			descripcion: 'MADERO',
		},
		{
			codigo: 50,
			descripcion: 'MARAVATÍO',
		},
		{
			codigo: 51,
			descripcion: 'MARCOS CASTELLANOS',
		},
		{
			codigo: 52,
			descripcion: 'LÁZARO CÁRDENAS',
		},
		{
			codigo: 53,
			descripcion: 'MORELIA',
		},
		{
			codigo: 54,
			descripcion: 'MORELOS',
		},
		{
			codigo: 55,
			descripcion: 'MÚGICA',
		},
		{
			codigo: 56,
			descripcion: 'NAHUATZEN',
		},
		{
			codigo: 57,
			descripcion: 'NOCUPÉTARO',
		},
		{
			codigo: 58,
			descripcion: 'NUEVO PARANGARICUTIRO',
		},
		{
			codigo: 59,
			descripcion: 'NUEVO URECHO',
		},
		{
			codigo: 60,
			descripcion: 'NUMARÁN',
		},
		{
			codigo: 61,
			descripcion: 'OCAMPO',
		},
		{
			codigo: 62,
			descripcion: 'PAJACUARÁN',
		},
		{
			codigo: 63,
			descripcion: 'PANINDÍCUARO',
		},
		{
			codigo: 64,
			descripcion: 'PARÁCUARO',
		},
		{
			codigo: 65,
			descripcion: 'PARACHO',
		},
		{
			codigo: 66,
			descripcion: 'PÁTZCUARO',
		},
		{
			codigo: 67,
			descripcion: 'PENJAMILLO',
		},
		{
			codigo: 68,
			descripcion: 'PERIBÁN',
		},
		{
			codigo: 69,
			descripcion: 'LA PIEDAD',
		},
		{
			codigo: 70,
			descripcion: 'PURÉPERO',
		},
		{
			codigo: 71,
			descripcion: 'PURUÁNDIRO',
		},
		{
			codigo: 72,
			descripcion: 'QUERÉNDARO',
		},
		{
			codigo: 73,
			descripcion: 'QUIROGA',
		},
		{
			codigo: 74,
			descripcion: 'COJUMATLÁN DE RÉGULES',
		},
		{
			codigo: 75,
			descripcion: 'LOS REYES',
		},
		{
			codigo: 76,
			descripcion: 'SAHUAYO',
		},
		{
			codigo: 77,
			descripcion: 'SAN LUCAS',
		},
		{
			codigo: 78,
			descripcion: 'SANTA ANA MAYA',
		},
		{
			codigo: 79,
			descripcion: 'SALVADOR ESCALANTE',
		},
		{
			codigo: 80,
			descripcion: 'SENGUIO',
		},
		{
			codigo: 81,
			descripcion: 'SUSUPUATO',
		},
		{
			codigo: 82,
			descripcion: 'TACÁMBARO',
		},
		{
			codigo: 83,
			descripcion: 'TANCÍTARO',
		},
		{
			codigo: 84,
			descripcion: 'TANGAMANDAPIO',
		},
		{
			codigo: 85,
			descripcion: 'TANGANCÍCUARO',
		},
		{
			codigo: 86,
			descripcion: 'TANHUATO',
		},
		{
			codigo: 87,
			descripcion: 'TARETAN',
		},
		{
			codigo: 88,
			descripcion: 'TARÍMBARO',
		},
		{
			codigo: 89,
			descripcion: 'TEPALCATEPEC',
		},
		{
			codigo: 90,
			descripcion: 'TINGAMBATO',
		},
		{
			codigo: 91,
			descripcion: 'TINGÜINDÍN',
		},
		{
			codigo: 92,
			descripcion: 'TIQUICHEO DE NICOLÁS ROMERO',
		},
		{
			codigo: 93,
			descripcion: 'TLALPUJAHUA',
		},
		{
			codigo: 94,
			descripcion: 'TLAZAZALCA',
		},
		{
			codigo: 95,
			descripcion: 'TOCUMBO',
		},
		{
			codigo: 96,
			descripcion: 'TUMBISCATÍO',
		},
		{
			codigo: 97,
			descripcion: 'TURICATO',
		},
		{
			codigo: 98,
			descripcion: 'TUXPAN',
		},
		{
			codigo: 99,
			descripcion: 'TUZANTLA',
		},
		{
			codigo: 100,
			descripcion: 'TZINTZUNTZAN',
		},
		{
			codigo: 101,
			descripcion: 'TZITZIO',
		},
		{
			codigo: 102,
			descripcion: 'URUAPAN',
		},
		{
			codigo: 103,
			descripcion: 'VENUSTIANO CARRANZA',
		},
		{
			codigo: 104,
			descripcion: 'VILLAMAR',
		},
		{
			codigo: 105,
			descripcion: 'VISTA HERMOSA',
		},
		{
			codigo: 106,
			descripcion: 'YURÉCUARO',
		},
		{
			codigo: 107,
			descripcion: 'ZACAPU',
		},
		{
			codigo: 108,
			descripcion: 'ZAMORA',
		},
		{
			codigo: 109,
			descripcion: 'ZINÁPARO',
		},
		{
			codigo: 110,
			descripcion: 'ZINAPÉCUARO',
		},
		{
			codigo: 111,
			descripcion: 'ZIRACUARETIRO',
		},
		{
			codigo: 112,
			descripcion: 'ZITÁCUARO',
		},
		{
			codigo: 113,
			descripcion: 'JOSÉ SIXTO VERDUZCO',
		},
	],
	17: [
		{
			codigo: 1,
			descripcion: 'AMACUZAC',
		},
		{
			codigo: 2,
			descripcion: 'ATLATLAHUCAN',
		},
		{
			codigo: 3,
			descripcion: 'AXOCHIAPAN',
		},
		{
			codigo: 4,
			descripcion: 'AYALA',
		},
		{
			codigo: 5,
			descripcion: 'COATLÁN DEL RÍO',
		},
		{
			codigo: 6,
			descripcion: 'CUAUTLA',
		},
		{
			codigo: 7,
			descripcion: 'CUERNAVACA',
		},
		{
			codigo: 8,
			descripcion: 'EMILIANO ZAPATA',
		},
		{
			codigo: 9,
			descripcion: 'HUITZILAC',
		},
		{
			codigo: 10,
			descripcion: 'JANTETELCO',
		},
		{
			codigo: 11,
			descripcion: 'JIUTEPEC',
		},
		{
			codigo: 12,
			descripcion: 'JOJUTLA',
		},
		{
			codigo: 13,
			descripcion: 'JONACATEPEC',
		},
		{
			codigo: 14,
			descripcion: 'MAZATEPEC',
		},
		{
			codigo: 15,
			descripcion: 'MIACATLÁN',
		},
		{
			codigo: 16,
			descripcion: 'OCUITUCO',
		},
		{
			codigo: 17,
			descripcion: 'PUENTE DE IXTLA',
		},
		{
			codigo: 18,
			descripcion: 'TEMIXCO',
		},
		{
			codigo: 19,
			descripcion: 'TEPALCINGO',
		},
		{
			codigo: 20,
			descripcion: 'TEPOZTLÁN',
		},
		{
			codigo: 21,
			descripcion: 'TETECALA',
		},
		{
			codigo: 22,
			descripcion: 'TETELA DEL VOLCÁN',
		},
		{
			codigo: 23,
			descripcion: 'TLALNEPANTLA',
		},
		{
			codigo: 24,
			descripcion: 'TLALTIZAPÁN DE ZAPATA',
		},
		{
			codigo: 25,
			descripcion: 'TLAQUILTENANGO',
		},
		{
			codigo: 26,
			descripcion: 'TLAYACAPAN',
		},
		{
			codigo: 27,
			descripcion: 'TOTOLAPAN',
		},
		{
			codigo: 28,
			descripcion: 'XOCHITEPEC',
		},
		{
			codigo: 29,
			descripcion: 'YAUTEPEC',
		},
		{
			codigo: 30,
			descripcion: 'YECAPIXTLA',
		},
		{
			codigo: 31,
			descripcion: 'ZACATEPEC',
		},
		{
			codigo: 32,
			descripcion: 'ZACUALPAN DE AMILPAS',
		},
		{
			codigo: 33,
			descripcion: 'TEMOAC',
		},
	],
	18: [
		{
			codigo: 1,
			descripcion: 'ACAPONETA',
		},
		{
			codigo: 2,
			descripcion: 'AHUACATLÁN',
		},
		{
			codigo: 3,
			descripcion: 'AMATLÁN DE CAÑAS',
		},
		{
			codigo: 4,
			descripcion: 'COMPOSTELA',
		},
		{
			codigo: 5,
			descripcion: 'HUAJICORI',
		},
		{
			codigo: 6,
			descripcion: 'IXTLÁN DEL RÍO',
		},
		{
			codigo: 7,
			descripcion: 'JALA',
		},
		{
			codigo: 8,
			descripcion: 'XALISCO',
		},
		{
			codigo: 9,
			descripcion: 'DEL NAYAR',
		},
		{
			codigo: 10,
			descripcion: 'ROSAMORADA',
		},
		{
			codigo: 11,
			descripcion: 'RUÍZ',
		},
		{
			codigo: 12,
			descripcion: 'SAN BLAS',
		},
		{
			codigo: 13,
			descripcion: 'SAN PEDRO LAGUNILLAS',
		},
		{
			codigo: 14,
			descripcion: 'SANTA MARÍA DEL ORO',
		},
		{
			codigo: 15,
			descripcion: 'SANTIAGO IXCUINTLA',
		},
		{
			codigo: 16,
			descripcion: 'TECUALA',
		},
		{
			codigo: 17,
			descripcion: 'TEPIC',
		},
		{
			codigo: 18,
			descripcion: 'TUXPAN',
		},
		{
			codigo: 19,
			descripcion: 'LA YESCA',
		},
		{
			codigo: 20,
			descripcion: 'BAHÍA DE BANDERAS',
		},
	],
	19: [
		{
			codigo: 1,
			descripcion: 'ABASOLO',
		},
		{
			codigo: 2,
			descripcion: 'AGUALEGUAS',
		},
		{
			codigo: 3,
			descripcion: 'LOS ALDAMAS',
		},
		{
			codigo: 4,
			descripcion: 'ALLENDE',
		},
		{
			codigo: 5,
			descripcion: 'ANÁHUAC',
		},
		{
			codigo: 6,
			descripcion: 'APODACA',
		},
		{
			codigo: 7,
			descripcion: 'ARAMBERRI',
		},
		{
			codigo: 8,
			descripcion: 'BUSTAMANTE',
		},
		{
			codigo: 9,
			descripcion: 'CADEREYTA JIMÉNEZ',
		},
		{
			codigo: 10,
			descripcion: 'EL CARMEN',
		},
		{
			codigo: 11,
			descripcion: 'CERRALVO',
		},
		{
			codigo: 12,
			descripcion: 'CIÉNEGA DE FLORES',
		},
		{
			codigo: 13,
			descripcion: 'CHINA',
		},
		{
			codigo: 14,
			descripcion: 'DOCTOR ARROYO',
		},
		{
			codigo: 15,
			descripcion: 'DOCTOR COSS',
		},
		{
			codigo: 16,
			descripcion: 'DOCTOR GONZÁLEZ',
		},
		{
			codigo: 17,
			descripcion: 'GALEANA',
		},
		{
			codigo: 18,
			descripcion: 'GARCÍA',
		},
		{
			codigo: 19,
			descripcion: 'SAN PEDRO GARZA GARCÍA',
		},
		{
			codigo: 20,
			descripcion: 'GENERAL BRAVO',
		},
		{
			codigo: 21,
			descripcion: 'GENERAL ESCOBEDO',
		},
		{
			codigo: 22,
			descripcion: 'GENERAL TERÁN',
		},
		{
			codigo: 23,
			descripcion: 'GENERAL TREVIÑO',
		},
		{
			codigo: 24,
			descripcion: 'GENERAL ZARAGOZA',
		},
		{
			codigo: 25,
			descripcion: 'GENERAL ZUAZUA',
		},
		{
			codigo: 26,
			descripcion: 'GUADALUPE',
		},
		{
			codigo: 27,
			descripcion: 'LOS HERRERAS',
		},
		{
			codigo: 28,
			descripcion: 'HIGUERAS',
		},
		{
			codigo: 29,
			descripcion: 'HUALAHUISES',
		},
		{
			codigo: 30,
			descripcion: 'ITURBIDE',
		},
		{
			codigo: 31,
			descripcion: 'JUÁREZ',
		},
		{
			codigo: 32,
			descripcion: 'LAMPAZOS DE NARANJO',
		},
		{
			codigo: 33,
			descripcion: 'LINARES',
		},
		{
			codigo: 34,
			descripcion: 'MARÍN',
		},
		{
			codigo: 35,
			descripcion: 'MELCHOR OCAMPO',
		},
		{
			codigo: 36,
			descripcion: 'MIER Y NORIEGA',
		},
		{
			codigo: 37,
			descripcion: 'MINA',
		},
		{
			codigo: 38,
			descripcion: 'MONTEMORELOS',
		},
		{
			codigo: 39,
			descripcion: 'MONTERREY',
		},
		{
			codigo: 40,
			descripcion: 'PARÁS',
		},
		{
			codigo: 41,
			descripcion: 'PESQUERÍA',
		},
		{
			codigo: 42,
			descripcion: 'LOS RAMONES',
		},
		{
			codigo: 43,
			descripcion: 'RAYONES',
		},
		{
			codigo: 44,
			descripcion: 'SABINAS HIDALGO',
		},
		{
			codigo: 45,
			descripcion: 'SALINAS VICTORIA',
		},
		{
			codigo: 46,
			descripcion: 'SAN NICOLÁS DE LOS GARZA',
		},
		{
			codigo: 47,
			descripcion: 'HIDALGO',
		},
		{
			codigo: 48,
			descripcion: 'SANTA CATARINA',
		},
		{
			codigo: 49,
			descripcion: 'SANTIAGO',
		},
		{
			codigo: 50,
			descripcion: 'VALLECILLO',
		},
		{
			codigo: 51,
			descripcion: 'VILLALDAMA',
		},
	],
	20: [
		{
			codigo: 1,
			descripcion: 'ABEJONES',
		},
		{
			codigo: 2,
			descripcion: 'ACATLÁN DE PÉREZ FIGUEROA',
		},
		{
			codigo: 3,
			descripcion: 'ASUNCIÓN CACALOTEPEC',
		},
		{
			codigo: 4,
			descripcion: 'ASUNCIÓN CUYOTEPEJI',
		},
		{
			codigo: 5,
			descripcion: 'ASUNCIÓN IXTALTEPEC',
		},
		{
			codigo: 6,
			descripcion: 'ASUNCIÓN NOCHIXTLÁN',
		},
		{
			codigo: 7,
			descripcion: 'ASUNCIÓN OCOTLÁN',
		},
		{
			codigo: 8,
			descripcion: 'ASUNCIÓN TLACOLULITA',
		},
		{
			codigo: 9,
			descripcion: 'AYOTZINTEPEC',
		},
		{
			codigo: 10,
			descripcion: 'EL BARRIO DE LA SOLEDAD',
		},
		{
			codigo: 11,
			descripcion: 'CALIHUALÁ',
		},
		{
			codigo: 12,
			descripcion: 'CANDELARIA LOXICHA',
		},
		{
			codigo: 13,
			descripcion: 'CIÉNEGA DE ZIMATLÁN',
		},
		{
			codigo: 14,
			descripcion: 'CIUDAD IXTEPEC',
		},
		{
			codigo: 15,
			descripcion: 'COATECAS ALTAS',
		},
		{
			codigo: 16,
			descripcion: 'COICOYÁN DE LAS FLORES',
		},
		{
			codigo: 17,
			descripcion: 'LA COMPAÑÍA',
		},
		{
			codigo: 18,
			descripcion: 'CONCEPCIÓN BUENAVISTA',
		},
		{
			codigo: 19,
			descripcion: 'CONCEPCIÓN PÁPALO',
		},
		{
			codigo: 20,
			descripcion: 'CONSTANCIA DEL ROSARIO',
		},
		{
			codigo: 21,
			descripcion: 'COSOLAPA',
		},
		{
			codigo: 22,
			descripcion: 'COSOLTEPEC',
		},
		{
			codigo: 23,
			descripcion: 'CUILÁPAM DE GUERRERO',
		},
		{
			codigo: 24,
			descripcion: 'CUYAMECALCO VILLA DE ZARAGOZA',
		},
		{
			codigo: 25,
			descripcion: 'CHAHUITES',
		},
		{
			codigo: 26,
			descripcion: 'CHALCATONGO DE HIDALGO',
		},
		{
			codigo: 27,
			descripcion: 'CHIQUIHUITLÁN DE BENITO JUÁREZ',
		},
		{
			codigo: 28,
			descripcion: 'EJUTLA DE CRESPO',
		},
		{
			codigo: 29,
			descripcion: 'ELOXOCHITLÁN DE FLORES MAGÓN',
		},
		{
			codigo: 30,
			descripcion: 'EL ESPINAL',
		},
		{
			codigo: 31,
			descripcion: 'TAMAZULÁPAM DEL ESPÍRITU SANTO',
		},
		{
			codigo: 32,
			descripcion: 'FRESNILLO DE TRUJANO',
		},
		{
			codigo: 33,
			descripcion: 'GUADALUPE ETLA',
		},
		{
			codigo: 34,
			descripcion: 'GUADALUPE DE RAMÍREZ',
		},
		{
			codigo: 35,
			descripcion: 'GUELATAO DE JUÁREZ',
		},
		{
			codigo: 36,
			descripcion: 'GUEVEA DE HUMBOLDT',
		},
		{
			codigo: 37,
			descripcion: 'MESONES HIDALGO',
		},
		{
			codigo: 38,
			descripcion: 'VILLA HIDALGO',
		},
		{
			codigo: 39,
			descripcion: 'HUAJUAPAN DE LEÓN',
		},
		{
			codigo: 40,
			descripcion: 'HUAUTEPEC',
		},
		{
			codigo: 41,
			descripcion: 'HUAUTLA DE JIMÉNEZ',
		},
		{
			codigo: 42,
			descripcion: 'IXTLÁN DE JUÁREZ',
		},
		{
			codigo: 43,
			descripcion: 'JUCHITÁN DE ZARAGOZA',
		},
		{
			codigo: 44,
			descripcion: 'LOMA BONITA',
		},
		{
			codigo: 45,
			descripcion: 'MAGDALENA APASCO',
		},
		{
			codigo: 46,
			descripcion: 'MAGDALENA JALTEPEC',
		},
		{
			codigo: 47,
			descripcion: 'SANTA MAGDALENA JICOTLÁN',
		},
		{
			codigo: 48,
			descripcion: 'MAGDALENA MIXTEPEC',
		},
		{
			codigo: 49,
			descripcion: 'MAGDALENA OCOTLÁN',
		},
		{
			codigo: 50,
			descripcion: 'MAGDALENA PEÑASCO',
		},
		{
			codigo: 51,
			descripcion: 'MAGDALENA TEITIPAC',
		},
		{
			codigo: 52,
			descripcion: 'MAGDALENA TEQUISISTLÁN',
		},
		{
			codigo: 53,
			descripcion: 'MAGDALENA TLACOTEPEC',
		},
		{
			codigo: 54,
			descripcion: 'MAGDALENA ZAHUATLÁN',
		},
		{
			codigo: 55,
			descripcion: 'MARISCALA DE JUÁREZ',
		},
		{
			codigo: 56,
			descripcion: 'MÁRTIRES DE TACUBAYA',
		},
		{
			codigo: 57,
			descripcion: 'MATÍAS ROMERO AVENDAÑO',
		},
		{
			codigo: 58,
			descripcion: 'MAZATLÁN VILLA DE FLORES',
		},
		{
			codigo: 59,
			descripcion: 'MIAHUATLÁN DE PORFIRIO DÍAZ',
		},
		{
			codigo: 60,
			descripcion: 'MIXISTLÁN DE LA REFORMA',
		},
		{
			codigo: 61,
			descripcion: 'MONJAS',
		},
		{
			codigo: 62,
			descripcion: 'NATIVIDAD',
		},
		{
			codigo: 63,
			descripcion: 'NAZARENO ETLA',
		},
		{
			codigo: 64,
			descripcion: 'NEJAPA DE MADERO',
		},
		{
			codigo: 65,
			descripcion: 'IXPANTEPEC NIEVES',
		},
		{
			codigo: 66,
			descripcion: 'SANTIAGO NILTEPEC',
		},
		{
			codigo: 67,
			descripcion: 'OAXACA DE JUÁREZ',
		},
		{
			codigo: 68,
			descripcion: 'OCOTLÁN DE MORELOS',
		},
		{
			codigo: 69,
			descripcion: 'LA PE',
		},
		{
			codigo: 70,
			descripcion: 'PINOTEPA DE DON LUIS',
		},
		{
			codigo: 71,
			descripcion: 'PLUMA HIDALGO',
		},
		{
			codigo: 72,
			descripcion: 'SAN JOSÉ DEL PROGRESO',
		},
		{
			codigo: 73,
			descripcion: 'PUTLA VILLA DE GUERRERO',
		},
		{
			codigo: 74,
			descripcion: 'SANTA CATARINA QUIOQUITANI',
		},
		{
			codigo: 75,
			descripcion: 'REFORMA DE PINEDA',
		},
		{
			codigo: 76,
			descripcion: 'LA REFORMA',
		},
		{
			codigo: 77,
			descripcion: 'REYES ETLA',
		},
		{
			codigo: 78,
			descripcion: 'ROJAS DE CUAUHTÉMOC',
		},
		{
			codigo: 79,
			descripcion: 'SALINA CRUZ',
		},
		{
			codigo: 80,
			descripcion: 'SAN AGUSTÍN AMATENGO',
		},
		{
			codigo: 81,
			descripcion: 'SAN AGUSTÍN ATENANGO',
		},
		{
			codigo: 82,
			descripcion: 'SAN AGUSTÍN CHAYUCO',
		},
		{
			codigo: 83,
			descripcion: 'SAN AGUSTÍN DE LAS JUNTAS',
		},
		{
			codigo: 84,
			descripcion: 'SAN AGUSTÍN ETLA',
		},
		{
			codigo: 85,
			descripcion: 'SAN AGUSTÍN LOXICHA',
		},
		{
			codigo: 86,
			descripcion: 'SAN AGUSTÍN TLACOTEPEC',
		},
		{
			codigo: 87,
			descripcion: 'SAN AGUSTÍN YATARENI',
		},
		{
			codigo: 88,
			descripcion: 'SAN ANDRÉS CABECERA NUEVA',
		},
		{
			codigo: 89,
			descripcion: 'SAN ANDRÉS DINICUITI',
		},
		{
			codigo: 90,
			descripcion: 'SAN ANDRÉS HUAXPALTEPEC',
		},
		{
			codigo: 91,
			descripcion: 'SAN ANDRÉS HUAYÁPAM',
		},
		{
			codigo: 92,
			descripcion: 'SAN ANDRÉS IXTLAHUACA',
		},
		{
			codigo: 93,
			descripcion: 'SAN ANDRÉS LAGUNAS',
		},
		{
			codigo: 94,
			descripcion: 'SAN ANDRÉS NUXIÑO',
		},
		{
			codigo: 95,
			descripcion: 'SAN ANDRÉS PAXTLÁN',
		},
		{
			codigo: 96,
			descripcion: 'SAN ANDRÉS SINAXTLA',
		},
		{
			codigo: 97,
			descripcion: 'SAN ANDRÉS SOLAGA',
		},
		{
			codigo: 98,
			descripcion: 'SAN ANDRÉS TEOTILÁLPAM',
		},
		{
			codigo: 99,
			descripcion: 'SAN ANDRÉS TEPETLAPA',
		},
		{
			codigo: 100,
			descripcion: 'SAN ANDRÉS YAÁ',
		},
		{
			codigo: 101,
			descripcion: 'SAN ANDRÉS ZABACHE',
		},
		{
			codigo: 102,
			descripcion: 'SAN ANDRÉS ZAUTLA',
		},
		{
			codigo: 103,
			descripcion: 'SAN ANTONINO CASTILLO VELASCO',
		},
		{
			codigo: 104,
			descripcion: 'SAN ANTONINO EL ALTO',
		},
		{
			codigo: 105,
			descripcion: 'SAN ANTONINO MONTE VERDE',
		},
		{
			codigo: 106,
			descripcion: 'SAN ANTONIO ACUTLA',
		},
		{
			codigo: 107,
			descripcion: 'SAN ANTONIO DE LA CAL',
		},
		{
			codigo: 108,
			descripcion: 'SAN ANTONIO HUITEPEC',
		},
		{
			codigo: 109,
			descripcion: 'SAN ANTONIO NANAHUATÍPAM',
		},
		{
			codigo: 110,
			descripcion: 'SAN ANTONIO SINICAHUA',
		},
		{
			codigo: 111,
			descripcion: 'SAN ANTONIO TEPETLAPA',
		},
		{
			codigo: 112,
			descripcion: 'SAN BALTAZAR CHICHICÁPAM',
		},
		{
			codigo: 113,
			descripcion: 'SAN BALTAZAR LOXICHA',
		},
		{
			codigo: 114,
			descripcion: 'SAN BALTAZAR YATZACHI EL BAJO',
		},
		{
			codigo: 115,
			descripcion: 'SAN BARTOLO COYOTEPEC',
		},
		{
			codigo: 116,
			descripcion: 'SAN BARTOLOMÉ AYAUTLA',
		},
		{
			codigo: 117,
			descripcion: 'SAN BARTOLOMÉ LOXICHA',
		},
		{
			codigo: 118,
			descripcion: 'SAN BARTOLOMÉ QUIALANA',
		},
		{
			codigo: 119,
			descripcion: 'SAN BARTOLOMÉ YUCUAÑE',
		},
		{
			codigo: 120,
			descripcion: 'SAN BARTOLOMÉ ZOOGOCHO',
		},
		{
			codigo: 121,
			descripcion: 'SAN BARTOLO SOYALTEPEC',
		},
		{
			codigo: 122,
			descripcion: 'SAN BARTOLO YAUTEPEC',
		},
		{
			codigo: 123,
			descripcion: 'SAN BERNARDO MIXTEPEC',
		},
		{
			codigo: 124,
			descripcion: 'SAN BLAS ATEMPA',
		},
		{
			codigo: 125,
			descripcion: 'SAN CARLOS YAUTEPEC',
		},
		{
			codigo: 126,
			descripcion: 'SAN CRISTÓBAL AMATLÁN',
		},
		{
			codigo: 127,
			descripcion: 'SAN CRISTÓBAL AMOLTEPEC',
		},
		{
			codigo: 128,
			descripcion: 'SAN CRISTÓBAL LACHIRIOAG',
		},
		{
			codigo: 129,
			descripcion: 'SAN CRISTÓBAL SUCHIXTLAHUACA',
		},
		{
			codigo: 130,
			descripcion: 'SAN DIONISIO DEL MAR',
		},
		{
			codigo: 131,
			descripcion: 'SAN DIONISIO OCOTEPEC',
		},
		{
			codigo: 132,
			descripcion: 'SAN DIONISIO OCOTLÁN',
		},
		{
			codigo: 133,
			descripcion: 'SAN ESTEBAN ATATLAHUCA',
		},
		{
			codigo: 134,
			descripcion: 'SAN FELIPE JALAPA DE DÍAZ',
		},
		{
			codigo: 135,
			descripcion: 'SAN FELIPE TEJALÁPAM',
		},
		{
			codigo: 136,
			descripcion: 'SAN FELIPE USILA',
		},
		{
			codigo: 137,
			descripcion: 'SAN FRANCISCO CAHUACUÁ',
		},
		{
			codigo: 138,
			descripcion: 'SAN FRANCISCO CAJONOS',
		},
		{
			codigo: 139,
			descripcion: 'SAN FRANCISCO CHAPULAPA',
		},
		{
			codigo: 140,
			descripcion: 'SAN FRANCISCO CHINDÚA',
		},
		{
			codigo: 141,
			descripcion: 'SAN FRANCISCO DEL MAR',
		},
		{
			codigo: 142,
			descripcion: 'SAN FRANCISCO HUEHUETLÁN',
		},
		{
			codigo: 143,
			descripcion: 'SAN FRANCISCO IXHUATÁN',
		},
		{
			codigo: 144,
			descripcion: 'SAN FRANCISCO JALTEPETONGO',
		},
		{
			codigo: 145,
			descripcion: 'SAN FRANCISCO LACHIGOLÓ',
		},
		{
			codigo: 146,
			descripcion: 'SAN FRANCISCO LOGUECHE',
		},
		{
			codigo: 147,
			descripcion: 'SAN FRANCISCO NUXAÑO',
		},
		{
			codigo: 148,
			descripcion: 'SAN FRANCISCO OZOLOTEPEC',
		},
		{
			codigo: 149,
			descripcion: 'SAN FRANCISCO SOLA',
		},
		{
			codigo: 150,
			descripcion: 'SAN FRANCISCO TELIXTLAHUACA',
		},
		{
			codigo: 151,
			descripcion: 'SAN FRANCISCO TEOPAN',
		},
		{
			codigo: 152,
			descripcion: 'SAN FRANCISCO TLAPANCINGO',
		},
		{
			codigo: 153,
			descripcion: 'SAN GABRIEL MIXTEPEC',
		},
		{
			codigo: 154,
			descripcion: 'SAN ILDEFONSO AMATLÁN',
		},
		{
			codigo: 155,
			descripcion: 'SAN ILDEFONSO SOLA',
		},
		{
			codigo: 156,
			descripcion: 'SAN ILDEFONSO VILLA ALTA',
		},
		{
			codigo: 157,
			descripcion: 'SAN JACINTO AMILPAS',
		},
		{
			codigo: 158,
			descripcion: 'SAN JACINTO TLACOTEPEC',
		},
		{
			codigo: 159,
			descripcion: 'SAN JERÓNIMO COATLÁN',
		},
		{
			codigo: 160,
			descripcion: 'SAN JERÓNIMO SILACAYOAPILLA',
		},
		{
			codigo: 161,
			descripcion: 'SAN JERÓNIMO SOSOLA',
		},
		{
			codigo: 162,
			descripcion: 'SAN JERÓNIMO TAVICHE',
		},
		{
			codigo: 163,
			descripcion: 'SAN JERÓNIMO TECÓATL',
		},
		{
			codigo: 164,
			descripcion: 'SAN JORGE NUCHITA',
		},
		{
			codigo: 165,
			descripcion: 'SAN JOSÉ AYUQUILA',
		},
		{
			codigo: 166,
			descripcion: 'SAN JOSÉ CHILTEPEC',
		},
		{
			codigo: 167,
			descripcion: 'SAN JOSÉ DEL PEÑASCO',
		},
		{
			codigo: 168,
			descripcion: 'SAN JOSÉ ESTANCIA GRANDE',
		},
		{
			codigo: 169,
			descripcion: 'SAN JOSÉ INDEPENDENCIA',
		},
		{
			codigo: 170,
			descripcion: 'SAN JOSÉ LACHIGUIRI',
		},
		{
			codigo: 171,
			descripcion: 'SAN JOSÉ TENANGO',
		},
		{
			codigo: 172,
			descripcion: 'SAN JUAN ACHIUTLA',
		},
		{
			codigo: 173,
			descripcion: 'SAN JUAN ATEPEC',
		},
		{
			codigo: 174,
			descripcion: 'ÁNIMAS TRUJANO',
		},
		{
			codigo: 175,
			descripcion: 'SAN JUAN BAUTISTA ATATLAHUCA',
		},
		{
			codigo: 176,
			descripcion: 'SAN JUAN BAUTISTA COIXTLAHUACA',
		},
		{
			codigo: 177,
			descripcion: 'SAN JUAN BAUTISTA CUICATLÁN',
		},
		{
			codigo: 178,
			descripcion: 'SAN JUAN BAUTISTA GUELACHE',
		},
		{
			codigo: 179,
			descripcion: 'SAN JUAN BAUTISTA JAYACATLÁN',
		},
		{
			codigo: 180,
			descripcion: 'SAN JUAN BAUTISTA LO DE SOTO',
		},
		{
			codigo: 181,
			descripcion: 'SAN JUAN BAUTISTA SUCHITEPEC',
		},
		{
			codigo: 182,
			descripcion: 'SAN JUAN BAUTISTA TLACOATZINTEPEC',
		},
		{
			codigo: 183,
			descripcion: 'SAN JUAN BAUTISTA TLACHICHILCO',
		},
		{
			codigo: 184,
			descripcion: 'SAN JUAN BAUTISTA TUXTEPEC',
		},
		{
			codigo: 185,
			descripcion: 'SAN JUAN CACAHUATEPEC',
		},
		{
			codigo: 186,
			descripcion: 'SAN JUAN CIENEGUILLA',
		},
		{
			codigo: 187,
			descripcion: 'SAN JUAN COATZÓSPAM',
		},
		{
			codigo: 188,
			descripcion: 'SAN JUAN COLORADO',
		},
		{
			codigo: 189,
			descripcion: 'SAN JUAN COMALTEPEC',
		},
		{
			codigo: 190,
			descripcion: 'SAN JUAN COTZOCÓN',
		},
		{
			codigo: 191,
			descripcion: 'SAN JUAN CHICOMEZÚCHIL',
		},
		{
			codigo: 192,
			descripcion: 'SAN JUAN CHILATECA',
		},
		{
			codigo: 193,
			descripcion: 'SAN JUAN DEL ESTADO',
		},
		{
			codigo: 194,
			descripcion: 'SAN JUAN DEL RÍO',
		},
		{
			codigo: 195,
			descripcion: 'SAN JUAN DIUXI',
		},
		{
			codigo: 196,
			descripcion: 'SAN JUAN EVANGELISTA ANALCO',
		},
		{
			codigo: 197,
			descripcion: 'SAN JUAN GUELAVÍA',
		},
		{
			codigo: 198,
			descripcion: 'SAN JUAN GUICHICOVI',
		},
		{
			codigo: 199,
			descripcion: 'SAN JUAN IHUALTEPEC',
		},
		{
			codigo: 200,
			descripcion: 'SAN JUAN JUQUILA MIXES',
		},
		{
			codigo: 201,
			descripcion: 'SAN JUAN JUQUILA VIJANOS',
		},
		{
			codigo: 202,
			descripcion: 'SAN JUAN LACHAO',
		},
		{
			codigo: 203,
			descripcion: 'SAN JUAN LACHIGALLA',
		},
		{
			codigo: 204,
			descripcion: 'SAN JUAN LAJARCIA',
		},
		{
			codigo: 205,
			descripcion: 'SAN JUAN LALANA',
		},
		{
			codigo: 206,
			descripcion: 'SAN JUAN DE LOS CUÉS',
		},
		{
			codigo: 207,
			descripcion: 'SAN JUAN MAZATLÁN',
		},
		{
			codigo: 208,
			descripcion: 'SAN JUAN MIXTEPEC JUXTLAHUACA',
		},
		{
			codigo: 209,
			descripcion: 'SAN JUAN MIXTEPEC MIAHUATLAN',
		},
		{
			codigo: 210,
			descripcion: 'SAN JUAN ÑUMÍ',
		},
		{
			codigo: 211,
			descripcion: 'SAN JUAN OZOLOTEPEC',
		},
		{
			codigo: 212,
			descripcion: 'SAN JUAN PETLAPA',
		},
		{
			codigo: 213,
			descripcion: 'SAN JUAN QUIAHIJE',
		},
		{
			codigo: 214,
			descripcion: 'SAN JUAN QUIOTEPEC',
		},
		{
			codigo: 215,
			descripcion: 'SAN JUAN SAYULTEPEC',
		},
		{
			codigo: 216,
			descripcion: 'SAN JUAN TABAÁ',
		},
		{
			codigo: 217,
			descripcion: 'SAN JUAN TAMAZOLA',
		},
		{
			codigo: 218,
			descripcion: 'SAN JUAN TEITA',
		},
		{
			codigo: 219,
			descripcion: 'SAN JUAN TEITIPAC',
		},
		{
			codigo: 220,
			descripcion: 'SAN JUAN TEPEUXILA',
		},
		{
			codigo: 221,
			descripcion: 'SAN JUAN TEPOSCOLULA',
		},
		{
			codigo: 222,
			descripcion: 'SAN JUAN YAEÉ',
		},
		{
			codigo: 223,
			descripcion: 'SAN JUAN YATZONA',
		},
		{
			codigo: 224,
			descripcion: 'SAN JUAN YUCUITA',
		},
		{
			codigo: 225,
			descripcion: 'SAN LORENZO',
		},
		{
			codigo: 226,
			descripcion: 'SAN LORENZO ALBARRADAS',
		},
		{
			codigo: 227,
			descripcion: 'SAN LORENZO CACAOTEPEC',
		},
		{
			codigo: 228,
			descripcion: 'SAN LORENZO CUAUNECUILTITLA',
		},
		{
			codigo: 229,
			descripcion: 'SAN LORENZO TEXMELÚCAN',
		},
		{
			codigo: 230,
			descripcion: 'SAN LORENZO VICTORIA',
		},
		{
			codigo: 231,
			descripcion: 'SAN LUCAS CAMOTLÁN',
		},
		{
			codigo: 232,
			descripcion: 'SAN LUCAS OJITLÁN',
		},
		{
			codigo: 233,
			descripcion: 'SAN LUCAS QUIAVINÍ',
		},
		{
			codigo: 234,
			descripcion: 'SAN LUCAS ZOQUIÁPAM',
		},
		{
			codigo: 235,
			descripcion: 'SAN LUIS AMATLÁN',
		},
		{
			codigo: 236,
			descripcion: 'SAN MARCIAL OZOLOTEPEC',
		},
		{
			codigo: 237,
			descripcion: 'SAN MARCOS ARTEAGA',
		},
		{
			codigo: 238,
			descripcion: 'SAN MARTÍN DE LOS CANSECOS',
		},
		{
			codigo: 239,
			descripcion: 'SAN MARTÍN HUAMELÚLPAM',
		},
		{
			codigo: 240,
			descripcion: 'SAN MARTÍN ITUNYOSO',
		},
		{
			codigo: 241,
			descripcion: 'SAN MARTÍN LACHILÁ',
		},
		{
			codigo: 242,
			descripcion: 'SAN MARTÍN PERAS',
		},
		{
			codigo: 243,
			descripcion: 'SAN MARTÍN TILCAJETE',
		},
		{
			codigo: 244,
			descripcion: 'SAN MARTÍN TOXPALAN',
		},
		{
			codigo: 245,
			descripcion: 'SAN MARTÍN ZACATEPEC',
		},
		{
			codigo: 246,
			descripcion: 'SAN MATEO CAJONOS',
		},
		{
			codigo: 247,
			descripcion: 'CAPULÁLPAM DE MÉNDEZ',
		},
		{
			codigo: 248,
			descripcion: 'SAN MATEO DEL MAR',
		},
		{
			codigo: 249,
			descripcion: 'SAN MATEO YOLOXOCHITLÁN',
		},
		{
			codigo: 250,
			descripcion: 'SAN MATEO ETLATONGO',
		},
		{
			codigo: 251,
			descripcion: 'SAN MATEO NEJÁPAM',
		},
		{
			codigo: 252,
			descripcion: 'SAN MATEO PEÑASCO',
		},
		{
			codigo: 253,
			descripcion: 'SAN MATEO PIÑAS',
		},
		{
			codigo: 254,
			descripcion: 'SAN MATEO RÍO HONDO',
		},
		{
			codigo: 255,
			descripcion: 'SAN MATEO SINDIHUI',
		},
		{
			codigo: 256,
			descripcion: 'SAN MATEO TLAPILTEPEC',
		},
		{
			codigo: 257,
			descripcion: 'SAN MELCHOR BETAZA',
		},
		{
			codigo: 258,
			descripcion: 'SAN MIGUEL ACHIUTLA',
		},
		{
			codigo: 259,
			descripcion: 'SAN MIGUEL AHUEHUETITLÁN',
		},
		{
			codigo: 260,
			descripcion: 'SAN MIGUEL ALOÁPAM',
		},
		{
			codigo: 261,
			descripcion: 'SAN MIGUEL AMATITLÁN',
		},
		{
			codigo: 262,
			descripcion: 'SAN MIGUEL AMATLÁN',
		},
		{
			codigo: 263,
			descripcion: 'SAN MIGUEL COATLÁN',
		},
		{
			codigo: 264,
			descripcion: 'SAN MIGUEL CHICAHUA',
		},
		{
			codigo: 265,
			descripcion: 'SAN MIGUEL CHIMALAPA',
		},
		{
			codigo: 266,
			descripcion: 'SAN MIGUEL DEL PUERTO',
		},
		{
			codigo: 267,
			descripcion: 'SAN MIGUEL DEL RÍO',
		},
		{
			codigo: 268,
			descripcion: 'SAN MIGUEL EJUTLA',
		},
		{
			codigo: 269,
			descripcion: 'SAN MIGUEL EL GRANDE',
		},
		{
			codigo: 270,
			descripcion: 'SAN MIGUEL HUAUTLA',
		},
		{
			codigo: 271,
			descripcion: 'SAN MIGUEL MIXTEPEC',
		},
		{
			codigo: 272,
			descripcion: 'SAN MIGUEL PANIXTLAHUACA',
		},
		{
			codigo: 273,
			descripcion: 'SAN MIGUEL PERAS',
		},
		{
			codigo: 274,
			descripcion: 'SAN MIGUEL PIEDRAS',
		},
		{
			codigo: 275,
			descripcion: 'SAN MIGUEL QUETZALTEPEC',
		},
		{
			codigo: 276,
			descripcion: 'SAN MIGUEL SANTA FLOR',
		},
		{
			codigo: 277,
			descripcion: 'VILLA SOLA DE VEGA',
		},
		{
			codigo: 278,
			descripcion: 'SAN MIGUEL SOYALTEPEC',
		},
		{
			codigo: 279,
			descripcion: 'SAN MIGUEL SUCHIXTEPEC',
		},
		{
			codigo: 280,
			descripcion: 'VILLA TALEA DE CASTRO',
		},
		{
			codigo: 281,
			descripcion: 'SAN MIGUEL TECOMATLÁN',
		},
		{
			codigo: 282,
			descripcion: 'SAN MIGUEL TENANGO',
		},
		{
			codigo: 283,
			descripcion: 'SAN MIGUEL TEQUIXTEPEC',
		},
		{
			codigo: 284,
			descripcion: 'SAN MIGUEL TILQUIÁPAM',
		},
		{
			codigo: 285,
			descripcion: 'SAN MIGUEL TLACAMAMA',
		},
		{
			codigo: 286,
			descripcion: 'SAN MIGUEL TLACOTEPEC',
		},
		{
			codigo: 287,
			descripcion: 'SAN MIGUEL TULANCINGO',
		},
		{
			codigo: 288,
			descripcion: 'SAN MIGUEL YOTAO',
		},
		{
			codigo: 289,
			descripcion: 'SAN NICOLÁS',
		},
		{
			codigo: 290,
			descripcion: 'SAN NICOLÁS HIDALGO',
		},
		{
			codigo: 291,
			descripcion: 'SAN PABLO COATLÁN',
		},
		{
			codigo: 292,
			descripcion: 'SAN PABLO CUATRO VENADOS',
		},
		{
			codigo: 293,
			descripcion: 'SAN PABLO ETLA',
		},
		{
			codigo: 294,
			descripcion: 'SAN PABLO HUITZO',
		},
		{
			codigo: 295,
			descripcion: 'SAN PABLO HUIXTEPEC',
		},
		{
			codigo: 296,
			descripcion: 'SAN PABLO MACUILTIANGUIS',
		},
		{
			codigo: 297,
			descripcion: 'SAN PABLO TIJALTEPEC',
		},
		{
			codigo: 298,
			descripcion: 'SAN PABLO VILLA DE MITLA',
		},
		{
			codigo: 299,
			descripcion: 'SAN PABLO YAGANIZA',
		},
		{
			codigo: 300,
			descripcion: 'SAN PEDRO AMUZGOS',
		},
		{
			codigo: 301,
			descripcion: 'SAN PEDRO APÓSTOL',
		},
		{
			codigo: 302,
			descripcion: 'SAN PEDRO ATOYAC',
		},
		{
			codigo: 303,
			descripcion: 'SAN PEDRO CAJONOS',
		},
		{
			codigo: 304,
			descripcion: 'SAN PEDRO COXCALTEPEC CÁNTAROS',
		},
		{
			codigo: 305,
			descripcion: 'SAN PEDRO COMITANCILLO',
		},
		{
			codigo: 306,
			descripcion: 'SAN PEDRO EL ALTO',
		},
		{
			codigo: 307,
			descripcion: 'SAN PEDRO HUAMELULA',
		},
		{
			codigo: 308,
			descripcion: 'SAN PEDRO HUILOTEPEC',
		},
		{
			codigo: 309,
			descripcion: 'SAN PEDRO IXCATLÁN',
		},
		{
			codigo: 310,
			descripcion: 'SAN PEDRO IXTLAHUACA',
		},
		{
			codigo: 311,
			descripcion: 'SAN PEDRO JALTEPETONGO',
		},
		{
			codigo: 312,
			descripcion: 'SAN PEDRO JICAYÁN',
		},
		{
			codigo: 313,
			descripcion: 'SAN PEDRO JOCOTIPAC',
		},
		{
			codigo: 314,
			descripcion: 'SAN PEDRO JUCHATENGO',
		},
		{
			codigo: 315,
			descripcion: 'SAN PEDRO MÁRTIR',
		},
		{
			codigo: 316,
			descripcion: 'SAN PEDRO MÁRTIR QUIECHAPA',
		},
		{
			codigo: 317,
			descripcion: 'SAN PEDRO MÁRTIR YUCUXACO',
		},
		{
			codigo: 318,
			descripcion: 'SAN PEDRO MIXTEPEC JUQUILA',
		},
		{
			codigo: 319,
			descripcion: 'SAN PEDRO MIXTEPEC MIAHUATLAN',
		},
		{
			codigo: 320,
			descripcion: 'SAN PEDRO MOLINOS',
		},
		{
			codigo: 321,
			descripcion: 'SAN PEDRO NOPALA',
		},
		{
			codigo: 322,
			descripcion: 'SAN PEDRO OCOPETATILLO',
		},
		{
			codigo: 323,
			descripcion: 'SAN PEDRO OCOTEPEC',
		},
		{
			codigo: 324,
			descripcion: 'SAN PEDRO POCHUTLA',
		},
		{
			codigo: 325,
			descripcion: 'SAN PEDRO QUIATONI',
		},
		{
			codigo: 326,
			descripcion: 'SAN PEDRO SOCHIÁPAM',
		},
		{
			codigo: 327,
			descripcion: 'SAN PEDRO TAPANATEPEC',
		},
		{
			codigo: 328,
			descripcion: 'SAN PEDRO TAVICHE',
		},
		{
			codigo: 329,
			descripcion: 'SAN PEDRO TEOZACOALCO',
		},
		{
			codigo: 330,
			descripcion: 'SAN PEDRO TEUTILA',
		},
		{
			codigo: 331,
			descripcion: 'SAN PEDRO TIDAÁ',
		},
		{
			codigo: 332,
			descripcion: 'SAN PEDRO TOPILTEPEC',
		},
		{
			codigo: 333,
			descripcion: 'SAN PEDRO TOTOLÁPAM',
		},
		{
			codigo: 334,
			descripcion: 'SAN PEDRO TUTUTEPEC',
		},
		{
			codigo: 335,
			descripcion: 'SAN PEDRO YANERI',
		},
		{
			codigo: 336,
			descripcion: 'SAN PEDRO YÓLOX',
		},
		{
			codigo: 337,
			descripcion: 'SAN PEDRO Y SAN PABLO AYUTLA',
		},
		{
			codigo: 338,
			descripcion: 'VILLA DE ETLA',
		},
		{
			codigo: 339,
			descripcion: 'SAN PEDRO Y SAN PABLO TEPOSCOLULA',
		},
		{
			codigo: 340,
			descripcion: 'SAN PEDRO Y SAN PABLO TEQUIXTEPEC',
		},
		{
			codigo: 341,
			descripcion: 'SAN PEDRO YUCUNAMA',
		},
		{
			codigo: 342,
			descripcion: 'SAN RAYMUNDO JALPAN',
		},
		{
			codigo: 343,
			descripcion: 'SAN SEBASTIÁN ABASOLO',
		},
		{
			codigo: 344,
			descripcion: 'SAN SEBASTIÁN COATLÁN',
		},
		{
			codigo: 345,
			descripcion: 'SAN SEBASTIÁN IXCAPA',
		},
		{
			codigo: 346,
			descripcion: 'SAN SEBASTIÁN NICANANDUTA',
		},
		{
			codigo: 347,
			descripcion: 'SAN SEBASTIÁN RÍO HONDO',
		},
		{
			codigo: 348,
			descripcion: 'SAN SEBASTIÁN TECOMAXTLAHUACA',
		},
		{
			codigo: 349,
			descripcion: 'SAN SEBASTIÁN TEITIPAC',
		},
		{
			codigo: 350,
			descripcion: 'SAN SEBASTIÁN TUTLA',
		},
		{
			codigo: 351,
			descripcion: 'SAN SIMÓN ALMOLONGAS',
		},
		{
			codigo: 352,
			descripcion: 'SAN SIMÓN ZAHUATLÁN',
		},
		{
			codigo: 353,
			descripcion: 'SANTA ANA',
		},
		{
			codigo: 354,
			descripcion: 'SANTA ANA ATEIXTLAHUACA',
		},
		{
			codigo: 355,
			descripcion: 'SANTA ANA CUAUHTÉMOC',
		},
		{
			codigo: 356,
			descripcion: 'SANTA ANA DEL VALLE',
		},
		{
			codigo: 357,
			descripcion: 'SANTA ANA TAVELA',
		},
		{
			codigo: 358,
			descripcion: 'SANTA ANA TLAPACOYAN',
		},
		{
			codigo: 359,
			descripcion: 'SANTA ANA YARENI',
		},
		{
			codigo: 360,
			descripcion: 'SANTA ANA ZEGACHE',
		},
		{
			codigo: 361,
			descripcion: 'SANTA CATALINA QUIERÍ',
		},
		{
			codigo: 362,
			descripcion: 'SANTA CATARINA CUIXTLA',
		},
		{
			codigo: 363,
			descripcion: 'SANTA CATARINA IXTEPEJI',
		},
		{
			codigo: 364,
			descripcion: 'SANTA CATARINA JUQUILA',
		},
		{
			codigo: 365,
			descripcion: 'SANTA CATARINA LACHATAO',
		},
		{
			codigo: 366,
			descripcion: 'SANTA CATARINA LOXICHA',
		},
		{
			codigo: 367,
			descripcion: 'SANTA CATARINA MECHOACÁN',
		},
		{
			codigo: 368,
			descripcion: 'SANTA CATARINA MINAS',
		},
		{
			codigo: 369,
			descripcion: 'SANTA CATARINA QUIANÉ',
		},
		{
			codigo: 370,
			descripcion: 'SANTA CATARINA TAYATA',
		},
		{
			codigo: 371,
			descripcion: 'SANTA CATARINA TICUÁ',
		},
		{
			codigo: 372,
			descripcion: 'SANTA CATARINA YOSONOTÚ',
		},
		{
			codigo: 373,
			descripcion: 'SANTA CATARINA ZAPOQUILA',
		},
		{
			codigo: 374,
			descripcion: 'SANTA CRUZ ACATEPEC',
		},
		{
			codigo: 375,
			descripcion: 'SANTA CRUZ AMILPAS',
		},
		{
			codigo: 376,
			descripcion: 'SANTA CRUZ DE BRAVO',
		},
		{
			codigo: 377,
			descripcion: 'SANTA CRUZ ITUNDUJIA',
		},
		{
			codigo: 378,
			descripcion: 'SANTA CRUZ MIXTEPEC',
		},
		{
			codigo: 379,
			descripcion: 'SANTA CRUZ NUNDACO',
		},
		{
			codigo: 380,
			descripcion: 'SANTA CRUZ PAPALUTLA',
		},
		{
			codigo: 381,
			descripcion: 'SANTA CRUZ TACACHE DE MINA',
		},
		{
			codigo: 382,
			descripcion: 'SANTA CRUZ TACAHUA',
		},
		{
			codigo: 383,
			descripcion: 'SANTA CRUZ TAYATA',
		},
		{
			codigo: 384,
			descripcion: 'SANTA CRUZ XITLA',
		},
		{
			codigo: 385,
			descripcion: 'SANTA CRUZ XOXOCOTLÁN',
		},
		{
			codigo: 386,
			descripcion: 'SANTA CRUZ ZENZONTEPEC',
		},
		{
			codigo: 387,
			descripcion: 'SANTA GERTRUDIS',
		},
		{
			codigo: 388,
			descripcion: 'SANTA INÉS DEL MONTE',
		},
		{
			codigo: 389,
			descripcion: 'SANTA INÉS YATZECHE',
		},
		{
			codigo: 390,
			descripcion: 'SANTA LUCÍA DEL CAMINO',
		},
		{
			codigo: 391,
			descripcion: 'SANTA LUCÍA MIAHUATLÁN',
		},
		{
			codigo: 392,
			descripcion: 'SANTA LUCÍA MONTEVERDE',
		},
		{
			codigo: 393,
			descripcion: 'SANTA LUCÍA OCOTLÁN',
		},
		{
			codigo: 394,
			descripcion: 'SANTA MARÍA ALOTEPEC',
		},
		{
			codigo: 395,
			descripcion: 'SANTA MARÍA APAZCO',
		},
		{
			codigo: 396,
			descripcion: 'SANTA MARÍA LA ASUNCIÓN',
		},
		{
			codigo: 397,
			descripcion: 'HEROICA CIUDAD DE TLAXIACO',
		},
		{
			codigo: 398,
			descripcion: 'AYOQUEZCO DE ALDAMA',
		},
		{
			codigo: 399,
			descripcion: 'SANTA MARÍA ATZOMPA',
		},
		{
			codigo: 400,
			descripcion: 'SANTA MARÍA CAMOTLÁN',
		},
		{
			codigo: 401,
			descripcion: 'SANTA MARÍA COLOTEPEC',
		},
		{
			codigo: 402,
			descripcion: 'SANTA MARÍA CORTIJO',
		},
		{
			codigo: 403,
			descripcion: 'SANTA MARÍA COYOTEPEC',
		},
		{
			codigo: 404,
			descripcion: 'SANTA MARÍA CHACHOÁPAM',
		},
		{
			codigo: 405,
			descripcion: 'VILLA DE CHILAPA DE DÍAZ',
		},
		{
			codigo: 406,
			descripcion: 'SANTA MARÍA CHILCHOTLA',
		},
		{
			codigo: 407,
			descripcion: 'SANTA MARÍA CHIMALAPA',
		},
		{
			codigo: 408,
			descripcion: 'SANTA MARÍA DEL ROSARIO',
		},
		{
			codigo: 409,
			descripcion: 'SANTA MARÍA DEL TULE',
		},
		{
			codigo: 410,
			descripcion: 'SANTA MARÍA ECATEPEC',
		},
		{
			codigo: 411,
			descripcion: 'SANTA MARÍA GUELACÉ',
		},
		{
			codigo: 412,
			descripcion: 'SANTA MARÍA GUIENAGATI',
		},
		{
			codigo: 413,
			descripcion: 'SANTA MARÍA HUATULCO',
		},
		{
			codigo: 414,
			descripcion: 'SANTA MARÍA HUAZOLOTITLÁN',
		},
		{
			codigo: 415,
			descripcion: 'SANTA MARÍA IPALAPA',
		},
		{
			codigo: 416,
			descripcion: 'SANTA MARÍA IXCATLÁN',
		},
		{
			codigo: 417,
			descripcion: 'SANTA MARÍA JACATEPEC',
		},
		{
			codigo: 418,
			descripcion: 'SANTA MARÍA JALAPA DEL MARQUÉS',
		},
		{
			codigo: 419,
			descripcion: 'SANTA MARÍA JALTIANGUIS',
		},
		{
			codigo: 420,
			descripcion: 'SANTA MARÍA LACHIXÍO',
		},
		{
			codigo: 421,
			descripcion: 'SANTA MARÍA MIXTEQUILLA',
		},
		{
			codigo: 422,
			descripcion: 'SANTA MARÍA NATIVITAS',
		},
		{
			codigo: 423,
			descripcion: 'SANTA MARÍA NDUAYACO',
		},
		{
			codigo: 424,
			descripcion: 'SANTA MARÍA OZOLOTEPEC',
		},
		{
			codigo: 425,
			descripcion: 'SANTA MARÍA PÁPALO',
		},
		{
			codigo: 426,
			descripcion: 'SANTA MARÍA PEÑOLES',
		},
		{
			codigo: 427,
			descripcion: 'SANTA MARÍA PETAPA',
		},
		{
			codigo: 428,
			descripcion: 'SANTA MARÍA QUIEGOLANI',
		},
		{
			codigo: 429,
			descripcion: 'SANTA MARÍA SOLA',
		},
		{
			codigo: 430,
			descripcion: 'SANTA MARÍA TATALTEPEC',
		},
		{
			codigo: 431,
			descripcion: 'SANTA MARÍA TECOMAVACA',
		},
		{
			codigo: 432,
			descripcion: 'SANTA MARÍA TEMAXCALAPA',
		},
		{
			codigo: 433,
			descripcion: 'SANTA MARÍA TEMAXCALTEPEC',
		},
		{
			codigo: 434,
			descripcion: 'SANTA MARÍA TEOPOXCO',
		},
		{
			codigo: 435,
			descripcion: 'SANTA MARÍA TEPANTLALI',
		},
		{
			codigo: 436,
			descripcion: 'SANTA MARÍA TEXCATITLÁN',
		},
		{
			codigo: 437,
			descripcion: 'SANTA MARÍA TLAHUITOLTEPEC',
		},
		{
			codigo: 438,
			descripcion: 'SANTA MARÍA TLALIXTAC',
		},
		{
			codigo: 439,
			descripcion: 'SANTA MARÍA TONAMECA',
		},
		{
			codigo: 440,
			descripcion: 'SANTA MARÍA TOTOLAPILLA',
		},
		{
			codigo: 441,
			descripcion: 'SANTA MARÍA XADANI',
		},
		{
			codigo: 442,
			descripcion: 'SANTA MARÍA YALINA',
		},
		{
			codigo: 443,
			descripcion: 'SANTA MARÍA YAVESÍA',
		},
		{
			codigo: 444,
			descripcion: 'SANTA MARÍA YOLOTEPEC',
		},
		{
			codigo: 445,
			descripcion: 'SANTA MARÍA YOSOYÚA',
		},
		{
			codigo: 446,
			descripcion: 'SANTA MARÍA YUCUHITI',
		},
		{
			codigo: 447,
			descripcion: 'SANTA MARÍA ZACATEPEC',
		},
		{
			codigo: 448,
			descripcion: 'SANTA MARÍA ZANIZA',
		},
		{
			codigo: 449,
			descripcion: 'SANTA MARÍA ZOQUITLÁN',
		},
		{
			codigo: 450,
			descripcion: 'SANTIAGO AMOLTEPEC',
		},
		{
			codigo: 451,
			descripcion: 'SANTIAGO APOALA',
		},
		{
			codigo: 452,
			descripcion: 'SANTIAGO APÓSTOL',
		},
		{
			codigo: 453,
			descripcion: 'SANTIAGO ASTATA',
		},
		{
			codigo: 454,
			descripcion: 'SANTIAGO ATITLÁN',
		},
		{
			codigo: 455,
			descripcion: 'SANTIAGO AYUQUILILLA',
		},
		{
			codigo: 456,
			descripcion: 'SANTIAGO CACALOXTEPEC',
		},
		{
			codigo: 457,
			descripcion: 'SANTIAGO CAMOTLÁN',
		},
		{
			codigo: 458,
			descripcion: 'SANTIAGO COMALTEPEC',
		},
		{
			codigo: 459,
			descripcion: 'SANTIAGO CHAZUMBA',
		},
		{
			codigo: 460,
			descripcion: 'SANTIAGO CHOÁPAM',
		},
		{
			codigo: 461,
			descripcion: 'SANTIAGO DEL RÍO',
		},
		{
			codigo: 462,
			descripcion: 'SANTIAGO HUAJOLOTITLÁN',
		},
		{
			codigo: 463,
			descripcion: 'SANTIAGO HUAUCLILLA',
		},
		{
			codigo: 464,
			descripcion: 'SANTIAGO IHUITLÁN PLUMAS',
		},
		{
			codigo: 465,
			descripcion: 'SANTIAGO IXCUINTEPEC',
		},
		{
			codigo: 466,
			descripcion: 'SANTIAGO IXTAYUTLA',
		},
		{
			codigo: 467,
			descripcion: 'SANTIAGO JAMILTEPEC',
		},
		{
			codigo: 468,
			descripcion: 'SANTIAGO JOCOTEPEC',
		},
		{
			codigo: 469,
			descripcion: 'SANTIAGO JUXTLAHUACA',
		},
		{
			codigo: 470,
			descripcion: 'SANTIAGO LACHIGUIRI',
		},
		{
			codigo: 471,
			descripcion: 'SANTIAGO LALOPA',
		},
		{
			codigo: 472,
			descripcion: 'SANTIAGO LAOLLAGA',
		},
		{
			codigo: 473,
			descripcion: 'SANTIAGO LAXOPA',
		},
		{
			codigo: 474,
			descripcion: 'SANTIAGO LLANO GRANDE',
		},
		{
			codigo: 475,
			descripcion: 'SANTIAGO MATATLÁN',
		},
		{
			codigo: 476,
			descripcion: 'SANTIAGO MILTEPEC',
		},
		{
			codigo: 477,
			descripcion: 'SANTIAGO MINAS',
		},
		{
			codigo: 478,
			descripcion: 'SANTIAGO NACALTEPEC',
		},
		{
			codigo: 479,
			descripcion: 'SANTIAGO NEJAPILLA',
		},
		{
			codigo: 480,
			descripcion: 'SANTIAGO NUNDICHE',
		},
		{
			codigo: 481,
			descripcion: 'SANTIAGO NUYOÓ',
		},
		{
			codigo: 482,
			descripcion: 'SANTIAGO PINOTEPA NACIONAL',
		},
		{
			codigo: 483,
			descripcion: 'SANTIAGO SUCHILQUITONGO',
		},
		{
			codigo: 484,
			descripcion: 'SANTIAGO TAMAZOLA',
		},
		{
			codigo: 485,
			descripcion: 'SANTIAGO TAPEXTLA',
		},
		{
			codigo: 486,
			descripcion: 'VILLA TEJÚPAM DE LA UNIÓN',
		},
		{
			codigo: 487,
			descripcion: 'SANTIAGO TENANGO',
		},
		{
			codigo: 488,
			descripcion: 'SANTIAGO TEPETLAPA',
		},
		{
			codigo: 489,
			descripcion: 'SANTIAGO TETEPEC',
		},
		{
			codigo: 490,
			descripcion: 'SANTIAGO TEXCALCINGO',
		},
		{
			codigo: 491,
			descripcion: 'SANTIAGO TEXTITLÁN',
		},
		{
			codigo: 492,
			descripcion: 'SANTIAGO TILANTONGO',
		},
		{
			codigo: 493,
			descripcion: 'SANTIAGO TILLO',
		},
		{
			codigo: 494,
			descripcion: 'SANTIAGO TLAZOYALTEPEC',
		},
		{
			codigo: 495,
			descripcion: 'SANTIAGO XANICA',
		},
		{
			codigo: 496,
			descripcion: 'SANTIAGO XIACUÍ',
		},
		{
			codigo: 497,
			descripcion: 'SANTIAGO YAITEPEC',
		},
		{
			codigo: 498,
			descripcion: 'SANTIAGO YAVEO',
		},
		{
			codigo: 499,
			descripcion: 'SANTIAGO YOLOMÉCATL',
		},
		{
			codigo: 500,
			descripcion: 'SANTIAGO YOSONDÚA',
		},
		{
			codigo: 501,
			descripcion: 'SANTIAGO YUCUYACHI',
		},
		{
			codigo: 502,
			descripcion: 'SANTIAGO ZACATEPEC',
		},
		{
			codigo: 503,
			descripcion: 'SANTIAGO ZOOCHILA',
		},
		{
			codigo: 504,
			descripcion: 'NUEVO ZOQUIÁPAM',
		},
		{
			codigo: 505,
			descripcion: 'SANTO DOMINGO INGENIO',
		},
		{
			codigo: 506,
			descripcion: 'SANTO DOMINGO ALBARRADAS',
		},
		{
			codigo: 507,
			descripcion: 'SANTO DOMINGO ARMENTA',
		},
		{
			codigo: 508,
			descripcion: 'SANTO DOMINGO CHIHUITÁN',
		},
		{
			codigo: 509,
			descripcion: 'SANTO DOMINGO DE MORELOS',
		},
		{
			codigo: 510,
			descripcion: 'SANTO DOMINGO IXCATLÁN',
		},
		{
			codigo: 511,
			descripcion: 'SANTO DOMINGO NUXAÁ',
		},
		{
			codigo: 512,
			descripcion: 'SANTO DOMINGO OZOLOTEPEC',
		},
		{
			codigo: 513,
			descripcion: 'SANTO DOMINGO PETAPA',
		},
		{
			codigo: 514,
			descripcion: 'SANTO DOMINGO ROAYAGA',
		},
		{
			codigo: 515,
			descripcion: 'SANTO DOMINGO TEHUANTEPEC',
		},
		{
			codigo: 516,
			descripcion: 'SANTO DOMINGO TEOJOMULCO',
		},
		{
			codigo: 517,
			descripcion: 'SANTO DOMINGO TEPUXTEPEC',
		},
		{
			codigo: 518,
			descripcion: 'SANTO DOMINGO TLATAYÁPAM',
		},
		{
			codigo: 519,
			descripcion: 'SANTO DOMINGO TOMALTEPEC',
		},
		{
			codigo: 520,
			descripcion: 'SANTO DOMINGO TONALÁ',
		},
		{
			codigo: 521,
			descripcion: 'SANTO DOMINGO TONALTEPEC',
		},
		{
			codigo: 522,
			descripcion: 'SANTO DOMINGO XAGACÍA',
		},
		{
			codigo: 523,
			descripcion: 'SANTO DOMINGO YANHUITLÁN',
		},
		{
			codigo: 524,
			descripcion: 'SANTO DOMINGO YODOHINO',
		},
		{
			codigo: 525,
			descripcion: 'SANTO DOMINGO ZANATEPEC',
		},
		{
			codigo: 526,
			descripcion: 'SANTOS REYES NOPALA',
		},
		{
			codigo: 527,
			descripcion: 'SANTOS REYES PÁPALO',
		},
		{
			codigo: 528,
			descripcion: 'SANTOS REYES TEPEJILLO',
		},
		{
			codigo: 529,
			descripcion: 'SANTOS REYES YUCUNÁ',
		},
		{
			codigo: 530,
			descripcion: 'SANTO TOMÁS JALIEZA',
		},
		{
			codigo: 531,
			descripcion: 'SANTO TOMÁS MAZALTEPEC',
		},
		{
			codigo: 532,
			descripcion: 'SANTO TOMÁS OCOTEPEC',
		},
		{
			codigo: 533,
			descripcion: 'SANTO TOMÁS TAMAZULAPAN',
		},
		{
			codigo: 534,
			descripcion: 'SAN VICENTE COATLÁN',
		},
		{
			codigo: 535,
			descripcion: 'SAN VICENTE LACHIXÍO',
		},
		{
			codigo: 536,
			descripcion: 'SAN VICENTE NUÑÚ',
		},
		{
			codigo: 537,
			descripcion: 'SILACAYOÁPAM',
		},
		{
			codigo: 538,
			descripcion: 'SITIO DE XITLAPEHUA',
		},
		{
			codigo: 539,
			descripcion: 'SOLEDAD ETLA',
		},
		{
			codigo: 540,
			descripcion: 'VILLA DE TAMAZULÁPAM DEL PROGRESO',
		},
		{
			codigo: 541,
			descripcion: 'TANETZE DE ZARAGOZA',
		},
		{
			codigo: 542,
			descripcion: 'TANICHE',
		},
		{
			codigo: 543,
			descripcion: 'TATALTEPEC DE VALDÉS',
		},
		{
			codigo: 544,
			descripcion: 'TEOCOCUILCO DE MARCOS PÉREZ',
		},
		{
			codigo: 545,
			descripcion: 'TEOTITLÁN DE FLORES MAGÓN',
		},
		{
			codigo: 546,
			descripcion: 'TEOTITLÁN DEL VALLE',
		},
		{
			codigo: 547,
			descripcion: 'TEOTONGO',
		},
		{
			codigo: 548,
			descripcion: 'TEPELMEME VILLA DE MORELOS',
		},
		{
			codigo: 549,
			descripcion: 'VILLA TEZOATLÁN DE SEGURA Y LUNA',
		},
		{
			codigo: 550,
			descripcion: 'SAN JERÓNIMO TLACOCHAHUAYA',
		},
		{
			codigo: 551,
			descripcion: 'TLACOLULA DE MATAMOROS',
		},
		{
			codigo: 552,
			descripcion: 'TLACOTEPEC PLUMAS',
		},
		{
			codigo: 553,
			descripcion: 'TLALIXTAC DE CABRERA',
		},
		{
			codigo: 554,
			descripcion: 'TOTONTEPEC VILLA DE MORELOS',
		},
		{
			codigo: 555,
			descripcion: 'TRINIDAD ZAACHILA',
		},
		{
			codigo: 556,
			descripcion: 'LA TRINIDAD VISTA HERMOSA',
		},
		{
			codigo: 557,
			descripcion: 'UNIÓN HIDALGO',
		},
		{
			codigo: 558,
			descripcion: 'VALERIO TRUJANO',
		},
		{
			codigo: 559,
			descripcion: 'SAN JUAN BAUTISTA VALLE NACIONAL',
		},
		{
			codigo: 560,
			descripcion: 'VILLA DÍAZ ORDAZ',
		},
		{
			codigo: 561,
			descripcion: 'YAXE',
		},
		{
			codigo: 562,
			descripcion: 'MAGDALENA YODOCONO DE PORFIRIO DÍAZ',
		},
		{
			codigo: 563,
			descripcion: 'YOGANA',
		},
		{
			codigo: 564,
			descripcion: 'YUTANDUCHI DE GUERRERO',
		},
		{
			codigo: 565,
			descripcion: 'VILLA DE ZAACHILA',
		},
		{
			codigo: 566,
			descripcion: 'SAN MATEO YUCUTINDOO',
		},
		{
			codigo: 567,
			descripcion: 'ZAPOTITLÁN LAGUNAS',
		},
		{
			codigo: 568,
			descripcion: 'ZAPOTITLÁN PALMAS',
		},
		{
			codigo: 569,
			descripcion: 'SANTA INÉS DE ZARAGOZA',
		},
		{
			codigo: 570,
			descripcion: 'ZIMATLÁN DE ÁLVAREZ',
		},
	],
	21: [
		{
			codigo: 1,
			descripcion: 'ACAJETE',
		},
		{
			codigo: 2,
			descripcion: 'ACATENO',
		},
		{
			codigo: 3,
			descripcion: 'ACATLÁN',
		},
		{
			codigo: 4,
			descripcion: 'ACATZINGO',
		},
		{
			codigo: 5,
			descripcion: 'ACTEOPAN',
		},
		{
			codigo: 6,
			descripcion: 'AHUACATLÁN',
		},
		{
			codigo: 7,
			descripcion: 'AHUATLÁN',
		},
		{
			codigo: 8,
			descripcion: 'AHUAZOTEPEC',
		},
		{
			codigo: 9,
			descripcion: 'AHUEHUETITLA',
		},
		{
			codigo: 10,
			descripcion: 'AJALPAN',
		},
		{
			codigo: 11,
			descripcion: 'ALBINO ZERTUCHE',
		},
		{
			codigo: 12,
			descripcion: 'ALJOJUCA',
		},
		{
			codigo: 13,
			descripcion: 'ALTEPEXI',
		},
		{
			codigo: 14,
			descripcion: 'AMIXTLÁN',
		},
		{
			codigo: 15,
			descripcion: 'AMOZOC',
		},
		{
			codigo: 16,
			descripcion: 'AQUIXTLA',
		},
		{
			codigo: 17,
			descripcion: 'ATEMPAN',
		},
		{
			codigo: 18,
			descripcion: 'ATEXCAL',
		},
		{
			codigo: 19,
			descripcion: 'ATLIXCO',
		},
		{
			codigo: 20,
			descripcion: 'ATOYATEMPAN',
		},
		{
			codigo: 21,
			descripcion: 'ATZALA',
		},
		{
			codigo: 22,
			descripcion: 'ATZITZIHUACÁN',
		},
		{
			codigo: 23,
			descripcion: 'ATZITZINTLA',
		},
		{
			codigo: 24,
			descripcion: 'AXUTLA',
		},
		{
			codigo: 25,
			descripcion: 'AYOTOXCO DE GUERRERO',
		},
		{
			codigo: 26,
			descripcion: 'CALPAN',
		},
		{
			codigo: 27,
			descripcion: 'CALTEPEC',
		},
		{
			codigo: 28,
			descripcion: 'CAMOCUAUTLA',
		},
		{
			codigo: 29,
			descripcion: 'CAXHUACAN',
		},
		{
			codigo: 30,
			descripcion: 'COATEPEC',
		},
		{
			codigo: 31,
			descripcion: 'COATZINGO',
		},
		{
			codigo: 32,
			descripcion: 'COHETZALA',
		},
		{
			codigo: 33,
			descripcion: 'COHUECAN',
		},
		{
			codigo: 34,
			descripcion: 'CORONANGO',
		},
		{
			codigo: 35,
			descripcion: 'COXCATLÁN',
		},
		{
			codigo: 36,
			descripcion: 'COYOMEAPAN',
		},
		{
			codigo: 37,
			descripcion: 'COYOTEPEC',
		},
		{
			codigo: 38,
			descripcion: 'CUAPIAXTLA DE MADERO',
		},
		{
			codigo: 39,
			descripcion: 'CUAUTEMPAN',
		},
		{
			codigo: 40,
			descripcion: 'CUAUTINCHÁN',
		},
		{
			codigo: 41,
			descripcion: 'CUAUTLANCINGO',
		},
		{
			codigo: 42,
			descripcion: 'CUAYUCA DE ANDRADE',
		},
		{
			codigo: 43,
			descripcion: 'CUETZALAN DEL PROGRESO',
		},
		{
			codigo: 44,
			descripcion: 'CUYOACO',
		},
		{
			codigo: 45,
			descripcion: 'CHALCHICOMULA DE SESMA',
		},
		{
			codigo: 46,
			descripcion: 'CHAPULCO',
		},
		{
			codigo: 47,
			descripcion: 'CHIAUTLA',
		},
		{
			codigo: 48,
			descripcion: 'CHIAUTZINGO',
		},
		{
			codigo: 49,
			descripcion: 'CHICONCUAUTLA',
		},
		{
			codigo: 50,
			descripcion: 'CHICHIQUILA',
		},
		{
			codigo: 51,
			descripcion: 'CHIETLA',
		},
		{
			codigo: 52,
			descripcion: 'CHIGMECATITLÁN',
		},
		{
			codigo: 53,
			descripcion: 'CHIGNAHUAPAN',
		},
		{
			codigo: 54,
			descripcion: 'CHIGNAUTLA',
		},
		{
			codigo: 55,
			descripcion: 'CHILA',
		},
		{
			codigo: 56,
			descripcion: 'CHILA DE LA SAL',
		},
		{
			codigo: 57,
			descripcion: 'CHILA HONEY',
		},
		{
			codigo: 58,
			descripcion: 'CHILCHOTLA',
		},
		{
			codigo: 59,
			descripcion: 'CHINANTLA',
		},
		{
			codigo: 60,
			descripcion: 'DOMINGO ARENAS',
		},
		{
			codigo: 61,
			descripcion: 'ELOXOCHITLÁN',
		},
		{
			codigo: 62,
			descripcion: 'EPATLÁN',
		},
		{
			codigo: 63,
			descripcion: 'ESPERANZA',
		},
		{
			codigo: 64,
			descripcion: 'FRANCISCO Z. MENA',
		},
		{
			codigo: 65,
			descripcion: 'GENERAL FELIPE ÁNGELES',
		},
		{
			codigo: 66,
			descripcion: 'GUADALUPE',
		},
		{
			codigo: 67,
			descripcion: 'GUADALUPE VICTORIA',
		},
		{
			codigo: 68,
			descripcion: 'HERMENEGILDO GALEANA',
		},
		{
			codigo: 69,
			descripcion: 'HUAQUECHULA',
		},
		{
			codigo: 70,
			descripcion: 'HUATLATLAUCA',
		},
		{
			codigo: 71,
			descripcion: 'HUAUCHINANGO',
		},
		{
			codigo: 72,
			descripcion: 'HUEHUETLA',
		},
		{
			codigo: 73,
			descripcion: 'HUEHUETLÁN EL CHICO',
		},
		{
			codigo: 74,
			descripcion: 'HUEJOTZINGO',
		},
		{
			codigo: 75,
			descripcion: 'HUEYAPAN',
		},
		{
			codigo: 76,
			descripcion: 'HUEYTAMALCO',
		},
		{
			codigo: 77,
			descripcion: 'HUEYTLALPAN',
		},
		{
			codigo: 78,
			descripcion: 'HUITZILAN DE SERDÁN',
		},
		{
			codigo: 79,
			descripcion: 'HUITZILTEPEC',
		},
		{
			codigo: 80,
			descripcion: 'IGNACIO ALLENDE',
		},
		{
			codigo: 81,
			descripcion: 'IXCAMILPA DE GUERRERO',
		},
		{
			codigo: 82,
			descripcion: 'IXCAQUIXTLA',
		},
		{
			codigo: 83,
			descripcion: 'IXTACAMAXTITLÁN',
		},
		{
			codigo: 84,
			descripcion: 'IXTEPEC',
		},
		{
			codigo: 85,
			descripcion: 'IZÚCAR DE MATAMOROS',
		},
		{
			codigo: 86,
			descripcion: 'JALPAN',
		},
		{
			codigo: 87,
			descripcion: 'JOLALPAN',
		},
		{
			codigo: 88,
			descripcion: 'JONOTLA',
		},
		{
			codigo: 89,
			descripcion: 'JOPALA',
		},
		{
			codigo: 90,
			descripcion: 'JUAN C. BONILLA',
		},
		{
			codigo: 91,
			descripcion: 'JUAN GALINDO',
		},
		{
			codigo: 92,
			descripcion: 'JUAN N. MÉNDEZ',
		},
		{
			codigo: 93,
			descripcion: 'LAFRAGUA',
		},
		{
			codigo: 94,
			descripcion: 'LIBRES',
		},
		{
			codigo: 95,
			descripcion: 'LA MAGDALENA TLATLAUQUITEPEC',
		},
		{
			codigo: 96,
			descripcion: 'MAZAPILTEPEC DE JUÁREZ',
		},
		{
			codigo: 97,
			descripcion: 'MIXTLA',
		},
		{
			codigo: 98,
			descripcion: 'MOLCAXAC',
		},
		{
			codigo: 99,
			descripcion: 'CAÑADA MORELOS',
		},
		{
			codigo: 100,
			descripcion: 'NAUPAN',
		},
		{
			codigo: 101,
			descripcion: 'NAUZONTLA',
		},
		{
			codigo: 102,
			descripcion: 'NEALTICAN',
		},
		{
			codigo: 103,
			descripcion: 'NICOLÁS BRAVO',
		},
		{
			codigo: 104,
			descripcion: 'NOPALUCAN',
		},
		{
			codigo: 105,
			descripcion: 'OCOTEPEC',
		},
		{
			codigo: 106,
			descripcion: 'OCOYUCAN',
		},
		{
			codigo: 107,
			descripcion: 'OLINTLA',
		},
		{
			codigo: 108,
			descripcion: 'ORIENTAL',
		},
		{
			codigo: 109,
			descripcion: 'PAHUATLÁN',
		},
		{
			codigo: 110,
			descripcion: 'PALMAR DE BRAVO',
		},
		{
			codigo: 111,
			descripcion: 'PANTEPEC',
		},
		{
			codigo: 112,
			descripcion: 'PETLALCINGO',
		},
		{
			codigo: 113,
			descripcion: 'PIAXTLA',
		},
		{
			codigo: 114,
			descripcion: 'PUEBLA',
		},
		{
			codigo: 115,
			descripcion: 'QUECHOLAC',
		},
		{
			codigo: 116,
			descripcion: 'QUIMIXTLÁN',
		},
		{
			codigo: 117,
			descripcion: 'RAFAEL LARA GRAJALES',
		},
		{
			codigo: 118,
			descripcion: 'LOS REYES DE JUÁREZ',
		},
		{
			codigo: 119,
			descripcion: 'SAN ANDRÉS CHOLULA',
		},
		{
			codigo: 120,
			descripcion: 'SAN ANTONIO CAÑADA',
		},
		{
			codigo: 121,
			descripcion: 'SAN DIEGO LA MESA TOCHIMILTZINGO',
		},
		{
			codigo: 122,
			descripcion: 'SAN FELIPE TEOTLALCINGO',
		},
		{
			codigo: 123,
			descripcion: 'SAN FELIPE TEPATLÁN',
		},
		{
			codigo: 124,
			descripcion: 'SAN GABRIEL CHILAC',
		},
		{
			codigo: 125,
			descripcion: 'SAN GREGORIO ATZOMPA',
		},
		{
			codigo: 126,
			descripcion: 'SAN JERÓNIMO TECUANIPAN',
		},
		{
			codigo: 127,
			descripcion: 'SAN JERÓNIMO XAYACATLÁN',
		},
		{
			codigo: 128,
			descripcion: 'SAN JOSÉ CHIAPA',
		},
		{
			codigo: 129,
			descripcion: 'SAN JOSÉ MIAHUATLÁN',
		},
		{
			codigo: 130,
			descripcion: 'SAN JUAN ATENCO',
		},
		{
			codigo: 131,
			descripcion: 'SAN JUAN ATZOMPA',
		},
		{
			codigo: 132,
			descripcion: 'SAN MARTÍN TEXMELUCAN',
		},
		{
			codigo: 133,
			descripcion: 'SAN MARTÍN TOTOLTEPEC',
		},
		{
			codigo: 134,
			descripcion: 'SAN MATÍAS TLALANCALECA',
		},
		{
			codigo: 135,
			descripcion: 'SAN MIGUEL IXITLÁN',
		},
		{
			codigo: 136,
			descripcion: 'SAN MIGUEL XOXTLA',
		},
		{
			codigo: 137,
			descripcion: 'SAN NICOLÁS BUENOS AIRES',
		},
		{
			codigo: 138,
			descripcion: 'SAN NICOLÁS DE LOS RANCHOS',
		},
		{
			codigo: 139,
			descripcion: 'SAN PABLO ANICANO',
		},
		{
			codigo: 140,
			descripcion: 'SAN PEDRO CHOLULA',
		},
		{
			codigo: 141,
			descripcion: 'SAN PEDRO YELOIXTLAHUACA',
		},
		{
			codigo: 142,
			descripcion: 'SAN SALVADOR EL SECO',
		},
		{
			codigo: 143,
			descripcion: 'SAN SALVADOR EL VERDE',
		},
		{
			codigo: 144,
			descripcion: 'SAN SALVADOR HUIXCOLOTLA',
		},
		{
			codigo: 145,
			descripcion: 'SAN SEBASTIÁN TLACOTEPEC',
		},
		{
			codigo: 146,
			descripcion: 'SANTA CATARINA TLALTEMPAN',
		},
		{
			codigo: 147,
			descripcion: 'SANTA INÉS AHUATEMPAN',
		},
		{
			codigo: 148,
			descripcion: 'SANTA ISABEL CHOLULA',
		},
		{
			codigo: 149,
			descripcion: 'SANTIAGO MIAHUATLÁN',
		},
		{
			codigo: 150,
			descripcion: 'HUEHUETLÁN EL GRANDE',
		},
		{
			codigo: 151,
			descripcion: 'SANTO TOMÁS HUEYOTLIPAN',
		},
		{
			codigo: 152,
			descripcion: 'SOLTEPEC',
		},
		{
			codigo: 153,
			descripcion: 'TECALI DE HERRERA',
		},
		{
			codigo: 154,
			descripcion: 'TECAMACHALCO',
		},
		{
			codigo: 155,
			descripcion: 'TECOMATLÁN',
		},
		{
			codigo: 156,
			descripcion: 'TEHUACÁN',
		},
		{
			codigo: 157,
			descripcion: 'TEHUITZINGO',
		},
		{
			codigo: 158,
			descripcion: 'TENAMPULCO',
		},
		{
			codigo: 159,
			descripcion: 'TEOPANTLÁN',
		},
		{
			codigo: 160,
			descripcion: 'TEOTLALCO',
		},
		{
			codigo: 161,
			descripcion: 'TEPANCO DE LÓPEZ',
		},
		{
			codigo: 162,
			descripcion: 'TEPANGO DE RODRÍGUEZ',
		},
		{
			codigo: 163,
			descripcion: 'TEPATLAXCO DE HIDALGO',
		},
		{
			codigo: 164,
			descripcion: 'TEPEACA',
		},
		{
			codigo: 165,
			descripcion: 'TEPEMAXALCO',
		},
		{
			codigo: 166,
			descripcion: 'TEPEOJUMA',
		},
		{
			codigo: 167,
			descripcion: 'TEPETZINTLA',
		},
		{
			codigo: 168,
			descripcion: 'TEPEXCO',
		},
		{
			codigo: 169,
			descripcion: 'TEPEXI DE RODRÍGUEZ',
		},
		{
			codigo: 170,
			descripcion: 'TEPEYAHUALCO',
		},
		{
			codigo: 171,
			descripcion: 'TEPEYAHUALCO DE CUAUHTÉMOC',
		},
		{
			codigo: 172,
			descripcion: 'TETELA DE OCAMPO',
		},
		{
			codigo: 173,
			descripcion: 'TETELES DE AVILA CASTILLO',
		},
		{
			codigo: 174,
			descripcion: 'TEZIUTLÁN',
		},
		{
			codigo: 175,
			descripcion: 'TIANGUISMANALCO',
		},
		{
			codigo: 176,
			descripcion: 'TILAPA',
		},
		{
			codigo: 177,
			descripcion: 'TLACOTEPEC DE BENITO JUÁREZ',
		},
		{
			codigo: 178,
			descripcion: 'TLACUILOTEPEC',
		},
		{
			codigo: 179,
			descripcion: 'TLACHICHUCA',
		},
		{
			codigo: 180,
			descripcion: 'TLAHUAPAN',
		},
		{
			codigo: 181,
			descripcion: 'TLALTENANGO',
		},
		{
			codigo: 182,
			descripcion: 'TLANEPANTLA',
		},
		{
			codigo: 183,
			descripcion: 'TLAOLA',
		},
		{
			codigo: 184,
			descripcion: 'TLAPACOYA',
		},
		{
			codigo: 185,
			descripcion: 'TLAPANALÁ',
		},
		{
			codigo: 186,
			descripcion: 'TLATLAUQUITEPEC',
		},
		{
			codigo: 187,
			descripcion: 'TLAXCO',
		},
		{
			codigo: 188,
			descripcion: 'TOCHIMILCO',
		},
		{
			codigo: 189,
			descripcion: 'TOCHTEPEC',
		},
		{
			codigo: 190,
			descripcion: 'TOTOLTEPEC DE GUERRERO',
		},
		{
			codigo: 191,
			descripcion: 'TULCINGO',
		},
		{
			codigo: 192,
			descripcion: 'TUZAMAPAN DE GALEANA',
		},
		{
			codigo: 193,
			descripcion: 'TZICATLACOYAN',
		},
		{
			codigo: 194,
			descripcion: 'VENUSTIANO CARRANZA',
		},
		{
			codigo: 195,
			descripcion: 'VICENTE GUERRERO',
		},
		{
			codigo: 196,
			descripcion: 'XAYACATLÁN DE BRAVO',
		},
		{
			codigo: 197,
			descripcion: 'XICOTEPEC',
		},
		{
			codigo: 198,
			descripcion: 'XICOTLÁN',
		},
		{
			codigo: 199,
			descripcion: 'XIUTETELCO',
		},
		{
			codigo: 200,
			descripcion: 'XOCHIAPULCO',
		},
		{
			codigo: 201,
			descripcion: 'XOCHILTEPEC',
		},
		{
			codigo: 202,
			descripcion: 'XOCHITLÁN DE VICENTE SUÁREZ',
		},
		{
			codigo: 203,
			descripcion: 'XOCHITLÁN TODOS SANTOS',
		},
		{
			codigo: 204,
			descripcion: 'YAONÁHUAC',
		},
		{
			codigo: 205,
			descripcion: 'YEHUALTEPEC',
		},
		{
			codigo: 206,
			descripcion: 'ZACAPALA',
		},
		{
			codigo: 207,
			descripcion: 'ZACAPOAXTLA',
		},
		{
			codigo: 208,
			descripcion: 'ZACATLÁN',
		},
		{
			codigo: 209,
			descripcion: 'ZAPOTITLÁN',
		},
		{
			codigo: 210,
			descripcion: 'ZAPOTITLÁN DE MÉNDEZ',
		},
		{
			codigo: 211,
			descripcion: 'ZARAGOZA',
		},
		{
			codigo: 212,
			descripcion: 'ZAUTLA',
		},
		{
			codigo: 213,
			descripcion: 'ZIHUATEUTLA',
		},
		{
			codigo: 214,
			descripcion: 'ZINACATEPEC',
		},
		{
			codigo: 215,
			descripcion: 'ZONGOZOTLA',
		},
		{
			codigo: 216,
			descripcion: 'ZOQUIAPAN',
		},
		{
			codigo: 217,
			descripcion: 'ZOQUITLÁN',
		},
	],
	22: [
		{
			codigo: 1,
			descripcion: 'AMEALCO DE BONFIL',
		},
		{
			codigo: 2,
			descripcion: 'PINAL DE AMOLES',
		},
		{
			codigo: 3,
			descripcion: 'ARROYO SECO',
		},
		{
			codigo: 4,
			descripcion: 'CADEREYTA DE MONTES',
		},
		{
			codigo: 5,
			descripcion: 'COLÓN',
		},
		{
			codigo: 6,
			descripcion: 'CORREGIDORA',
		},
		{
			codigo: 7,
			descripcion: 'EZEQUIEL MONTES',
		},
		{
			codigo: 8,
			descripcion: 'HUIMILPAN',
		},
		{
			codigo: 9,
			descripcion: 'JALPAN DE SERRA',
		},
		{
			codigo: 10,
			descripcion: 'LANDA DE MATAMOROS',
		},
		{
			codigo: 11,
			descripcion: 'EL MARQUÉS',
		},
		{
			codigo: 12,
			descripcion: 'PEDRO ESCOBEDO',
		},
		{
			codigo: 13,
			descripcion: 'PEÑAMILLER',
		},
		{
			codigo: 14,
			descripcion: 'QUERÉTARO',
		},
		{
			codigo: 15,
			descripcion: 'SAN JOAQUÍN',
		},
		{
			codigo: 16,
			descripcion: 'SAN JUAN DEL RÍO',
		},
		{
			codigo: 17,
			descripcion: 'TEQUISQUIAPAN',
		},
		{
			codigo: 18,
			descripcion: 'TOLIMÁN',
		},
	],
	23: [
		{
			codigo: 1,
			descripcion: 'COZUMEL',
		},
		{
			codigo: 2,
			descripcion: 'FELIPE CARRILLO PUERTO',
		},
		{
			codigo: 3,
			descripcion: 'ISLA MUJERES',
		},
		{
			codigo: 4,
			descripcion: 'OTHÓN P. BLANCO',
		},
		{
			codigo: 5,
			descripcion: 'BENITO JUÁREZ',
		},
		{
			codigo: 6,
			descripcion: 'JOSÉ MARÍA MORELOS',
		},
		{
			codigo: 7,
			descripcion: 'LÁZARO CÁRDENAS',
		},
		{
			codigo: 8,
			descripcion: 'SOLIDARIDAD',
		},
	],
	24: [
		{
			codigo: 1,
			descripcion: 'AHUALULCO',
		},
		{
			codigo: 2,
			descripcion: 'ALAQUINES',
		},
		{
			codigo: 3,
			descripcion: 'AQUISMÓN',
		},
		{
			codigo: 4,
			descripcion: 'ARMADILLO DE LOS INFANTE',
		},
		{
			codigo: 5,
			descripcion: 'CÁRDENAS',
		},
		{
			codigo: 6,
			descripcion: 'CATORCE',
		},
		{
			codigo: 7,
			descripcion: 'CEDRAL',
		},
		{
			codigo: 8,
			descripcion: 'CERRITOS',
		},
		{
			codigo: 9,
			descripcion: 'CERRO DE SAN PEDRO',
		},
		{
			codigo: 10,
			descripcion: 'CIUDAD DEL MAÍZ',
		},
		{
			codigo: 11,
			descripcion: 'CIUDAD FERNÁNDEZ',
		},
		{
			codigo: 12,
			descripcion: 'TANCANHUITZ DE SANTOS',
		},
		{
			codigo: 13,
			descripcion: 'CIUDAD VALLES',
		},
		{
			codigo: 14,
			descripcion: 'COXCATLÁN',
		},
		{
			codigo: 15,
			descripcion: 'CHARCAS',
		},
		{
			codigo: 16,
			descripcion: 'ÉBANO',
		},
		{
			codigo: 17,
			descripcion: 'GUADALCÁZAR',
		},
		{
			codigo: 18,
			descripcion: 'HUEHUETLÁN',
		},
		{
			codigo: 19,
			descripcion: 'LAGUNILLAS',
		},
		{
			codigo: 20,
			descripcion: 'MATEHUALA',
		},
		{
			codigo: 21,
			descripcion: 'MEXQUITIC DE CARMONA',
		},
		{
			codigo: 22,
			descripcion: 'MOCTEZUMA',
		},
		{
			codigo: 23,
			descripcion: 'RAYÓN',
		},
		{
			codigo: 24,
			descripcion: 'RIOVERDE',
		},
		{
			codigo: 25,
			descripcion: 'SALINAS',
		},
		{
			codigo: 26,
			descripcion: 'SAN ANTONIO',
		},
		{
			codigo: 27,
			descripcion: 'SAN CIRO DE ACOSTA',
		},
		{
			codigo: 28,
			descripcion: 'SAN LUIS POTOSÍ',
		},
		{
			codigo: 29,
			descripcion: 'SAN MARTÍN CHALCHICUAUTLA',
		},
		{
			codigo: 30,
			descripcion: 'SAN NICOLÁS TOLENTINO',
		},
		{
			codigo: 31,
			descripcion: 'SANTA CATARINA',
		},
		{
			codigo: 32,
			descripcion: 'SANTA MARÍA DEL RÍO',
		},
		{
			codigo: 33,
			descripcion: 'SANTO DOMINGO',
		},
		{
			codigo: 34,
			descripcion: 'SAN VICENTE TANCUAYALAB',
		},
		{
			codigo: 35,
			descripcion: 'SOLEDAD DE GRACIANO SÁNCHEZ',
		},
		{
			codigo: 36,
			descripcion: 'TAMASOPO',
		},
		{
			codigo: 37,
			descripcion: 'TAMAZUNCHALE',
		},
		{
			codigo: 38,
			descripcion: 'TAMPACÁN',
		},
		{
			codigo: 39,
			descripcion: 'TAMPAMOLÓN CORONA',
		},
		{
			codigo: 40,
			descripcion: 'TAMUÍN',
		},
		{
			codigo: 41,
			descripcion: 'TANLAJÁS',
		},
		{
			codigo: 42,
			descripcion: 'TANQUIÁN DE ESCOBEDO',
		},
		{
			codigo: 43,
			descripcion: 'TIERRA NUEVA',
		},
		{
			codigo: 44,
			descripcion: 'VANEGAS',
		},
		{
			codigo: 45,
			descripcion: 'VENADO',
		},
		{
			codigo: 46,
			descripcion: 'VILLA DE ARRIAGA',
		},
		{
			codigo: 47,
			descripcion: 'VILLA DE GUADALUPE',
		},
		{
			codigo: 48,
			descripcion: 'VILLA DE LA PAZ',
		},
		{
			codigo: 49,
			descripcion: 'VILLA DE RAMOS',
		},
		{
			codigo: 50,
			descripcion: 'VILLA DE REYES',
		},
		{
			codigo: 51,
			descripcion: 'VILLA HIDALGO',
		},
		{
			codigo: 52,
			descripcion: 'VILLA JUÁREZ',
		},
		{
			codigo: 53,
			descripcion: 'AXTLA DE TERRAZAS',
		},
		{
			codigo: 54,
			descripcion: 'XILITLA',
		},
		{
			codigo: 55,
			descripcion: 'ZARAGOZA',
		},
		{
			codigo: 56,
			descripcion: 'VILLA DE ARISTA',
		},
	],
	25: [
		{
			codigo: 1,
			descripcion: 'AHOME',
		},
		{
			codigo: 2,
			descripcion: 'ANGOSTURA',
		},
		{
			codigo: 3,
			descripcion: 'BADIRAGUATO',
		},
		{
			codigo: 4,
			descripcion: 'CONCORDIA',
		},
		{
			codigo: 5,
			descripcion: 'COSALÁ',
		},
		{
			codigo: 6,
			descripcion: 'CULIACÁN',
		},
		{
			codigo: 7,
			descripcion: 'CHOIX',
		},
		{
			codigo: 8,
			descripcion: 'ELOTA',
		},
		{
			codigo: 9,
			descripcion: 'ESCUINAPA',
		},
		{
			codigo: 10,
			descripcion: 'EL FUERTE',
		},
		{
			codigo: 11,
			descripcion: 'GUASAVE',
		},
		{
			codigo: 12,
			descripcion: 'MAZATLÁN',
		},
		{
			codigo: 13,
			descripcion: 'MOCORITO',
		},
		{
			codigo: 14,
			descripcion: 'ROSARIO',
		},
		{
			codigo: 15,
			descripcion: 'SALVADOR ALVARADO',
		},
		{
			codigo: 16,
			descripcion: 'SAN IGNACIO',
		},
		{
			codigo: 17,
			descripcion: 'SINALOA',
		},
		{
			codigo: 18,
			descripcion: 'NAVOLATO',
		},
	],
	26: [
		{
			codigo: 1,
			descripcion: 'ACONCHI',
		},
		{
			codigo: 2,
			descripcion: 'AGUA PRIETA',
		},
		{
			codigo: 3,
			descripcion: 'ALAMOS',
		},
		{
			codigo: 4,
			descripcion: 'ALTAR',
		},
		{
			codigo: 5,
			descripcion: 'ARIVECHI',
		},
		{
			codigo: 6,
			descripcion: 'ARIZPE',
		},
		{
			codigo: 7,
			descripcion: 'ATIL',
		},
		{
			codigo: 8,
			descripcion: 'BACADÉHUACHI',
		},
		{
			codigo: 9,
			descripcion: 'BACANORA',
		},
		{
			codigo: 10,
			descripcion: 'BACERAC',
		},
		{
			codigo: 11,
			descripcion: 'BACOACHI',
		},
		{
			codigo: 12,
			descripcion: 'BÁCUM',
		},
		{
			codigo: 13,
			descripcion: 'BANÁMICHI',
		},
		{
			codigo: 14,
			descripcion: 'BAVIÁCORA',
		},
		{
			codigo: 15,
			descripcion: 'BAVISPE',
		},
		{
			codigo: 16,
			descripcion: 'BENJAMÍN HILL',
		},
		{
			codigo: 17,
			descripcion: 'CABORCA',
		},
		{
			codigo: 18,
			descripcion: 'CAJEME',
		},
		{
			codigo: 19,
			descripcion: 'CANANEA',
		},
		{
			codigo: 20,
			descripcion: 'CARBÓ',
		},
		{
			codigo: 21,
			descripcion: 'LA COLORADA',
		},
		{
			codigo: 22,
			descripcion: 'CUCURPE',
		},
		{
			codigo: 23,
			descripcion: 'CUMPAS',
		},
		{
			codigo: 24,
			descripcion: 'DIVISADEROS',
		},
		{
			codigo: 25,
			descripcion: 'EMPALME',
		},
		{
			codigo: 26,
			descripcion: 'ETCHOJOA',
		},
		{
			codigo: 27,
			descripcion: 'FRONTERAS',
		},
		{
			codigo: 28,
			descripcion: 'GRANADOS',
		},
		{
			codigo: 29,
			descripcion: 'GUAYMAS',
		},
		{
			codigo: 30,
			descripcion: 'HERMOSILLO',
		},
		{
			codigo: 31,
			descripcion: 'HUACHINERA',
		},
		{
			codigo: 32,
			descripcion: 'HUÁSABAS',
		},
		{
			codigo: 33,
			descripcion: 'HUATABAMPO',
		},
		{
			codigo: 34,
			descripcion: 'HUÉPAC',
		},
		{
			codigo: 35,
			descripcion: 'IMURIS',
		},
		{
			codigo: 36,
			descripcion: 'MAGDALENA',
		},
		{
			codigo: 37,
			descripcion: 'MAZATÁN',
		},
		{
			codigo: 38,
			descripcion: 'MOCTEZUMA',
		},
		{
			codigo: 39,
			descripcion: 'NACO',
		},
		{
			codigo: 40,
			descripcion: 'NÁCORI CHICO',
		},
		{
			codigo: 41,
			descripcion: 'NACOZARI DE GARCÍA',
		},
		{
			codigo: 42,
			descripcion: 'NAVOJOA',
		},
		{
			codigo: 43,
			descripcion: 'NOGALES',
		},
		{
			codigo: 44,
			descripcion: 'ONAVAS',
		},
		{
			codigo: 45,
			descripcion: 'OPODEPE',
		},
		{
			codigo: 46,
			descripcion: 'OQUITOA',
		},
		{
			codigo: 47,
			descripcion: 'PITIQUITO',
		},
		{
			codigo: 48,
			descripcion: 'PUERTO PEÑASCO',
		},
		{
			codigo: 49,
			descripcion: 'QUIRIEGO',
		},
		{
			codigo: 50,
			descripcion: 'RAYÓN',
		},
		{
			codigo: 51,
			descripcion: 'ROSARIO',
		},
		{
			codigo: 52,
			descripcion: 'SAHUARIPA',
		},
		{
			codigo: 53,
			descripcion: 'SAN FELIPE DE JESÚS',
		},
		{
			codigo: 54,
			descripcion: 'SAN JAVIER',
		},
		{
			codigo: 55,
			descripcion: 'SAN LUIS RÍO COLORADO',
		},
		{
			codigo: 56,
			descripcion: 'SAN MIGUEL DE HORCASITAS',
		},
		{
			codigo: 57,
			descripcion: 'SAN PEDRO DE LA CUEVA',
		},
		{
			codigo: 58,
			descripcion: 'SANTA ANA',
		},
		{
			codigo: 59,
			descripcion: 'SANTA CRUZ',
		},
		{
			codigo: 60,
			descripcion: 'SÁRIC',
		},
		{
			codigo: 61,
			descripcion: 'SOYOPA',
		},
		{
			codigo: 62,
			descripcion: 'SUAQUI GRANDE',
		},
		{
			codigo: 63,
			descripcion: 'TEPACHE',
		},
		{
			codigo: 64,
			descripcion: 'TRINCHERAS',
		},
		{
			codigo: 65,
			descripcion: 'TUBUTAMA',
		},
		{
			codigo: 66,
			descripcion: 'URES',
		},
		{
			codigo: 67,
			descripcion: 'VILLA HIDALGO',
		},
		{
			codigo: 68,
			descripcion: 'VILLA PESQUEIRA',
		},
		{
			codigo: 69,
			descripcion: 'YÉCORA',
		},
		{
			codigo: 70,
			descripcion: 'GENERAL PLUTARCO ELÍAS CALLES',
		},
	],
	27: [
		{
			codigo: 1,
			descripcion: 'BALANCÁN',
		},
		{
			codigo: 2,
			descripcion: 'CÁRDENAS',
		},
		{
			codigo: 3,
			descripcion: 'CENTLA',
		},
		{
			codigo: 4,
			descripcion: 'CENTRO VILLAHERMOSA',
		},
		{
			codigo: 5,
			descripcion: 'COMALCALCO',
		},
		{
			codigo: 6,
			descripcion: 'CUNDUACÁN',
		},
		{
			codigo: 7,
			descripcion: 'EMILIANO ZAPATA',
		},
		{
			codigo: 8,
			descripcion: 'HUIMANGUILLO',
		},
		{
			codigo: 9,
			descripcion: 'JALAPA',
		},
		{
			codigo: 10,
			descripcion: 'JALPA DE MÉNDEZ',
		},
		{
			codigo: 11,
			descripcion: 'JONUTA',
		},
		{
			codigo: 12,
			descripcion: 'MACUSPANA',
		},
		{
			codigo: 13,
			descripcion: 'NACAJUCA',
		},
		{
			codigo: 14,
			descripcion: 'PARAÍSO',
		},
		{
			codigo: 15,
			descripcion: 'TACOTALPA',
		},
		{
			codigo: 16,
			descripcion: 'TEAPA',
		},
		{
			codigo: 17,
			descripcion: 'TENOSIQUE',
		},
	],
	28: [
		{
			codigo: 1,
			descripcion: 'ABASOLO',
		},
		{
			codigo: 2,
			descripcion: 'ALDAMA',
		},
		{
			codigo: 3,
			descripcion: 'ALTAMIRA',
		},
		{
			codigo: 4,
			descripcion: 'ANTIGUO MORELOS',
		},
		{
			codigo: 5,
			descripcion: 'BURGOS',
		},
		{
			codigo: 6,
			descripcion: 'BUSTAMANTE',
		},
		{
			codigo: 7,
			descripcion: 'CAMARGO',
		},
		{
			codigo: 8,
			descripcion: 'CASAS',
		},
		{
			codigo: 9,
			descripcion: 'CIUDAD MADERO',
		},
		{
			codigo: 10,
			descripcion: 'CRUILLAS',
		},
		{
			codigo: 11,
			descripcion: 'GÓMEZ FARÍAS',
		},
		{
			codigo: 12,
			descripcion: 'GONZÁLEZ',
		},
		{
			codigo: 13,
			descripcion: 'GÜÉMEZ',
		},
		{
			codigo: 14,
			descripcion: 'GUERRERO',
		},
		{
			codigo: 15,
			descripcion: 'GUSTAVO DÍAZ ORDAZ',
		},
		{
			codigo: 16,
			descripcion: 'HIDALGO',
		},
		{
			codigo: 17,
			descripcion: 'JAUMAVE',
		},
		{
			codigo: 18,
			descripcion: 'JIMÉNEZ',
		},
		{
			codigo: 19,
			descripcion: 'LLERA',
		},
		{
			codigo: 20,
			descripcion: 'MAINERO',
		},
		{
			codigo: 21,
			descripcion: 'EL MANTE',
		},
		{
			codigo: 22,
			descripcion: 'MATAMOROS',
		},
		{
			codigo: 23,
			descripcion: 'MÉNDEZ',
		},
		{
			codigo: 24,
			descripcion: 'MIER',
		},
		{
			codigo: 25,
			descripcion: 'MIGUEL ALEMÁN',
		},
		{
			codigo: 26,
			descripcion: 'MIQUIHUANA',
		},
		{
			codigo: 27,
			descripcion: 'NUEVO LAREDO',
		},
		{
			codigo: 28,
			descripcion: 'NUEVO MORELOS',
		},
		{
			codigo: 29,
			descripcion: 'OCAMPO',
		},
		{
			codigo: 30,
			descripcion: 'PADILLA',
		},
		{
			codigo: 31,
			descripcion: 'PALMILLAS',
		},
		{
			codigo: 32,
			descripcion: 'REYNOSA',
		},
		{
			codigo: 33,
			descripcion: 'RÍO BRAVO',
		},
		{
			codigo: 34,
			descripcion: 'SAN CARLOS',
		},
		{
			codigo: 35,
			descripcion: 'SAN FERNANDO',
		},
		{
			codigo: 36,
			descripcion: 'SAN NICOLÁS',
		},
		{
			codigo: 37,
			descripcion: 'SOTO LA MARINA',
		},
		{
			codigo: 38,
			descripcion: 'TAMPICO',
		},
		{
			codigo: 39,
			descripcion: 'TULA',
		},
		{
			codigo: 40,
			descripcion: 'VALLE HERMOSO',
		},
		{
			codigo: 41,
			descripcion: 'VICTORIA',
		},
		{
			codigo: 42,
			descripcion: 'VILLAGRÁN',
		},
		{
			codigo: 43,
			descripcion: 'XICOTÉNCATL',
		},
	],
	29: [
		{
			codigo: 1,
			descripcion: 'AMAXAC DE GUERRERO',
		},
		{
			codigo: 2,
			descripcion: 'APETATITLÁN DE ANTONIO CARVAJAL',
		},
		{
			codigo: 3,
			descripcion: 'ATLANGATEPEC',
		},
		{
			codigo: 4,
			descripcion: 'ATLTZAYANCA',
		},
		{
			codigo: 5,
			descripcion: 'APIZACO',
		},
		{
			codigo: 6,
			descripcion: 'CALPULALPAN',
		},
		{
			codigo: 7,
			descripcion: 'EL CARMEN TEQUEXQUITLA',
		},
		{
			codigo: 8,
			descripcion: 'CUAPIAXTLA',
		},
		{
			codigo: 9,
			descripcion: 'CUAXOMULCO',
		},
		{
			codigo: 10,
			descripcion: 'CHIAUTEMPAN',
		},
		{
			codigo: 11,
			descripcion: 'MUÑOZ DE DOMINGO ARENAS',
		},
		{
			codigo: 12,
			descripcion: 'ESPAÑITA',
		},
		{
			codigo: 13,
			descripcion: 'HUAMANTLA',
		},
		{
			codigo: 14,
			descripcion: 'HUEYOTLIPAN',
		},
		{
			codigo: 15,
			descripcion: 'IXTACUIXTLA DE MARIANO MATAMOROS',
		},
		{
			codigo: 16,
			descripcion: 'IXTENCO',
		},
		{
			codigo: 17,
			descripcion: 'MAZATECOCHCO DE JOSÉ MARÍA MORELOS',
		},
		{
			codigo: 18,
			descripcion: 'CONTLA DE JUAN CUAMATZI',
		},
		{
			codigo: 19,
			descripcion: 'TEPETITLA DE LARDIZÁBAL',
		},
		{
			codigo: 20,
			descripcion: 'SANCTÓRUM DE LÁZARO CÁRDENAS',
		},
		{
			codigo: 21,
			descripcion: 'NANACAMILPA DE MARIANO ARISTA',
		},
		{
			codigo: 22,
			descripcion: 'ACUAMANALA DE MIGUEL HIDALGO',
		},
		{
			codigo: 23,
			descripcion: 'NATÍVITAS',
		},
		{
			codigo: 24,
			descripcion: 'PANOTLA',
		},
		{
			codigo: 25,
			descripcion: 'SAN PABLO DEL MONTE',
		},
		{
			codigo: 26,
			descripcion: 'SANTA CRUZ TLAXCALA',
		},
		{
			codigo: 27,
			descripcion: 'TENANCINGO',
		},
		{
			codigo: 28,
			descripcion: 'TEOLOCHOLCO',
		},
		{
			codigo: 29,
			descripcion: 'TEPEYANCO',
		},
		{
			codigo: 30,
			descripcion: 'TERRENATE',
		},
		{
			codigo: 31,
			descripcion: 'TETLA',
		},
		{
			codigo: 32,
			descripcion: 'TETLATLAHUCA',
		},
		{
			codigo: 33,
			descripcion: 'TLAXCALA',
		},
		{
			codigo: 34,
			descripcion: 'TLAXCO',
		},
		{
			codigo: 35,
			descripcion: 'TOCATLÁN',
		},
		{
			codigo: 36,
			descripcion: 'TOTOLAC',
		},
		{
			codigo: 37,
			descripcion: 'ZILTLALTÉPEC DE TRINIDAD SÁNCHEZ SANTOS',
		},
		{
			codigo: 38,
			descripcion: 'TZOMPANTEPEC',
		},
		{
			codigo: 39,
			descripcion: 'XALOZTOC',
		},
		{
			codigo: 40,
			descripcion: 'XALTOCAN',
		},
		{
			codigo: 41,
			descripcion: 'PAPALOTLA DE XICOHTÉNCATL',
		},
		{
			codigo: 42,
			descripcion: 'XICOHTZINCO',
		},
		{
			codigo: 43,
			descripcion: 'YAUHQUEMEHCAN',
		},
		{
			codigo: 44,
			descripcion: 'ZACATELCO',
		},
	],
	30: [
		{
			codigo: 1,
			descripcion: 'ACAJETE',
		},
		{
			codigo: 2,
			descripcion: 'ACATLÁN',
		},
		{
			codigo: 3,
			descripcion: 'ACAYUCAN',
		},
		{
			codigo: 4,
			descripcion: 'ACTOPAN',
		},
		{
			codigo: 5,
			descripcion: 'ACULA',
		},
		{
			codigo: 6,
			descripcion: 'ACULTZINGO',
		},
		{
			codigo: 7,
			descripcion: 'CAMARÓN DE TEJEDA',
		},
		{
			codigo: 8,
			descripcion: 'ALPATLÁHUAC',
		},
		{
			codigo: 9,
			descripcion: 'ALTO LUCERO',
		},
		{
			codigo: 10,
			descripcion: 'ALTOTONGA',
		},
		{
			codigo: 11,
			descripcion: 'ALVARADO',
		},
		{
			codigo: 12,
			descripcion: 'AMATITLÁN',
		},
		{
			codigo: 13,
			descripcion: 'AMATLÁN TUXPAN',
		},
		{
			codigo: 14,
			descripcion: 'AMATLÁN DE LOS REYES',
		},
		{
			codigo: 15,
			descripcion: 'ANGEL R. CABADA',
		},
		{
			codigo: 16,
			descripcion: 'LA ANTIGUA',
		},
		{
			codigo: 17,
			descripcion: 'APAZAPAN',
		},
		{
			codigo: 18,
			descripcion: 'AQUILA',
		},
		{
			codigo: 19,
			descripcion: 'ASTACINGA',
		},
		{
			codigo: 20,
			descripcion: 'ATLAHUILCO',
		},
		{
			codigo: 21,
			descripcion: 'ATOYAC',
		},
		{
			codigo: 22,
			descripcion: 'ATZACAN',
		},
		{
			codigo: 23,
			descripcion: 'ATZALAN',
		},
		{
			codigo: 24,
			descripcion: 'TLALTETELA',
		},
		{
			codigo: 25,
			descripcion: 'AYAHUALULCO',
		},
		{
			codigo: 26,
			descripcion: 'BANDERILLA',
		},
		{
			codigo: 27,
			descripcion: 'BENITO JUÁREZ',
		},
		{
			codigo: 28,
			descripcion: 'BOCA DEL RÍO',
		},
		{
			codigo: 29,
			descripcion: 'CALCAHUALCO',
		},
		{
			codigo: 30,
			descripcion: 'CAMERINO Z. MENDOZA',
		},
		{
			codigo: 31,
			descripcion: 'CARRILLO PUERTO',
		},
		{
			codigo: 32,
			descripcion: 'CATEMACO',
		},
		{
			codigo: 33,
			descripcion: 'CAZONES DE HERRERA',
		},
		{
			codigo: 34,
			descripcion: 'CERRO AZUL',
		},
		{
			codigo: 35,
			descripcion: 'CITLALTÉPETL',
		},
		{
			codigo: 36,
			descripcion: 'COACOATZINTLA',
		},
		{
			codigo: 37,
			descripcion: 'COAHUITLÁN',
		},
		{
			codigo: 38,
			descripcion: 'COATEPEC',
		},
		{
			codigo: 39,
			descripcion: 'COATZACOALCOS',
		},
		{
			codigo: 40,
			descripcion: 'COATZINTLA',
		},
		{
			codigo: 41,
			descripcion: 'COETZALA',
		},
		{
			codigo: 42,
			descripcion: 'COLIPA',
		},
		{
			codigo: 43,
			descripcion: 'COMAPA',
		},
		{
			codigo: 44,
			descripcion: 'CÓRDOBA',
		},
		{
			codigo: 45,
			descripcion: 'COSAMALOAPAN',
		},
		{
			codigo: 46,
			descripcion: 'COSAUTLÁN DE CARVAJAL',
		},
		{
			codigo: 47,
			descripcion: 'COSCOMATEPEC',
		},
		{
			codigo: 48,
			descripcion: 'COSOLEACAQUE',
		},
		{
			codigo: 49,
			descripcion: 'COTAXTLA',
		},
		{
			codigo: 50,
			descripcion: 'COXQUIHUI',
		},
		{
			codigo: 51,
			descripcion: 'COYUTLA',
		},
		{
			codigo: 52,
			descripcion: 'CUICHAPA',
		},
		{
			codigo: 53,
			descripcion: 'CUITLÁHUAC',
		},
		{
			codigo: 54,
			descripcion: 'CHACALTIANGUIS',
		},
		{
			codigo: 55,
			descripcion: 'CHALMA',
		},
		{
			codigo: 56,
			descripcion: 'CHICONAMEL',
		},
		{
			codigo: 57,
			descripcion: 'CHICONQUIACO',
		},
		{
			codigo: 58,
			descripcion: 'CHICONTEPEC',
		},
		{
			codigo: 59,
			descripcion: 'CHINAMECA',
		},
		{
			codigo: 60,
			descripcion: 'CHINAMPA DE GOROSTIZA',
		},
		{
			codigo: 61,
			descripcion: 'LAS CHOAPAS',
		},
		{
			codigo: 62,
			descripcion: 'CHOCAMÁN',
		},
		{
			codigo: 63,
			descripcion: 'CHONTLA',
		},
		{
			codigo: 64,
			descripcion: 'CHUMATLÁN',
		},
		{
			codigo: 65,
			descripcion: 'EMILIANO ZAPATA',
		},
		{
			codigo: 66,
			descripcion: 'ESPINAL',
		},
		{
			codigo: 67,
			descripcion: 'FILOMENO MATA',
		},
		{
			codigo: 68,
			descripcion: 'FORTÍN',
		},
		{
			codigo: 69,
			descripcion: 'GUTIÉRREZ ZAMORA',
		},
		{
			codigo: 70,
			descripcion: 'HIDALGOTITLÁN',
		},
		{
			codigo: 71,
			descripcion: 'HUATUSCO',
		},
		{
			codigo: 72,
			descripcion: 'HUAYACOCOTLA',
		},
		{
			codigo: 73,
			descripcion: 'HUEYAPAN DE OCAMPO',
		},
		{
			codigo: 74,
			descripcion: 'HUILOAPAN DE CUAUHTÉMOC',
		},
		{
			codigo: 75,
			descripcion: 'IGNACIO DE LA LLAVE',
		},
		{
			codigo: 76,
			descripcion: 'ILAMATLÁN',
		},
		{
			codigo: 77,
			descripcion: 'ISLA',
		},
		{
			codigo: 78,
			descripcion: 'IXCATEPEC',
		},
		{
			codigo: 79,
			descripcion: 'IXHUACÁN DE LOS REYES',
		},
		{
			codigo: 80,
			descripcion: 'IXHUATLÁN DEL CAFÉ',
		},
		{
			codigo: 81,
			descripcion: 'IXHUATLANCILLO',
		},
		{
			codigo: 82,
			descripcion: 'IXHUATLÁN DEL SURESTE',
		},
		{
			codigo: 83,
			descripcion: 'IXHUATLÁN DE MADERO',
		},
		{
			codigo: 84,
			descripcion: 'IXMATLAHUACAN',
		},
		{
			codigo: 85,
			descripcion: 'IXTACZOQUITLÁN',
		},
		{
			codigo: 86,
			descripcion: 'JALACINGO',
		},
		{
			codigo: 87,
			descripcion: 'XALAPA',
		},
		{
			codigo: 88,
			descripcion: 'JALCOMULCO',
		},
		{
			codigo: 89,
			descripcion: 'JÁLTIPAN',
		},
		{
			codigo: 90,
			descripcion: 'JAMAPA',
		},
		{
			codigo: 91,
			descripcion: 'JESÚS CARRANZA',
		},
		{
			codigo: 92,
			descripcion: 'XICO',
		},
		{
			codigo: 93,
			descripcion: 'JILOTEPEC',
		},
		{
			codigo: 94,
			descripcion: 'JUAN RODRÍGUEZ CLARA',
		},
		{
			codigo: 95,
			descripcion: 'JUCHIQUE DE FERRER',
		},
		{
			codigo: 96,
			descripcion: 'LANDERO Y COSS',
		},
		{
			codigo: 97,
			descripcion: 'LERDO DE TEJADA',
		},
		{
			codigo: 98,
			descripcion: 'MAGDALENA',
		},
		{
			codigo: 99,
			descripcion: 'MALTRATA',
		},
		{
			codigo: 100,
			descripcion: 'MANLIO FABIO ALTAMIRANO',
		},
		{
			codigo: 101,
			descripcion: 'MARIANO ESCOBEDO',
		},
		{
			codigo: 102,
			descripcion: 'MARTÍNEZ DE LA TORRE',
		},
		{
			codigo: 103,
			descripcion: 'MECATLÁN',
		},
		{
			codigo: 104,
			descripcion: 'MECAYAPAN',
		},
		{
			codigo: 105,
			descripcion: 'MEDELLÍN',
		},
		{
			codigo: 106,
			descripcion: 'MIAHUATLÁN',
		},
		{
			codigo: 107,
			descripcion: 'LAS MINAS',
		},
		{
			codigo: 108,
			descripcion: 'MINATITLÁN',
		},
		{
			codigo: 109,
			descripcion: 'MISANTLA',
		},
		{
			codigo: 110,
			descripcion: 'MIXTLA DE ALTAMIRANO',
		},
		{
			codigo: 111,
			descripcion: 'MOLOACÁN',
		},
		{
			codigo: 112,
			descripcion: 'NAOLINCO',
		},
		{
			codigo: 113,
			descripcion: 'NARANJAL',
		},
		{
			codigo: 114,
			descripcion: 'NAUTLA',
		},
		{
			codigo: 115,
			descripcion: 'NOGALES',
		},
		{
			codigo: 116,
			descripcion: 'OLUTA',
		},
		{
			codigo: 117,
			descripcion: 'OMEALCA',
		},
		{
			codigo: 118,
			descripcion: 'ORIZABA',
		},
		{
			codigo: 119,
			descripcion: 'OTATITLÁN',
		},
		{
			codigo: 120,
			descripcion: 'OTEAPAN',
		},
		{
			codigo: 121,
			descripcion: 'OZULUAMA DE MASCAREÑAS',
		},
		{
			codigo: 122,
			descripcion: 'PAJAPAN',
		},
		{
			codigo: 123,
			descripcion: 'PÁNUCO',
		},
		{
			codigo: 124,
			descripcion: 'PAPANTLA',
		},
		{
			codigo: 125,
			descripcion: 'PASO DEL MACHO',
		},
		{
			codigo: 126,
			descripcion: 'PASO DE OVEJAS',
		},
		{
			codigo: 127,
			descripcion: 'LA PERLA',
		},
		{
			codigo: 128,
			descripcion: 'PEROTE',
		},
		{
			codigo: 129,
			descripcion: 'PLATÓN SÁNCHEZ',
		},
		{
			codigo: 130,
			descripcion: 'PLAYA VICENTE',
		},
		{
			codigo: 131,
			descripcion: 'POZA RICA DE HIDALGO',
		},
		{
			codigo: 132,
			descripcion: 'LAS VIGAS DE RAMÍREZ',
		},
		{
			codigo: 133,
			descripcion: 'PUEBLO VIEJO',
		},
		{
			codigo: 134,
			descripcion: 'PUENTE NACIONAL',
		},
		{
			codigo: 135,
			descripcion: 'RAFAEL DELGADO',
		},
		{
			codigo: 136,
			descripcion: 'RAFAEL LUCIO',
		},
		{
			codigo: 137,
			descripcion: 'LOS REYES',
		},
		{
			codigo: 138,
			descripcion: 'RÍO BLANCO',
		},
		{
			codigo: 139,
			descripcion: 'SALTABARRANCA',
		},
		{
			codigo: 140,
			descripcion: 'SAN ANDRÉS TENEJAPAN',
		},
		{
			codigo: 141,
			descripcion: 'SAN ANDRÉS TUXTLA',
		},
		{
			codigo: 142,
			descripcion: 'SAN JUAN EVANGELISTA',
		},
		{
			codigo: 143,
			descripcion: 'SANTIAGO TUXTLA',
		},
		{
			codigo: 144,
			descripcion: 'SAYULA DE ALEMÁN',
		},
		{
			codigo: 145,
			descripcion: 'SOCONUSCO',
		},
		{
			codigo: 146,
			descripcion: 'SOCHIAPA',
		},
		{
			codigo: 147,
			descripcion: 'SOLEDAD ATZOMPA',
		},
		{
			codigo: 148,
			descripcion: 'SOLEDAD DE DOBLADO',
		},
		{
			codigo: 149,
			descripcion: 'SOTEAPAN',
		},
		{
			codigo: 150,
			descripcion: 'TAMALÍN',
		},
		{
			codigo: 151,
			descripcion: 'TAMIAHUA',
		},
		{
			codigo: 152,
			descripcion: 'TAMPICO ALTO',
		},
		{
			codigo: 153,
			descripcion: 'TANCOCO',
		},
		{
			codigo: 154,
			descripcion: 'TANTIMA',
		},
		{
			codigo: 155,
			descripcion: 'TANTOYUCA',
		},
		{
			codigo: 156,
			descripcion: 'TATATILA',
		},
		{
			codigo: 157,
			descripcion: 'CASTILLO DE TEAYO',
		},
		{
			codigo: 158,
			descripcion: 'TECOLUTLA',
		},
		{
			codigo: 159,
			descripcion: 'TEHUIPANGO',
		},
		{
			codigo: 160,
			descripcion: 'TEMAPACHE',
		},
		{
			codigo: 161,
			descripcion: 'TEMPOAL',
		},
		{
			codigo: 162,
			descripcion: 'TENAMPA',
		},
		{
			codigo: 163,
			descripcion: 'TENOCHTITLÁN',
		},
		{
			codigo: 164,
			descripcion: 'TEOCELO',
		},
		{
			codigo: 165,
			descripcion: 'TEPATLAXCO',
		},
		{
			codigo: 166,
			descripcion: 'TEPETLÁN',
		},
		{
			codigo: 167,
			descripcion: 'TEPETZINTLA',
		},
		{
			codigo: 168,
			descripcion: 'TEQUILA',
		},
		{
			codigo: 169,
			descripcion: 'JOSÉ AZUETA',
		},
		{
			codigo: 170,
			descripcion: 'TEXCATEPEC',
		},
		{
			codigo: 171,
			descripcion: 'TEXHUACÁN',
		},
		{
			codigo: 172,
			descripcion: 'TEXISTEPEC',
		},
		{
			codigo: 173,
			descripcion: 'TEZONAPA',
		},
		{
			codigo: 174,
			descripcion: 'TIERRA BLANCA',
		},
		{
			codigo: 175,
			descripcion: 'TIHUATLÁN',
		},
		{
			codigo: 176,
			descripcion: 'TLACOJALPAN',
		},
		{
			codigo: 177,
			descripcion: 'TLACOLULAN',
		},
		{
			codigo: 178,
			descripcion: 'TLACOTALPAN',
		},
		{
			codigo: 179,
			descripcion: 'TLACOTEPEC DE MEJÍA',
		},
		{
			codigo: 180,
			descripcion: 'TLACHICHILCO',
		},
		{
			codigo: 181,
			descripcion: 'TLALIXCOYAN',
		},
		{
			codigo: 182,
			descripcion: 'TLALNELHUAYOCAN',
		},
		{
			codigo: 183,
			descripcion: 'TLAPACOYAN',
		},
		{
			codigo: 184,
			descripcion: 'TLAQUILPA',
		},
		{
			codigo: 185,
			descripcion: 'TLILAPAN',
		},
		{
			codigo: 186,
			descripcion: 'TOMATLÁN',
		},
		{
			codigo: 187,
			descripcion: 'TONAYÁN',
		},
		{
			codigo: 188,
			descripcion: 'TOTUTLA',
		},
		{
			codigo: 189,
			descripcion: 'TUXPAN',
		},
		{
			codigo: 190,
			descripcion: 'TUXTILLA',
		},
		{
			codigo: 191,
			descripcion: 'URSULO GALVÁN',
		},
		{
			codigo: 192,
			descripcion: 'VEGA DE ALATORRE',
		},
		{
			codigo: 193,
			descripcion: 'VERACRUZ',
		},
		{
			codigo: 194,
			descripcion: 'VILLA ALDAMA',
		},
		{
			codigo: 195,
			descripcion: 'XOXOCOTLA',
		},
		{
			codigo: 196,
			descripcion: 'YANGA',
		},
		{
			codigo: 197,
			descripcion: 'YECUATLAN',
		},
		{
			codigo: 198,
			descripcion: 'ZACUALPAN',
		},
		{
			codigo: 199,
			descripcion: 'ZARAGOZA',
		},
		{
			codigo: 200,
			descripcion: 'ZENTLA',
		},
		{
			codigo: 201,
			descripcion: 'ZONGOLICA',
		},
		{
			codigo: 202,
			descripcion: 'ZONTECOMATLÁN',
		},
		{
			codigo: 203,
			descripcion: 'ZOZOCOLCO DE HIDALGO',
		},
		{
			codigo: 204,
			descripcion: 'AGUA DULCE',
		},
		{
			codigo: 205,
			descripcion: 'EL HIGO',
		},
		{
			codigo: 206,
			descripcion: 'NANCHITAL DE LÁZARO CÁRDENAS DEL RÍO',
		},
		{
			codigo: 207,
			descripcion: 'TRES VALLES',
		},
	],
	31: [
		{
			codigo: 1,
			descripcion: 'ABALÁ',
		},
		{
			codigo: 2,
			descripcion: 'ACANCEH',
		},
		{
			codigo: 3,
			descripcion: 'AKIL',
		},
		{
			codigo: 4,
			descripcion: 'BACA',
		},
		{
			codigo: 5,
			descripcion: 'BOKOBÁ',
		},
		{
			codigo: 6,
			descripcion: 'BUCTZOTZ',
		},
		{
			codigo: 7,
			descripcion: 'CACALCHÉN',
		},
		{
			codigo: 8,
			descripcion: 'CALOTMUL',
		},
		{
			codigo: 9,
			descripcion: 'CANSAHCAB',
		},
		{
			codigo: 10,
			descripcion: 'CANTAMAYEC',
		},
		{
			codigo: 11,
			descripcion: 'CELESTÚN',
		},
		{
			codigo: 12,
			descripcion: 'CENOTILLO',
		},
		{
			codigo: 13,
			descripcion: 'CONKAL',
		},
		{
			codigo: 14,
			descripcion: 'CUNCUNUL',
		},
		{
			codigo: 15,
			descripcion: 'CUZAMÁ',
		},
		{
			codigo: 16,
			descripcion: 'CHACSINKÍN',
		},
		{
			codigo: 17,
			descripcion: 'CHANKOM',
		},
		{
			codigo: 18,
			descripcion: 'CHAPAB',
		},
		{
			codigo: 19,
			descripcion: 'CHEMAX',
		},
		{
			codigo: 20,
			descripcion: 'CHICXULUB PUEBLO',
		},
		{
			codigo: 21,
			descripcion: 'CHICHIMILÁ',
		},
		{
			codigo: 22,
			descripcion: 'CHIKINDZONOT',
		},
		{
			codigo: 23,
			descripcion: 'CHOCHOLÁ',
		},
		{
			codigo: 24,
			descripcion: 'CHUMAYEL',
		},
		{
			codigo: 25,
			descripcion: 'DZÁN',
		},
		{
			codigo: 26,
			descripcion: 'DZEMUL',
		},
		{
			codigo: 27,
			descripcion: 'DZIDZANTÚN',
		},
		{
			codigo: 28,
			descripcion: 'DZILAM DE BRAVO',
		},
		{
			codigo: 29,
			descripcion: 'DZILAM GONZÁLEZ',
		},
		{
			codigo: 30,
			descripcion: 'DZITÁS',
		},
		{
			codigo: 31,
			descripcion: 'DZONCAUICH',
		},
		{
			codigo: 32,
			descripcion: 'ESPITA',
		},
		{
			codigo: 33,
			descripcion: 'HALACHÓ',
		},
		{
			codigo: 34,
			descripcion: 'HOCABÁ',
		},
		{
			codigo: 35,
			descripcion: 'HOCTÚN',
		},
		{
			codigo: 36,
			descripcion: 'HOMÚN',
		},
		{
			codigo: 37,
			descripcion: 'HUHÍ',
		},
		{
			codigo: 38,
			descripcion: 'HUNUCMÁ',
		},
		{
			codigo: 39,
			descripcion: 'IXIL',
		},
		{
			codigo: 40,
			descripcion: 'IZAMAL',
		},
		{
			codigo: 41,
			descripcion: 'KANASÍN',
		},
		{
			codigo: 42,
			descripcion: 'KANTUNIL',
		},
		{
			codigo: 43,
			descripcion: 'KAUA',
		},
		{
			codigo: 44,
			descripcion: 'KINCHIL',
		},
		{
			codigo: 45,
			descripcion: 'KOPOMÁ',
		},
		{
			codigo: 46,
			descripcion: 'MAMA',
		},
		{
			codigo: 47,
			descripcion: 'MANÍ',
		},
		{
			codigo: 48,
			descripcion: 'MAXCANÚ',
		},
		{
			codigo: 49,
			descripcion: 'MAYAPÁN',
		},
		{
			codigo: 50,
			descripcion: 'MÉRIDA',
		},
		{
			codigo: 51,
			descripcion: 'MOCOCHÁ',
		},
		{
			codigo: 52,
			descripcion: 'MOTUL',
		},
		{
			codigo: 53,
			descripcion: 'MUNA',
		},
		{
			codigo: 54,
			descripcion: 'MUXUPIP',
		},
		{
			codigo: 55,
			descripcion: 'OPICHÉN',
		},
		{
			codigo: 56,
			descripcion: 'OXKUTZCAB',
		},
		{
			codigo: 57,
			descripcion: 'PANABÁ',
		},
		{
			codigo: 58,
			descripcion: 'PETO',
		},
		{
			codigo: 59,
			descripcion: 'PROGRESO',
		},
		{
			codigo: 60,
			descripcion: 'QUINTANA ROO',
		},
		{
			codigo: 61,
			descripcion: 'RÍO LAGARTOS',
		},
		{
			codigo: 62,
			descripcion: 'SACALUM',
		},
		{
			codigo: 63,
			descripcion: 'SAMAHIL',
		},
		{
			codigo: 64,
			descripcion: 'SANAHCAT',
		},
		{
			codigo: 65,
			descripcion: 'SAN FELIPE',
		},
		{
			codigo: 66,
			descripcion: 'SANTA ELENA',
		},
		{
			codigo: 67,
			descripcion: 'SEYÉ',
		},
		{
			codigo: 68,
			descripcion: 'SINANCHÉ',
		},
		{
			codigo: 69,
			descripcion: 'SOTUTA',
		},
		{
			codigo: 70,
			descripcion: 'SUCILÁ',
		},
		{
			codigo: 71,
			descripcion: 'SUDZAL',
		},
		{
			codigo: 72,
			descripcion: 'SUMA',
		},
		{
			codigo: 73,
			descripcion: 'TAHDZIÚ',
		},
		{
			codigo: 74,
			descripcion: 'TAHMEK',
		},
		{
			codigo: 75,
			descripcion: 'TEABO',
		},
		{
			codigo: 76,
			descripcion: 'TECOH',
		},
		{
			codigo: 77,
			descripcion: 'TEKAL DE VENEGAS',
		},
		{
			codigo: 78,
			descripcion: 'TEKANTÓ',
		},
		{
			codigo: 79,
			descripcion: 'TEKAX',
		},
		{
			codigo: 80,
			descripcion: 'TEKIT',
		},
		{
			codigo: 81,
			descripcion: 'TEKOM',
		},
		{
			codigo: 82,
			descripcion: 'TELCHAC PUEBLO',
		},
		{
			codigo: 83,
			descripcion: 'TELCHAC PUERTO',
		},
		{
			codigo: 84,
			descripcion: 'TEMAX',
		},
		{
			codigo: 85,
			descripcion: 'TEMOZÓN',
		},
		{
			codigo: 86,
			descripcion: 'TEPAKÁN',
		},
		{
			codigo: 87,
			descripcion: 'TETIZ',
		},
		{
			codigo: 88,
			descripcion: 'TEYA',
		},
		{
			codigo: 89,
			descripcion: 'TICUL',
		},
		{
			codigo: 90,
			descripcion: 'TIMUCUY',
		},
		{
			codigo: 91,
			descripcion: 'TINUM',
		},
		{
			codigo: 92,
			descripcion: 'TIXCACALCUPUL',
		},
		{
			codigo: 93,
			descripcion: 'TIXKOKOB',
		},
		{
			codigo: 94,
			descripcion: 'TIXMEHUAC',
		},
		{
			codigo: 95,
			descripcion: 'TIXPÉHUAL',
		},
		{
			codigo: 96,
			descripcion: 'TIZIMÍN',
		},
		{
			codigo: 97,
			descripcion: 'TUNKÁS',
		},
		{
			codigo: 98,
			descripcion: 'TZUCACAB',
		},
		{
			codigo: 99,
			descripcion: 'UAYMA',
		},
		{
			codigo: 100,
			descripcion: 'UCÚ',
		},
		{
			codigo: 101,
			descripcion: 'UMÁN',
		},
		{
			codigo: 102,
			descripcion: 'VALLADOLID',
		},
		{
			codigo: 103,
			descripcion: 'XOCCHEL',
		},
		{
			codigo: 104,
			descripcion: 'YAXCABÁ',
		},
		{
			codigo: 105,
			descripcion: 'YAXKUKUL',
		},
		{
			codigo: 106,
			descripcion: 'YOBAÍN',
		},
	],
	32: [
		{
			codigo: 1,
			descripcion: 'APOZOL',
		},
		{
			codigo: 2,
			descripcion: 'APULCO',
		},
		{
			codigo: 3,
			descripcion: 'ATOLINGA',
		},
		{
			codigo: 4,
			descripcion: 'BENITO JUÁREZ',
		},
		{
			codigo: 5,
			descripcion: 'CALERA',
		},
		{
			codigo: 6,
			descripcion: 'CAÑITAS DE FELIPE PESCADOR',
		},
		{
			codigo: 7,
			descripcion: 'CONCEPCIÓN DEL ORO',
		},
		{
			codigo: 8,
			descripcion: 'CUAUHTÉMOC',
		},
		{
			codigo: 9,
			descripcion: 'CHALCHIHUITES',
		},
		{
			codigo: 10,
			descripcion: 'FRESNILLO',
		},
		{
			codigo: 11,
			descripcion: 'TRINIDAD GARCÍA DE LA CADENA',
		},
		{
			codigo: 12,
			descripcion: 'GENARO CODINA',
		},
		{
			codigo: 13,
			descripcion: 'GENERAL ENRIQUE ESTRADA',
		},
		{
			codigo: 14,
			descripcion: 'GENERAL FRANCISCO R. MURGUÍA',
		},
		{
			codigo: 15,
			descripcion: 'EL PLATEADO DE JOAQUÍN AMARO',
		},
		{
			codigo: 16,
			descripcion: 'GENERAL PÁNFILO NATERA',
		},
		{
			codigo: 17,
			descripcion: 'GUADALUPE',
		},
		{
			codigo: 18,
			descripcion: 'HUANUSCO',
		},
		{
			codigo: 19,
			descripcion: 'JALPA',
		},
		{
			codigo: 20,
			descripcion: 'JEREZ',
		},
		{
			codigo: 21,
			descripcion: 'JIMÉNEZ DEL TEUL',
		},
		{
			codigo: 22,
			descripcion: 'JUAN ALDAMA',
		},
		{
			codigo: 23,
			descripcion: 'JUCHIPILA',
		},
		{
			codigo: 24,
			descripcion: 'LORETO',
		},
		{
			codigo: 25,
			descripcion: 'LUIS MOYA',
		},
		{
			codigo: 26,
			descripcion: 'MAZAPIL',
		},
		{
			codigo: 27,
			descripcion: 'MELCHOR OCAMPO',
		},
		{
			codigo: 28,
			descripcion: 'MEZQUITAL DEL ORO',
		},
		{
			codigo: 29,
			descripcion: 'MIGUEL AUZA',
		},
		{
			codigo: 30,
			descripcion: 'MOMAX',
		},
		{
			codigo: 31,
			descripcion: 'MONTE ESCOBEDO',
		},
		{
			codigo: 32,
			descripcion: 'MORELOS',
		},
		{
			codigo: 33,
			descripcion: 'MOYAHUA DE ESTRADA',
		},
		{
			codigo: 34,
			descripcion: 'NOCHISTLÁN DE MEJÍA',
		},
		{
			codigo: 35,
			descripcion: 'NORIA DE ÁNGELES',
		},
		{
			codigo: 36,
			descripcion: 'OJOCALIENTE',
		},
		{
			codigo: 37,
			descripcion: 'PÁNUCO',
		},
		{
			codigo: 38,
			descripcion: 'PINOS',
		},
		{
			codigo: 39,
			descripcion: 'RÍO GRANDE',
		},
		{
			codigo: 40,
			descripcion: 'SAIN ALTO',
		},
		{
			codigo: 41,
			descripcion: 'EL SALVADOR',
		},
		{
			codigo: 42,
			descripcion: 'SOMBRERETE',
		},
		{
			codigo: 43,
			descripcion: 'SUSTICACÁN',
		},
		{
			codigo: 44,
			descripcion: 'TABASCO',
		},
		{
			codigo: 45,
			descripcion: 'TEPECHITLÁN',
		},
		{
			codigo: 46,
			descripcion: 'TEPETONGO',
		},
		{
			codigo: 47,
			descripcion: 'TEÚL DE GONZÁLEZ ORTEGA',
		},
		{
			codigo: 48,
			descripcion: 'TLALTENANGO DE SÁNCHEZ ROMÁN',
		},
		{
			codigo: 49,
			descripcion: 'VALPARAÍSO',
		},
		{
			codigo: 50,
			descripcion: 'VETAGRANDE',
		},
		{
			codigo: 51,
			descripcion: 'VILLA DE COS',
		},
		{
			codigo: 52,
			descripcion: 'VILLA GARCÍA',
		},
		{
			codigo: 53,
			descripcion: 'VILLA GONZÁLEZ ORTEGA',
		},
		{
			codigo: 54,
			descripcion: 'VILLA HIDALGO',
		},
		{
			codigo: 55,
			descripcion: 'VILLANUEVA',
		},
		{
			codigo: 56,
			descripcion: 'ZACATECAS',
		},
	],
};
