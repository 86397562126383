// in ./updateConfirmado.js
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { crudUpdateMany } from 'react-admin';
import URL from '../../../url';

class ResetViewsButton extends Component {
    state = {
      data: {},
    }

    componentDidMount() {
      const agrupamiento = localStorage.getItem('empresa');
      const sucursal = localStorage.getItem('sucursal');
      fetch(`${URL()}/epe/total/${agrupamiento}/${sucursal}`)
        .then(response => response.json())
        .then(data => this.setState({ data }));
    }

    render() {
      const {
        data,
      } = this.state;
      return (
        <Fragment>
          <div>
          Personal registrado en SAP en esta sucursal:
            {data.sap}
          </div>
          <div>
            Empleados No Reconocidos:
            {data.enr}
          </div>
          <div>
            Empleados Reconocidos:
            {data.er}
          </div>
        </Fragment>
      );
    }
}

export default connect(undefined, { crudUpdateMany })(ResetViewsButton);
