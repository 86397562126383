import React from 'react';
import {
  Create,
  SimpleForm,
  required,
  NumberInput,
  TextInput,
  DateInput,
} from 'react-admin';

const validateTaller = [required()];

export const EmpleadoCorregidoGrupoCreate = props => (
  <Create {...props} title="Captura de Empleado">
    <SimpleForm redirect="show">
      <NumberInput
        label="No de Empleado"
        source="noEmpleado"
        validate={validateTaller}
      />
      <TextInput
        label="Nombres"
        source="nombres"
        validate={validateTaller}
      />
      <TextInput
        label="Apellido Paterno"
        source="paterno"
        validate={validateTaller}
      />
      <TextInput
        label="Apellido Materno"
        source="materno"
        validate={validateTaller}
      />
      <DateInput
        label="Fecha de Nacimiento"
        source="fechaNacimiento"
        validate={validateTaller}
      />
      <DateInput
        label="Fecha de Alta"
        source="fechaAlta"
        validate={validateTaller}
      />
    </SimpleForm>
  </Create>
);

export { EmpleadoCorregidoGrupoCreate as default };
