import React from 'react';
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  FormDataConsumer,
  TextInput,
  regex,
  RadioButtonGroupInput,
  AutocompleteInput,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Aside = () => (
  <div>
    <Paper className={styles.root} elevation={1}>
      <Typography variant="display1" component="h3">
        Instrucciones
      </Typography>
      <Typography component="p">
        CAPTURAR LOS VEHICULOS QUE SE ENCUENTRAN EN LA SUCURSAL
        INDICANDO SI ES BLINDADO O UTILITARIO Y SI TIENE UNA
        TARJETA DE GASOLINA ASIGNADA, ASI COMO SI ESTA
        ASIGNADA A UNA PERSONA O DEPARTAMENTO.
      </Typography>
    </Paper>
  </div>
);

const Vplaca = [required(), regex(/^[a-zA-Z0-9]+$/, 'Debe contener solo letras y numeros.')];
const VNoSerie = [required(), regex(/^[a-zA-Z0-9]+$/, 'Debe contener solo letras y numeros.')];
const VEconomico = [regex(/^[a-zA-Z0-9]+$/, 'Debe contener solo letras y numeros.')];
const VRequired = [required()];

const validateGasolinaEdit = (values) => {
  const errors = {};
  if (values.tieneNoEconomico === '2') {
    if (!values.noEconomico) { errors.noEconomico = ['El no. economico es requerido']; }
  }
  if (values.tieneTarjetaGasolina === '1') {
    if (!values.tarjetaGasolina) { errors.tarjetaGasolina = ['La tarjeta de gasolina es requerida']; }
  }
  if (values.asignado === '1') {
    if (!values.empleadoId) { errors.empleadoId = ['El empleado es requerido']; }
  }
  if (values.asignado === '2') {
    if (!values.area) { errors.area = ['El area es requerida']; }
  }
  return errors;
};

export const GasolinaEdit = props => (
  <div>
    <Aside />
    <Edit {...props} title="Captura de parque vehicular y tarjetas de gasolina">
      <SimpleForm redirect="list" validate={validateGasolinaEdit}>
        <TextInput source="placa" label="Placa" validate={Vplaca} />
        <TextInput source="noSerie" label="No. Serie del motor" validate={VNoSerie} />
        <RadioButtonGroupInput
          source="tieneNoEconomico"
          choices={[
            { id: '1', name: 'No' },
            { id: '2', name: 'Si' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tieneNoEconomico === '2'
                      && <TextInput source="noEconomico" label="No. Economico" validate={VEconomico} {...rest} />
                 }
        </FormDataConsumer>
        <RadioButtonGroupInput
          source="tipoCombustibleId"
          choices={[
            { id: '1', name: 'Gasolina' },
            { id: '2', name: 'Gas' },
            { id: '3', name: 'Diesel' },
          ]}
          validate={VRequired}
        />
        <RadioButtonGroupInput
          source="tieneTarjetaGasolina"
          choices={[
            { id: '1', name: 'Si' },
            { id: '2', name: 'No' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.tieneTarjetaGasolina === '1'
                      && <TextInput source="noTarjeta" label="Tarjeta de Gasolina Asociada" {...rest} />
                 }
        </FormDataConsumer>
        <RadioButtonGroupInput
          source="tipoVehiculoId"
          choices={[
            { id: '1', name: 'Blindado' },
            { id: '2', name: 'Utilitario' },
            { id: '4', name: 'Particular' },
          ]}
          validate={VRequired}
        />
        <SelectInput
          source="asignado"
          choices={[
            { id: '1', name: 'Persona' },
            { id: '2', name: 'Area' },
          ]}
          validate={VRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.asignado === '1'
                      && (
                      <ReferenceInput
                        label="Asignado a:"
                        source="empleadoId"
                        reference="epe"
                        filter={{ agrupamiento: localStorage.getItem('empresa'), sucursal: localStorage.getItem('sucursal') }}
                        {...rest}
                      >
                        <AutocompleteInput
                          optionText={choice => `${choice.noEmpleado} ${choice.nombres} ${choice.paterno}`}
                          optionValue="id"
                        />
                        {/* <SelectInput optionText={fullNameRenderer} /> */}
                      </ReferenceInput>
                      )
                 }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.asignado === '2'
                      && <TextInput source="area" label="Area asignada" {...rest} />
                 }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>

  </div>
);

export { GasolinaEdit as default };
